/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { directus } from "../../services/directus";
import LayoutComponent from "../../components/LayoutComponent";
import UserContext from "../../contexts/userContext";
import { MANAGER_ROLE_ID } from "../../utils/utils";
import Seo from "../../components/Seo";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import CreateComponent from "../../components/usersForm/CreateComponent";

export default function UserCreate() {
  const navigate = useNavigate();
  const [userName, setUserName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);

  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [validationError, setValidationError] = React.useState({
    userNameError: false,
    phoneNoError: false,
    emailError: false,
    passError: false,
    cpassError: false,
    EmailFormatError: false,
    passwordMismatchError: false,
    invalidPhoneNoError: false,
  });
  const { details, AuthCheck, companyDetails } = React.useContext(UserContext);
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;

  const ManagerKey1 = MANAGER_ROLE_ID;
  React.useEffect(() => {
    AuthCheck();
    const maxLength = 10;
    if (phoneNo?.length > maxLength) {
      setPhoneNo(phoneNo.substring(0, maxLength));
    }
  }, [phoneNo?.length]);

  React.useEffect(() => {
    var {
      emailError,
      cpassError,
      phoneNoError,
      passError,
      userNameError,
      EmailFormatError,
      invalidPhoneNoError,
      passwordMismatchError
    } = validationError;
    if (!!userName && !!phoneNo && !!password && !!confirmPassword && !!email) {
      if (
        !(
          emailError ||
          cpassError ||
          phoneNoError ||
          passError ||
          userNameError ||
          EmailFormatError ||
          invalidPhoneNoError||
          passwordMismatchError
        )
      ) {
        setDetailsConfirmModel(true);
        // createHandle();
      } else {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);
  const createHandle = async (e) => {
    AuthCheck();
    if (userName?.trim()?.length > 2) {
      if (phoneNo.trim()?.length >= 10) {
        if (password?.length >= 4) {
          var flagEmail = false;
          await directus.users
            .readByQuery({
              fields: ["*"],
              filter: {
                email: { _eq: email },
                default_branch: { _eq: branch },
                default_company: { _eq: company },
              },
            })
            .then((res) => {
              res?.data?.length
                ? toast.error("Email address already exists")
                : (flagEmail = true);
            })
            .catch((e) => console.log(e));
          if (flagEmail) {
            var flag = false;
            await directus.users
              .readByQuery({
                fields: ["*"],
                filter: {
                  contact_number: { _eq: phoneNo },
                  default_branch: { _eq: branch },
                  default_company: { _eq: company },
                },
              })
              .then((res) => {
                res?.data?.length
                  ? toast.error("Contact number already exists")
                  : (flag = true);
              })
              .catch((e) => console.log(e));
            if (flag) {
              if (password === confirmPassword) {
                if (userName?.trim()?.length > 2) {
                  await directus.users
                    .createOne({
                      first_name: userName,
                      email: email,
                      password: password,
                      password_copy: password,
                      contact_number: phoneNo,
                      is_manager_user: true,
                      default_branch: branch,
                      default_company: company,
                      role: ManagerKey1,
                    })
                    .then((res) => {
                      navigate(
                        `/${companyDetails?.company}/${companyDetails?.branch}/users/manage`
                      );
                      toast.success(" Created successfully");
                    })
                    .catch((err) => {
                      navigate(".", { replace: true });
                      console.log(err);
                      toast.error(err?.message);
                    });
                } else {
                  toast.error("Enter valid name");
                }
              } else {
                toast.error("Password mismatch");
              }
            }
          }
        } else {
          toast.error("Set minimum 4 character password");
        }
      } else {
        toast.error("Invalid phone number");
      }
    } else {
      toast.error("Enter valid user name (minimum 3 character)");
    }
  };
  return (
    <LayoutComponent title={"CREATE MANAGER"} fixedFooter={true} center={true}>
      <Seo title={"Manager create"} company={true} />
      <CreateComponent
        validationError={validationError}
        userName={userName}
        setUserName={setUserName}
        email={email}
        setEmail={setEmail}
        phoneNo={phoneNo}
        setPhoneNo={setPhoneNo}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        setValidationError={setValidationError}
        userType={"Manager"}
      />
      {detailsConfirmModel ? (
        <ConfirmationModal
          openModel={detailsConfirmModel}
          setIsOpenModel={setDetailsConfirmModel}
          callFunction={createHandle}
          content={"Are you sure the given user information is valid?"}
        />
      ) : null}
    </LayoutComponent>
  );
}
