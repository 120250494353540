/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import CustomerLayout from "../../components/CustomerLayout";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import { directus } from "../../services/directus";
import { useParams } from "react-router-dom";
import placeHolderImg from "../../assets/images/placeHolder.png";
import { getAssetURL } from "../../utils/get-asset-url";
import moment from "moment";
import { toast } from "react-toastify";
import Pagination from "../../components/Pagination";

function OffersAndPromotion() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [allOffers, setAllOffers] = React.useState();
  const [showCoupon, setShowCoupon] = React.useState({});
  const [companyID, setCompanyID] = React.useState({});
  const [page, setPage] = React.useState(
    Number(new URLSearchParams(window.location.search).get("page")) || 1
  );
  const [pagination, setPagination] = React.useState();
  const { companyName, branchName } = useParams();

  const paginationCount = 9;
  React.useEffect(() => {
    setIsLoading(false);
    CompanyBranchCheck();
  }, []);
  React.useEffect(() => {
    setIsLoading(true);
    Object.keys(companyID)?.length > 0 && offersDataCall();
    const handleBackPress = () => {
      // for handle back to reload page
      window.location.reload();
    };
    window.onpopstate = handleBackPress;
  }, [page, showCoupon, companyID]);
  const offersDataCall = async () => {
    await directus
      .items("Offers_and_Promotions")
      .readByQuery({
        fields: ["*"],
        filter: {
          branch_info: { id: { _eq: companyID?.branch } },
          date_from: { _lte: new Date() },
          date_to:{_gte: new Date()},
          is_expiry: { _eq: false },
        },
        limit: paginationCount,
        page: page,
        sort: ["date_to","id"],
        meta: ["filter_count"],
      })
      .then((res) => {
        setAllOffers(res?.data);
        setPagination(Number(res?.meta?.filter_count));
      })
      .catch((e) => console.log(e));
    setIsLoading(false);
  };
  const numPages =
    pagination % paginationCount === 0
      ? Math.floor(pagination / paginationCount)
      : Math.floor(pagination / paginationCount) + 1;

  const CompanyBranchCheck = async () => {
    await directus
      .items("Companies")
      .readByQuery({
        fields: ["id"],
        filter: {
          status: { _eq: "published" },
          slug: { _eq: companyName },
          branches: { slug: { _eq: branchName } },
        },
      })
      .then(async (res) => {
        if (res?.data[0]) {
          await directus
            .items("Branch")
            .readByQuery({
              fields: ["id"],
              filter: {
                status: { _eq: "published" },
                slug: { _eq: branchName },
              },
            })
            .then((e) => {
              setCompanyID({
                company: res?.data[0]?.id,
                branch: e?.data[0]?.id,
              });
            });
        } else {
          setCompanyID({});
          toast.error("Invalid company / branch");
        }
      })
      .catch((e) => console.log(e));
  };
const copyHandle=(prop)=>{
  navigator?.clipboard?.writeText(prop);
  toast?.success("Coupon code copied to clipboard")
}
  const offerCard = (prop) => {
    return (
      <div className="offer-coupons flex flex-col justify-between items-center pb-0 border rounded-md border-gray-200 shadow-sm">
        <div className="justify-start min-w-[80px] border-b border-[#e0e0e0] w-full min-h-[200px] max-h-[205px] flex items-center">
          <img
            src={
              prop?.offer_image
                ? getAssetURL(prop?.offer_image)
                : placeHolderImg
            }
            alt="Offer-logo"
            width={""}
            className="block mx-auto rounded-t-md min-h-[200px] max-h-[205px] max-w-full"
          />
        </div>
        <div className="p-2 w-full justify-center text-center coupon-detail min-h-[90px] items-center flex flex-col">
          <div className="text-black text-md font-medium whitespace-normal break-word leading-none pb-2 min-h-[40px] flex items-center">
            {prop?.short_description}
          </div>
          <div className="flex justify-center flex-col text-sm text-gray-600 items-center min-h-[40px]">
            {prop?.date_to && (
              <span>
                Valid till {moment(prop?.date_to)?.format("MMM-Do-YYYY")}
              </span>
            )}
            {prop?.view_count > 0 ?<span>{prop?.view_count} {prop?.view_count===1?"user":"users"} viewed</span>
            :<span className=""></span>}
          </div>
        </div>
        <div className="justify-end w-full">
          <button
            className={`w-full inline-flex items-center justify-center text-base font-medium rounded-b-md border p-3 border-primary ${showCoupon?.id !== prop?.id? " bg-primary text-white ":" coupon-code text-primary"} focus:outline-none ${
              prop?.coupon_code?.length > 0
                ? prop?.id === showCoupon?.id
                  ? "opacity-100 min-w-[110px]"
                  : "opacity-100"
                : "opacity-40 cursor-default pointer-events-none"
            }`}
            onClick={() =>
              prop?.coupon_code?.length > 0 &&
              prop?.id !== showCoupon?.id ?
              viewCountHandle({ id: prop?.id, count: prop?.view_count }):copyHandle(prop?.coupon_code )
            }
          >
            <span className="p-[5px]">{showCoupon?.id === prop?.id ? prop?.coupon_code : "Show Coupon"}</span>
          </button>
        </div>
      </div>
    );
  };

  const viewCountHandle = async (prop) => {
    const offerPromoSession = JSON?.parse(sessionStorage?.getItem("offerPromo")) || [];
    if(!offerPromoSession?.includes(prop?.id)){
      await directus
      .items("Offers_and_Promotions")
      .updateOne(prop?.id, { view_count: prop?.count ? prop?.count + 1 : 1 })
      .then((res) => {
        sessionStorage?.setItem('offerPromo',JSON?.stringify([...offerPromoSession, prop?.id]))
      })
      .catch((e) => console.log(e));
    }
    setShowCoupon(prop);
  };

  Object?.keys(showCoupon)?.length > 0 &&
    setTimeout(() => {
      setShowCoupon({});
    }, 6000);
  return (
    <CustomerLayout
      home={true}
      goBack={true}
      fixedFooter={true}
      containerView={false}
      isCompany={true}
    >
      <Seo title={"Offers&Promotions"} company={true} />

      {!isLoading && Object?.keys(companyID)?.length <= 0 ? (
        <LoadingSpinner />
      ) : (
        <div className="relative  w-full bg-white inline-block  firefox-scrollbar overflow-hidden overflow-y-auto">
        
          <main className="main-content-area px-6 py-8">
          <div className="text-black-800 pb-3 font-bold">Offers & Promotions :</div>
            {allOffers?.length > 0 ? (
              <div className="mx-auto grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-[30px]">
                {allOffers?.map((e) => (
                  <div key={e?.id} className=" py-0.5">
                    {offerCard(e)}
                  </div>
                ))}
              </div>
            ) : (
              <div
                className={`border rounded-md border-gray-200 whitespace-nowrap text-sm my-5 justify-items-center p-4 text-gray-500 flex justify-center text-center shadow-md ring-1 ring-black ring-opacity-5`}
              >
                <p className="text-center place-self-center">No Offers and Promotions available</p>
              </div>
            )}
            {/* pagination */}

            {numPages > 1 ? (
              <Pagination
                numPages={numPages}
                data={allOffers}
                page={page}
                handleNavigation={(e)=>setPage(e)}
              />
            ) : null}
          </main>
        </div>
      )}
    </CustomerLayout>
  );
}

export default OffersAndPromotion;
