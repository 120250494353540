/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Link } from "react-router-dom";
import PasswordShowHide from "../PasswordShowHide";

function AdminCredential(props) {
  const { adminDetails, setAdminDetails, validationError } = props;
  const [passwordType, setPasswordType] = React.useState(false);
  const [cPasswordType, setCPasswordType] = React.useState(false);

  function updateFields(field, state) {
    setAdminDetails((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }
  React.useEffect(() => {
    const maxLength = 10;
    if (adminDetails?.adminPhoneNo?.trim()?.length > maxLength) {
      updateFields(
        "adminPhoneNo",
        adminDetails?.adminPhoneNo.substring(0, maxLength)
      );
    }
  }, [adminDetails?.adminPhoneNo?.trim()?.length]);
  return (
    <div className="space-y-6 bg-white py-6">
      <div className="flex justify-start items-start flex-col md:flex-row">
        <label
          htmlFor="admin-email-01"
          className="text-sm font-medium text-gray-900 min-w-[165px] "
        >
          Admin Email ID *
        </label>
        <input
          type="email"
          name="user-name"
          id="admin-email-01"
          // required
          value={adminDetails?.adminEmail || ""}
          onChange={(e) => updateFields("adminEmail", e.target.value)}
          className={`${(validationError?.adminEmailError &&
            adminDetails?.adminEmail?.trim()?.length < 1) ||
            (validationError?.adminEmailFormatError && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(adminDetails?.adminEmail))
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } pl-4 py-2 pr-9 min-w-[310px] block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
        />
      </div>
      {validationError?.adminEmailError &&
        adminDetails?.adminEmail?.trim()?.length < 1 && (
          <p className="text-xs text-right text-red-500 mt-2">
            *Email is required
          </p>
        )}
      {validationError?.adminEmailFormatError &&
        adminDetails?.adminEmail?.trim()?.length > 0 && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(adminDetails?.adminEmail) && (
          <p className="text-xs text-right text-red-500 mt-2">
            *Enter valid email address
          </p>
        )}
      <div className="flex justify-start items-start flex-col md:flex-row">
        <label
          htmlFor="admin-phone"
          className="text-sm font-medium text-gray-900 min-w-[165px] "
        >
          Admin Phone Number *
        </label>
        <input
          type="text"
          name="user-name"
          id="admin-phone"
          // required
          value={adminDetails?.adminPhoneNo || ""}
          pattern="[0-9]*"
          inputMode="numeric"
          onChange={(e) =>
            /^\d*\.?\d*$/.test(e.target.value) &&
            updateFields("adminPhoneNo", e.target.value)
          }
          className={`${(validationError?.adminPhoneNoError &&
            adminDetails?.adminPhoneNo?.length < 1) ||
            validationError?.lenPhoneNoError
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } pl-4 py-1.5 pr-9 min-w-[310px] block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
        />
      </div>
      {validationError?.adminPhoneNoError &&
        adminDetails?.adminPhoneNo?.length < 1 && (
          <p className="text-xs text-right text-red-500 mt-2">
            *Phone number is required
          </p>
        )}
      {((validationError?.adminPhoneNoError === false &&
        validationError?.lenPhoneNoError) ||
        (adminDetails?.adminPhoneNo?.trim()?.length > 0 &&
          adminDetails?.adminPhoneNo?.trim()?.length < 10)) && (
          <p className="text-xs text-right text-red-500 mt-2">
            *Enter valid phone number
          </p>
        )}
      <div className="flex justify-start items-start relative flex-col md:flex-row">
        <label
          htmlFor="admin-pwd-01"
          className="text-sm font-medium text-gray-900 min-w-[165px] "
        >
          Admin Password *
        </label>
        <input
          type={passwordType ? "text" : "password"}
          name="user-name"
          id="admin-pwd-01"
          // required
          value={adminDetails?.password || ""}
          onChange={(e) => updateFields("password", e.target.value)}
          className={`${validationError?.passwordError && adminDetails?.password?.length < 1
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } pl-4 py-1.5 pr-9 min-w-[310px] block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
        />
        {/* <!-- Show/Hide password --> */}
        <PasswordShowHide
          setShow={setPasswordType}
          Show={passwordType}
          center={true}
        />
      </div>
      {validationError?.passwordError && adminDetails?.password?.length < 1 && (
        <p className="text-xs text-right text-red-500 mt-2">
          *Password is required
        </p>
      )}
      <div className="flex justify-start items-start relative flex-col md:flex-row">
        <label
          htmlFor="admin-con-pwd-01"
          className="text-sm font-medium text-gray-900 min-w-[165px] "
        >
          Confirm Password *
        </label>
        <input
          type={cPasswordType ? "text" : "password"}
          name="user-name"
          id="admin-con-pwd-01"
          // required
          value={adminDetails?.confirmPassword || ""}
          onChange={(e) => updateFields("confirmPassword", e.target.value)}
          className={`${((validationError?.confirmPasswordError &&
            adminDetails?.confirmPassword?.length < 1) || (validationError?.passwordMismatchError &&
              adminDetails?.confirmPassword?.length > 0 &&
              adminDetails?.password?.trim() !== adminDetails?.confirmPassword?.trim()))
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } pl-4 py-1.5 pr-9 min-w-[310px] block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
        />
        {/* <!-- Show/Hide password --> */}
        <PasswordShowHide
          setShow={setCPasswordType}
          Show={cPasswordType}
          center={true}
        />
      </div>
      {validationError?.confirmPasswordError &&
        adminDetails?.confirmPassword?.trim()?.length < 1 && (
          <p className="text-xs text-right text-red-500 mt-2">
            *Confirm password is required
          </p>
        )}
      {validationError?.passwordMismatchError &&
        adminDetails?.confirmPassword?.trim()?.length > 0 &&
        adminDetails?.password?.trim() !== adminDetails?.confirmPassword?.trim() && (
          <p className="text-xs text-right text-red-500 mt-2">
            *Password and Confirm password does not match
          </p>
        )}
      {/* <div className="flex items-center mb-4">
        <input
          id="default-radio-1"
          type="checkbox"
          name="default-radio"
          value={adminDetails?.subscription}
          onChange={() =>
            updateFields("subscription", !adminDetails?.subscription)
          }
          checked={adminDetails?.subscription === true ? true : false}
          className={`${
            validationError?.subscriptionError &&
            adminDetails?.subscription === false
              ? "border-red-300 focus:border-red-300"
              : "border-gray-300 focus:border-primary"
          } w-6 h-6 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2`}
        />
        <label
          htmlFor="default-radio-1"
          className="ml-2 text-base font-medium text-gray-900 "
        >
          Free for only one year.
        </label>
      </div>
      {validationError?.subscriptionError &&
        adminDetails?.subscription === false && (
          <p className="text-xs text-right text-red-500 mt-2">
            *Subscription is required
          </p>
        )} */}
      <div className="flex justify-start items-start mb-4">
        <input
          id="default-radio-1"
          type="checkbox"
          value={adminDetails?.terms}
          onChange={() => updateFields("terms", !adminDetails?.terms)}
          checked={adminDetails?.terms === true ? true : false}
          name="default-radio"
          className={`${validationError?.termsError && adminDetails?.terms === false
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } w-6 h-6 mt-1 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2`}
        />
        <label
          htmlFor="default-radio-1"
          className="ml-2 text-base font-medium text-gray-900 "
        >
          By checking this box, you agree to be bound by our{" "}
          <Link
            to={`/partner/terms-of-service`}
            target="_blank"
            className="text-primary"
          >
            terms and conditions{" "}
          </Link>
          agreement
        </label>
      </div>
      {validationError?.termsError && adminDetails?.terms === false && (
        <p className="text-xs text-right text-red-500 mt-2">
          *Terms is required
        </p>
      )}
      <div className="flex justify-start items-start mb-4">
        <input
          id="valet_fee"
          type="checkbox"
          value={adminDetails?.valetFee}
          onChange={() => updateFields("valetFee", !adminDetails?.valetFee)}
          checked={adminDetails?.valetFee === true ? true : false}
          name="valet_fee"
          className={`${ "border-gray-300 focus:border-primary"
            } w-6 h-6 mt-1 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2`}
        />
        <label
          htmlFor="valet_fee"
          className="ml-2 text-base font-medium text-gray-900 "
        >
          Do you collect parking fees from customers also you can update this later.

        </label>
      </div>
      {adminDetails?.valetFee && <>
        <div className="flex justify-start items-start flex-col md:flex-row">
          <label
            htmlFor="valet_fee_value"
            className="text-sm font-medium text-gray-900 min-w-[165px] "
          >
            Enter the Parking Charge*
          </label>
          <input
            type="text"
            name="user-name"
            id="valet_fee_value"
            // required
            value={adminDetails?.valetFeeValue || ""}
            pattern="[0-9]*"
            inputMode="numeric"
            onChange={(e) =>
              /^\d*\.?\d*$/.test(e.target.value) &&
              updateFields("valetFeeValue", e.target.value)
            }
            className={`${(validationError?.valetFeeValueError &&
              Number(adminDetails?.valetFeeValue) <= 0) ? "border-red-300 focus:border-red-300"
              :
              "border-gray-300 focus:border-primary"
              } pl-4 py-1.5 pr-9 min-w-[310px] block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
          />
        </div>
        {validationError?.valetFeeValueError && Number(adminDetails?.valetFeeValue) <= 0 && (
          <p className="text-xs text-right text-red-500 mt-2">
            *Parking charge is required
          </p>
        )}
      </>}

    </div>
  );
}

export default AdminCredential;
