/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomerLayout from "../../components/CustomerLayout";
import VehicleOtp from "../../components/VehicleOtp";
import { directus } from "../../services/directus";
import Seo from "../../components/Seo";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
function CustomerLogin() {
  const [vehicle, setVehicle] = React.useState("");
  const [phonWithVehicle, setPhoneWithVehicle] = React.useState("");
  const [otpNo, setOtpNo] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [isLoginOpen, setIsLoginOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState([]);
  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
  const [companyID, setCompanyID] = React.useState({});
  const { companyName, branchName } = useParams();
  const branch_No = companyID?.branch;
  const company = companyID?.company;

  const navigate = useNavigate();
  React.useEffect(() => {
    CompanyBranchCheck();
  }, []);
  const CompanyBranchCheck = async () => {
    await directus
      .items("Companies")
      .readByQuery({
        fields: ["id","company_logo.id"],
        filter: {
          status: { _eq: "published" },
          slug: { _eq: companyName },
          branches: { slug: { _eq: branchName } },
        },
      })
      .then(async (res) => {
        if (res?.data[0]) {
          await directus
            .items("Branch")
            .readByQuery({
              fields: ["id","chat_room_id"],
              filter: {
                status: { _eq: "published" },
                slug: { _eq: branchName },
              },
            })
            .then((response) => {
              setCompanyID({
                company: res?.data[0]?.id,
                branch: response?.data[0]?.id,
                chatRoomId: response?.data[0]?.chat_room_id
              });
            });
        } else {
          toast.error("Invalid company / branch");
        }
      })
      .catch((e) => console.log(e));
  };
  React.useEffect(() => {
    if (
      vehicle?.trim()?.replace(" ", "")?.length === 4 &&
      otpNo?.trim()?.replace(" ", "")?.length === 4
    ) {
      VehicleInfo({ type: "otp" });
    } else {
      vehicle?.trim()?.length === 4 &&
        otpNo?.trim()?.length === 4 &&
        toast.error("Validation failed");
    }
    if (
      phonWithVehicle?.trim()?.replace(" ", "")?.length === 4 &&
      phoneNo?.trim()?.replace(" ", "")?.length === 10
    ) {
      VehicleInfo({ type: "phoneNo" });
    } else {
      phonWithVehicle?.trim()?.length === 4 &&
        phoneNo?.trim()?.length === 10 &&
        toast.error("Validation failed");
    }
  }, [
    otpNo?.length,
    vehicle?.length,
    phonWithVehicle?.length,
    phoneNo?.length,
  ]);
  const VehicleInfo = async ({ type }) => {
    let vehicleFilter = vehicle?.length > 1 ? vehicle : phonWithVehicle;
    const userData =
      type === "otp"
        ? await directus
          .items("Valet_Information")
          .readByQuery({
            fields: ["id","Vehicle_Number","status_id.sequence","date_updated","Customer_Phone_Number"],
            sort: ["-date_updated"],
            filter: {
              OTP_Value: { _eq: otpNo },
              branch: { _eq: branch_No },
              company: { _eq: company },
            },
          })
          .then((res) => {
            const filterValue = res?.data?.filter((e) => {
              return e?.Vehicle_Number?.slice(-4) === vehicleFilter
                ? e
                : "";
            });
            return filterValue?.[0];
          })
          .catch((e) => console.log(e))
        : await directus
          .items("Valet_Information")
          .readByQuery({
            fields: ["id","Vehicle_Number","status_id.sequence","date_updated","Customer_Phone_Number"],
            sort: ["-date_updated"],
            filter: {
              Customer_Phone_Number: { _eq: phoneNo },
              branch: { _eq: branch_No },
              company: { _eq: company },
            },
          })
          .then((res) => {
            const filterValue = res?.data?.filter((e) => {
              return e?.Vehicle_Number?.slice(-4) === vehicleFilter
                ? e
                : "";
            });
            return filterValue?.[0];
          })
          .catch((e) => console.log(e));
    if (userData) {
      if (type === "otp") {
        userData?.Vehicle_Number?.slice(-4) === vehicleFilter
          ? navigate(
            `/${companyName}/${branchName}/customer/status?id1=${otpNo}&id2=${vehicle}`
          )
          : toast.error("Invalid details");
      } else {
        if (userData?.status_id?.sequence === 6) {
          if (userData?.Vehicle_Number?.slice(-4) === vehicleFilter) {
            setUserDetails(userData);
            setDetailsConfirmModel(true);
          } else {
            toast.error("Invalid details");
          }
        } else {
          userData?.Vehicle_Number?.slice(-4) === vehicleFilter
            ? navigate(
              `/${companyName}/${branchName}/customer/status?id1=${phoneNo}&id2=${phonWithVehicle}`
            )
            : toast.error("Invalid details");
        }
      }
    } else {
      toast.error("No vehicle information found");
    }
  };
  const moveHandle = async () => {
    var a = Math.floor(100000 + Math.random() * 900000);
    const randomOtp = a.toString().substring(0, 4);
    await directus
      .items("Valet_Information")
      .createOne({
        Vehicle_Number: userDetails?.Vehicle_Number,
        OTP_Value: randomOtp,
        Customer_Phone_Number: userDetails?.Customer_Phone_Number,
        branch: branch_No,
        company: company,
      })
      .then(async (res) => {
        setDetailsConfirmModel(false);
        toast.success("Request sent successfully");
        navigate(
          `/${companyName}/${branchName}/customer/status?id1=${phoneNo}&id2=${phonWithVehicle}`
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };
  const handleChange = (value) => {
    setOtpNo(value);
  };
  const handleVehicle = (value) => {
    setVehicle(value);
  };
  const handlePhoneWithVehicle = (value) => {
    setPhoneWithVehicle(value);
  };
  const handlePhone = (value) => {
    setPhoneNo(value);
  };
  return (
    <CustomerLayout home={false} goBack={true} refresh={isLoginOpen} containerView={false} isCompany={true} chatRoomId={companyID?.chatRoomId}>
      <Seo title={`Customer Login | ${companyName} | ${branchName}`} />
      <div className="relative  w-full bg-white inline-block  firefox-scrollbar overflow-hidden overflow-y-auto">
        <main className="main-content-area px-6 py-8 ">
          <div className="mx-auto">
            <div className="customer-login">
              <div className="mt-1 mb-1 block">
                <form method="POST">
                  <div className="space-y-6 bg-white py-6">
                    <div className={`grid grid-cols-6 gap-4 ${isLoginOpen ? "login-section max-w-[700px] mx-auto" : "register-login-section"}`}>
                      {!isLoginOpen && <>
                        <div className="col-span-6 title text-black font-bold text-xl block text-center pb-4">Register / Login</div>
                        <div className="col-span-6 text-center">
                          <div
                            onClick={() =>
                              navigate(
                                `/${companyName}/${branchName}/customer/register`
                              )
                            }
                            className="font-semibold text-primary cursor-pointer"
                          >
                            <button className="sm:min-w-[300px] inline-block p-2 md:p-3 rounded-md border border-vspurple bg-primary text-white text-md text-center focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                              Create a new vehicle registration
                            </button>
                          </div>
                        </div>
                      </>}
                      {!isLoginOpen && <div className="w-full col-span-6 bg-white block text-center or-text my-2">
                        <div className="bg-primary flex items-center mx-auto rounded-[50%] border border-white justify-center text-white text-[24px] w-[60px] h-[60px] font-medium relative z-[1]">
                          Or
                        </div>
                      </div>}
                      {!isLoginOpen && <div className="col-span-6 text-center">
                        <div
                          onClick={() =>
                            setIsLoginOpen(true)
                          }
                          className="font-semibold text-primary cursor-pointer"
                        >
                          <button className="sm:min-w-[300px] p-2 md:p-3 rounded-md border border-secondary bg-vsorangered text-white text-center text-md inline-block focus:outline-none focus:ring-2 focus:ring-vsorangered focus:ring-offset-2">
                            Login with registered vehicle
                          </button>
                        </div>
                      </div>}
                      {isLoginOpen && <div
                        className={`col-span-6 ${phoneNo?.length > 0 || phonWithVehicle?.length > 0
                          ? "pointer-events-none"
                          : ""
                          }`}
                      >
                        <label
                          htmlFor="u-mail-id1"
                          className="block text-sm font-bold text-gray-700 mb-2"
                        >
                          Enter the OTP *
                        </label>

                        <VehicleOtp
                          numInputs={4}
                          handleChange={handleChange}
                          setOtpNo={setOtpNo}
                          readOnly={false}
                          required={true}
                          hasError={
                            phoneNo?.trim()?.length > 0 ||
                              phonWithVehicle?.trim()?.length > 0
                              ? false
                              : true
                          }
                          inputType={'number'}
                        />
                      </div>}
                      {isLoginOpen && <div
                        className={`col-span-6 ${phoneNo?.length > 0 || phonWithVehicle?.length > 0
                          ? "pointer-events-none"
                          : ""
                          }`}
                      >
                        <label
                          htmlFor="u-mail-id1"
                          className="block text-sm font-bold text-gray-700 mb-2"
                        >
                          Enter the last 4 value of vehicle *
                        </label>
                        <VehicleOtp
                          numInputs={4}
                          handleChange={handleVehicle}
                          setOtpNo={setVehicle}
                          readOnly={false}
                          required={true}
                          hasError={
                            phoneNo?.trim()?.length > 0 ||
                              phonWithVehicle?.trim()?.length > 0
                              ? false
                              : true
                          }
                          inputType={'text'}
                        />
                      </div>}
                      {isLoginOpen && <div className="w-full col-span-6 bg-white block text-center or-text my-2">
                        <div className="bg-primary flex items-center mx-auto rounded-[50%] border border-white justify-center text-white text-[24px] w-[60px] h-[60px] font-medium relative z-[1]">
                          Or
                        </div>
                      </div>}
                      {isLoginOpen && <div
                        className={`col-span-6 mb-5 ${otpNo?.length > 0 || vehicle?.length > 0
                          ? "pointer-events-none"
                          : ""
                          }`}
                      >
                        <label
                          htmlFor="u-mail-id1"
                          className="block text-sm font-bold text-gray-700 mb-2"
                        >
                          Enter the registered phone number *
                        </label>
                        <VehicleOtp
                          numInputs={10}
                          handleChange={handlePhone}
                          setOtpNo={setPhoneNo}
                          readOnly={false}
                          required={true}
                          type={"phone"}
                          hasError={
                            otpNo?.trim()?.length > 0 ||
                              vehicle?.trim()?.length > 0
                              ? false
                              : true
                          }
                        />
                      </div>}
                      {isLoginOpen && <div
                        className={`col-span-6 ${otpNo?.length > 0 || vehicle?.length > 0
                          ? "pointer-events-none"
                          : ""
                          }`}
                      >
                        <label
                          htmlFor="u-mail-id1"
                          className="block text-sm font-bold text-gray-700 mb-2"
                        >
                          Enter the last 4 value of vehicle *
                        </label>
                        <VehicleOtp
                          numInputs={4}
                          handleChange={handlePhoneWithVehicle}
                          setOtpNo={setPhoneWithVehicle}
                          readOnly={false}
                          required={true}
                          hasError={
                            otpNo?.trim()?.length > 0 ||
                              vehicle?.trim()?.length > 0
                              ? false
                              : true
                          }
                          inputType={'text'}
                        />
                      </div>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
      {detailsConfirmModel ? (
        <ConfirmationModal
          openModel={detailsConfirmModel}
          setIsOpenModel={setDetailsConfirmModel}
          callFunction={moveHandle}
          content={`Are you sure to park again?`}
        />
      ) : null}
    </CustomerLayout>
  );
}

export default CustomerLogin;
