/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Files from "react-files";
import Select from "react-select";
import { directus } from "../../services/directus";
import { toast } from "react-toastify";

function CompanyInfoEdit(props) {
  const { info, setInfo, validationError, branchData } = props;
  const [logoSize, setLogoSize] = React.useState("");
  const [tagOptions, setTagOptions] = React.useState("");
  const [stateOptions, setStateOptions] = React.useState("");
  const [cityOptions, setCityOptions] = React.useState("");
  const [optionsLoaded, setOptionsLoaded] = React.useState(false)
  function updateFields(field, state) {
    setInfo((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }
  const logoChangedHandler = (event) => {
    let file_size = event[0]?.size;
    if (file_size / 1000000 < 2) {
      setLogoSize("");
      updateFields("logo", event[0]);
    } else {
      setLogoSize("* Please upload the attachment image less than 2MB.");
    }
  };

  const preview = (props) => {
    return (
      props?.value && (
        <div className="files-list">
          <ul>
            <li
              key={props?.value?.id}
              className="flex flex-row justify-evenly text-center place-items-center"
            >
              <div>
                <div className="p-5 my-1">
                  {props?.value?.preview?.type === "image" ? (
                    <img
                      className=""
                      src={props?.value?.preview?.url}
                      alt="Preview"
                    />
                  ) : (
                    <div className="">{props?.value?.extension}</div>
                  )}
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900 min-w-[150px] ">
                    {props?.value?.name}
                  </div>
                </div>
              </div>
            </li>
            <div
              className="list-item-remove flex justify-end"
              onClick={() => updateFields(props?.set, "")}
            >
              <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                stroke="#ff0000"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="css-i6dzq1 cursor-pointer"
              >
                <title>Delete</title>
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            </div>
          </ul>
        </div>
      )
    );
  };
  const styles1 = {
    menuList: (base) => ({
      ...base,
      height: "110px",

      "::-webkit-scrollbar": {
        width: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "#2684FF",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    control: (base, state) => ({
      ...base,
      cursor: "pointer",
      border: "none",
      marginTop: "4px",
    }),
  };

  React.useEffect(() => {
    TagCategoryCall();
    StatesCall();
    info?.State?.id && CityCall(info?.State?.id) 
  }, [info?.State?.id]);

  React.useEffect(()=>{

    stateOptions && stateOptions?.map((e)=> {
      if(branchData?.state_id?.id === e?.id){
        updateFields('State', e)
        CityCall(e?.id)
      }
      return ''
    })
    
    let selectedTags = []
    tagOptions && tagOptions?.map((e)=> {
    return  branchData?.tags?.map((i)=> {
        if(i.Tags_Category_id === e.Tags_Category_id){
          selectedTags.push(e)
        }
        return ''
      })
    })

    updateFields('Tags', selectedTags)
    updateFields('Address', branchData?.address)
  }, [optionsLoaded])

  React.useEffect(()=>{
    cityOptions && cityOptions?.map((e)=> {
      if(branchData?.city_id?.id === e?.id){
        updateFields('City', e)
      }
      return ''
    }) 
  }, [cityOptions])

  const TagCategoryCall = async () => {
    await directus
      .items("Tags_Category")
      .readByQuery({
        fields: ["*"],
      })
      .then((res) => {
        const tagOptionsRes = res?.data?.map(e => { return { ...e, Tags_Category_id: e?.id } })
        setTagOptions(tagOptionsRes);
        return "";
      })
      .catch((e) => console.log(e));
  };
  const StatesCall = async () => {
    await directus
      .items("States")
      .readByQuery({
        fields: ["*"],
      })
      .then((res) => {
        setStateOptions(res?.data);
        setOptionsLoaded(true)
        return "";
      })
      .catch((e) => console.log(e));
  };
  const CityCall = async (state_id) => {
    await directus
      .items("Cities")
      .readByQuery({
        fields: ["*"],
        filter: {
          state_id: { _eq: state_id },
        }
      })
      .then((res) => {
        setCityOptions(res?.data);
        return "";
      })
      .catch((e) => console.log(e));
  };

  const selectedTags =
    tagOptions &&
    tagOptions?.filter((cv) => {
      const val = !info?.Tags.find((e) => {
        return e.Tags_Category_id === cv.id;
      });
      return val === true ? null : cv
    });

  const selectedState =
    stateOptions &&
    stateOptions?.filter((cv) => {
      return info?.State?.id === cv.id;
    });
   const selectedCity =
    cityOptions &&
    cityOptions?.filter((cv) => {
      return info?.City?.id === cv.id;
    });
  return (
    <div className="space-y-6 bg-white pb-6 company-edit-form-wrapper" >
      {props?.logo !== false && (
        <div className="flex flex-col justify-start items-start md:flex-row gap-2">
          <label
            htmlFor="company-logo-1"
            className="text-sm font-medium text-gray-900 min-w-[250px] "
          >
            Company Logo
          </label>
          <div className={`block w-full ${props?.allFieldEdit === false ? " pointer-events-none" : ''}`}>
            <Files
              className={`${validationError?.logoError && info?.logo?.length < 1
                ? "border-red-300 focus:border-red-300"
                : "border-gray-300 focus:border-primary"
                } text-gray-400  pl-4 py-1.5 pr-9 w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0 h-10 flex items-center font-bold text-[#bbb] text-[14px]`}
              onChange={(e) => logoChangedHandler(e)}
              onError={(e)=> toast.error("Error while opening the image")}
              accepts={["image/*"]}
              multiple={false}
              maxFileSize={2 * 1024 * 1024}
              minFileSize={0}
              clickable
            >
              Drop files here or click to upload
            </Files>
            {info?.logo && preview({ value: info?.logo, set: "logo" })}
          </div>
        </div>
      )}
      {validationError?.logoError &&
        info?.logo?.length < 1 &&
        logoSize?.trim()?.length === 0 && (
          <p className="text-xs text-right text-red-500 error-msg mt-0">
            *Company Logo is required
          </p>
        )}
      {logoSize?.trim()?.length > 1 && (
        <p className="text-xs text-right text-red-500 error-msg mt-0">
          {logoSize?.trim()}
        </p>
      )}
      <div className="flex flex-col justify-start items-start md:flex-row gap-2">
        <label
          htmlFor="company-address-1"
          className="text-sm font-medium text-gray-900 min-w-[250px] "
        >
          Branch Address *
        </label>
        <textarea
          name="user-name"
          id="company-address-1"
          disabled={props?.allFieldEdit === false ? true : false}
          value={info?.Address}
          onChange={(e) => updateFields("Address", e.target.value)}
          className={`${validationError?.AddressError && info?.Address?.trim()?.length < 1
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } pl-4 py-2 pr-9 block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary  focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
        ></textarea>
      </div>
      {validationError?.AddressError && info?.Address?.trim()?.length < 1 && (
        <p className="text-xs text-right text-red-500 error-msg mt-0">
          *Branch Address is required
        </p>
      )}

      <div className={`${props?.allFieldEdit === false ? " pointer-events-none" : ''} flex flex-col justify-start items-start md:flex-row gap-2 relative`}>
        <label
          htmlFor="company-tag-1"
          className="text-sm font-medium text-gray-900 min-w-[250px]"
        >
          State or Union territory*
        </label>
        <div className="relative w-full">
          <Select
            id="state"
            classNamePrefix="react-select"
            className=" react-select-container  border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] h-10 items-center text-[14px] capitalize"
            defaultMenuIsOpen={false}
            maxMenuHeight={250}
            onChange={(e) => {
              updateFields(
                "State",
                e
              )
              updateFields(
                "City",
                "")
              CityCall(e?.id)
            }
            }
            options={info?.State?.length > 0 ?
              selectedState
              : stateOptions}
            isMulti={false}
            hideSelectedOptions={true}
            value={info?.State?.id ? info?.State : ""}
            getOptionLabel={(option) => option.state_name}
            getOptionValue={(option) => option}
            placeholder={"Select your State or Union territory"}
            theme={(theme) => ({
              ...theme,
              colors: {
                neutral0: "white",
                neutral50: "#9CA3AF",
              },
            })}
            styles={{
              styles1,
              placeholder: (base) => ({
                ...base,
                fontWeight: "bold",
                color: "#9CA3AF",
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                boxShadow: "#8765E2",
                borderColor: "#D1D5DB",
              }),
              input: (base, state) => ({
                ...base,
                '[type="text"]': {
                  border: "none",
                  boxShadow: "none",
                  outline: "none",
                },
              }),
            }}
          />
          {info?.State?.id && (
            <div
              className="w-full absolute right-20 close-icon"
              onClick={() => {
                updateFields("State", "")
                updateFields("City", "")
              }}
            >
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
              >
                <title>Clear</title>
                <path
                  d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                  fill="#000"
                ></path>
              </svg>
            </div>
          )}
        </div>
      </div>
      {validationError?.StateError && info?.State?.length < 1 && (
        <p className="text-xs text-right text-red-500 error-msg mt-0">
          *State or Union territory is required
        </p>
      )}
      {info?.State?.id &&
        <>
          <div className="flex flex-col justify-start items-start md:flex-row gap-2 relative">
            <label
              htmlFor="company-tag-1"
              className="text-sm font-medium text-gray-900 min-w-[250px]"
            >
              City or Town *
            </label>
            <div className={`relative w-full  ${props?.allFieldEdit === false ? " pointer-events-none" : ''}`}>
              <Select
                id="city"
                classNamePrefix="react-select"
                className=" react-select-container  border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] h-10 items-center text-[14px] capitalize"
                defaultMenuIsOpen={false}
                maxMenuHeight={250}
                onChange={(e) => {
                  updateFields("City", e)
                }
                }
                options={info?.City?.length > 0 ? selectedCity : cityOptions}
                isMulti={false}
                hideSelectedOptions={true}
                value={info?.City?.id ? info?.City : ""}
                getOptionLabel={(option) => option?.city_name}
                getOptionValue={(option) => option}
                placeholder={"Select your City or Town"}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    neutral0: "white",
                    neutral50: "#9CA3AF",
                  },
                })}
                styles={{
                  styles1,
                  placeholder: (base) => ({
                    ...base,
                    fontWeight: "bold",
                    color: "#9CA3AF",
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "#8765E2",
                    borderColor: "#D1D5DB",
                  }),
                  input: (base, state) => ({
                    ...base,
                    '[type="text"]': {
                      border: "none",
                      boxShadow: "none",
                      outline: "none",
                    },
                  }),
                }}
              />
              {info?.City?.id && (
                <div
                  className="w-full absolute right-20 close-icon"
                  onClick={() => {
                    updateFields(
                      "City",
                      "")
                  }}
                >
                  <svg
                    height="20"
                    width="20"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    focusable="false"
                    className="css-tj5bde-Svg"
                  >
                    <title>Clear</title>
                    <path
                      d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                      fill="#000"
                    ></path>
                  </svg>
                </div>
              )}
            </div>
          </div>
          {validationError?.CityError && info?.City?.length < 1 && (
            <p className="text-xs text-right text-red-500 error-msg mt-0">
              *City or Town is required
            </p>
          )}
        </>
      }
      <div className={`flex flex-col justify-start items-start md:flex-row gap-2 ${props?.allFieldEdit === false ? " pointer-events-none" : ''}`}>
        <label
          htmlFor="company-tag-1"
          className="text-sm font-medium text-gray-900 min-w-[250px]"
        >
          Tags (Categories) *
        </label>
        <Select
          id="tags"
          classNamePrefix="react-select"
          className=" react-select-container  border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] h-10 items-center text-[14px] capitalize"
          defaultMenuIsOpen={false}
          maxMenuHeight={250}
          onChange={(e) => {
            updateFields(
              "Tags",
              e?.map((e) => e)
            )
          }
          }
          options={tagOptions}
          isMulti={true}
          hideSelectedOptions={true}
          value={info?.Tags?.length > 0 ? selectedTags : ""}
          getOptionLabel={(option) => {
            return option.Name
          }}
          getOptionValue={(option) => option}
          placeholder={"Select your tags"}
          theme={(theme) => ({
            ...theme,
            colors: {
              neutral0: "white",
              neutral50: "#9CA3AF",
            },
          })}
          styles={{
            styles1,
            placeholder: (base) => ({
              ...base,
              fontWeight: "bold",
              color: "#9CA3AF",
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              boxShadow: "#8765E2",
              borderColor: "#D1D5DB",
            }),
            input: (base, state) => ({
              ...base,
              '[type="text"]': {
                border: "none",
                boxShadow: "none",
                outline: "none",
              },
            }),
          }}
        />
      </div>
      {validationError?.TagsError && info?.Tags?.length < 1 && (
        <p className="text-xs text-right text-red-500 error-msg mt-0">
          *Tags is required
        </p>
      )}
    </div>
  );
}

export default CompanyInfoEdit;
