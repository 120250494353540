import React from "react";
import { useLocation } from "react-router-dom";
import checkMarkIcon from "../assets/images/check-mark-verified.gif";
import CustomerLayout from "../components/CustomerLayout";
import Seo from "../components/Seo";

function Success() {
  const { state } = useLocation();

  return (
    <CustomerLayout goHome={true} containerView={false}>
      <Seo title={"Success"} />
      {/* <div className="page__wrapper flex justify-start items-center flex-col bg-[rgb(243,244,246)] font-primary">
        <div
          className={`max-w-[1200px] relative w-full inline-block h-screen firefox-scrollbar shadow-sm min-h-screen max-h-screen  bg-white  overflow-hidden overflow-y-auto`}
        > */}
          <div className="main-content-area">
            <div className="flex justify-center w-full items-center p-10">
              {state ? (
                state === "company" ? (
                  <div>
                    <div className="flex items-center justify-center p-10">
                      <span className="mr-2 rounded-full w-[50%]">
                        {" "}
                        <img
                          src={checkMarkIcon}
                          alt="succcess"
                          style={{ margin: "0 auto" }}
                        />
                      </span>
                    </div>
                    <div className="bg-teal-100 border border-teal-400  px-4 py-3 rounded relative text-teal text-xl text-center">
                      Your company and first branch registration request has
                      been submitted successfully. We will reach you shortly via
                      registered admin email address.
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="flex items-center justify-center p-10">
                      <span className="mr-2 rounded-full w-[50%]">
                        {" "}
                        <img
                          src={checkMarkIcon}
                          alt="succcess"
                          style={{ margin: "0 auto" }}
                        />
                      </span>
                    </div>
                    <div className="bg-teal-100 border border-teal-400  px-4 py-3 rounded relative text-teal  text-xl">
                      Your branch registration request has been submitted
                      successfully. We will reach you shortly via the registered
                      admin email address.
                    </div>
                  </div>
                )
              ) : (
                <div>No data found</div>
              )}{" "}
            </div>
          </div>
        {/* </div>
      </div> */}
    </CustomerLayout>
  );
}

export default Success;
