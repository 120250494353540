/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CompanyInfo from "../../components/companyRegistration/CompanyInfo";
import LayoutComponent from "../../components/LayoutComponent";
import Seo from "../../components/Seo";
import { directus } from "../../services/directus";
import { RegistrationMailSender } from "../../utils/Mailsender";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import UserContext from "../../contexts/userContext";
import LoadingSpinner from "../../components/LoadingSpinner";
import NotFoundPage from "../NotFoundPage";

function ApplicationCorrection() {
    const { details } = React.useContext(UserContext);
    const [preventDouble, setPreventDouble] = React.useState(false)
    const [confirmModal, setConfirmModal] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [info, setInfo] = React.useState({
        companyName: "",
        cinNumber: "",
        logo: "",
        Address: "",
        Tags: [],
        State: "",
        City: "",
        docs1: "",
        docs2: "",
        location: "",
        typeOfCompany: "",
        valetFee: false,
        valetFeeValue: ""
    });
    const navigate = useNavigate();
    const [validationError, setValidationError] = React.useState({
        companyNameError: false,
        cinNumberError: false,
        cinNumberFormatError: false,
        // logoError: false,
        AddressError: false,
        StateError: false,
        CityError: false,
        TagsError: false,
        docs1Error: false,
        docs2Error: false,
        locationError: false,
        typeOfCompanyError: false,
        valetFeeValueError: false,
    });
    async function fieldValidation() {
        if (info?.companyName?.trim()?.length < 1) {
            updateValidationError("companyNameError", true);
        }
        if (info?.cinNumber?.trim()?.length < 1) {
            updateValidationError("cinNumberError", true);
        }
        if (
            !!!/^[a-zA-Z0-9]{4,30}$/gm.test(
                info?.cinNumber?.trim()
            )
        ) {
            updateValidationError("cinNumberFormatError", true);
        } else {
            updateValidationError("cinNumberFormatError", false);

        }
        if (!!!info?.Address?.trim()) {
            updateValidationError("AddressError", true);
        }
        if (!!!info?.Tags?.length > 0) {
            updateValidationError("TagsError", true);
        }
        if (!!!info?.State?.id) {
            updateValidationError("StateError", true);
        }
        if (!!!info?.City?.id) {
            updateValidationError("CityError", true);
        }
        if (!!!info?.docs1 && !!!info?.docs2) {
            updateValidationError("docs1Error", true);
        }
        if (!!!info?.docs1 && !!!info?.docs2) {
            updateValidationError("docs2Error", true);
        }
        if (!!!info?.location?.trim()) {
            updateValidationError("locationError", true);
        }
        if (!!!info?.typeOfCompany?.trim()) {
            updateValidationError("typeOfCompanyError", true);
        }
        if (info?.valetFee === true && Number(info?.valetFeeValue) <= 0) {
            updateValidationError("valetFeeValueError", true);
        }
        return validationError;
    }

    function updateValidationError(field, state) {
        setValidationError((prevState) => ({
            ...prevState,
            [field]: state,
        }));
    }
    React.useEffect(() => {
        setInfo({
            companyName: details?.default_company?.name,
            cinNumber: details?.default_branch?.cin_gov_number,
            logo: details?.default_company?.company_logo,
            Address: details?.default_branch?.address,
            Tags: details?.default_branch?.tags ? details?.default_branch?.tags : [],
            State: details?.default_branch?.state_id,
            City: details?.default_branch?.city_id,
            docs1: details?.default_branch?.document_1 ? details?.default_branch?.document_1 : '',
            docs2: details?.default_branch?.document_2 ? details?.default_branch?.document_2 : '',
            location: details?.default_branch?.name,
            typeOfCompany: details?.default_branch?.type_of_organization,
            valetFee: Number(details?.default_branch?.valet_fee) > 0 ? true : false,
            valetFeeValue: details?.default_branch?.valet_fee
        })
        if (details) {
            if (details?.default_branch?.status === "document_verified" || details?.default_branch?.status === "contract_agreement_pending" ||
                details?.default_branch?.status === "contract_agreement_review_in_progress" || details?.default_branch?.status === "contract_signature_pending"
                || details?.default_branch?.status === "contract_signature") { navigate(`/${details?.default_company?.slug}/${details?.default_branch?.slug}/onboarding-form`) }
            setLoading(false)
        }
    }, [details])
    React.useEffect(() => {
        var {
            companyNameError,
            cinNumberError,
            cinNumberFormatError,
            AddressError,
            TagsError,
            StateError,
            CityError,
            docs1Error,
            docs2Error,
            locationError,
            typeOfCompanyError,
            valetFeeValueError
        } = validationError;
        if (
            !!info?.companyName?.trim() &&
            !!info?.cinNumber?.trim() &&
            /^[a-zA-Z0-9]{4,30}$/gm.test(
                info?.cinNumber?.trim()
            ) &&
            !!info?.Address?.trim() &&
            !!info?.Tags?.length > 0 &&
            !!info?.State?.id &&
            !!info?.City?.id &&
            (!!info?.docs1 || !!info?.docs2) &&
            !!info?.location?.trim() &&
            !!info?.typeOfCompany
        ) {

            if (
                !(
                    companyNameError ||
                    cinNumberError ||
                    cinNumberFormatError ||
                    AddressError ||
                    TagsError ||
                    StateError ||
                    CityError ||
                    docs1Error ||
                    docs2Error ||
                    locationError ||
                    typeOfCompanyError || valetFeeValueError
                )
            ) {
                setConfirmModal(true);
            }

        } else {
            if (
                companyNameError ||
                cinNumberError ||
                cinNumberFormatError ||
                AddressError ||
                TagsError ||
                StateError ||
                CityError ||
                docs1Error ||
                docs2Error ||
                locationError ||
                typeOfCompanyError
            ) {
                toast.error("Validation Failed!!!");
            }
        }
    }, [validationError]);

    const submitHandle = async (e) => {
        setValidationError({
            companyNameError: false,
            cinNumberError: false,
            cinNumberFormatError: false,
            AddressError: false,
            TagsError: false,
            StateError: false,
            CityError: false,
            docs1Error: false,
            docs2Error: false,
            locationError: false,
            typeOfCompanyError: false,
            valetFeeValueError: false
        });
        await fieldValidation();
    };
    const createImageHandle = async (props) => {
        const formData = new FormData();
        formData.append("folder", props);
        const val = await directus.files
            .createOne(formData)
            .then((res) => {
                return res?.id;
            })
            .catch((err) => {
                console.log("Image create error", err?.message);
            });
        return val;
    };
    const tagsIds = async (prop) => {
        const updateIds = [];
        const createIds = [];
        const removeIds = [];
        info?.Tags?.map((e) => {
            const val = details?.default_branch?.tags?.filter(
                (val) => val?.Tags_Category_id === e?.Tags_Category_id
            );
            val?.[0]?.id > 0
                ? updateIds?.push(val[0]?.Tags_Category_id)
                : createIds?.push({ Branch_id: prop, Tags_Category_id: e?.Tags_Category_id });
            return "";
        });
        details?.default_branch?.tags?.map(
            (val) =>
                updateIds?.includes(val?.Tags_Category_id) ? '' : removeIds?.push(val?.id)
        );
        createIds?.length > 0 && await directus
            .items("Branch_Tags_Category")
            .createMany(createIds)
            .then(async (res) => { })
            .catch((e) => console.log(e, "tags creation error"));
        removeIds?.length > 0 && await directus
            .items("Branch_Tags_Category")
            .deleteMany(removeIds).catch(e => console.log(e));
    };
    const branchUpdateHandle = async (prop) => {
        setPreventDouble(true)
        const ImageLogoId = info?.logo ? (!!details?.default_company?.logo?.filename_disk ? (details?.default_company?.logo?.filename_disk !== info?.docs1?.filename_disk ? await createImageHandle(info?.logo) : details?.default_company?.logo?.id ? details?.default_company?.logo?.id : null) : (await createImageHandle(info?.logo))) : null

        const ImageDocs1Id = info?.docs1 ? (!!details?.default_branch?.document_1?.filename_disk ? (details?.default_branch?.document_1?.filename_disk !== info?.docs1?.filename_disk ?
            await createImageHandle(info?.docs1) : details?.default_branch?.document_1?.id ? details?.default_branch?.document_1?.id : null) : (await createImageHandle(info?.docs1))) : null;

        const ImageDocs2Id = info?.docs2 ? (!!details?.default_branch?.document_2?.filename_disk ? (details?.default_branch?.document_2?.filename_disk !== info?.docs2?.filename_disk ? await createImageHandle(info?.docs2) : details?.default_branch?.document_2?.id ? details?.default_branch?.document_2?.id : null) : (await createImageHandle(info?.docs2))) : null;
        const bodyValue = {
            address: info?.Address?.trim(),
            state_id: info?.State?.id,
            cin_gov_number: info?.cinNumber?.trim(),
            city_id: info?.City?.id,
            document_1: ImageDocs1Id,
            document_2: ImageDocs2Id,
            type_of_organization: info?.typeOfCompany,
            status: 'document_in_progress',
            reason_for_rejection: "",
            valet_fee: info?.valetFee ? info?.valetFeeValue ? Number(info?.valetFeeValue) : null : null
        };
        await directus
            .items("Branch")
            .updateOne(Number(details?.default_branch?.id), bodyValue)
            .then(async (res) => {

                const body =
                {
                    company_logo: ImageLogoId,
                    address: info?.Address?.trim(),
                    type_of_organization: info?.typeOfCompany,
                    state_id: info?.State?.id,
                    city_id: info?.City?.id,
                    document_1: ImageDocs1Id,
                    document_2: ImageDocs2Id,
                }
                await companyUpdate({
                    id: details?.default_company?.id,
                    body: body
                });
                await tagsIds(details?.default_branch?.id);

                await RegistrationMailSender({
                    emailTo: details?.email?.trim(),
                    type: "branch",
                    branchName: info?.location?.trim(),
                    companyName: info?.companyName?.trim(),
                    submitType: "document_correction"
                });

                toast.success("Data updated successfully")
                setTimeout(() => {
                    setPreventDouble(false)
                    window.location.reload();
                }, 2000)
            })
            .catch((err) => {
                navigate(".", { replace: true });
                console.log(err);
                setPreventDouble(false)
                toast.error(err);
            });
    };
    const companyUpdate = async (prop) => {
        await directus
            .items("Companies")
            .updateOne(Number(prop?.id), prop?.body)
            .then(async (res) => { })
            .catch((err) => {
                toast.error(err);
            });
    };
    const BranchStatus = details?.default_branch?.status === 'document_in_pending' || details?.default_branch?.status === 'rejected' || details?.default_branch?.status === "document_in_progress"
    return (
        <>
            {loading ? (
                <LoadingSpinner />
            ) : (!BranchStatus ? <NotFoundPage /> : <LayoutComponent title={"DOCUMENT DATA CORRECTION"} chat={false}>
                <Seo title={"Application Correction"} />
                <div>
                    <main className="main-content-area px-6 new-company">
                        <div className="block__status-bar bg-opacity-60 bg-primary">
                            <div className="flex flex-col justify-between items-center md:flex-row">
                                <label
                                    htmlFor="document-status"
                                    className="hidden md:flex text-base font-medium text-white min-w-[250px] "
                                >
                                    Document status
                                </label>
                                <div className="multi-step-container flex items-center justify-end w-full">
                                    {['document_in_pending', "document_in_progress"]?.map((e, i) => <span key={i} className={` uppercase step flex items-center text-white justify-center ${(details?.default_branch?.status === e) || (details?.default_branch?.status === "rejected" && i === 0) ? ' font-bold bg-secondary bg-opacity-90' : "bg-secondary bg-opacity-30"}`}>{e === "document_in_pending" ? "Pending" : "In progress"}</span>)}
                                </div>
                            </div>
                        </div>
                        
                        <div className="mx-auto block pt-16 pb-8">
                            {(details?.default_branch?.status !== 'document_in_pending'
                                ) && <div className=" col-span-6 text-center font-normal normal-case flex justify-center gap-1 m-4 p-2 rounded-md border text-green-800 border-green-400 bg-green-100">
                                    We're currently reviewing your application and will get back to you within the next two to three working days.
                            </div>}
                            {details?.default_branch?.status !== "document_in_progress"&&<div className="block__form data__correction">
                                <CompanyInfo
                                    info={info}
                                    setInfo={setInfo}
                                    validationError={validationError}
                                    type={"company"}
                                    allFieldEdit={details?.default_branch?.status === 'rejected' ||
                                        details?.default_branch?.status === 'document_in_pending'
                                        ? true : false}
                                    mainFieldDisable={true}
                                />

                                <div className={`flex justify-start items-center mb-4 ${details?.default_branch?.status === 'rejected' ||
                                    details?.default_branch?.status === 'document_in_pending'
                                    ? '' : " pointer-events-none"}`}>
                                <label
                                        htmlFor="valet_fee"
                                        className="hidden"
                                    >
                                        Valet Fee
                                </label>  
                                    <input
                                        id="valet_fee"
                                        type="checkbox"
                                        value={info?.valetFee}
                                        onChange={() => setInfo(prevState => ({
                                            ...prevState,
                                            "valetFee": !info?.valetFee
                                        }))}
                                        checked={info?.valetFee === true ? true : false}
                                        name="valet_fee"
                                        className={`${"border-gray-300 focus:border-primary"
                                            } w-6 h-6 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2`}
                                    />
                                    <label
                                        htmlFor="valet_fee"
                                        className="ml-2 text-base font-medium text-gray-900 "
                                    >
                                        Do you collect parking fee from customer

                                    </label>
                                </div>
                                {info?.valetFee && <>
                                    <div className={`flex justify-start items-start flex-col md:flex-row ${details?.default_branch?.status === 'rejected' ||
                                        details?.default_branch?.status === 'document_in_pending'
                                        ? '' : " pointer-events-none"}`}>
                                        <label
                                            htmlFor="valet_fee_value"
                                            className="text-sm font-medium text-gray-900 min-w-[165px] "
                                        >
                                            Enter the Parking Charge*
                                        </label>
                                        <input
                                            type="text"
                                            name="user-name"
                                            id="valet_fee_value"
                                            value={info?.valetFeeValue || ""}
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            onChange={(e) =>
                                                /^\d*\.?\d*$/.test(e.target.value) &&
                                                setInfo(prevState => ({
                                                    ...prevState,
                                                    "valetFeeValue": e.target.value
                                                }))

                                            }
                                            className={`${(validationError?.valetFeeValueError &&
                                                Number(info?.valetFeeValue) <= 0) ? "border-red-300 focus:border-red-300"
                                                :
                                                "border-gray-300 focus:border-primary"
                                                } pl-4 py-1.5 pr-9 min-w-[310px] block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
                                        />
                                    </div>
                                    {validationError?.valetFeeValueError && Number(info?.valetFeeValue) <= 0 && (
                                        <p className="text-xs text-right text-red-500 mt-2">
                                            *Parking charge is required
                                        </p>
                                    )}
                                </>}
                                <div className="form__actions flex justify-center items-center gap-5 mt-10">

                                    <button
                                        onClick={() => submitHandle()}
                                        disabled={preventDouble}
                                        className={`inline-flex items-center 
                                        ${details?.default_branch?.status !== 'document_in_pending' ?
                                                "hidden"
                                                : ""
                                            }
                                             justify-center rounded-md border-transparent h-10 border-2 bg-primary px-4 py-2 text-sm font-semibold text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto`}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>}
                        </div>
                    </main>
                </div>
                {confirmModal ? (
                    <ConfirmationModal
                        openModel={confirmModal}
                        setIsOpenModel={setConfirmModal}
                        callFunction={branchUpdateHandle}
                        content={"Are you sure the given information is valid?"}
                    />
                ) : null}
            </LayoutComponent>)}

        </>

    )
}

export default ApplicationCorrection;