/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { directus } from "../../services/directus";
import LayoutComponent from "../../components/LayoutComponent";
import UserContext from "../../contexts/userContext";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import { userPresent } from "../../utils/CommonFunctions";

export default function StaffEdit() {
  const [userDetails, setUserDetails] = React.useState();
  const navigate = useNavigate();
  const [userName, setUserName] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [defaultPhoneNo, setDefaultPhoneNo] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);

  const { state } = useLocation();
  const { details, AuthCheck, companyDetails } = React.useContext(UserContext);
  const [validationError, setValidationError] = React.useState({
    userNameError: false,
    phoneNoError: false,
    invalidPhoneNoError: false,
  });
  const [isLoading, setIsLoading] = React.useState(true);
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;
  React.useEffect(() => {
    AuthCheck();
    userDataCall();
  }, []);
  React.useEffect(() => {
    const maxLength = 10;
    if (phoneNo?.length > maxLength) {
      setPhoneNo(phoneNo.substring(0, maxLength));
    }
  }, [phoneNo?.length]);
  const userDataCall = async () => {
    AuthCheck();
    await directus.users
      .readByQuery({
        fields: ["*"],
        filter: { id: { _eq: state } },
      })
      .then((res) => {
        setUserName(res?.data[0]?.first_name);
        setUserDetails(res?.data[0]);
        setDefaultPhoneNo(res?.data[0]?.contact_number);
        setPhoneNo(res?.data[0]?.contact_number);
        setPassword(res?.data[0]?.pin_number);
        setIsLoading(false);
        return "";
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong");
      });
  };
  React.useEffect(() => {
    var {
      phoneNoError,
      userNameError,
      invalidPhoneNoError,
    } = validationError;
    if (!!userName && !!phoneNo) {
      if (
        !(
          phoneNoError ||
          userNameError ||
          invalidPhoneNoError
        )
      ) {
        setDetailsConfirmModel(true);
      } else {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);
  const phoneNoExist = async () => {
    var flag = false;
    await directus.users
      .readByQuery({
        fields: ["*"],
        filter: {
          contact_number: { _eq: phoneNo },
          default_branch: { _eq: branch },
          default_company: { _eq: company },
        },
      })
      .then((res) => (res?.data[0] ? (flag = false) : (flag = true)))
      .catch((e) => console.log(e));
    return flag;
  };
  const updatehandle = async (e) => {
    AuthCheck();
    let noChange =
      userDetails?.first_name !== userName ||
      userDetails?.pin_number !== password ||
      userDetails?.contact_number !== phoneNo;
    const updateFields = userDetails?.pin_number !== password
      ? {
        first_name: userName,
        pin_number: password,
        contact_number: phoneNo,
        is_staff_user: true,
      }
      : {
        first_name: userName,
        contact_number: phoneNo,
        is_staff_user: true,
      };

    if (noChange) {
      if (userName?.trim()?.length > 2) {
        if (phoneNo?.length === 10) {
          const LoggedCheck = await userPresent({ details: details });
          if (LoggedCheck?.status) {
            if (phoneNo !== defaultPhoneNo ? await phoneNoExist() : true) {
              await directus.users
                .updateOne(state, updateFields)
                .then((res) => {
                  navigate(
                    `/${companyDetails?.company}/${companyDetails?.branch}/staff/manage`
                  );
                  toast.success(" Updated successfully");
                })
                .catch((err) => {
                  navigate(".", { replace: true });
                  console.log(err);
                  toast.error(err?.message);
                });
            } else {
              toast.error("Contact number already exists");
            }
          } else {
            toast.error(
              "Your account was suspended, kindly contact the respective manager/admin."
            );
          }

        } else {
          toast.error("Enter valid phone number");
        }
      } else {
        toast.error("Enter valid name");
      }
    } else {
      toast.error("There is no changes");
    }
  };
  async function fieldValidation() {
    if (!!!userName?.trim()) {
      updateValidationError("userNameError", true);
    }
    if (!!!phoneNo?.trim()) {
      updateValidationError("phoneNoError", true);
    } else {
      if (phoneNo.trim()?.length < 10) {
        updateValidationError("invalidPhoneNoError", true);
      }
    }

    if (!!!password?.trim()) {
      updateValidationError("passError", true);
    }

    if (
      phoneNo?.trim()?.length === 10 &&
      password?.trim() &&
      userName?.trim()
    ) {
      setValidationError({
        userNameError: false,
        phoneNoError: false,
        passError: false,
      });
    }

    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }

  const handleModal = async (e) => {

    if (isUpdate) {
      setValidationError({
        userNameError: false,
        phoneNoError: false,
        passError: false,
      });
      await fieldValidation();
    }
  };
  let isUpdate =
    userDetails?.first_name !== userName ||
    userDetails?.pin_number !== password ||
    userDetails?.contact_number !== phoneNo;
  return (
    <LayoutComponent title={"EDIT STAFF"} fixedFooter={true} center={true}>
      <Seo title={"Staff edit"} company={true} />
      {isLoading ? (
        <LoadingSpinner />
      ) : <div className="main-content-area px-6 max-sm:px-5 pt-0">
        <div className="mx-auto">
          <div className="">
            <div className="mt-1 mb-1 block">
              <div className="space-y-6 bg-white py-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700 mb-2">
                      Name *
                    </label>
                    <input
                      className={`appearance-none ${validationError.userNameError && userName?.length < 1
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                        }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                      placeholder="Enter the name"
                      id="grid-name"
                      type="text"
                      onChange={(e) => setUserName(e.target.value)}
                      value={userName || ""}
                    />
                    {validationError.userNameError && userName?.length < 1 && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Username is required
                      </p>
                    )}
                  </div>
                  <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700 mb-2">
                      Phone number *
                    </label>
                    <input
                      className={`appearance-none ${(validationError?.phoneNoError && phoneNo?.length < 1) ||
                        (validationError.invalidPhoneNoError &&
                          phoneNo.trim()?.length < 10)
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                        }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                      placeholder="Enter the phone number"
                      id="phone-no"
                      type="text"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      maxLength={10}
                      onChange={(e) =>
                        /^\d*\.?\d*$/.test(e.target.value) &&
                        setPhoneNo(e.target.value)
                      }
                      value={phoneNo || ""}
                    />
                    {validationError?.phoneNoError && phoneNo?.length < 1 && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Phone number is required
                      </p>
                    )}
                    {validationError?.invalidPhoneNoError &&
                      phoneNo?.length < 10 && (
                        <p className="text-xs text-right text-red-500 mt-2">
                          *Invalid Phone number
                        </p>
                      )}
                  </div>
                  <div className="col-span-6 relative">
                    <label className="block text-sm font-bold text-gray-700 mb-2">
                      PIN *
                    </label>
                    <div className="relative">
                      <input
                        className={`appearance-none ${validationError.passError && password?.length < 1
                          ? "border-red-300 focus:border-red-300"
                          : "border-gray-300 focus:border-primary"
                          } cursor-default block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                        placeholder="Generate the PIN"
                        id="password"
                        type={"text"}
                        readOnly
                        onChange={null}
                        value={password || ""}
                      />
                      <span className="absolute text-center right-5 top-1/2 bottom-0 -translate-y-1/2">
                        <button
                          type="button"
                          onClick={(e) => {
                            setPassword(Math.floor(100000 + Math.random() * 900000).toString().substring(0, 4))
                          }
                          }
                          className="inline-flex items-center justify-center text-sm font-semibold text-primary "
                        >
                          <span className="ml-1.5">{password?.trim()?.length > 0 ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                          </svg>
                            : "Generate PIN"}</span>
                        </button>
                      </span>
                    </div>
                    {validationError.passError && password?.length < 1 && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Pin is required
                      </p>
                    )}
                  </div>
                  <div className="col-span-6 flex justify-end items-end pt-5">
                    <button
                      type="button"
                      onClick={(e) => handleModal(e)}
                      className={`inline-flex items-center justify-center border h-10 border-transparent hover:border-vspurple rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-md shadow-green-100 hover:bg-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto ${!isUpdate ? "opacity-50 ..." : ""
                        }`} >
                      <span className="ml-1.5">Update Staff</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {detailsConfirmModel ? (
        <ConfirmationModal
          openModel={detailsConfirmModel}
          setIsOpenModel={setDetailsConfirmModel}
          callFunction={updatehandle}
          content={
            "Are you sure the given user information is valid and to update the info?"
          }
        />
      ) : null}
    </LayoutComponent>
  );
}
