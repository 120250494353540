/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { directus } from "../services/directus";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";
import Seo from "../components/Seo";
import CustomerLayout from "../components/CustomerLayout";
import seoData from '../seoContent.json';

function PartnerWithUs() {
  const [companyList, setCompanyList] = React.useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = React.useState();
  const [selectedBranchName, setSelectedBranchName] = React.useState();
  const companyName = React.useRef(null);
  const branchName = React.useRef(null);
  const navigate = useNavigate();
  const branchListCall = async () => {
    await directus
      .items("Companies")
      .readByQuery({
        fields: ["id","name","status","slug","branches","branches.id","branches.name","branches.status","branches.slug"],
        filter: { status: { _eq: "published" } },
      })
      .then((res) => {
        setCompanyList(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  React.useEffect(() => {
    branchListCall();
  }, []);
  const styles1 = {
    menuList: (base) => ({
      ...base,
      height: "110px",

      "::-webkit-scrollbar": {
        width: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "#2684FF",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: "none",
      marginTop: "4px",
      borderColor: state.isFocused ? "grey" : "red",
    }),
  };
  return (
    <CustomerLayout len={true} head={"partner"} refProp={companyName}>
      <Seo title={seoData?.partner_with_us?.title} />
      <div className="w-full bg-[rgb(243,244,246)]">
        <div
          className="m-container bg-white py-6 px-6 shadow-sm m-auto"
          ref={companyName}
        >
          <div className="block text-xl md:text-2xl w-full text-center font-medium">
            Login
          </div>
          <div className="flex flex-col justify-between items-center mt-6">
            <div
              className="bg-white input-boxes flex justify-center border border-1 border-gray-300 rounded-md p-1 w-full sm:w-[90%] mx-auto"
              id="login"
            >
              <div className="w-full">
                {" "}
                <Select
                  id="branch"
                  classNamePrefix="cursor-pointer react-select"
                  className="react-select-container border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] capitalize"
                  defaultMenuIsOpen={false}
                  maxMenuHeight={250}
                  onChange={(e) => {setSelectedCompanyName(e);
                 return setSelectedBranchName("")}}
                  options={companyList}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => {
                    return {
                      id: option.id,
                      branches: option?.branches,
                      cname: option?.name,
                    };
                  }}
                  placeholder={"Select your Company"}
                  styles={{
                    styles1,
                    placeholder: (base)=>({
                      ...base,
                      fontWeight: 'bold',
                      color:'#9CA3AF'
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      border: "none",
                      boxShadow: "none",
                    }), input: (base, state) => ({
                      ...base,
                      '[type="text"]': {
                        border: "none",
                        boxShadow: "none",
                        outline: "none"
                      }
                    })
                  }}
                />
              </div>
              <div className="w-full border-l-2  border-gray-500 flex items-center">
                {" "}
                <Select
                  id="branch"
                  ref={branchName}
                  isDisabled={selectedCompanyName ? false : true}
                  value={selectedBranchName?selectedBranchName:''}
                  classNamePrefix="cursor-pointer react-select"
                  className=" react-select-container  border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] capitalize"
                  defaultMenuIsOpen={false}
                  maxMenuHeight={250}
                  onChange={(e) => setSelectedBranchName(e)}
                  options={selectedCompanyName?.branches?.filter(
                    (e) => e?.status === "published"
                  )}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder={"Select your Branch"}
                  styles={{
                    styles1,
                    placeholder: (base)=>({
                      ...base,
                      fontWeight: 'bold',
                      color:'#9CA3AF'
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      border: "none",
                      boxShadow: "none",
                    }), input: (base, state) => ({
                      ...base,
                      '[type="text"]': {
                        border: "none",
                        boxShadow: "none",
                        outline: "none"
                      }
                    })
                  }}
                />
              </div>
            </div>
            {selectedCompanyName?.slug && selectedBranchName?.slug && (
              <div className="flex ml-2 mt-4">
                <button
                  type="button"
                  title="Login"
                  onClick={() =>
                    navigate(
                      `/${selectedCompanyName?.slug}/${selectedBranchName?.slug}/login`,
                      {
                        state: {
                          companyName: selectedCompanyName?.slug,
                          branchName: selectedBranchName?.slug,
                        },
                      }
                    )
                  }
                  className="gonext-btn min-h-[40px] min-w-[140px] inline-flex items-center justify-center p-4 px-7 border border-primary bg-primary rounded-md text-sm font-medium text-white shadow-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto"
                > Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full bg-[rgb(243,244,246)] register-page-or-text">
        <div className="w-full bg-white block text-center or-text py-4">
          <div className="bg-primary flex items-center mx-auto rounded-[50%] border border-white justify-center text-white text-[24px] w-[60px] h-[60px] font-medium relative z-[1]">
            Or
          </div>
        </div>
      </div>
      <div className="w-full bg-[rgb(243,244,246)] flex relative bg-cover">
      <div className="m-container bg-white flex flex-wrap  p-6 align-center">
          <div className="w-full text-xl md:text-2xl font-medium text-center block p-4 pt-0 pb-2 text-black">
            Register your company on ValetSearch
          </div>
          <div className="flex flex-col md:flex-row gap-3 py-4 justify-center items-center w-full">
            <div className="link-sections">
              <Link to="/company-register">
                <div className="reg p-2 md:p-3 border bg-primary text-center block text-white rounded-md">
                  Register your company & branch
                </div>
              </Link>
            </div>
            <div className="link-sections">
              <Link to="/branch-register">
                <div className="reg p-2 md:p-3 border border-vspurple bg-white text-center block text-vspurple rounded-md">
                  Register your new branch If your company is already listed!
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white py-6 border-t-2 border-gray-200">
        <div className="m-container px-6 m-auto">
          <div className="block text-xl md:text-2xl w-full text-center py-4 font-medium">
            Here are some advantages that vendors can benefit from by partnering
            with ValetSearch:
          </div>
          <div className="">
            <div className="flex justify-center flex-wrap gap-[25px] my-4 benefits">
              <div className="bg-[rgb(243,244,246)] shadow-sm p-4 rounded-md grid col-span-1 benefit-list">
                <div className="flex items-center flex-col justify-center">
                  <span className="m-2 bg-white rounded-full p-1 w-[100px] h-[100px] flex items-center justify-center">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABcElEQVR4nO2UP0vDQBiHH5CC+Af8BoKTOhXUSYtrHY3gpLh1qquIuyBB9AM4OgiCg1NBnIriYAdB8BOIdhPEyUHlhTfwcqRJg5cGND842jd3l+e5XHKQfy6A74T29wU2gANt90UIRAmAz6IEAgOX34e8BCox11YduMgc5SGwDdwC4ylw8hBoAl96wxuV6AWXbAHn2rzCo/aYAPeapoG/A3eOSBx8HWgAK7+FNwz8A1gGRoBrA5dtcPOk/a1+QYfAbsrKF02fSFwBaz3ul0kgNI9zpw/4DDAHTGs9obW0sawCYcypdZkAl3S0r611YObWsgiEZuIr8OaIxMG9CYQOXB7nvJGI4FXzLVd9CYRmwovZS8kC8AwsaV03Y+s+BMIEeJRh89+rwL4Z2AVmSY83gc2YlctKp7SN6rgTBZz6FhgCzpyV18zE6Chta93JYwsqwKSpBy7g5v8J7OmBclyUQEsvSmcpICm3oFa+hAzoM/wBUQectVUUPtYAAAAASUVORK5CYII="
                      alt="Increased Efficiency"
                    />
                  </span>
                  <div className="content-text my-2 text-center">
                    <div className="text-lg font-bold mb-1">
                      Increased Efficiency
                    </div>
                    <span className="text-md">
                      ValetSearch's digital valet parking application is
                      designed to streamline parking operations, making the
                      parking process faster and more efficient for both vendors
                      and customers. By partnering with ValetSearch, vendors
                      can reduce wait times, increase capacity, and improve
                      customer satisfaction.
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-[rgb(243,244,246)] shadow-sm p-4 rounded-md grid col-span-1 benefit-list">
                <div className="flex items-center flex-col justify-center">
                  <span className="m-2 bg-white rounded-full p-1 w-[100px] h-[100px] flex items-center justify-center">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAADYElEQVR4nNWWS0iVQRTHfyqCLRJcFAUFXrtu2gQt2mRl4CLaSKCbFFy0CK2I8HEXVlRwwcogoryFZSY9oIIoggi6CFFIux5uzF5uqqtlLiwrohsT/7kO4/ddH+miPxz4Zuac/3nMmZkP/iPkARuAdqAPSAE/JSnNHQfKpDtvWAS0yEka+AycBb4DW4CtwA/gnNbS0m0GCv7VeRXwDrgBvAVuA0uAo8BJR+8U0AYsBe5I19psm4vjXCAOvFFJjeNOzecpw2JHvwT4qDUjF2RTJo4jQM5snF8DXgDDwBDwTSWtBOqAp0CRY1OkuTrpNMtmSBzPgSszDSIO9GrvI8AXoEENZrIaAb4Co8BvyajmRqRjGnWXbCPi6lUlpt3zV0ChxheBA57OXWXpo1JrLg4CXfouFHdoTyxSw5l9Q81mMlvs6b0GSgPsS7XmolAchgunJwJPR4vO9BMgpk630VsUqNRB5zxPaz55l7hi4jY+moKMU8BaYDNwGpgAPgFJ4Iz6YAcwACxT4+VKijQ3IJ0G2STFMSFOw70a+CC7DMwN92xySD4wDqwCKoA9QEKdbubfq7S/JKOaG5dOQjYV4hgXp8VDYL0z/tu1pkQWa4B+pqIG6CEcPdLx0S9OC3NMj7kKfaqCRbVuMh87lV0YEtLxcVMnzGIj8NhVGNYeWtQDHQFEjapWGNql46NDnBbLdXNmYB6TVm2DkXu6OGKe3FdjxUIkKR1/vlecdtyqxyyDfXpMguSRJNu3lRO6gpMz4DM+MyhTZLu9bjU4JPG/z0tcXAduBXBYmLegO8COCkX3wCOI6Ekd1ItnA6jWA2Ok1uFJBzhf55S+M8Qug3xlkXbksl6yZieAqgCi/dI/PEnHJl1w7UrwkmMXdFynwK1AJGALTEYWI/o7coMfUxAuTMDbg57mqF4rc2msyNID8YB51Nlpb25WaHTKnfbEXKt2P01JyxWI78zohqFa2Yf+lERV6kGvAj7KFURKAbnzpuQW9U7zJWa797mK1r0+q5wM4s5WWIwpCLcabXNtvlrHIBGQgb//1mG2cWjzTRdAd0AG0wWwUuNsW5kVOXLklqvGycAPwHfYr2Y2p2reUew0a0mIQ7N2FXi5EAHsdY5rU4jDqB4b8zc074jKoXFmHSyowwXFH03gHqF1uYtTAAAAAElFTkSuQmCC"
                      alt="Advanced Technology"
                    />
                  </span>
                  <div className="content-text my-2 text-center">
                    <div className="text-lg font-bold mb-1">
                      Advanced Technology
                    </div>
                    <span className="text-md">
                      ValetSearch uses cutting-edge technology to provide
                      real-time tracking, secure payment processing, and
                      customizable reporting tools. These features enable
                      vendors to better manage their parking operations,
                      optimize resources, and maximize revenue.
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-[rgb(243,244,246)] shadow-sm p-4 rounded-md grid col-span-1 benefit-list">
                <div className="flex items-center flex-col justify-center">
                  <span className="m-2 bg-white rounded-full p-1 w-[100px] h-[100px] flex items-center justify-center">
                     <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABcElEQVR4nO3VsUscQRjG4SeQQkifIlaC6RXBYC3YmCKkUDhQS1tJkYAWnp3WsThIipBYqVxlFUFBrDRNqvwBB2kThEPRC4aFSbhb13Pvdm/VkBfeZpjve38zszPLHVcFuxn9NgtAHVWsdulq6JEJ4GWG+qj2P0D9nz6CSvCtAEzgK44xUjTAB5xiL7iB10UBvMAvjDaNPcc5BooAWMH3hLmLeFYEwBwuMZmivicAfdgPEIdYwlCRAJEehFsQ/Wi+BZgdPNIlwAw2E9xI+RCN4wfWEwAa1/SOMv/qVVhFLTbpI55o1RqmXdU7HMXG+kOP5p61kBVltqgcaEva6z2+4GFs/DO2b6gthYwoK1HlFBBP8RMHmMcsNnCGsSzhnUAMhq2NQE6wheE8wv/oTSho+VAS9Cm4naZwgWUdqpxiJx4H57bybnci15XnAZFbeDcQuYd3AtGz8DQQPQ9vB1FYeBJE4eHxdyLTPZdRC8H3V78BW1efNhFA7o8AAAAASUVORK5CYII="
                      alt="Cost-Effective"
                    />
                  </span>
                  <div className="content-text my-2 text-center">
                    <div className="text-lg font-bold mb-1">Cost-Effective</div>
                    <span className="text-md">
                      By partnering with ValetSearch, vendors can save on the
                      costs associated with managing a traditional parking
                      operation. This includes the cost of hiring and training
                      personnel, purchasing equipment, and managing logistics.
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-[rgb(243,244,246)] shadow-sm p-4 rounded-md grid col-span-1 benefit-list">
                <div className="flex items-center flex-col justify-center">
                  <span className="m-2 bg-white rounded-full p-1 w-[100px] h-[100px] flex items-center justify-center">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAACcElEQVR4nO2WTYiNURjHfww1rnI1o+RjYXPFIBqXCKHkMy6Sj/FRzGJqNiIpi1lMkq8oGzUbikRMzWLIKM3Gwg6LmcUsrgXSSGaBBVfjTqf+d3o7nfPec997k+Rfz+I95zz//9Pzcd4D/5EMdcA6oFW2Vms1wW7gFpD27B8F3gNFy94Bhz0+aXHmQgJoAvKyrLV3xSFs20XLJxvhM9xBSAPdwA9gkdYOBoiXbL98FoujOyajsdgCpIAJwFtL5CfwFLjvCCAvn5Q4qkbWIbIEmAEc8WShOYnQaeBhxEzDGbQ4BEYl7gvgkHyPWZxGw4sTQFfEcmXqHxfAAfnmLM7jSTKzNEEApkRVoU7pa9D3oEPkgcxeH5BPozgqvqRmAf3AV2Ch1rYDvwNG0JzZKp8mcfSLMwjNwDDwGshYe2fKBDHqaLKMuIZDJ2MjcAmo9+xvBt44xF8Bmzw+9eLcQA0xH9gF7HRk6q/FMmBHyLU8RfN7A3gE3AMuAGuAiQmE54inVK4vvh9TBrgOjMQ02hDQDkwNEDbjd1KTYPOYS2kcq4BngWNWshE11lzcWKnG9Pnfjh5+UoGwbQX9EY1gCVc1kj6f78DyaAB9AUKmbp26VAqeMy9U744YnrwtHhrAB5XKNOF0PTruAJ+tc+3qdBdHj3xJEkBR9kn12wdMU6Ot1pR06f6/7CjTKT1SqDaAokX8XNfvAmC2ur5gPVZNgLHoq6IJ48wEN7OcuB2A6dBrumL3ADeV9kqEfwHn4lLuC+AlMM+xPwnYBtwFvpUR/wisp0KcBc4DkwPOpvTe63WM4+PQlNcKjcBeoA1Y8SeF+WcwBv9Lb19VRcZkAAAAAElFTkSuQmCC"
                      alt="Increased Revenue"
                    />
                  </span>
                  <div className="content-text my-2 text-center">
                    <div className="text-lg font-bold mb-1">
                      Increased Revenue
                    </div>
                    <span className="text-md">
                      ValetSearch's advanced features and user-friendly
                      interface make it easy for customers to find parking. This
                      can help increase revenue for vendors by attracting more
                      customers and generating repeat business.
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-[rgb(243,244,246)] shadow-sm p-4 rounded-md grid col-span-1 benefit-list">
                <div className="flex items-center flex-col justify-center">
                  <span className="m-2 bg-white rounded-full p-1 w-[100px] h-[100px] flex items-center justify-center">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABfklEQVR4nO2VwUoDMRCGP7s+Q1WwoCdfQp9BPHjt0YP4ZFK0F0U8uooHe1d68A3ErfUeCfyBsJrsJmxbFH8YyMxO/vl3kp2Ff3zHOjAEnmV2XbAEnh5wDLwApmavwIlIm5DN8+glTqV4qLWL25wmZPOYFhutNSGbx3ib6q2bZghI5jGyQoqd7y5PqoBkHhNIrBO1FZDMUwZa5a/vWgjI5umt+jN0KDoaRF3xrAYPOssQBsAI+JBdAHsNfPcpAkzkxtvibz+cr41tZ/A1brDKn4C+/JGeXamgtWvFzpVjcydeF7MFGM+sCNRyU3vbgWIz+ZMAR2uUEQEz+a4jFhuKVREBbWZIUIx/BLciPPVyzhS7kd/XnuyiMRyp2DuwDxzozW3skCVgTZet3mJ3AReGXWAMfAYumLU5cAnsLKJ4FSlct6prEWMR24m3GcnbUgdcbmeYizRW3BdhNC86g/EGSeg/4c/75MHTBJ8wRN4m5/cLMInWGcqM4gsZw/w5fAHJQyOI6p7xOQAAAABJRU5ErkJggg=="
                      alt="Brand Awareness"
                    />
                  </span>
                  <div className="content-text my-2 text-center">
                    <div className="text-lg font-bold mb-1">
                      Brand Awareness
                    </div>
                    <span className="text-md">
                      Partnering with ValetSearch can help vendors increase
                      brand awareness and visibility.ValetSearch's platform
                      provides opportunities for vendors to showcase their brand
                      and reach new customers, ultimately driving business
                      growth.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block text-xl md:text-2xl w-full text-center py-4 font-medium">
            Overall, partnering with ValetSearch can provide vendors with a
            competitive advantage in the parking industry, enabling them to
            optimize their operations, increase revenue, and improve customer
            satisfaction.
          </div>
        </div>
      </div>
    </CustomerLayout>
  );
}

export default PartnerWithUs;
