/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomerLayout from "../../components/CustomerLayout";
import { directus } from "../../services/directus";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import InputMask from 'react-input-mask';
import { valetNumberRegex,vehicleMinLetterCheck } from "../../utils/CommonFunctions";

function Register() {
  const [vehicleFormat, setVehicleFormat] = React.useState("");
  const vehicle = !!vehicleFormat ? vehicleFormat?.replaceAll('-', '')?.toUpperCase() : "";
  const [userName, setUserName] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [companyID, setCompanyID] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [select, setSelect] = React.useState(true);

  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
  const [validationError, setValidationError] = React.useState({
    phoneNoError: false,
    vehicleNumberError: false,
    invalidVehicleNoError: false,
    invalidPhoneNoError: false,
    termsError: false,
  });
  const navigate = useNavigate();
  const branch_No = companyID?.branch;
  const company = companyID?.company;
  const { companyName, branchName } = useParams();
  const isAlphaNumeric = (str) => valetNumberRegex.test(str);
  const CompanyBranchCheck = async () => {
    await directus
      .items("Companies")
      .readByQuery({
        fields: ["id"],
        filter: {
          status: { _eq: "published" },
          slug: { _eq: companyName },
          branches: { slug: { _eq: branchName } },
        },
      })
      .then(async (res) => {
        if (res?.data[0]) {
          await directus
            .items("Branch")
            .readByQuery({
              fields: ["id","chat_room_id"],
              filter: {
                status: { _eq: "published" },
                slug: { _eq: branchName },
              },
            })
            .then((response) => {
              setCompanyID({
                company: res?.data[0]?.id,
                branch: response?.data[0]?.id,
                chatRoomId: response?.data[0]?.chat_room_id

              });
            });
        } else {
          toast.error("Invalid company / branch");
        }
      })
      .catch((e) => console.log(e));
    setIsLoading(true);
  };
  React.useEffect(() => {
    CompanyBranchCheck();
    const maxLength = 10;
    if (phoneNo?.length > maxLength) {
      setPhoneNo(phoneNo.substring(0, maxLength));
    }
  }, [phoneNo?.length]);

  React.useEffect(() => {
    var {
      invalidVehicleNoError,
      invalidPhoneNoError,
      termsError,
      phoneNoError,
      vehicleNumberError,
    } = validationError;
    if (!!vehicle && !!phoneNo) {
      if (
        !(
          invalidVehicleNoError ||
          invalidPhoneNoError ||
          phoneNoError ||
          termsError ||
          vehicleNumberError
        )
      ) {
        // usersCall()
        setDetailsConfirmModel(true);
      } else {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);
  const vehicleNoExist = async () => {
    var flag = false;
    await directus
      .items("Valet_Information")
      .readByQuery({
        fields: ["status_id.sequence"],
        sort: ["-date_updated"],
        filter: {
          Vehicle_Number: { _eq: vehicle },
          branch: { _eq: branch_No },
          company: { _eq: company },
        },
      })
      .then((res) => {
        return res?.data[0]
          ? res?.data[0]?.status_id?.sequence !== 6
            ? (flag = true)
            : (flag = false)
          : (flag = false);
      })
      .catch((e) => console.log(e));
    return flag;
  };
  async function fieldValidation() {
    if (!!!phoneNo?.trim()) {
      updateValidationError("phoneNoError", true);
    } else {
      if (phoneNo.trim()?.length < 10) {
        updateValidationError("invalidPhoneNoError", true);
      }
    }

    if (vehicle?.trim()?.length < 1) {
      updateValidationError("vehicleNumberError", true);
    } else {
      if (
        vehicle?.trim()?.length > 0 &&
       ( vehicle.replaceAll(/\s/g, "")?.length < 5||!vehicleMinLetterCheck.test(vehicle))
      ) {
        updateValidationError("invalidVehicleNoError", true);
      }
    }
    if (!select) updateValidationError("termsError", true);
    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }
  const handleModal = async (e) => {
    e.preventDefault();
    setValidationError({
      vehicleNumberError: false,
      phoneNoError: false,
      termsError: false,
    });
    await fieldValidation();
  };
  const timeUpdate = async (prop) => {
    await directus
      .items("Valet_Information")
      .updateOne(Number(prop?.id), {
        started_time: prop?.date_updated,
      })
      .then((res) => { })
      .catch((err) => {
        console.log(err);
      });
  };
  const phoneNoExist = async () => {
    var flag = false;
    await directus
      .items("Valet_Information")
      .readByQuery({
        fields: ["id"],
        filter: {
          Customer_Phone_Number: { _eq: phoneNo },
          branch: { _eq: branch_No },
          company: { _eq: company },
        },
      })
      .then((res) => (res?.data[0] ? (flag = true) : (flag = false)))
      .catch((e) => console.log(e));
    return flag;
  };
  async function callback() {
    if (userName?.trim()?.length ? userName?.trim()?.length > 2 : true) {
      if (vehicle?.trim()?.length > 5 && vehicle?.trim()?.length <= 10 && isAlphaNumeric(vehicle)) {
        var a = Math.floor(100000 + Math.random() * 900000);
        const randomOtp = a.toString().substring(0, 4);
        const PhoneNoExitRes = !(await phoneNoExist());
        if (PhoneNoExitRes) {
          const vehicleNoExitRes = !(await vehicleNoExist());
          if (vehicleNoExitRes) {
            await directus
              .items("Valet_Information")
              .createOne({
                Customer_Name: userName,
                Vehicle_Number: vehicle,
                OTP_Value: randomOtp,
                Customer_Phone_Number: phoneNo,
                branch: branch_No,
                company: company,
              })
              .then(async (res) => {
                await timeUpdate(res);
                navigate(
                  `/${companyName}/${branchName}/customer/status?id1=${phoneNo}&id2=${vehicle?.slice(-4)}`);
                toast.success("Registered successfully");
              })
              .catch((err) => {
                // navigate('.', { replace: true });
                console.log(err);
                toast.error(err?.message);
              });
          } else {
            toast.error("This vehicle is already in progress");
          }
        } else {
          toast.error(
            "This mobile number already exists, kindly log in with your mobile number / enter some other number"
          );
        }
      } else {
        toast.error("Invalid vehicle number");
      }
    } else {
      toast.error("Enter valid user name (minimum 3 character)");
    }
  }
  return (
    <>
      <Seo title={`Registration | ${companyName} | ${branchName}`} />
      {
        <CustomerLayout
          home={true}
          goBack={true}
          containerView={false}
          isCompany={true}
        >
          {!isLoading ? (
            <LoadingSpinner />
          ) : !companyID?.company ? (
            <div className="flex justify-center place-center">
              Invalid url Kindly scan QR again
            </div>
          ) : (
            <div className="relative  w-full inline-block  firefox-scrollbar  overflow-hidden overflow-y-auto">
              <main className="main-content-area px-6 py-8">
                <div className="mx-auto">
                  <div className="">
                    <div className=" block">
                      <form method="POST" onSubmit={(e) => handleModal(e)}>
                        <div className="space-y-6 bg-white">
                          <div className="grid grid-cols-6 gap-6 max-w-[700px] mx-auto">
                            <div className={`col-span-6`}>
                              <label htmlFor="vehicle-number" className="block text-sm font-bold text-gray-700 mb-2">
                                Enter your vehicle number *
                              </label>
                              <InputMask
                                id="vehicle-number"
                                mask="*-*-*-*-*-*-*-*-*-*"
                                value={vehicleFormat?.toUpperCase()}
                                maskChar=""
                                placeholder="A-A-0-0-A-A-0-0-0-0"
                                className={`appearance-none ${(validationError?.vehicleNumberError &&
                                  vehicle?.length < 1) ||
                                  (validationError?.invalidVehicleNoError &&
                                  (  vehicle?.trim()?.length < 5||!vehicleMinLetterCheck.test(vehicle)))
                                  ? "border-red-300 focus:border-red-300"
                                  : "border-gray-300 focus:border-primary"
                                  }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-primary focus:border-primary placeholder:text-gray-300`}
                                onChange={(e) => setVehicleFormat(e?.target?.value)}
                              />
                              {(validationError.vehicleNumberError ||
                                validationError?.invalidVehicleNoError) &&
                                vehicle?.length < 1 && (
                                  <p className="text-xs text-right clear-both block text-red-500 mt-2">
                                    *Vehicle number is required
                                  </p>
                                )}
                              {validationError?.invalidVehicleNoError &&
                               (( vehicle?.length > 0 &&
                                vehicle?.length < 10)||!vehicleMinLetterCheck.test(vehicle)) && (
                                  <p className="clear-both block text-xs text-right text-red-500 mt-2">
                                    *Invalid Vehicle number
                                  </p>
                                )}
                            </div>
                            <div className="col-span-6">
                              <label className="block text-sm font-bold text-gray-700 mb-2" htmlFor="phone-no">
                                Enter your phone number *
                              </label>
                              <input
                                className={`appearance-none ${(validationError?.phoneNoError &&
                                  phoneNo?.length < 1) ||
                                  (validationError?.invalidPhoneNoError &&
                                    phoneNo?.trim()?.length < 10)
                                  ? "border-red-300 focus:border-red-300"
                                  : "border-gray-300 focus:border-primary"
                                  }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-primary focus:border-primary placeholder:text-gray-300`}
                                placeholder="Enter your phone number"
                                id="phone-no"
                                type="text"
                                pattern="[0-9]*"
                                inputMode="numeric"
                                maxLength={10}
                                onChange={(e) =>
                                  /^\d*\.?\d*$/.test(e.target.value) &&
                                  setPhoneNo(e.target.value)
                                }
                                value={phoneNo || ""}
                              />
                              {(validationError?.phoneNoError ||
                                validationError?.invalidPhoneNoError) &&
                                phoneNo?.length < 1 && (
                                  <p className="text-xs text-right text-red-500 mt-2">
                                    *Phone number field is required
                                  </p>
                                )}
                              {validationError?.invalidPhoneNoError &&
                                phoneNo?.trim()?.length > 0 &&
                                phoneNo?.trim()?.length < 10 && (
                                  <p className="text-xs text-right text-red-500 mt-2">
                                    *Invalid Phone number
                                  </p>
                                )}
                            </div>
                            <div className="col-span-6">
                              <label className="block text-sm font-bold text-gray-700 mb-2" htmlFor="u-name-01">
                                Enter your name
                              </label>
                              <input
                                type="text"
                                name="name"
                                id="u-name-01"
                                placeholder="Enter your name"
                                autoComplete="name"
                                value={userName || ""}
                                onChange={(e) => setUserName(e?.target?.value)}
                                className={`mt-1 pl-4 py-3 pr-9 min-w-[310px] block w-full rounded-md border text-sm shadow-sm  border-gray-300 focus:border-primary
                                 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300`}
                              />
                            </div>
                            <div className="col-span-6 flex justify-start items-center text-sm gap-2">
                              <input
                                type="checkbox"
                                name="Terms and Conditions"
                                onChange={(e) => {
                                  setSelect(!select);
                                }}
                                checked={select ? true : false}
                                className={`h-4 w-4 rounded ${validationError.termsError && select === false
                                  ? "border-red-500"
                                  : "border-gray-300"
                                  }  text-primary focus:ring-primary`}
                                id="u-terms-condi-01"
                              />
                              <label
                                htmlFor={`u-terms-condi-01 `}
                                className={`${validationError.termsError && select === false
                                  ? "text-red-500"
                                  : ""
                                  }`}
                              >
                                I accept the{" "}
                                <Link
                                  to={`/customer/terms-of-service`}
                                  target="_blank"
                                  className="text-primary"
                                >
                                  terms and conditions
                                </Link>
                              </label>
                            </div>
                            <div
                              className="col-span-6 flex justify-center items-center pt-5"
                              x-data="{showLoader:false}"
                            >
                              <button
                                type="submit"
                                className="flex items-center justify-center h-12 border w-1/2 rounded-md  border-transparent bg-primary px-4 py-2 text-sm font-semibold text-white hover:bg-vspurple hover:border-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                              >
                                <span>Submit</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          )}
          {detailsConfirmModel ? (
            <ConfirmationModal
              openModel={detailsConfirmModel}
              setIsOpenModel={setDetailsConfirmModel}
              callFunction={callback}
              content={`Are you sure the given information is valid ? <br/> <br/>
               <strong>Vehicle number:</strong> ${vehicle} <br/> 
               <strong>Phone number:</strong> 
               ${phoneNo} 
               ${userName.length > 0
                  ? `<br /><strong>Name:</strong> ${userName}`
                  : ``
                }`}
            />
          ) : null}
        </CustomerLayout>
      }
    </>
  );
}

export default Register;
