/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomerLayout from "../../components/CustomerLayout";
import { directus } from "../../services/directus";
import Seo from "../../components/Seo";
import { sha256 } from 'js-sha256';
import { PAY_API_BASE_URL, FRONTEND_URL, PHONEPE_MERCHANT_ID, PHONEPE_MERCHANT_KEY, PAY_API_ENPOINT } from "../../utils/utils";
import LoadingSpinner from "../../components/LoadingSpinner";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import UserContext from "../../contexts/userContext";

function CustomerPayment() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [companyID, setCompanyID] = React.useState({});
    const { companyName, branchName } = useParams();
    const [valetFee, setValetFee] = React.useState(0);
    const [valetTip, setvaletTip] = React.useState(0);
    const [valetCustomTip, setValetCustomTip] = React.useState(0);
    const [valetTotal, setValetTotal] = React.useState();
    const branch_No = companyID?.branch;
    const company = companyID?.company;
    const [stateValue, setStateValue] = React.useState({});
    const [tax, setTax] = React.useState();
    const [paymentConfirmModel, setPaymentConfirmModel] = React.useState(false);
    const { commonInfo } = React.useContext(UserContext);
    const paymentStatus = stateValue?.payment_status;
    const otpOrPhone = new URLSearchParams(window.location.search).get("id1");
    const vehicleNo = new URLSearchParams(window.location.search).get("id2");
    
    React.useEffect(() => {
        commonInfo&&setTax(commonInfo?.tax_config_in_percent)
      },[commonInfo])
    React.useEffect(() => {
        CompanyBranchCheck();
        reFreshCall();
        setTimeout(() => {
            setIsLoading(true);
        }, 5000);
    }, [company]);

    const reFreshCall = async () => {
        if (otpOrPhone && branch_No && company) {
            let vehicleFilter = vehicleNo;
            const userData =
                otpOrPhone?.trim()?.length === 4
                    ? await directus
                        .items("Valet_Information")
                        .readByQuery({
                            fields: ["id","Vehicle_Number","date_updated","branch.valet_fee","payment_status","valet_tip"],
                            sort: ["-date_updated"],
                            filter: {
                                OTP_Value: { _eq: otpOrPhone },
                                branch: { _eq: branch_No },
                                company: { _eq: company },
                            },
                        })
                        .then((res) => {
                            const filterValue = res?.data?.filter((e) => {
                                return e?.Vehicle_Number?.slice(-4) === vehicleFilter
                                    ? e
                                    : "";
                            });
                            return filterValue?.[0];
                        })
                        .catch((e) => console.log(e))
                    : await directus
                        .items("Valet_Information")
                        .readByQuery({
                            fields: ["id","Vehicle_Number","date_updated","branch.valet_fee","payment_status","valet_tip"],
                            sort: ["-date_updated"],
                            filter: {
                                Customer_Phone_Number: { _eq: otpOrPhone },
                                branch: { _eq: branch_No },
                                company: { _eq: company },
                            },
                        })
                        .then((res) => {
                            const filterValue = res?.data?.filter((e) => {
                                return e?.Vehicle_Number?.slice(-4) === vehicleFilter
                                    ? e
                                    : "";
                            });
                            return filterValue?.[0];
                        })
                        .catch((e) => console.log(e));
            userData?.payment_status === 'paid' ? setValetTotal(0): setValetTotal(userData?.branch?.valet_fee);
            setStateValue(userData);
        }

    };

    const CompanyBranchCheck = async () => {
        await directus
            .items("Companies")
            .readByQuery({
                fields: ["id","name"],
                filter: {
                    status: { _eq: "published" },
                    slug: { _eq: companyName },
                    branches: { slug: { _eq: branchName } },
                },
            })
            .then(async (res) => {
                if (res?.data[0]) {
                    await directus
                        .items("Branch")
                        .readByQuery({
                            fields: ["id","name","chat_room_id","valet_fee"],
                            filter: {
                                status: { _eq: "published" },
                                slug: { _eq: branchName },
                            },
                        })
                        .then((response) => {
                            setValetFee(response?.data[0]?.valet_fee)
                            setCompanyID({
                                company: res?.data[0]?.id,
                                branch: response?.data[0]?.id,
                                companyName: res?.data[0]?.name,
                                branchName: response?.data[0]?.name,
                                chatRoomId: response?.data[0]?.chat_room_id,
                                valetFee: response?.data[0]?.valet_fee
                            });
                        });
                } else {
                    toast.error("Invalid company / branch");
                }
            })
            .catch((e) => {
                console.log(e)
            });
    };

    const randomString = (length, chars) => {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
    const valetPayOnline = async () => {
        var orderPayload = {
            "merchantId": PHONEPE_MERCHANT_ID,
            "merchantTransactionId": "EW" + randomString(30, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'),
            "amount": valetTotal * 100,
            "merchantUserId": "EW" + randomString(30, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'),
            "redirectUrl": `${FRONTEND_URL}/${companyName}/${branchName}/customer/status?id1=${otpOrPhone}&id2=${vehicleNo}`,
            "redirectMode": "REDIRECT",
            "callbackUrl": `${FRONTEND_URL}/${companyName}/${branchName}/customer/status?id1=${otpOrPhone}&id2=${vehicleNo}`,
            "paymentInstrument": {
                "type": "PAY_PAGE"
            }
        }
        const filterBody=stateValue?.payment_status==='paid'?{
            payment_mode: 'online',
            valet_tip: valetTip,
            merchant_tip_id: orderPayload?.merchantId,
            merchant_transaction_tip_id: orderPayload?.merchantTransactionId,
        }:{
            valet_fee: valetFee,
            payment_mode: 'online',
            valet_tip: valetTip,
            merchant_id: orderPayload?.merchantId,
            merchant_transaction_id: orderPayload?.merchantTransactionId,
            tax_in_percent: tax
        }
        await directus
            .items("Valet_Information")
            .updateOne(Number(stateValue?.id),filterBody)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
        var payloadEncloded = btoa(JSON.stringify(orderPayload));
        const hash = sha256.create()
        hash.update(payloadEncloded + PAY_API_ENPOINT + PHONEPE_MERCHANT_KEY)
        var checkoutSumValue = hash.hex() + "###1";
        await fetch(PAY_API_BASE_URL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({
                "token": checkoutSumValue,
                "request": payloadEncloded
            }),
        })
            .then(async (response) => {
                // get json response here
                let data = await response.json();
                let output = JSON.parse(data?.output);
                if (output?.success) {
                    if (output?.data?.instrumentResponse?.redirectInfo?.url) {
                        window.location.href = output?.data?.instrumentResponse?.redirectInfo?.url;
                    }
                    else {
                        toast.error("Sorry for inconvenience. Unable to proceed payment due to some technical issue. Try again later!")
                    }
                }
                else {
                    toast.error("Sorry for inconvenience. Unable to proceed payment due to some technical issue. Try again later!")
                }
            })
            .catch((e) => {
                console.log(e)
                toast.error("Sorry for inconvenience. Unable to proceed payment due to some technical issue. Try again later!")
            });
    }
    
    return (
        <CustomerLayout home={false} goBack={true} containerView={false} isCompany={true} chatRoomId={companyID?.chatRoomId}>
            <Seo title={`Payment | ${companyName} | ${branchName}`} />
            {!isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="relative  w-full inline-block  firefox-scrollbar  overflow-hidden overflow-y-auto">
                    {stateValue !== undefined && Object.keys(stateValue)?.length > 0 ? (
                        <div className="relative  w-full bg-white inline-block  firefox-scrollbar overflow-hidden overflow-y-auto">
                            <main className="main-content-area px-6 py-8 ">
                                <div className="mx-auto">
                                    <div className="customer-login">
                                        <div className="mt-1 mb-1 block">
                                            <form method="POST">
                                                <div className="space-y-6 bg-white py-6">
                                                    <div className={`grid grid-cols-6 gap-4 payment register-login-section`}>
                                                        {<>
                                                            <div className="col-span-6 title text-black font-bold text-xl text-center block pb-4">Valet Payment</div>
                                                            <div className="col-span-6 title text-black font-bold text-xl block">
                                                                <div className={`${paymentStatus === 'paid' ? 'justify-center' : null} flex items-center gap-1 `}>
                                                                    <label htmlFor="payment-status" className={`${paymentStatus !== 'paid' ? 'min-w-[175px] max-sm:min-w-[115px] max-sm:max-w-[115px]' : 'pr-2'} max-sm:text-lg`}>Status:</label>
                                                                    {/* <span className="currency-symbol">₹</span> */}
                                                                    <span className={`text-lg font-medium border px-2.5 py-0.5 rounded text-center ${paymentStatus === "paid" ? 'text-green-800 border-green-400 bg-green-100' : 'text-red-800 border-red-400 bg-red-100'}`}>{paymentStatus === "paid" ? "Paid" : "Not paid"}</span>
                                                                </div>
                                                            </div>
                                                            {paymentStatus !== "paid" && <div className="col-span-6 title text-black font-bold text-xl block">
                                                                <div className="flex items-center gap-1">
                                                                    <label htmlFor="valet-fee" className="min-w-[175px] max-sm:min-w-[115px] max-sm:max-w-[115px] max-sm:text-lg">Valet Fee: </label>
                                                                    <span className="currency-symbol">Rs.</span>
                                                                    <input type="number" id="valet-fee" name="valet-fee" defaultValue={valetFee?.toFixed(2)}
                                                                        className="read-only:bg-gray-100 pointer-events-none border-0 w-full p-0" />
                                                                </div>
                                                            </div>}
                                                            {Number(stateValue?.valet_tip)<=0 && <div className="col-span-6 title text-black font-bold text-xl block">
                                                                <div className="flex items-start gap-1">
                                                                    <label className="min-w-[175px] max-sm:min-w-[115px] max-sm:max-w-[115px] max-sm:text-lg" htmlFor="valet-fee">Valet Tips: </label>
                                                                    <div className="pb-2">
                                                                        <div className="pb-2 font-normal text-base">Show your support for the team at <span className="font-bold">{companyID?.companyName} - {companyID?.branchName}</span></div>
                                                                        <div className="w-full grid grid-cols-6 max-xs:grid-cols-3 gap-x-1 gap-y-2 items-center">
                                                                            {[10, 20, 50]?.map((res, i) => <div key={i} onClick={(e) => {
                                                                                setvaletTip(valetTip===res?"":res)
                                                                                setValetTotal(paymentStatus !== "paid" ? (valetTip===res?0: res) + (Number(valetFee)) :valetTip===res?0: res)
                                                                            }} className={`${valetTip === res ? "bg-secondary" : "bg-primary border-vspurple"} mx-auto cursor-pointer w-10 inline-block p-2 rounded-full border text-white text-sm text-center focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2`}>
                                                                                {res}
                                                                            </div>)}
                                                                            <div onClick={(e) => {
                                                                                setvaletTip(valetTip==='custom'?"":'custom')
                                                                                valetTip!=='custom'&&  setValetCustomTip(0)
                                                                                setValetTotal(paymentStatus !== "paid" ? (Number(valetFee)) : 0)
                                                                            }} className={`${valetTip === 'custom' ? "bg-secondary" : "bg-primary"} col-span-2 max-xs:col-span-3 cursor-pointer inline-block p-2 rounded-full border border-vspurple bg-primary text-white text-sm text-center focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2`}>
                                                                                Custom
                                                                            </div>
                                                                            {valetTip === 'custom' && 
                                                                            <>
                                                                            <label htmlFor="valet-tips" className="hidden">Valet Tips</label>  
                                                                            <input type="number" id="valet-tips" className="col-span-5 max-xs:col-span-3 mt-2" name="valet-tips" defaultValue={valetCustomTip} autoFocus
                                                                                onChange={(e) => {
                                                                                    setValetCustomTip(e?.target?.value)
                                                                                    setValetTotal(paymentStatus !== "paid" ? (Number(valetFee) + Number(e?.target?.value)) : Number(e?.target?.value))
                                                                                }} />
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        {(Number(valetTip) > 0 || Number(valetCustomTip) > 0) && <div className="pt-2 font-normal text-sm">Thank you, we appreciate it!</div>}
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                            {(paymentStatus !== "paid" || (Number(stateValue?.valet_tip)<=0)) && <div className="col-span-6 title text-black font-bold text-xl block">
                                                                <div className="flex items-center gap-1">
                                                                    <label htmlFor="valet-fee" className="min-w-[175px] max-sm:min-w-[115px] max-sm:max-w-[115px] max-sm:text-lg">Total Amount: </label>
                                                                    <span className="currency-symbol">Rs.</span>
                                                                    <label htmlFor="valet-total" className="hidden">Valet Total</label>  
                                                                    <input type="number" id="valet-total" name="valet-total" value={valetTotal?.toFixed(2) || "0"} onChange={(e) => setValetTotal(e?.target?.value)} className="read-only:bg-gray-100 pointer-events-none border-0 w-full p-0" />
                                                                </div>
                                                            </div>}
                                                            <div className="col-span-6 text-center flex justify-center items-center max-sm:flex-col-reverse gap-x-10 gap-y-5 my-5">
                                                                <button type="button" onClick={(e) => navigate(`/${companyName}/${branchName}/customer/status?id1=${otpOrPhone}&id2=${vehicleNo}`)} className="flex-col font-semibold cursor-pointer min-w-[200px] max-w-[200px]  inline-block p-2 md:p-3 rounded-md border-2 border-red-600 bg-red-50 text-red-600 text-md text-center items-center focus:outline-none focus:ring-offset-2">
                                                                    Back
                                                                </button>
                                                                {(paymentStatus !== "paid" || Number(stateValue?.valet_tip)>=0) && <button type="button" onClick={(e) => setPaymentConfirmModel(true)} className={`${Number(valetTotal)<= 0? "pointer-events-none cursor-not-allowed  opacity-50":""} flex-col font-semibold text-primary cursor-pointer min-w-[200px] max-w-[200px]  inline-block p-2 md:p-3 rounded-md border border-vspurple bg-primary text-white text-md text-center justify-center items-center focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2`}>
                                                                    Pay Now
                                                                </button>}
                                                            </div>
                                                        </>}
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="text-center font-semibold justify-evenly text-lg md:text-xl text-red-600 px-2.5 py-2">
                                                During the payment processing, Please do not close the payment window or press the browser back button
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </main>
                        </div>

                    ) : (
                        <div
                            className={`border rounded-md border-gray-200 whitespace-nowrap text-sm m-10 justify-items-center p-4 text-gray-500 flex justify-center text-center mt-10 shadow-md ring-1 ring-black ring-opacity-5`}
                        >
                            <p className="text-center place-self-center">No data found</p>
                        </div>
                    )}
                </div >
            )}
            {paymentConfirmModel ? (
                <ConfirmationModal
                    openModel={paymentConfirmModel}
                    setIsOpenModel={setPaymentConfirmModel}
                    callFunction={valetPayOnline}
                    content={`<div><div>Are you sure to pay via online mode?</div>
                    <div class="font-bold mt-2">Total amount: ${valetTotal?.toFixed(2)}</div>
                    </div>`}
                />
            ) : null}
        </CustomerLayout>
    );
}

export default CustomerPayment;
