/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { toast } from "react-toastify";
import LayoutComponent from "../../components/LayoutComponent";
import Seo from "../../components/Seo";
import { directus } from "../../services/directus";
import { RegistrationMailSender } from "../../utils/Mailsender";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import UserContext from "../../contexts/userContext";
import { PDFDownloadLink, Document, Page, Text, StyleSheet, View, Font } from '@react-pdf/renderer';
import moment from "moment";
import Files from "react-files";
import LoadingSpinner from "../../components/LoadingSpinner";
import NotFoundPage from "../NotFoundPage";
import { useNavigate } from "react-router-dom";

function OnBoardingForm() {
  Font.register({
    family: "Epilogue_Bold",
    src: "https://www.valetsearch.com/fonts/Epilogue-Bold.woff",
  })

  const [userName, setUserName] = React.useState("");
  const [agreementInfo, setAgreementInfo] = React.useState("");
  const [showPdf, setShowPdf] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [designation, setDesignation] = React.useState("");
  const [contractDefault, setContractDefault] = React.useState("");
  const [pdfFile, setPdfFile] = React.useState("");
  const [pdfSize, setPdfFileSize] = React.useState("");
  const [dataConfirmModal, setDataConfirmModal] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { details } = React.useContext(UserContext);
  const navigate = useNavigate();
  const [validationError, setValidationError] = React.useState({
    userNameError: false,
    addressError: false,
    emailError: false,
    EmailFormatError: false,
    designationError: false,
  });
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#FFFF',
      padding: '15mm',
      position: 'relative',
    },
    textBold: {
      fontFamily: "Epilogue_Bold",
    },
    section: {
      margin: 2,
      padding: 10,
      flexGrow: 1,
    },
    paragraphSection: {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    mainheading: {
      fontSize: 18,
      fontFamily: "Epilogue_Bold",
      fontWeight: 'bold',
      textAlign: 'center',
      paddingBottom: '25px',
    },
    normalText: {
      fontSize: 12,
      lineHeight: 1.5,
      whiteSpace: 'normal',
      wordBreak: 'breakWord',
      flexWrap: 'wrap',
    },
    pointText: {
      fontSize: 12,
      lineHeight: 'normal',
      whiteSpace: 'normal',
      wordBreak: 'breakWord',
      flexWrap: 'wrap',
      paddingTop: 3,
      paddingBottom: 3,
    },
    underline: {
      textDecoration: 'underline',
      minWidth: '90px',
    },
    tableText: {
      fontSize: 12,
      lineHeight: 'normal',
      borderBottom: '0.5px solid #333',
      padding: 5,
      whiteSpace: 'normal',
      wordBreak: 'breakWord',
      flexWrap: 'wrap',
    },
    tableTextSign: {
      fontSize: 12,
      lineHeight: 'normal',
      borderBottom: '0.5px solid #333',
      minHeight: 80,
      padding: 5,
    },
    tableTextDate: {
      fontSize: 14,
      lineHeight: 'normal',
      padding: 5,
    },
    boldText: {
      fontSize: 12,
      fontFamily: "Epilogue_Bold",
    },
    listText: {
      fontSize: 12,
      lineHeight: 'normal',
      padding: 6,
    },
    listBold: {
      fontSize: 12,
      fontFamily: "Epilogue_Bold",
      display: 'block',
      paddingLeft: '15px',
      paddingBottom: '5px',
    },
    innerlistText: {
      fontSize: 12,
      lineHeight: 1.5,
      paddingLeft: '35px',
      display: 'block',
    },
  });

  async function fieldValidation() {
    if (!!!userName?.trim()) {
      updateValidationError("userNameError", true);
    }
    if (!!!address?.trim()) {
      updateValidationError("addressError", true);
    }
    if (!!!email?.trim()) {
      updateValidationError("emailError", true);
    }
    if (!!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      updateValidationError("EmailFormatError", true);
    }
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      updateValidationError("EmailFormatError", false);
    }
    if (!!!designation?.trim()) {
      updateValidationError("designationError", true);
    }
    if (
      address?.trim()?.length > 10 &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&
      designation?.trim() &&
      userName?.trim()) {
      setValidationError({
        userNameError: false,
        addressError: false,
        designationError: false,
        emailError: false,
        EmailFormatError: false,
      });
    }

    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }
  const handleModal = async (e) => {
    setValidationError({
      userNameError: false,
      addressError: false,
      designationError: false,
      emailError: false,
      EmailFormatError: false,
    });
    await fieldValidation();
  };
  const ContractForm = () => (
    <Document wrap>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.mainheading}>ValetSearch Partner Enrollment Agreement</Text>
          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}>
              <Text style={styles.textBold}>THIS AGREEMENT</Text> (the "Agreement") is made and entered into on the <Text style={styles.underline}>{moment(new Date()).format("DD-MM-YYYY")}</Text> by and between {contractDefault?.company_name}, having its registered office at {contractDefault?.company_address}  ("Company"), and <Text style={styles.underline}>{details?.default_company?.name + "-" + details?.default_branch?.name}, {address} ("Partner").</Text>
            </Text>
          </View>
          <View style={styles.paragraphSection}>
            <View>
              <Text style={styles.normalText}><Text style={styles.textBold}>Recitals </Text> WHEREAS the Company provides <Text style={styles.underline}> {contractDefault?.detail_of_business}</Text> and WHEREAS, the Partner desires to avail itself of these services under the terms set forth herein;</Text>
            </View>
          </View>
          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}>NOW, THEREFORE, in consideration of the mutual covenants and agreements contained herein, the parties hereto agree as follows:</Text>
          </View>
          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>1. Scope of Services :</Text> The Company shall provide  <Text style={styles.underline}>{contractDefault?.service_description}</Text></Text>
          </View>
          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>2. Compensation :</Text> The company will deduct a platform commission of <Text style={styles.underline}>{details?.default_branch?.platform_charges}% </Text> (minimum Rs. 3 excluding GST) per vehicle entry, either online or in cash, for each vehicle entry. If the payment is online, additional payment gateway charges will apply. The remaining balance after these deductions will be paid to the vendor partner within five business days, including any statutory or other deductions. For the first six months, there will be no platform charges for valet services only,  as a promotional offer. After this period, a minimum fee of Rs. 3 excluding GST per vehicle entry will be charged.</Text>
            <Text style={[styles.normalText,styles.paragraphSection]}> If a cash payment is collected by the vendor partner, the vendor partner must pay {contractDefault?.company_name} the corresponding amount. Any differences between online and cash collections will result in a balance that is either payable to or receivable from the vendor, and will be adjusted accordingly.</Text>
          </View>
          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}> 3. Term/Termination : </Text>This Agreement commences on <Text style={styles.underline}>{moment(new Date()).format("DD-MM-YYYY")}</Text> and will remain in effect for 11 months. It may be extended by mutual consent. Either party may terminate by providing 60 days notice. Specific consequences of termination, including the settlement of accounts and return of property. </Text>
          </View>
          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>4. Independent Contractor Status : </Text> The Company acts strictly as a digital facilitator and is not involved in the Partner's day-to-day operations, bearing no responsibility for such operations. </Text>
          </View>
          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>5. Proprietary Information and Confidentiality : </Text> All deliverables under this agreement will be the sole property of the Company. Both parties agree to maintain the confidentiality of information exchanged during the partnership. </Text>
          </View>
          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}> 6. Data Protection and Privacy : </Text>The handling, storage, and processing of personal data will comply with relevant data protection laws, ensuring the privacy and security of customer information. </Text>
          </View>
          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>7. Dispute Resolution : </Text>Disputes arising under this agreement will be resolved through mediation and arbitration prior to any litigation.</Text>
          </View>
          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>8. Service Level Agreements (SLAs): </Text> The Company guarantees service levels as specified herein, including uptime, support response times, and quality metrics.</Text>
            <Text style={styles.normalText}>This Service Level Agreement (SLA) is made part of the ValetSearch Partner Enrollment Agreement entered into on the 21st day of February 2024, by and between EWall Solutions Pvt. Ltd ("Service Provider"), and Sangeetha Foods ("Client").</Text>
            <View style={styles.listText}>
              <Text style={styles.listBold}>A. Purpose : </Text>
              <Text style={styles.innerlistText}> a. The purpose of this SLA is to specify the service levels provided by the Service Provider to the Client and to outline the metrics by which the services are measured and the remedies.</Text>
            </View>
            <View style={styles.listText}>
              <Text style={styles.listBold}>B. Service Scope : </Text>
              <Text style={styles.innerlistText}> a. The Service Provider will offer ValetSearch services, including but not limited to online reservation systems, real-time tracking of valet services, customer feedback collection, and analytics services.</Text>
            </View>
            <Text style={styles.listText}><Text style={styles.listBold}>C. Service Availability: </Text>
              <Text style={styles.innerlistText}> a. The Service Provider guarantees an uptime of 99% for the ValetSearch services.</Text>
              <Text style={styles.innerlistText}> b. Scheduled maintenance shall be communicated at least 48 hours in advance and conducted during low-traffic hours to minimize impact.</Text>
            </Text>
            <View style={styles.listText}>
              <Text style={styles.listBold}>D. Response Times : </Text>
              <Text style={styles.innerlistText}> a. Support queries will be acknowledged within 2 hours during business hours (10 AM to 6 PM, local time).</Text>
              <Text style={styles.innerlistText}> b. Critical issues affecting service availability will be addressed within 4 hours.</Text>
              <Text style={styles.innerlistText}> c. Non-critical issues will be addressed within 2 business days.</Text>
            </View>
            <View style={styles.listText}>
              <Text style={styles.listBold}>E. Limitations :</Text>
              <Text style={styles.innerlistText}> a. This SLA does not cover service issues caused by factors outside of the Service Provider's reasonable control, including but not limited to acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or other labor problems (excluding those involving the Service Provider's employees), Internet service provider failures or delays, or denial of service attacks.</Text>
            </View>
            <View style={styles.listText}>
              <Text style={styles.listBold}>F. Amendments : </Text>
              <Text style={styles.innerlistText}> a. This SLA may be amended by mutual consent of both parties in writing.</Text>
            </View>
          </View>

          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>9. Indemnification : </Text> Each party agrees to indemnify and hold the other harmless from any losses, damages, or costs resulting from breaches of this agreement or negligence. </Text>
          </View>

          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>10. Force Majeure : </Text> Neither party will be liable for failure to perform its obligations if such failure is caused by unforeseeable circumstances beyond their control. </Text>
          </View>

          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>11. Compliance with Laws : </Text> Both parties will comply with all applicable laws and regulations in the performance of their obligations under this agreement. </Text>
          </View>

          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>12. Amendment and Waiver : </Text> This agreement can only be amended in writing with the mutual consent of both parties. The waiver of any breach or condition shall not affect the subsequent enforcement of that or any other provision.</Text>
          </View>

          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>13. Governing Law : </Text> This agreement is governed by the laws of India, including the Indian Contract Act, 1872, the Information Technology Act, 2000, and other relevant legislation.</Text>
          </View>

          <View style={styles.paragraphSection}>
            <Text style={styles.normalText}><Text style={styles.textBold}>14. Notices : </Text> All notices and communications must be in writing and sent to the parties' addresses as specified herein.</Text>
          </View>
          <View style={styles.paragraphSection}>
            <Text style={styles.normalText} ><Text style={styles.textBold}>15. Contact Point:</Text> For service letter and notices</Text>
            <View style={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
              <View style={{ width: "100%", paddingTop: '10px' }}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ width: "48%", marginRight: '2%', paddingTop: '2px', paddingBottom: '6px' }}>
                    <Text style={styles.boldText}>For the Company</Text>
                  </View>
                  <View style={{ width: "48%", marginLeft: '2%', paddingTop: '2px', paddingBottom: '6px' }}>
                    <Text style={styles.boldText}>For Partner Company</Text>
                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ width: "48%", marginRight: '2%', paddingTop: '2px', paddingBottom: '2px' }}>
                    <Text style={styles.normalText}>
                      <Text style={styles.boldText}>Name :  </Text>
                      {contractDefault?.authority_name}
                    </Text>
                  </View>
                  <View style={{ width: "48%", marginLeft: '2%', paddingTop: '2px', paddingBottom: '2px' }}>
                    <Text style={styles.normalText}>
                      <Text style={styles.boldText}>Name :  </Text>
                      {userName}
                    </Text>
                  </View>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ width: "48%", marginRight: '2%', paddingTop: '2px', paddingBottom: '2px' }}>
                    <Text style={styles.normalText}>
                      <Text style={styles.boldText}>Address :  </Text>
                      {contractDefault?.company_address}
                    </Text>
                  </View>
                  <View style={{ width: "48%", marginLeft: '2%', paddingTop: '2px', paddingBottom: '2px' }}>
                    <Text style={styles.normalText}>
                      <Text style={styles.boldText}>Address :  </Text>
                      {address}
                    </Text>
                  </View>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ width: "48%", marginRight: '2%', paddingTop: '2px', paddingBottom: '2px' }}>
                    <Text style={styles.normalText}>
                      <Text style={styles.boldText}>Email : </Text>
                      {contractDefault?.email}
                    </Text>
                  </View>
                  <View style={{ width: "48%", marginLeft: '2%', paddingTop: '2px', paddingBottom: '2px' }}>
                    <Text style={styles.normalText}>
                      <Text style={styles.boldText}>Email : </Text>
                      {email}
                    </Text>
                  </View>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', paddingTop: '15px' }}>
                  <View style={{ width: "48%", marginRight: '2%', border: "1px solid #333" }}>
                    <View style={{ borderBottom: "0.5px solid #333" }}>
                      <Text style={styles.tableText}>
                        <Text style={styles.boldText}>For {contractDefault?.company_name} </Text>
                      </Text>
                    </View>
                    <View style={{ borderBottom: "0.5px solid #333" }}>
                      <Text style={styles.tableTextSign}>
                        <Text style={styles.boldText}>Signature :</Text>
                      </Text>
                    </View>
                    <View style={{ borderBottom: "0.5px solid #333" }}>
                      <Text style={styles.tableText}>
                        <Text style={styles.boldText}>Name :  </Text>
                        {contractDefault?.authority_name}
                      </Text>
                    </View>
                    <View style={{ borderBottom: "0.5px solid #333" }}>
                      <Text style={styles.tableText}>
                        <Text style={styles.boldText}>Designation : </Text>
                        {contractDefault?.designation}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.tableTextDate}>
                        <Text style={styles.boldText}>Date : </Text>
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: "48%", marginLeft: '2%', border: "1px solid #333" }}>
                    <View style={{ borderBottom: "0.5px solid #333" }}>
                      <Text style={styles.tableText}>
                        <Text style={styles.boldText}>For {details?.default_company?.name} </Text>
                      </Text>
                    </View>
                    <View style={{ borderBottom: "0.5px solid #333" }}>
                      <Text style={styles.tableTextSign}>
                        <Text style={styles.boldText}>Signature: </Text>
                      </Text>
                    </View>
                    <View style={{ borderBottom: "0.5px solid #333" }}>
                      <Text style={styles.tableText}>
                        <Text style={styles.boldText}>Name  :  </Text>
                        {userName}
                      </Text>
                    </View>
                    <View style={{ borderBottom: "0.5px solid #333" }}>
                      <Text style={styles.tableText}>
                        <Text style={styles.boldText}>Designation :</Text>
                        {designation}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.tableTextDate}>
                        <Text style={styles.boldText}>Date :</Text>
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

            </View>
          </View>
        </View>
        <Text style={[{ position: 'absolute', fontSize: 10, bottom: 15, left: 0, right: 0, textAlign: 'center', color: '#000' }]} render={({ pageNumber, totalPages }) => (
          `Page ${pageNumber} of ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
  React.useEffect(() => {
    const contract_default = async () => {
      await directus
        .items("Contract_agreement_default")
        .readByQuery({
          fields: ["*"],
        })
        .then((res) => {
          setContractDefault(res?.data);
          return "";
        })
        .catch((e) => console.log(e));
    }
    contract_default()
    if (details) {
      setLoading(false)
      if (details?.default_branch?.status === 'published') {
        navigate(`/${details?.default_company?.slug}/${details?.default_branch?.slug}/login`)
      }
      if (details?.default_branch?.agreement_id?.length > 0) {
        const agreementInfo = details?.default_branch?.agreement_id[details?.default_branch?.agreement_id?.length - 1]
        setAgreementInfo(agreementInfo)
        setUserName(agreementInfo?.name)
        setAddress(agreementInfo?.address)
        setDesignation(agreementInfo?.designation)
        setEmail(agreementInfo?.email)
      }
    }
  }, [details])
  React.useEffect(() => {
    var {
      emailError,
      addressError,
      designationError,
      userNameError,
      EmailFormatError,
    } = validationError;
    if (!!userName && !!address && !!designation && !!email) {
      if (
        !(
          emailError ||
          addressError ||
          designationError ||
          userNameError ||
          EmailFormatError
        )
      ) {
        setDataConfirmModal(true);
      } else {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);
  const AgreementCreateHandle = async () => {
    if (agreementInfo?.id) {
      const response = await ContractUpdate({
        body: {
          name: userName,
          email: email,
          designation: designation,
          address: address,
        }
      })
      if (response) {
        await branchUpdate({ body: { status: "contract_agreement_review_in_progress" } })
        await RegistrationMailSender({
          emailTo: details?.email?.trim(),
          type: "branch",
          branchName: details?.default_branch?.name?.trim(),
          companyName: details?.default_company?.name?.trim(),
          submitType: "agreement_submit"   // agreement_update
        });
        setShowPdf(true)
        window.location.reload()
      }
    } else {
      const agreement_id = await directus.items("Contract_agreement")
        .createOne({
          submitted_date: moment(new Date()).format("YYYY-MM-DD"),
          name: userName,
          email: email,
          designation: designation,
          address: address,
        })
        .then((res) => {
          setShowPdf(true)
          return res?.id;
        })
        .catch((err) => {
          console.log(err?.message);
        });
      if (agreement_id) {
        await RegistrationMailSender({
          emailTo: details?.email?.trim(),
          type: "branch",
          branchName: details?.default_branch?.name?.trim(),
          companyName: details?.default_company?.name?.trim(),
          submitType: "agreement_submit"
        });
        const oldAgreementIds = details?.default_branch?.agreement_id?.length > 0 ? details?.default_branch?.agreement_id?.map(e => e?.id) : []
        await branchUpdate({ body: { agreement_id: [...oldAgreementIds, agreement_id], status: "contract_agreement_review_in_progress" } });
        window.location.reload()
      }
    }
  }
  const preview = (props) => {
    return (
      props?.value && (
        <div className="files-list">
          <ul>
            <li
              key={props?.value?.id}
              className="flex flex-row justify-evenly text-center place-items-center"
            >
              <div>
                <div className="mt-2">
                  {props?.value?.preview?.type === "image" ? (
                    <img
                      className=""
                      src={props?.value?.preview?.url}
                      alt="Preview"
                    />
                  ) : (
                    <div className="">{props?.value?.extension}</div>
                  )}
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900 min-w-[150px] ">
                    {props?.value?.name}
                  </div>
                </div>
              </div>
            </li>
            <div className="list-item-remove" onClick={() => setPdfFile("")}>
              <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                stroke="#ff0000"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="css-i6dzq1 float-right"
              >
                <title>Delete</title>
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            </div>
          </ul>
        </div>
      )
    );
  };
  const ImageHandler = (event) => {
    let file_size = event[0]?.size;
    if (file_size / 1000000 < 2) {
      setPdfFileSize("");
      setPdfFile(event[0]);
    } else {
      setPdfFileSize(
        "* Please upload the attachment less than 2MB. / .doc, .pdf, .docx"
      );
    }
  };
  const createFileInDirectus = async (props) => {
    const formData = new FormData();
    formData.append("folder", props);
    const val = await directus.files
      .createOne(formData)
      .then((res) => {
        return res?.id;
      })
      .catch((err) => {
        console.log("Directus file create error:", err?.message);
      });
    return val;
  };
  const ContractUpdate = async ({ body }) => {
    const res = await directus
      .items("Contract_agreement")
      .updateOne(Number(agreementInfo?.id), body)
      .then(async (res) => {
        return true
      })
      .catch((err) => {
        toast.error(err);
      });
    return res
  }
  const branchUpdate = async ({ body }) => {
    const res = await directus
      .items("Branch")
      .updateOne(Number(details?.default_branch?.id), body)
      .then(async (res) => {
        return true
      }).catch((err) => {
        console.log(err?.message);
      });
    return res
  }
  const ContractHandle = async () => {
    if (agreementInfo?.id) {
      const Docs1Id = await createFileInDirectus(pdfFile);
      const response = await ContractUpdate({
        body: {
          name: userName,
          email: email,
          designation: designation,
          address: address,
          contract_agreement: Docs1Id,
          commission_value: details?.default_branch?.platform_charges
        }
      })
      if (response) {
        await branchUpdate({ body: { status: "contract_signature" } })
        await RegistrationMailSender({
          emailTo: details?.email?.trim(),
          type: "branch",
          branchName: details?.default_branch?.name?.trim(),
          companyName: details?.default_company?.name?.trim(),
          submitType: "signature_submit"
        });
        setShowPdf(true)
        window.location.reload()
      }
    }
  }
  const agreementDetail = details?.default_branch?.agreement_id

  const isUpdate = (agreementDetail?.name !== userName || agreementDetail?.address !== address || agreementDetail?.email !== email || agreementDetail?.designation !== designation)
  const inputDisable = details?.default_branch?.status === "contract_agreement_review_in_progress"

  const BranchStatus = details?.default_branch?.status === 'document_verified' || details?.default_branch?.status === 'contract_agreement_pending' || details?.default_branch?.status === "contract_agreement_review_in_progress" || details?.default_branch?.status === "contract_signature_pending" || details?.default_branch?.status === "contract_signature"

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (!BranchStatus ? <NotFoundPage /> : <LayoutComponent title={"ONBOARDING FORM"} chat={false}>
        <Seo title={"Onboarding Form"} />
        <div className="main-content-area px-6 max-sm:px-5 pt-0 new-company">
          <div className="block__status-bar onboarding bg-opacity-60 bg-primary">
            <div className="flex flex-col justify-between items-center md:flex-row">
              <label
                htmlFor="document-status"
                className="hidden md:flex text-base font-medium text-white min-w-[150px] "
              >
                Document status
              </label>
              <div className="multi-step-container flex items-center justify-end w-full">
                {['contract_agreement_pending', 'contract_agreement_review_in_progress', 'contract_signature_pending', 'contract_signature']?.map((e, i) => <span key={i} className={` uppercase step flex items-center text-white justify-center ${(details?.default_branch?.status === e || (details?.default_branch?.status === "document_verified" && i === 0)) ? ' font-bold bg-secondary bg-opacity-90' : "bg-secondary bg-opacity-30"}`}>{e === "contract_agreement_pending" ? "Pending" : e === "contract_agreement_review_in_progress" ? "In progress" : e === "contract_signature_pending" ? "Signature pending" : "Verification"}</span>)}
              </div>
            </div>
          </div>
          <div className="mx-auto block pt-16 pb-8 onboarding_form">
            <div className="">
              <div className="mt-1 mb-1 block">
                <div className="space-y-6 bg-white py-6">
                  
                  {(details?.default_branch?.status !== "contract_signature" &&
                    details?.default_branch?.status !== "contract_signature_pending") && <div className="grid grid-cols-6 gap-6">
                   {(showPdf || inputDisable
                      ) && <div className=" col-span-6 text-center font-normal normal-case flex justify-center gap-1 p-2 rounded-md border text-green-800 border-green-400 bg-green-100">
                          Your form submitted successfully. We will get back to you with in a three working days.
                        </div>}
                     { !inputDisable&&<>
                      <div className="col-span-6">
                        <label htmlFor='user-name' className="block text-sm font-bold text-gray-700 mb-2">
                            Name of the Authority Signatory*
                        </label>
                        <input
                          className={`appearance-none ${validationError.userNameError && userName?.length < 1
                            ? "border-red-300 focus:border-red-300"
                            : "border-gray-300 focus:border-primary"
                            }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                          placeholder="Enter the name"
                          id="user-name"
                          type="text"
                          disabled={inputDisable}
                          onChange={(e) => setUserName(e.target.value)}
                          value={userName || ""}
                        />
                        {validationError.userNameError && userName?.length < 1 && (
                          <p className="text-xs text-right text-red-500 mt-2">
                            *Name is required
                          </p>
                        )}
                      </div>
                      <div className="col-span-6">
                        <label htmlFor="user-email" className="block text-sm font-bold text-gray-700 mb-2">
                          Authority Signatory Email *
                        </label>
                        <input
                          className={`appearance-none ${(validationError?.emailError && email?.length < 1) ||
                            (validationError?.EmailFormatError &&
                              email?.length > 0 &&
                              !!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                email
                              ))
                            ? "border-red-300 focus:border-red-300"
                            : "border-gray-300 focus:border-primary"
                            }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                          placeholder="Enter the email"
                          id="user-email"
                          type="email"
                          disabled={inputDisable}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email || ""}
                        />
                        {validationError?.emailError && email?.trim()?.length < 1 && (
                          <p className="text-xs text-right text-red-500 mt-2">
                            *Email address is required
                          </p>
                        )}
                        {validationError?.EmailFormatError &&
                          email?.trim()?.length > 0 &&
                          !!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            email
                          ) && (
                            <p className="text-xs text-right text-red-500 mt-2">
                              *Enter a valid email address
                            </p>
                          )}
                      </div>
                      <div className="col-span-6">
                        <label htmlFor="address-1" className="block text-sm font-bold text-gray-700 mb-2">
                        Authority Signatory Address *
                        </label>
                        <textarea
                          className={`appearance-none ${(validationError?.addressError && address?.length < 1)
                            ? "border-red-300 focus:border-red-300"
                            : "border-gray-300 focus:border-primary"
                            }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                          placeholder="Enter the Address"
                          id="address-1"
                          type="text"
                          disabled={inputDisable}
                          onChange={(e) =>
                            setAddress(e.target.value)
                          }
                          value={address || ""}
                        />
                        {validationError?.addressError && address?.length < 1 && (
                          <p className="text-xs text-right text-red-500 mt-2">
                            *Address is required
                          </p>
                        )}

                      </div>
                      <div className="col-span-6 relative">
                        <label htmlFor="designation" className="block text-sm font-bold text-gray-700 mb-2">
                        Authority Signatory Designation *
                        </label>
                        <div className="relative">
                          <input
                            className={`appearance-none ${validationError.designationError && designation?.length < 1
                              ? "border-red-300 focus:border-red-300"
                              : "border-gray-300 focus:border-primary"
                              }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                            placeholder="Enter the designation"
                            id="designation"
                            type={"text"}
                            disabled={inputDisable}
                            onChange={(e) => setDesignation(e.target.value)}
                            value={designation || ""}
                          />
                        </div>
                        {validationError.designationError && designation?.length < 1 && (
                          <p className="text-xs text-right text-red-500 mt-2">
                            *Designation is required
                          </p>
                        )}
                      </div>
                      
                      <div className={`col-span-6 flex justify-end items-end ${details?.default_branch?.status === "contract_agreement_review_in_progress" || !isUpdate || showPdf ? 'hidden' : ''}`}>
                        <button
                          type="button"
                          onClick={(e) => handleModal(e)}
                          disabled={details?.default_branch?.status === "contract_agreement_review_in_progress" || !isUpdate || showPdf}
                          className={`inline-flex items-center justify-center  border h-10 border-transparent rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-md  hover:bg-vspurple hover border-vspurple focus:outline-none focus:ring-2 focus:ring-vspurple focus:ring-offset-2 sm:w-auto ${!isUpdate || showPdf ? "opacity-50" : ""}`}
                        >
                          <span className="">Save</span>
                        </button>
                      </div>
                      </>}
                  
                    </div>}
                  {(details?.default_branch?.status === "contract_signature" || details?.default_branch?.status === "contract_signature_pending") && <>
                    {details?.default_branch?.status !== "contract_signature" && <>
                      <div className={`text-center font-normal normal-case flex justify-center gap-1 m-4 ${details?.default_branch?.status === "contract_agreement_review_in_progress" ? " pointer-events-none" : ""}`}>
                        <PDFDownloadLink
                          className="bg-[#FFFFFF] border-[#1a1a1a] text-[12px] cursor-pointer w-25 inline-block p-3 rounded-md border text-[#1a1a1a] text-md text-center focus:outline-none focus:ring-2 focus:ring-[#808080] focus:ring-offset-2" document={<ContractForm />} fileName={`ValetSearch_Contract.pdf`}>
                          <span className="inline-flex">{'Contract Agreement Form'}
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0,0,256,256">
                              <g fill="#1a1a1a" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
                                <g transform="scale(10.66667,10.66667)">
                                  <path d="M10.5,2c-0.81706,0 -1.5,0.68294 -1.5,1.5v6.5h-4.41406l7.41406,7.41406l7.41406,-7.41406h-4.41406v-6.5c0,-0.81706 -0.68294,-1.5 -1.5,-1.5zM11,4h2v8h1.58594l-2.58594,2.58594l-2.58594,-2.58594h1.58594zM2,20v2h20v-2z">
                                  </path>
                                </g>
                              </g>
                            </svg>
                          </span>
                        </PDFDownloadLink>
                      </div>
                      <div className="text-center font-normal normal-case flex justify-center gap-1 m-4 p-2 rounded-md text-primary ">
                        Attach your contract as document with your signature.
                      </div>
                      <div className="attach_contract_upload text-center font-normal normal-case flex items-start max-sm:items-end justify-center gap-3 m-4 p-2 ">
                        <div className={`block w-full ${details?.default_branch?.status === "contract_signature" ? "pointer-events-none" : ""}`}>
                          <Files
                            className={`${pdfSize?.trim()?.length > 1
                              ? "border-red-300 focus:border-red-300"
                              : "focus:border-primary"
                              } pl-4 py-2.5 pr-9 min-w-[300px] w-full rounded border text-sm shadow-sm border-gray-200 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-0 flex items-center font-bold text-[#bbb] text-[14px]`}
                            onChange={(e) => ImageHandler(e)}
                            accepts={[".pdf", ".doc", ".docx"]}
                            multiple={false}
                            maxFileSize={2 * 1024 * 1024}
                            minFileSize={0}
                            clickable

                          >
                            Drop files here or click to upload
                          </Files>
                          {pdfFile &&
                            pdfFile?.preview?.url !== null &&
                            preview({ value: pdfFile })}
                          {pdfSize?.trim()?.length > 1 && (
                            <p className="text-xs text-right text-red-500 error-msg mt-0">
                              {pdfSize?.trim()}
                            </p>
                          )}
                        </div>
                        <div className="max-sm:mt-3">
                          <button
                            type="button"
                            onClick={(e) => setDataConfirmModal(true)}
                            disabled={showPdf || !pdfFile || details?.default_branch?.status === "contract_signature"}
                            className={`inline-flex items-center justify-center  border h-10 border-transparent rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-md  hover:bg-vspurple hover border-vspurple focus:outline-none focus:ring-2 focus:ring-vspurple focus:ring-offset-2 sm:w-auto ${(pdfFile || !showPdf) && (details?.default_branch?.status !== "contract_signature") ? "" : "opacity-50"}`}
                          >
                            <span className="">Submit</span>
                          </button>
                        </div>
                      </div>
                    </>}
                    {(showPdf || details?.default_branch?.status === "contract_signature"
                    ) && <div className=" col-span-6 text-center font-normal normal-case flex justify-center gap-1 p-2 rounded-md border text-green-800 border-green-400 bg-green-100">
                        Your form submitted successfully. We will get back to you with in a three working days.
                      </div>}
                  </>}

                </div>
              </div>
            </div>
          </div>

        </div>
        {dataConfirmModal ? (
          <ConfirmationModal
            openModel={dataConfirmModal}
            setIsOpenModel={setDataConfirmModal}
            callFunction={details?.default_branch?.status === "contract_signature_pending" ? ContractHandle : AgreementCreateHandle}
            content={"Are you sure the given information is valid?"}
          />
        ) : null}
      </LayoutComponent>)}
    </>

  )
}

export default OnBoardingForm