/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import Seo from "../components/Seo";
import CustomerLayout from "../components/CustomerLayout";
import {
  CustomerLoginRedirect,
  cusLogin,
  partnerLogin,
  partnerRegister,
  createVehicle,
  dashboardAssignPopup,
  partnerDashboard,
  hamburgerMenu,
  masterVehicleSearch,
  staffInOutStatusBoard,
  manageManager,
  createManager,
  editManager,
  manageManagerInactive,
  scanQRCode,
  editValetNumber,
  homeSection,
  editValetNumberHeader,
  manageOffers,
  createOffers,
  offerChangeStatus,
  offerHomepage,
  offerCustomerPage,
  analyticsPage,
  valetPayments,
  vendorChatIcon,
  vendorChatPopup,
  customerChatIcon,
  customerChatPopup
} from "../assets/index.js";
import seoData from "../seoContent.json";
import { Link } from "react-router-dom";

function Instruction() {
  const [loading, setLoading] = React.useState(true);
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  React.useEffect(() => {
    setLoading(false);
  }, []);
  const CustomerInstruct = () => {
    return (
      <div className="mt-6 mb-6 flex justify-center flex-col">
        <b>INSTRUCTIONS :</b>
        <br />
        <ol id="customer-condition" className="mt-4">
          <li className="parent-li">
            <div className="ml-4">
              <div>
                <b>Find the right place:</b> Explore restaurants or your desired
                premises on ValetSearch based on your location or tags(categories) like restaurants, airport, hotels etc. 
                Look for restaurants that offer ValetSearch parking!
              </div>
              <br/>
              <b>Park Your Car:</b>
              <br />
              <div className="ml-4 mt-4">
                <b>Scan the QR Code:</b> Upon arrival at the restaurant, locate
                the ValetSearch QR code displayed prominently. Scan the code
                using your smartphone camera.
              </div>
              <div className="ml-4 mt-4">
                <b>No QR Code?:</b> If no QR code is available, simply approach
                the valet staff and inform them about your ValetSearch parking
                request.
              </div>
              <br />
            </div>
          </li>
          <li className="parent-li">
            <div className="ml-4">
              <b>Register or Login</b>
              <br />
              <div className="row flex text-center justify-center my-4 flex-col mt-4">
                <img
                  src={CustomerLoginRedirect}
                  alt="customer-registration-form"
                  width={300}
                  height={300}
                  style={{ margin: "0 auto" }}
                />
                <span className="text-[12px] font-bold p-1 uppercase">
                  CUSTOMER LOGIN / REGISTRATION PAGE
                </span>
              </div>
              <ul>
                <li>
                  <div className="mt-4">
                    <div>
                      <b>New User:</b> Scanning the QR code will redirect you to
                      the ValetSearch customer login page. Here, click <b>"Create a
                      new vehicle registration"</b>. Fill out the form with your
                      vehicle number and phone number (mandatory). Agree to the
                      terms and conditions before submitting. You'll then be
                      directed to your vehicle status page.
                    </div>
                    <div className="row flex text-center justify-center my-4 flex-col mt-4">
                      <img
                        src={createVehicle}
                        alt="customer-registration-form"
                        width={600}
                        height={600}
                        style={{ margin: "0 auto" }}
                      />
                      <span className="text-[12px] font-bold p-1 uppercase">
                        CUSTOMER NEW VEHICLE REGISTRATION FORM
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="mt-4">
                    <div>
                      <b>Existing User:</b> Enter your registered phone number
                      and the last 4 digits of your vehicle license plate.
                      Confirm the details to proceed to your vehicle status
                      page.
                    </div>
                    <div className="row flex text-center justify-center my-4 flex-col mt-4">
                      <img
                        src={cusLogin}
                        alt="customer-registration-form"
                        width={400}
                        height={400}
                        style={{ margin: "0 auto" }}
                      />
                      <span className="text-[12px] font-bold p-1 uppercase">
                        CUSTOMER LOGIN PAGE
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className="parent-li">
            <div className="ml-4">
              <b>Valet Staff Assistance</b>
              <br />
              <div className="mt-4">
                After a few minutes, our partner valet staff member will
                arrive to collect your vehicle for parking. Make sure to note
                the OTP displayed on your vehicle status page for future
                reference.
              </div>
            </div>
          </li>
          <li className="parent-li">
            <div className="ml-4">
              <b>Parking Fee (if applicable)</b>
              <br />
              <div className="mt-4">
                  If the restaurant charges a parking fee, you'll see the <b>Pay Now</b> button on your vehicle status page. By clicking the <b>"Pay Now"</b> button you will be redirected to the payment page from there you can pay the valet fee via online payment(<b>Phonepe</b>). A draft / paid receipt will be available for your reference on the vehicle status page and can download them to your devices in PDF.
              </div>
            </div>
          </li>
          <li className="parent-li">
            <div className="ml-4">
              <b>Monitor Vehicle Status</b>
              <br />
              <div className="mt-4">
                  The vehicle status page keeps you updated on your vehicle's current status throughout your parking experience. You'll see changes like "Waiting," "Parked," and more.
              </div>
              <ul>
                <li>
                  <div className="ml-4  mt-4">
                    <b>Ready to Leave?</b>
                    <br />
                    <div className="ml-4 mt-4">
                      When you're ready to depart, simply click the "READY TO
                      LEAVE" button on your vehicle status page. This will
                      request the valet staff to retrieve your vehicle.
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ml-4 mt-4">
                    <b>Vehicle Pickup</b>
                    <br />
                    <div className="ml-4 mt-4">
                      The ValetSearch system will assign a valet staff member to pick up your vehicle from the parking area and update the status as <b>“Ready to collect”</b> when they are nearby you. Based on the vehicle deliver to you the vehicle status changes to <b>“Delivered”</b> 
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ml-4 mt-4">
                    <b>Track Status Updates</b>
                    <br />
                    <div className="ml-4 mt-4">
                      The vehicle status page will display real-time updates on
                      your vehicle's pickup and arrival. Once delivered, the
                      status will change to <b>"Delivered"</b>.
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className="parent-li">
            <div className="ml-4">
              <b>Explore Restaurant Offers (Optional)</b>
              <br />
              <div className="mt-4">
                To view any special offers or promotions from the restaurant,
                visit the restaurant's card on the ValetSearch homepage and
                click <b>"Offers & Promotions"</b>. This will take you to a dedicated
                page showcasing the restaurant's deals. You can view details and
                even copy any coupon codes offered.
              </div>
              <div className="flex justify-center">
                <div className="row flex text-center justify-center my-4 flex-col mt-4">
                  <img
                    src={offerHomepage}
                    alt="customer-registration-form"
                    width={400}
                    height={600}
                    style={{ margin: "0 auto" }}
                  />
                  <span className="text-[12px] font-bold p-1 uppercase">
                    OFFERS & PROMOTIONS HOME PAGE MENU
                  </span>
                </div>
                <div className="row flex text-center justify-center my-4 flex-col mt-4">
                  <img
                    src={offerCustomerPage}
                    height={600}
                    width={600}
                    alt="customer-registration-form"
                    style={{ margin: "0 auto" }}
                  />
                  <span className="text-[12px] font-bold p-1 uppercase">
                    OFFERS & PROMOTIONS CUSTOMER PAGE
                  </span>
                </div>
              </div>
            </div>
          </li>
          <li className="parent-li">
            <div className="ml-4">
              <b>Chat Support</b>
              <br />
              <div className="mt-4">
                If you have any emergencies or queries related to your vehicle, you can use the chat support available on the vehicle status page. Our partner valet executive at the branch will assist you based on your request. Please note that chat history is not currently maintained for future reference.
              </div>
              <div className="flex justify-center">
                <div className="row flex text-center justify-center my-4 flex-col mt-4">
                  <img
                    src={customerChatIcon}
                    alt="customer-chat-icon"
                    width={400}
                    height={600}
                    style={{ margin: "0 auto" }}
                  />
                  <span className="text-[12px] font-bold p-1 uppercase">
                     CUSTOMER CHAT ICON ON THE VEHICLE STATUS PAGE
                  </span>
                </div>
                <div className="row flex text-center justify-center my-4 flex-col mt-4">
                  <img
                    src={customerChatPopup}
                    height={400}
                    width={400}
                    alt="customer-chat-popup"
                    style={{ margin: "0 auto" }}
                  />
                  <span className="text-[12px] font-bold p-1 uppercase">
                    CUSTOMER CHAT POPUP
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    );
  };
  const PartnerInstruction = () => {
    return (
      <div className="mt-6 mb-6 flex justify-center flex-col">
        <b>INSTRUCTIONS :</b>
        <br />
        <div>
          This guide will walk you through the steps to register your company or branch on <Link className="text-primary" to="/">ValetSearch</Link>, finalize your partner enrollment agreement, and get published on the platform.
        </div>
        <br />
        <ol id="partner-condition" className="mt-4">
          <li class="parent-li">
            <div className="ml-4">
              <b>Register Your Company/branch</b>
              <br />
              <ol>
                <li className="ml-4 mt-4">
                  Click on the <b>"Register your Company & branch"</b> button.
                </li>
                <li className="ml-4 mt-4">
                  Fill in all the required company details and create admin
                  credentials (username and password).
                </li>
                <li className="ml-4 mt-4">
                  Click <b>"Submit"</b> to complete your registration.
                </li>
              </ol>
              <div className="row flex text-center justify-center my-4 flex-col mt-4">
                <img
                  src={partnerRegister}
                  alt="customer-registration-form"
                  width={800}
                  height={450}
                  style={{ margin: "0 auto" }}
                />
                <span className="text-[12px] font-bold p-1 uppercase">
                  VENDOR'S COMPANY / BRANCH REGISTRATION SECTION
                </span>
              </div>
            </div>
          </li>
          <li class="parent-li">
            <div className="ml-4">
              <b>Document Review</b>
              <br />
              <div className="mt-4">
                Once you register, ValetSearch will review your submitted
                documents. If everything is in order, you'll proceed to the partner enrollment
                agreement section. In case of missing or incomplete documents,
                they will be moved to a <b>"pending"</b> section, and you'll
                receive an acknowledgment email with a document update URL to
                rectify any issues.
              </div>
            </div>
          </li>
          <li class="parent-li">
            <div className="ml-4">
              <b>Agreement Review</b>
              <br />
              <div className="mt-4">
                Fill out the agreement with ValetSearch. <br /> <br />
                ValetSearch will review your agreement details. If approved, you'll move to the signature section. If there are any revisions needed, the agreement will be marked as <b>"pending"</b> and you'll receive an acknowledgment email with an agreement update URL to address the feedback.
              </div>
            </div>
          </li>
          <li class="parent-li">
            <div className="ml-4">
              <b>Signature and Verification</b>
              <br />
              <ol>
                <li className="ml-4 mt-4">
                  Download your completed partner enrollment agreement form from the URL provided in the acknowledgement email which you have received in previous step.
                </li>
                <li className="ml-4 mt-4">
                  Print the downloaded agreement form.
                </li>
                <li className="ml-4 mt-4">
                  Obtain the signature of the authorized company representative
                  (head/admin) on the printed agreement document.
                </li>
                <li className="ml-4 mt-4">
                  Scan the signed agreement document or take a clear picture.
                </li>
                <li className="ml-4 mt-4">
                  Upload the signed agreement document back to ValetSearch portal.
                </li>
              </ol>
            </div>
          </li>
          <li class="parent-li">
            <div className="ml-4">
              <b>Publish Company</b>
              <br />
              <div className="mt-4">
                ValetSearch will review your uploaded signature and, upon verification, publish your company or branch on the platform. You'll then receive a confirmation email with a login URL based on the main admin account (the one you created during registration) login as Admin/Manager with the admin credentials.  
              </div>
              <div className="mt-4">
                <strong>Important Note:</strong>
                <br />
                <span>
                  Ensure the uploaded document clearly shows the signature for
                  verification purposes.
                </span>
              </div>
            </div>
          </li>
        </ol>
      </div>
    );
  };
  return (
    <>
      <Seo title={seoData?.how_to_use?.title} />

      <CustomerLayout footer={false} head={true}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="p-6 lg:p-8 border-gray-200 dark:border-gray-700">
            <div className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
              <button
                className={`${activeTab === 0 ? "bg-primary text-white" : "bg-gray-300"
                  } py-2 px-4 mr-2 rounded-md`}
                onClick={() => handleTabClick(0)}
              >
                Customers
              </button>
              <button
                className={`${activeTab === 1 ? "bg-primary text-white" : "bg-gray-300"
                  } py-2 px-4 rounded-md`}
                onClick={() => handleTabClick(1)}
              >
                Vendors
              </button>
            </div>

            <div className="mt-4">
              {activeTab === 0 && <div>{CustomerInstruct()}</div>}
              {activeTab === 1 && <div>{PartnerInstruction()}</div>}
            </div>
          </div>
        )}
      </CustomerLayout>
    </>
  );
}

export default Instruction;
