import React from "react";
import PasswordShowHide from "../PasswordShowHide";

function EditComponent({
  userName,
  setUserName,
  email,
  phoneNo,
  setPhoneNo,
  password,
  setPassword,
  confirmPassword,
  validationError,
  setValidationError,
  setConfirmPassword,
  userType,
  userDetails,
}) {
  const [passShow, setPassShow] = React.useState(false);
  const [cpassShow, setCPassShow] = React.useState(false);
  async function fieldValidation() {
    if (!!!userName?.trim()) {
      updateValidationError("userNameError", true);
    }
    if (!!!phoneNo?.trim()) {
      updateValidationError("phoneNoError", true);
    } else {
      if (phoneNo.trim()?.length < 10) {
        updateValidationError("invalidPhoneNoError", true);
      }
    }
    if (password?.trim()?.length > 0 || confirmPassword?.trim()?.length > 0) {
      if (password?.trim() !== confirmPassword?.trim()) {
        updateValidationError("passwordMismatchError", true);
      }
    }
    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }
  let isUpdate =
    userDetails?.first_name !== userName ||
    userDetails?.email !== email ||
    password?.trim()?.length ||
    userDetails?.contact_number !== phoneNo;

  const handleModal = async (e) => {
    e.preventDefault();
    if (isUpdate) {
      setValidationError({ userNameError: false, phoneNoError: false });
      await fieldValidation();
    }
  };

  return (
    <div className="main-content-area px-6 py-8">
      <div className="mx-auto">
        <div className="mt-1 block">
          <form method="POST" onSubmit={(e) => handleModal(e)}>
            <div className="space-y-6 bg-white ">
              <div className="grid grid-cols-6 gap-6 max-w-[700px] mx-auto">
                <div className="col-span-6">
                  <label className="block text-sm font-bold text-gray-700 mb-2">
                    Name *
                  </label>
                  <input
                    className={`appearance-none ${
                      validationError.userNameError && userName?.length < 1
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                    }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    placeholder="Enter the name"
                    id="grid-name"
                    type="text"
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName || ""}
                  />
                  {validationError.userNameError && userName?.length < 1 && (
                    <p className="text-xs text-right text-red-500 mt-2">
                      *Username is required
                    </p>
                  )}
                </div>
                <div className="col-span-6">
                  <label className="block text-sm font-bold text-gray-700 mb-2">
                    Phone number *
                  </label>
                  <input
                    className={`appearance-none ${
                      (validationError.phoneNoError && phoneNo?.length < 1) ||
                      validationError.invalidPhoneNoError
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                    }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    placeholder="Enter the phone number"
                    id="phone-no"
                    type="text"
                    pattern="[0-9]*" 
                    inputMode="numeric"
                    maxLength={10}
                    onChange={(e) =>
                      /^\d*\.?\d*$/.test(e.target.value) &&
                      setPhoneNo(e.target.value)
                    }
                    value={phoneNo || ""}
                  />
                  {validationError.phoneNoError && phoneNo?.length < 1 && (
                    <p className="text-xs text-right text-red-500 mt-2">
                      *Phone number is required
                    </p>
                  )}
                  {validationError.invalidPhoneNoError &&
                    phoneNo?.length < 10 && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Invalid Phone number
                      </p>
                    )}
                </div>
                <div className="col-span-6">
                  <label className="block text-sm font-bold text-gray-700 mb-2">
                    Email *
                  </label>
                  <input
                    className="appearance-none pointer-events-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    placeholder="Enter the email"
                    id="grid-name"
                    type="email"
                    onChange={(e) => console.log(e.target.value)}
                    value={email || ""}
                  />
                </div>

                <div className="col-span-6 relative">
                  <label className="block text-sm font-bold text-gray-700 mb-2">
                    Password *
                  </label>
                  <input
                    className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    placeholder="Enter the password"
                    id="Password"
                    type={passShow ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password || ""}
                  />
                  <PasswordShowHide setShow={setPassShow} Show={passShow} />
                </div>
                <div className="col-span-6 relative">
                  <label className="block text-sm font-bold text-gray-700 mb-2">
                    Confirm password *
                  </label>
                  <input
                    className={`appearance-none ${
                      validationError?.passwordMismatchError &&
                      (confirmPassword?.trim()?.length > 0 ||
                        password?.trim()?.length > 0) &&
                      password?.trim() !== confirmPassword?.trim()
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                    }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    placeholder="Enter the confirm password "
                    id="confirm-password"
                    type={cpassShow ? "text" : "password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword || ""}
                  />
                  <PasswordShowHide setShow={setCPassShow} Show={cpassShow} />
                </div>
                {validationError?.passwordMismatchError &&
                  (confirmPassword?.trim()?.length > 0 ||
                    password?.trim()?.length > 0) &&
                  password?.trim() !== confirmPassword?.trim() && (
                    <p className="col-span-6 text-xs text-right  text-red-500 relative top-[-12px]">
                      *Password and Confirm password does not match
                    </p>
                  )}
                <div className="col-span-6 flex justify-end items-end pt-5">
                  <button
                    type="submit"
                    className={`inline-flex items-center justify-center border h-10 border-transparent hover:border-vspurple rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-md shadow-green-100 hover:bg-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto ${
                      !isUpdate ? "opacity-50 ..." : ""
                    }`}
                  >
                    <span className="ml-1.5">Save {userType}</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditComponent;
