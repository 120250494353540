import React from "react";
import Footer from "./Footer";
import HeaderComponent from "./HeaderComponent";
import StaffChat from "./StaffChat";
import UserContext from "../contexts/userContext";

const LayoutComponent = (props) => {
  const [fullscreen, Setfullscreen] = React.useState(false);
  const [siteLogo, setSiteLogo] = React.useState();
  const { commonInfo } = React.useContext(UserContext);

  React.useEffect(() => {
    commonInfo&&setSiteLogo(commonInfo)
  }, [commonInfo])

  const isMobile = window.innerWidth <= 767;
  const headLogo = isMobile
    ? (siteLogo?.mobile_site_logo?.length > 0
      ? siteLogo?.mobile_site_logo
      : "")
    : (siteLogo?.site_logo?.length > 0
      ? siteLogo?.site_logo
      : '');
  return (
    <div className="page__wrapper flex justify-start items-center flex-col bg-[rgb(243,244,246)] font-primary">
      <div
        className={`max-w-[1200px] relative w-full inline-block h-screen firefox-scrollbar shadow-sm min-h-screen max-h-screen  bg-white  ${fullscreen ? "overflow-hidden" : "overflow-hidden overflow-y-auto"
          } `}
      >
        <HeaderComponent
          search={props?.search}
          login={props?.login}
          title={props?.title}
          create={props?.create}
          allUsers={props?.allUsers}
          Setfullscreen={Setfullscreen}
          setReloadData={props?.setReloadData}
          reloadData={props?.reloadData}
          siteLogo={siteLogo}
        />
        <main
          className={`dt-main-height ${props?.middleWidth === false ? "admin-dashboard" : "inner-admin-pages max-w-[700px] mx-auto"} ${props?.center ? "grid items-center" : ""}`}
        // style={{
        //   paddingBottom: `${footerHeight ? footerHeight + 20 : 65}px`,
        // }}
        >
          {props?.children}
        </main>
        {props?.chat !== false && <div className="relative right-4 bottom-0">
          <StaffChat />
        </div>}
        {!(isMobile && props?.footerShow) && <Footer fixedFooter={props?.fixedFooter} siteLogo={headLogo} isMobile={isMobile} />}
      </div>
    </div>
  );
};

export default LayoutComponent;
