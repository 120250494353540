/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LayoutComponent from "../components/LayoutComponent";
import UserContext from "../contexts/userContext";
import { directus } from "../services/directus";
import Seo from "../components/Seo";
import ConfirmationModal from "../components/popupModals/ConfirmationModal";
import { userPresent, valetNumberRegex, vehicleMinLetterCheck } from "../utils/CommonFunctions";
import InputMask from 'react-input-mask';

function Edit(prop) {
  const { state } = useLocation();
  const [userName, setUserName] = React.useState(state?.Customer_Name);
  const [vehicleFormat, setVehicleFormat] = React.useState(state?.Vehicle_Number);
  const vehicleNo = !!vehicleFormat ? vehicleFormat?.replaceAll('-', '')?.toUpperCase() : "";

  const [phoneNo, setPhoneNo] = React.useState(state?.Customer_Phone_Number);
  const [email, setEmail] = React.useState(state?.email);

  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);

  const [validationError, setValidationError] = React.useState({
    vehicleNumberError: false,
    phoneNoError: false,
    emailError: false,
    invalidPhoneNoError: false,
    invalidVehicleNoError: false,
  });
  const navigate = useNavigate();
  const { details, AuthCheck, companyDetails } = React.useContext(UserContext);
  const branch = details?.default_branch?.id;
  const isAlphaNumeric = (str) => valetNumberRegex.test(str);

  React.useEffect(() => {
    const maxLength = 10;
    if (phoneNo?.length > maxLength) {
      setPhoneNo(phoneNo.substring(0, maxLength));
    }
  }, [phoneNo?.length]);
  React.useEffect(() => {
    AuthCheck();
    const isUpdate =
      userName !== state?.Customer_Name ||
      vehicleNo !== state?.Vehicle_Number ||
      phoneNo !== state?.Customer_Phone_Number ||
      email !== state?.email;
    var {
      phoneNoError,
      vehicleNumberError,
      invalidVehicleNoError,
      invalidPhoneNoError,
      emailError,
    } = validationError;
    if (isUpdate) {
      if (!!phoneNo && !!vehicleNo) {
        if (
          !(
            phoneNoError ||
            vehicleNumberError ||
            invalidVehicleNoError ||
            invalidPhoneNoError ||
            (email?.trim()?.length > 0 ? emailError : false)
          )
        ) {
          setDetailsConfirmModel(true);
        } else {
          toast.error("Validation Failed!!!");
        }
      }
    }
  }, [validationError]);
  async function fieldValidation() {
    if (vehicleNo?.trim()?.length < 1) {
      updateValidationError("vehicleNumberError", true);
    } else {
      if (
        vehicleNo?.trim()?.length > 0 &&
        (vehicleNo?.replaceAll(/\s/g, "")?.length < 5 || !vehicleMinLetterCheck.test(vehicleNo))
      ) {
        updateValidationError("invalidVehicleNoError", true);
      }
    }
    if (email?.trim()?.length > 0) {
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        ? updateValidationError("emailError", false)
        : updateValidationError("emailError", true);
    }
    if (!!!phoneNo?.trim()) {
      updateValidationError("phoneNoError", true);
    } else {
      if (phoneNo.trim()?.length < 10) {
        updateValidationError("invalidPhoneNoError", true);
      }
    }
    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }
  const updateHandle = async (e) => {
    AuthCheck();
    const PhoneNoValidation = phoneNo.trim()?.length >= 10 ? true : false;
    const body = {
      Customer_Name:
        userName !== state?.Customer_Name ? userName : state?.Customer_Name,
      Vehicle_Number:
        vehicleNo !== state?.Vehicle_Number ? vehicleNo : state?.Vehicle_Number,
      Customer_Phone_Number:
        phoneNo !== state?.Customer_Phone_Number
          ? phoneNo
          : state?.Customer_Phone_Number,
      branch: branch,
      email: email !== state?.email ? email : state?.email,
    };
    if (PhoneNoValidation) {
      if (vehicleNo?.trim()?.length > 4 && isAlphaNumeric(vehicleNo)) {
        if (userName.trim()?.length ? userName.trim()?.length > 2 : true) {
          const LoggedCheck = await userPresent({ details: details });
          if (LoggedCheck?.status) {
            if (LoggedCheck?.present) {
              await directus
                .items("Valet_Information")
                .updateOne(state?.id, body)
                .then((res) => {
                  navigate(
                    `/${companyDetails?.company}/${companyDetails?.branch}/dashboard?id=1`
                  );
                  toast.success("Data updated");
                })
                .catch((err) => {
                  navigate(".", { replace: true });
                  console.log(err);
                  toast.error("Something went wrong");
                });
            } else {
              toast.error("You are not logged in. Kindly contact the respective manager/admin.");
            }
          } else {
            toast.error(
              "Your account was suspended, kindly contact the respective manager/admin."
            );
          }
        } else {
          toast.error("Enter valid user name (minimum 3 character)  ");
        }
      } else {
        toast.error("Invalid vehicle number");
      }
    } else {
      toast.error("Invalid phone number");
    }
  };
  const isUpdateConfirm =
    userName !== state?.Customer_Name ||
    vehicleNo !== state?.Vehicle_Number ||
    phoneNo !== state?.Customer_Phone_Number ||
    email !== state?.email;
  const handleModal = async (e) => {
    if (isUpdateConfirm) {
      setValidationError({
        vehicleNumberError: false,
        phoneNoError: false,
        emailError: false,
        invalidPhoneNoError: false,
        invalidVehicleNoError: false,
      });
      await fieldValidation();
    }
  };
  return (
    <LayoutComponent title={"EDIT VEHICLE USER"} center={true}>
      <Seo title={"Edit valet"} company={true} />
      <div className="main-content-area px-6 py-8">
        <div className="mx-auto">
          <div className="">
            <div className="mt-1 block">
              {" "}
              <div className="space-y-6 bg-white">
                <div className="grid grid-cols-6 gap-6 max-w-[700px] mx-auto">
                  <div className={`col-span-6`}>
                    <label htmlFor="vehicle-number" className="block text-sm font-bold text-gray-700 mb-2">
                      Vehicle number *
                    </label>
                    <InputMask
                      id="vehicle-number"
                      mask="*-*-*-*-*-*-*-*-*-*"
                      maskChar=""
                      placeholder="A-A-0-0-A-A-0-0-0-0"
                      className={`appearance-none ${(validationError?.vehicleNumberError &&
                        vehicleNo?.length < 1) ||
                        (validationError?.invalidVehicleNoError &&
                          (vehicleNo?.trim()?.length < 5 || !vehicleMinLetterCheck.test(vehicleNo)))
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                        }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-primary focus:border-primary placeholder:text-gray-300`}
                      value={vehicleFormat?.toUpperCase()}
                      onChange={(e) => setVehicleFormat(e?.target?.value)}
                    />
                    {(validationError?.vehicleNumberError ||
                      validationError?.invalidVehicleNoError) &&
                      vehicleNo?.length < 1 && (
                        <p className="text-xs text-right text-red-500 mt-2">
                          *Vehicle Number is required
                        </p>
                      )}
                    {validationError?.invalidVehicleNoError &&
                      ((vehicleNo?.length > 0 &&
                        vehicleNo?.length < 10) || !vehicleMinLetterCheck.test(vehicleNo)) && (
                        <p className="text-xs clear-both block text-right text-red-500 mt-2">
                          *Invalid Vehicle Number
                        </p>
                      )}
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="phone-no" className="block text-sm font-bold text-gray-700 mb-2">
                      Phone number *
                    </label>
                    <input
                      className={`mt-1 pl-4 py-2 pr-9  block w-full rounded-md border text-sm shadow-sm  focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300
                         ${(validationError?.phoneNoError &&
                          phoneNo?.length < 1) ||
                          (validationError?.invalidPhoneNoError &&
                            phoneNo?.trim()?.length < 10)
                          ? "border-red-300"
                          : "border-gray-300"
                        }`}
                      placeholder="Enter your phone number"
                      id="phone-no"
                      type="text"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      onChange={(e) =>
                        /^\d*\.?\d*$/.test(e.target.value) &&
                        setPhoneNo(e.target.value)
                      }
                      value={phoneNo || ""}
                    />
                    {(validationError?.phoneNoError ||
                      validationError?.invalidPhoneNoError) &&
                      phoneNo?.length < 1 && (
                        <p className="text-xs text-right text-red-500 mt-2">
                          *Phone Number is required
                        </p>
                      )}
                    {validationError?.invalidPhoneNoError &&
                      phoneNo?.trim()?.length > 0 &&
                      phoneNo?.trim()?.length < 10 && (
                        <p className="text-xs text-right text-red-500 mt-2">
                          *Invalid Phone Number
                        </p>
                      )}
                    <p className="text-xs text-slate-500 mt-2">
                      To get valet parking availability, offers, and about new
                      restaurants
                    </p>
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="user-name" className="block text-sm font-bold text-gray-700 mb-2">
                      Name
                    </label>
                    <input
                      className={`mt-1 pl-4 py-2 pr-9  block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary
                        h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300`}
                      placeholder="Enter your name"
                      id="user-name"
                      type="text"
                      onChange={(e) => setUserName(e.target.value)}
                      value={userName || ""}
                    />
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="u-email-01" className="block text-sm font-bold text-gray-700 mb-2">
                      Email{" "}
                    </label>
                    <input
                      type="email"
                      name="email-address"
                      id="u-email-01"
                      placeholder="Enter your email"
                      autoComplete="email"
                      className={`mt-1 pl-4 py-2 pr-9 block w-full rounded-md border text-sm shadow-sm  h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 
                        ${validationError?.emailError && email?.length > 1
                          ? "border-red-300 focus:border-primary"
                          : "border-gray-300 focus:border-primary"
                        }`}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email || ""}
                    />
                    {email?.trim()?.length > 0 &&
                      validationError?.emailError && (
                        <p className="text-xs text-right text-red-500 mt-2">
                          *Enter valid email address
                        </p>
                      )}
                  </div>
                  <div className="col-span-6 flex justify-center items-end pt-5">
                    <button
                      type="button"
                      onClick={() => handleModal()}
                      className={`rounded-md inline-flex items-center min-w-[50%] justify-center border h-12 border-transparent hover:border-vspurple bg-primary px-4 py-2 text-sm font-semibold text-white  hover:bg-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto ${!isUpdateConfirm ? "opacity-50 ..." : ""
                        }`}
                    >
                      <span className="ml-1.5">Update</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {detailsConfirmModel ? (
        <ConfirmationModal
          openModel={detailsConfirmModel}
          setIsOpenModel={setDetailsConfirmModel}
          callFunction={updateHandle}
          content={
            "Are you sure the given vehicle information is valid and to update the info?"
          }
        />
      ) : null}
    </LayoutComponent>
  );
}

export default Edit;
