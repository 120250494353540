import React, { useRef } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
function PopupShare({ url }) {
  const shareUrl = url;
  const title = "" ?? "ValetSearch";
  const divRef = useRef(null);
  const [isDivVisible, setDivVisible] = React.useState(false);
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setDivVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className="share-icon flex"
      ref={divRef}
      onClick={() => setDivVisible(!isDivVisible)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeidth="1.5"
        stroke="currentColor"
        className="w-5 h-5"
      >
        <title>Share</title>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
        />
      </svg>
      {isDivVisible && (
        <div className="absolute z-50">
          <div className={`block `}></div>
          <div className="min-w-[200px] social-share-popup gap-2 items-center justify-center bg-white border border-[#e0e0e0] shadow-md z-50 p-3 rounded-md mt-4">
            <div className="Demo__some-network">
              <FacebookShareButton
                url={shareUrl}
                quote={title}
                className="Demo__some-network__share-button flex gap-2 items-center !text-[14px]"
              >
                <FacebookIcon size={26} round /> Share on Facebook
              </FacebookShareButton>
            </div>
            <div className="Demo__some-network mt-2">
              <TwitterShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button flex gap-2 items-center !text-[14px]"
              >
                {/* <TwitterIcon size={32} round /> */}
                <svg viewBox="0 0 64 64" width="26" height="26">
                  <title>Twitter</title>
                  <circle cx="32" cy="32" r="31" fill="#000000"></circle>
                  <path
                    d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z"
                    fill="white"
                  ></path>
                </svg>
                Share on Twitter
              </TwitterShareButton>
            </div>
            <div className="Demo__some-network mt-2">
              <WhatsappShareButton
                url={shareUrl}
                title={title}
                separator=":: "
                className="Demo__some-network__share-button flex gap-2 items-center !text-[14px]"
              >
                <WhatsappIcon size={26} round />
                Share on Whatsapp
              </WhatsappShareButton>
            </div>
            <div className="Demo__some-network mt-2">
              <LinkedinShareButton
                url={shareUrl}
                className="Demo__some-network__share-button flex gap-2 items-center !text-[14px]"
              >
                <LinkedinIcon size={26} round />
                Share on LinkedIn
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PopupShare;
