/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { directus } from "../services/directus";
import UserContext from "../contexts/userContext";
import { slide as Menu } from "react-burger-menu";
import { getAssetURL } from "../utils/get-asset-url";
import LogoutModel from "./popupModals/LogoutModel";
import AssignAndConfirmModal from "./popupModals/AssignAndConfirm";
import palceHolderImg from "../assets/images/placeHolder.png";
import site_mob_phs from "../assets/images/valetsearch_small_logo.svg";
import { userPresent } from "../utils/CommonFunctions";

const HeaderComponent = (props) => {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchModalIsOpen, setSearchIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [assignedStaff, setAssignedStaff] = React.useState({
    value: "-1",
    label: "Select the staff",
  });
  const [vehicleData, setVehicleData] = React.useState([]);
  const { details, AuthCheck, companyDetails } = React.useContext(UserContext);
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;
  const admin_user = details?.is_admin_user;
  const manager_user = details?.is_manager_user;
  var styles = {
    bmBurgerButton: {
      position: "absolute",
      width: "25px",
      height: "18px",
      top: "43px",
      right: "36px",
      zIndex: 1,
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "absolute",
      width: "50%",
      height: "100%",
      top: "0",
      zIndex: 3,
      bottom: "0",
      overflow: "auto",
    },
    bmMenu: {
      background: "white",
      padding: "1.5em 1.5em 0",
      fontSize: "1.15em",
      width: "100%",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
    },
    bmOverlay: {
      position: "absolute",
      top: "0",
      zIndex: 3,
      background: "rgba(0, 0, 0, 0.3)",
      left: "0",
      bottom: "0",
      overflow: "auto",
    },
  };
  const FilteredDataFetch = async ({ body }) => {
    const result = await directus
      .items("Valet_Information")
      .readByQuery({
        fields: ["*","status_id.next_status.id","id",
          "status_id.next_status.name","branch.valet_fee",
          "status_id.name","Assigned_Staff.first_name",
        "pickup_request_assigned"],
        filter: body,
      })
      .then(async (res) => {
        if (res?.data?.length) {
          setVehicleData(res?.data[0]);
          setSearchIsOpen(true);
          return "";
        } else {
          toast.error("No match found");
        }
      })
      .catch((e) => console.log(e, "err"))
    return result
  }
  const searchHandle = async (e) => {
    if (search?.trim()?.length > 1) {
      AuthCheck();
      const filterBody = details?.is_staff_user
        ? {
          OTP_Value: { _eq: search?.trim() },
          branch: { _eq: branch },
          company: { _eq: company },
          status_id: { sequence: { _neq: 6 } },
          // Assigned_Staff: { first_name: { _eq: details?.first_name } }
        }
        : {
          OTP_Value: { _eq: search?.trim() },
          branch: { _eq: branch },
          company: { _eq: company },
          status_id: { sequence: { _neq: 6 } },
        };
      if (search?.trim()?.length === 4 && (/^\d+$/.test(search?.trim()) === true)) {
        await FilteredDataFetch({ body: filterBody });
      }
      else if (search.trim()?.length === 10 && (/^\d+$/.test(search?.trim()) === true)) {
        await FilteredDataFetch({
          body: {
            Customer_Phone_Number: { _eq: search },
            branch: { _eq: branch },
            company: { _eq: company },
            status_id: { sequence: { _neq: 6 } },
          }
        });
      }
      else if (search.trim()?.length >= 5 && (/^\d+$/.test(search?.trim()) === false)) {
        await FilteredDataFetch({
          body: {
            Vehicle_Number: { _eq: search },
            branch: { _eq: branch },
            company: { _eq: company },
            status_id: { sequence: { _neq: 6 } },
          }
        });
      }
      else {
        toast.error(`${"Enter valid OTP / phone number / vehicle number"}`);
      }
    }
  };

  const statusUpdateHandle = async (prop) => {
    AuthCheck();

    const LoggedCheck = await userPresent({ details: details });
    if (LoggedCheck?.status) {
      if (!LoggedCheck?.present) {
        toast.error("You are not logged in. Kindly contact the respective manager/admin.");
      } else {
        const BodyValue =
          details?.is_staff_user === true
            ? {
              Assigned_Staff: { id: details?.id },
              status_id: vehicleData?.status_id?.next_status?.id,
            }
            : {
              status_id: vehicleData?.status_id?.next_status?.id,
            };
        if (vehicleData?.status_id?.next_status?.name !== undefined) {
          const vehicleStatus = await vehicleCurrentStatusCheck();
          if (vehicleStatus) {
            await directus
              .items("Valet_Information")
              .updateOne(Number(vehicleData?.id), BodyValue)
              .then((res) => {
                toast.success("Data updated");
                window.location.reload();
              })
              .catch((err) => {
                navigate(".", { replace: true });
                console.log(err);
                toast.error("Something went wrong");
              });
            setIsOpen(false);
          } else {
            toast.error(
              "This vehicle status is already changed, kindly refresh your page"
            );
          }
        } else {
          toast.error("There is no status to update");
        }
      }
    } else {
      toast.error("Your account was suspended, kindly contact the respective manager/admin.");
    }
  };

  const recordCashPayment = async() =>{
    AuthCheck();
    const LoggedCheck = await userPresent({ details: details });
    if (LoggedCheck?.status) {
      if (!LoggedCheck?.present) {
        toast.error("You are not logged in. Kindly contact the respective manager/admin.");
      } else {
        if(vehicleData?.payment_status ==='not_paid'){
          await directus
          .items("Valet_Information")
          .updateOne(Number(vehicleData?.id), {
            payment_status: 'paid',
            valet_fee: Number(vehicleData?.branch?.valet_fee),
            tax_in_percent: props?.siteLogo?.tax_config_in_percent,
            payment_mode :'cash'
          })
          .then((res) => {
            toast.success("Vehicle payment updated");
            window.location.reload();
          })
          .catch((err) => {
            navigate(".", { replace: true });
            console.log(err);
            toast.error("Something went wrong");
          });
          setIsOpen(false);
        }
        else{
          toast.error("The payment for the vehicle is already done");
        }
      }
    }
    else {
      toast.error("Your account was suspended, kindly contact the respective manager/admin.");
    }
  }

  const timeUpdate = async (prop) => {
    await directus
      .items("Valet_Information")
      .updateOne(Number(prop?.id), {
        started_time: prop?.date_updated,
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const vehicleCurrentStatusCheck = async () => {
    var val = false;
    await directus
      .items("Valet_Information")
      .readByQuery({
        fields: ["id","status_id.sequence"],
        filter: {
          id: { _eq: vehicleData?.id },
          company: { _eq: company },
          branch: { _eq: branch },
        },
      })
      .then((res) => {
        val =
          res?.data[0]?.status_id?.sequence === vehicleData?.status_id?.sequence
            ? true
            : false;
      })
      .catch((e) => {
        console.log(e);
      });
    return val;
  };
  const assignHandle = async () => {
    AuthCheck();
    if (assignedStaff?.value === "-1" && details?.is_staff_user !== true) {
      return toast.error("Assigned Staff not provided!!!");
    } else {

      const LoggedCheck = await userPresent({ details: details });
      if (LoggedCheck?.status) {
        const bodyValue =
          vehicleData?.pickup_request_assigned === false &&
            vehicleData?.status_id?.sequence === 3
            ? {
              Assigned_Staff: { id: assignedStaff?.value },
              pickup_request_assigned: true,
            }
            : { Assigned_Staff: { id: assignedStaff?.value } };
        await directus
          .items("Valet_Information")
          .updateOne(Number(vehicleData?.id), bodyValue)
          .then(async (res) => {
            if (res?.status_id?.sequence === 3) {
              setAssignedStaff(
                JSON.stringify({ value: "-1", label: "Select the staff" })
              );
              await timeUpdate(vehicleData);
              toast.success("Staff assigned successfully");
            } else {
              toast.success("Staff assigned successfully");
            }
          })
          .catch((err) => {
            navigate(".", { replace: true });
            console.log(err);
            toast.error("Something went wrong");
          });
        setIsOpen(false);
        setSearchIsOpen(false);
        props?.setReloadData(!props?.reloadData);
      }
      else {
        toast.error("Your account was suspended, kindly contact the respective manager/admin.");
      }
    }
  };
  const SideNavHandle = (e) => {
    props?.Setfullscreen(e?.isOpen);
    return true;
  };
  // const isMobile = window.innerWidth <= 768;
  const poweredByLogo =
    props?.siteLogo?.mobile_site_logo?.length > 0
      ? props?.siteLogo?.mobile_site_logo
      : "";
  return (
    <>
      {props?.login !== true ? (
        <header
          className={`w-full py-8 px-8 max-sm:px-5 p-8  ${props?.search !== true ? "border-b border-gray-300" : ""
            }`}
        >
          <div className="flex justify-between md:gap-0 items-center header-top">
            {props?.search === true ? (
              <div className="left-block w-full flex justify-start items-start gap-7 md:justify-between md:flex-row md:items-center header-search">
                <div
                  id="search_form"
                  className="w-full flex justify-start items-center gap-2"
                >
                  <div className="relative w-full">
                    <label
                        htmlFor="search_box"
                        className="hidden"
                    >
                        Search
                    </label>
                    <input
                      type="text"
                      name="search"
                      id="search_box"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      onKeyUp={(e) => {
                        if (e?.keyCode === 13) {
                          searchHandle(e);
                        }
                      }}
                      className="pl-4 py-2 pr-4 block w-full rounded-md border text-sm shadow-sm border-gray-200 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary md:min-w-[310px]"
                      placeholder="Search the Vehicle by OTP / Phone number / Vehicle number..."
                    />
                    <template x-if="showSearchClear===true">
                      <button
                        type="button"
                        className="absolute y-center right-3 flex justify-center items-center text-secondary opacity-20 bg-white rounded-full outline-none focus:opacity-100 hover:opacity-100"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <title>Clear</title>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </span>
                        <span className="hidden">clear button</span>
                      </button>
                    </template>
                  </div>
                  <button
                    type="button"
                    id="search_button"
                    title="Search"
                    onClick={(e) => searchHandle(e)}
                    className="whitespace-nowrap inline-flex items-center justify-center rounded-md border border-vsorangered shadow-sm bg-vswhite h-10 px-4 py-2 text-sm font-semibold text-sky-600 focus:outline-none focus:ring-2 focus:ring-vsorangered"
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="3"
                        stroke="#d06861"
                        className="w-4 h-4"
                      >
                        <title>Search</title>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </span>
                  </button>
                </div>

                <button
                  type="button"
                  onClick={() =>
                    navigate(
                      `/${companyDetails?.company}/${companyDetails?.branch}/valet-create`
                    )
                  }
                  className="inline-flex  items-center justify-center rounded-md border h-10 border-transparent bg-primary px-4 py-2 text-sm font-semibold text-white shadow-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto mr-6"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <title>Create</title>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                  <span className="ml-1.5 mobile-hidden">Vehicle</span>
                </button>
                <Menu
                  styles={styles}
                  right
                  onStateChange={(e) => SideNavHandle(e)}
                >
                  <div className="right-block">
                    <div className="flex flex-col h-full justify-between items-start pb-[60px]">
                      <div className="logo pb-4 justify-start text-center w-full gap-5 items-center">
                        <div className="flex flex-col">
                          <img
                            src={
                              companyDetails?.logo
                                ? getAssetURL(companyDetails?.logo)
                                : palceHolderImg
                            }
                            style={{ maxWidth: "110px", maxHeight: "50%" }}
                            alt="LOGO-1"
                          />
                          <p className="text-gray-400 text-[12px] font-bold pt-1 uppercase max-w-[95px]">
                            {companyDetails?.branchName}{" "}
                          </p>
                        </div>

                        <div></div>
                      </div>
                      <ul className="w-full list list-none text-sm text-gray-600 font-medium divide-y divide-slate-200">
                        <li className="menu-link py-3 uppercase">
                          <Link
                            to={`/${companyDetails?.company}/${companyDetails?.branch}/scan-your-qrcode`}
                            className="text-black"
                          >
                            Your QR code (scanned by customer)
                          </Link>
                        </li>
                        {(admin_user === true || manager_user === true) && (
                          <li className="menu-link py-3 uppercase">
                            <Link
                              to={`/${companyDetails?.company}/${companyDetails?.branch}/analytics`}
                              className="text-black"
                            >
                              Analytics
                            </Link>
                          </li>
                        )}
                        {(admin_user === true && Number(companyDetails?.branchValetFee) > 0) && (
                          <li className="menu-link py-3 uppercase">
                            <Link
                              to={`/${companyDetails?.company}/${companyDetails?.branch}/payments`}
                              className="text-black"
                            >
                              Payments
                            </Link>
                          </li>
                        )}
                        {(admin_user === true || manager_user === true) && (
                          <li className="menu-link py-3 uppercase">
                            <Link
                              to={`/${companyDetails?.company}/${companyDetails?.branch}/staff-in-out-status-board`}
                              className="text-black"
                            >
                              Staff In Out Status Board
                            </Link>
                          </li>
                        )}
                        {admin_user === true && (
                          <li className="menu-link py-3 uppercase">
                            <Link
                              to={`/${companyDetails?.company}/${companyDetails?.branch}/users/manage`}
                              className="text-black"
                            >
                              View manager user
                            </Link>
                          </li>
                        )}
                        {(admin_user === true || manager_user === true) && (
                          <li className="menu-link py-3 uppercase">
                            <Link
                              to={`/${companyDetails?.company}/${companyDetails?.branch}/staff/manage`}
                              className="text-black"
                            >
                              View staff user
                            </Link>
                          </li>
                        )}
                        {(admin_user === true || manager_user === true) && (
                          <li className="menu-link py-3 uppercase">
                            <Link
                              to={`/${companyDetails?.company}/${companyDetails?.branch}/vehicle-search`}
                              className="text-black"
                            >
                              Master vehicle search
                            </Link>
                          </li>
                        )}
                        {(admin_user === true || manager_user === true) && (
                          <li className="menu-link py-3 uppercase">
                            <Link
                              to={`/${companyDetails?.company}/${companyDetails?.branch}/edit-company-info`}
                              className="text-black"
                            >
                              Edit Company Info
                            </Link>
                          </li>
                        )}
                        {(admin_user === true || manager_user === true) && (
                          <li className="menu-link py-3 uppercase">
                            <Link
                              to={`/${companyDetails?.company}/${companyDetails?.branch}/edit-valet-fee`}
                              className="text-black"
                            >
                              Fee
                            </Link>
                          </li>
                        )}
                        {(admin_user === true || manager_user === true) && (
                          <li className="menu-link py-3 uppercase ">
                            <Link
                              to={`/${companyDetails?.company}/${companyDetails?.branch}/offers/manage`}
                              className="text-black"
                            >
                              Offers & Promotions
                            </Link>
                          </li>
                        )}
                        {(admin_user === true || manager_user === true) && (
                          <li className="menu-link py-3 uppercase">
                            <Link
                              to={`/${companyDetails?.company}/${companyDetails?.branch}/edit-valet-number`}
                              className="text-black"
                            >
                              Valet / Contact Number
                            </Link>
                          </li>
                        )}
                        <li className="menu-link py-3 uppercase">
                          <button
                            onClick={() => setIsOpen(true)}
                            className="text-black text-center flex items-center uppercase"
                          >
                            <span>Logout</span>
                            <span className="ml-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5   h-5 inline-block"
                              >
                                <title>Logout</title>
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                                />
                              </svg>
                            </span>
                          </button>
                        </li>
                      </ul>
                    </div>

                    <div className="powered-by text-black text-sm flex items-center justify-center bg-white absolute bottom-0 right-0 left-0 w-full m-auto max-md:px-[1.5em] py-[10px] max-md:pt-[10px]">
                      <span className="top-[9px] px-2">Powered by</span>
                      <img
                        className="max-w-[200px] m-0 p-0 w-[40px] h-[40px] max-md:w-[40px] max-md:h-[40px]"
                        src={
                          poweredByLogo
                            ? getAssetURL(poweredByLogo)
                            : site_mob_phs
                        }
                        style={{ objectFit: "contain" }}
                        alt="Logo"
                      />
                    </div>
                  </div>
                </Menu>
              </div>
            ) : props?.title ? (
              <div className="left-block flex justify-between items-center gap-4 w-full">
                <div
                  className={`${props?.create === "offers" ? " max-w-[50%]" : ""
                    }`}
                >
                  <h1 className="text-xl font-semibold text-gray-900">
                    {props?.title}
                  </h1>
                </div>
                <div className="flex justify-between gap-5">
                  {props?.create && (
                    <div
                      className={`right-block flex justify-end items-center gap-4 ml-0 md:ml-[15px]${props?.create !== "users" && props?.create !== "staff"
                          ? " ml-0 off_promo"
                          : ""
                        }`}
                    >
                      <Link
                        to={`/${companyDetails?.company}/${companyDetails?.branch}/${props?.create}/create`}
                        className="inline-flex items-center justify-center rounded-md border h-10 border-transparent bg-primary px-2 py-2 text-sm font-semibold text-white shadow-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <title>Create</title>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </span>
                        <span className="ml-1.5 c_button">
                          {props?.create === "users"
                            ? "Manager"
                            : props?.create === "staff"
                              ? "Staff"
                              : "Offers & Promotions"}
                        </span>
                      </Link>
                    </div>
                  )}
                  <div className="right-0 flex justify-end items-center">
                    <button
                      type="button"
                      title="Backward"
                      onClick={() => navigate(-1)}
                      className="w-full inline-flex items-center justify-center text-sm font-medium p-1 text-primary focus:outline-none  sm:w-auto left-4 top-[62px]"
                    >
                      <span className="back-icon mr-1 mt-[-3px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="3.5"
                          stroke="#EC8E46"
                          className="w-4 h-4"
                        >
                          <title>Back</title>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                          />
                        </svg>
                      </span>
                      Back
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </header>
      ) : (
        <header className="flex bg-white  justify-between items-center shadow-sm py-8 px-4 lg:px-10 border-b border-gray-300">
          <div className="left-block">
            <div className="font-medium text-2xl text-sky-600">
              VALET <span className="text-black	"> SEARCH</span>
            </div>
          </div>{" "}
        </header>
      )}
      {/* Logout model */}
      {modalIsOpen ? <LogoutModel setIsOpenModel={setIsOpen} /> : null}
      {/* </Modal> */}
      {searchModalIsOpen ? (
        <AssignAndConfirmModal
          setIsOpenModel={setSearchIsOpen}
          vehicleData={vehicleData}
          allUsers={props?.allUsers}
          assignHandle={assignHandle}
          statusUpdateHandle={statusUpdateHandle}
          recordCashPayment= {recordCashPayment}
          assignedStaff={assignedStaff}
          setAssignedStaff={setAssignedStaff}
        />
      ) : null}
    </>
  );
};
export default HeaderComponent;
