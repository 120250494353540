import { EMAIL_SENDER_ADDRESS, TRANS_MAIL_API_BASE_URL } from "./utils";
import { directus } from "../services/directus";
import Email_template from "./Email_template";

export async function RegistrationMailSender(props) {
  // { url,name, emailTo }
  const MailFetcher = async ({ msg }) => {
    await fetch(TRANS_MAIL_API_BASE_URL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(msg),
    })
      .then((e) => console.log(e, "success"))
      .catch((e) => console.log(e, "error"));
  };

  var name = "ValetSearch";

  const AdminMailList = await directus
    .items("Configuration_Settings")
    .readByQuery({ fields: ["admin_email_addresses", "site_logo"] })
    .then((res) => {
      return res?.data;
    })
    .catch((e) => {
      console.log(e);
    });

  AdminMailList?.admin_email_addresses?.split(",")?.map(async (e) => {
    let template = Email_template({
      user: "admin",
      companyName: props?.companyName,
      branchName: props?.branchName,
      siteLogo: AdminMailList?.site_logo,
      submitType: props?.submitType,
    });
    const subjectTitle=props?.submitType?props?.submitType==="document_correction"?`Document correction submission received from ${props?.companyName}/${props?.branchName}.`:
    props?.submitType==="agreement_submit"?`Onboarding form details submission received from ${props?.companyName}/${props?.branchName}.`:
    props?.submitType==="signature_submit"?` Contract Agreement submission received from ${props?.companyName}/${props?.branchName}`:""
    :  "One application has been received at ValetSearch for verification."
    var msg = {
      to: e,
      from: EMAIL_SENDER_ADDRESS,
      subject:subjectTitle,
      name: name,
      html: template,
    };
    return await MailFetcher({ msg });
  });
  let template1 = Email_template({
    companyName: props?.companyName,
    branchName: props?.branchName,
    siteLogo: AdminMailList?.site_logo,
    submitType: props?.submitType
  });

  const subjectTitleVendor=props?.submitType?props?.submitType==="document_correction"?"Acknowledgment for document correction submission.":
  props?.submitType==="agreement_submit"?"  Onboarding form details submission received.":
  props?.submitType==="signature_submit"?" Contract Agreement submission received.":""
  :  `Thank you for registering your ${props?.companyName} / ${props?.branchName} with ValetSearch.`

  var msg = {
    to: props?.emailTo,
    from: EMAIL_SENDER_ADDRESS,
    subject: subjectTitleVendor,
    name: name,
    html: template1,
  };
  props?.emailTo && (await MailFetcher({ msg }));

  return "";
}
