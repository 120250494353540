/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import LayoutComponent from "../../components/LayoutComponent";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import UserContext from "../../contexts/userContext";
import { directus } from "../../services/directus";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import Pagination from "../../components/Pagination";
import { userPresent } from "../../utils/CommonFunctions";

function OffersManage() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [allOffers, setAllOffers] = React.useState();
  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
  const [offerStatus, setOfferStatus] = React.useState({
    id: 1,
    status: false,
    name: "Active",
  });
  const { details, AuthCheck, companyDetails } = React.useContext(UserContext);
  const navigate = useNavigate();
  const [pagination, setPagination] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(
    Number(new URLSearchParams(window.location.search).get("page")) || 1
  );
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;
  React.useEffect(() => {
    AuthCheck();
    setIsLoading(true);
    const offersDataCall = async () => {
      const filterBody = offerStatus?.status
        ? {
            branch_info: { _eq: branch },
            is_expiry: { _eq: offerStatus?.status },
          }
        : {
            branch_info: { _eq: branch },
            is_expiry: { _eq: offerStatus?.status },
            date_to: { _gte: new Date() },
          };
      await directus
        .items("Offers_and_Promotions")
        .readByQuery({
          fields: ["*"],
          filter: filterBody,
          limit: paginationCount,
          page: page,
          meta: ["filter_count"],
        })
        .then((res) => {
          setAllOffers(res?.data);
          setPagination(Number(res?.meta?.filter_count));
        })
        .catch((e) => console.log(e));
      setIsLoading(false);
    };
    offersDataCall();
    const handleBackPress = () => {
      window.location.reload();
    };
    window.onpopstate = handleBackPress;
  }, [page, branch, offerStatus, company]);
  const styles1 = {
    menuList: (base) => ({
      ...base,
      height: "110px",

      "::-webkit-scrollbar": {
        width: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "#2684FF",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    control: (base, state) => ({
      ...base,
      cursor: "pointer",
      border: "none",
      marginTop: "4px",
    }),
  };
  const paginationCount = 10;
  const offersList = [];
  const selectHandle = (props) => {
    return selected.includes(props)
      ? setSelected(selected.filter((word) => word !== props))
      : setSelected([...selected, props]);
  };
  for (let i = 0; i < allOffers?.length; i++) {
    if (i < 10) {
      offersList.push(
        <tr className="hover:bg-gray-50" key={allOffers[i]?.id}>
          <td className="px-5 py-3 text-left font-semibold text-gray-900 remove-col">
            <label
              htmlFor="chk"
              className="hidden"
            >
              Select
            </label>
            <input
              type="checkbox"
              title="single-record"
              // value={data[i]?.id}
              checked={
                !selected.includes(allOffers[i]?.id) === true ? false : true
              }
              onClick={() => selectHandle(allOffers[i]?.id)}
              onChange={(e) => {}}
              name="chk"
              id="chk"
              className=" h-4 w-4 rounded border-gray-300 sm:left-6 text-primary"
            />
          </td>
          <td
            className="whitespace-nowrap px-5 py-3 text-lg text-gray-500"
            data-th="Expiry Date"
          >
            {allOffers[i]?.date_to ? allOffers[i]?.date_to : "---"}
          </td>
          <td
            className="px-5 py-3 text-lg font-medium text-gray-900"
            data-th="Description"
          >
            <span className="manager-name">
              {allOffers[i]?.short_description
                ? allOffers[i]?.short_description
                : "---"}
            </span>
          </td>

          <td
            className="whitespace-nowrap px-5 py-3 text-right text-lg text-gray-500 cursor-pointer"
            data-th="Edit"
            onClick={() =>
              navigate(
                `/${companyDetails?.company}/${companyDetails?.branch}/offers/edit`,
                {
                  state: allOffers[i]?.id,
                }
              )
            }
          >
            <span className="inline-block f-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <title>Edit</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            </span>
          </td>
        </tr>
      );
    }
  }
  const handleNavigation = (e) => {
    setPage(e);
    setSelected([]);
    return "";
  };
  const numPages =
    pagination % paginationCount === 0
      ? Math.floor(pagination / paginationCount)
      : Math.floor(pagination / paginationCount) + 1;

  const selectOption = [
    { id: 1, status: false, name: "Active" },
    { id: 1, status: true, name: "In Active" },
  ];
  const offersHandle = (e) => {
    setSelected([]);
    setOfferStatus(e);
  };
  const deActive = async (props) => {
    const LoggedCheck = await userPresent({ details: details });
    if (LoggedCheck?.status) {
      if (selected?.length) {
        await directus
          .items("Offers_and_Promotions")
          .updateMany(selected, {
            is_expiry: !offerStatus?.status,
          })
          .then((res) => {
            navigate(
              `/${companyDetails?.company}/${companyDetails?.branch}/offers/manage`
            );
            setDetailsConfirmModel(false);
            window.location.reload();
            toast.success(" Updated successfully");
          })
          .catch((err) => {
            navigate(".", { replace: true });
            toast.error(err?.message);
          });
        setSelected([]);
      }
    } else {
      setSelected([]);
      toast.error(
        "Your account was suspended, kindly contact the respective manager/admin."
      );
    }
  };
  return (
    <LayoutComponent
      title={"MANAGE OFFERS & PROMOTIONS"}
      create={"offers"}
      fixedFooter={true}
    >
      <Seo title={"Manage Offers&Promotions"} company={true} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="main-content-area px-6 max-sm:px-5 pt-0">
          <div className="py-8 flex flex-col">
            <div className="">
              <div className="inline-block min-w-full align-middle">
                <div className="pb-4 flex justify-end gap-4 items-center">
                  {selected?.length > 0 && (
                    <button
                      className="w-full inline-flex items-center justify-center text-sm font-medium rounded-md border p-2 border-primary text-primary focus:outline-none  sm:w-auto left-4 top-[62px]"
                      type="button"
                      onClick={() =>
                        setDetailsConfirmModel(!detailsConfirmModel)
                      }
                    >
                      Change Status
                    </button>
                  )}
                  <button
                    className="user-select-all w-full inline-flex items-center justify-center text-sm font-medium border p-2 border-primary text-primary focus:outline-none  sm:w-auto left-4 top-[62px]"
                    type="button"
                    onClick={() =>
                      setSelected(
                        selected?.length === allOffers?.length
                          ? []
                          : allOffers?.map((e) => e?.id)
                      )
                    }
                  >
                    Mark All
                  </button>
                  <Select
                    id="Users"
                    classNamePrefix="react-select"
                    className=" react-select-container border border-primary-500 rounded-md text-gray-900 text-sm min-h-[42px] min-w-[120px] capitalize"
                    defaultMenuIsOpen={false}
                    maxMenuHeight={250}
                    onChange={(e) => offersHandle(e)}
                    options={selectOption}
                    isSearchable={false}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    value={offerStatus}
                    styles={{
                      styles1,
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: "bold",
                        color: "#9CA3AF",
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        border: "none",
                        boxShadow: "none",
                      }),
                      input: (base, state) => ({
                        ...base,
                        '[type="text"]': {
                          border: "none",
                          boxShadow: "none",
                        },
                      }),
                    }}
                  />
                </div>
                <div
                  className={`parking-status-table relative border rounded-md border-gray-200 overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 md:rounded-lg ${
                    allOffers?.length <= 0 ? "bordered" : "no-border"
                  }`}
                >
                  {allOffers?.length ? (
                    <table className="min-w-full divide-y  divide-gray-300 manager-list-table">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-5 py-3 text-left font-semibold text-gray-900"
                          >
                            <label
                              htmlFor="chk"
                              className="hidden"
                            >
                              Select
                            </label>
                            <input
                              type="checkbox"
                              title="single-record"
                              checked={selected?.length === allOffers?.length}
                              onChange={(e) => {
                                setSelected(
                                  selected?.length === allOffers?.length
                                    ? []
                                    : allOffers?.map((e) => e?.id)
                                );
                              }}
                              id="chk"
                              name="chk"
                              className=" h-4 w-4 rounded border-gray-300 sm:left-6 text-primary"
                            />
                          </th>
                          <th
                            scope="col"
                            className="min-w-[12rem] px-5 py-3 text-left text-lg font-semibold text-gray-900"
                          >
                            Expiry Date
                          </th>
                          <th
                            scope="col"
                            className="px-5 py-3 text-left text-lg font-semibold text-gray-900"
                          >
                            Description
                          </th>
                          <th
                            scope="col"
                            className="px-5 py-3 text-right text-lg font-semibold text-gray-900"
                          >
                            Edit
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {offersList}
                      </tbody>
                    </table>
                  ) : (
                    <div className="whitespace-nowrap text-sm p-4 text-gray-500 flex justify-center">
                      {" "}
                      No data found
                    </div>
                  )}
                </div>
                {/* pagination */}
                {numPages > 1 ? (
                  <Pagination
                    numPages={numPages}
                    data={allOffers}
                    page={page}
                    handleNavigation={handleNavigation}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}{" "}
      {detailsConfirmModel ? (
        <ConfirmationModal
          openModel={detailsConfirmModel}
          setIsOpenModel={setDetailsConfirmModel}
          callFunction={deActive}
          content={` Are you sure to update given ${
            selected?.length === 1 ? "offer" : "offer's"
          } to ${offerStatus?.status ? "Active" : "In Active"} state?`}
        />
      ) : null}
    </LayoutComponent>
  );
}

export default OffersManage;
