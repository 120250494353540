/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { directus } from "../../services/directus";
import LayoutComponent from "../../components/LayoutComponent";
import UserContext from "../../contexts/userContext";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import EditComponent from "../../components/usersForm/EditComponent";

export default function UserEdit() {
  const [userDetails, setUserDetails] = React.useState();
  const navigate = useNavigate();
  const [userName, setUserName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [defaultPhoneNo, setDefaultPhoneNo] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
  const [validationError, setValidationError] = React.useState({
    userNameError: false,
    invalidPhoneNoError: false,
    phoneNoError: false,
    passwordMismatchError: false,
  });
  const { state } = useLocation();
  const { details, AuthCheck, companyDetails } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;

  React.useEffect(() => {
    AuthCheck();
    userDataCall();
  }, []);
  const userDataCall = async () => {
    await directus.users
      .readByQuery({
        fields: ["*"],
        filter: { id: { _eq: state } },
      })
      .then((res) => {
        setUserName(res?.data[0]?.first_name);
        setEmail(res?.data[0]?.email);
        setUserDetails(res?.data[0]);
        setPhoneNo(res?.data[0]?.contact_number);
        setDefaultPhoneNo(res?.data[0]?.contact_number);
        setIsLoading(false);
        return "";
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong");
      });
  };
  React.useEffect(() => {
    const maxLength = 10;
    if (phoneNo?.length > maxLength) {
      setPhoneNo(phoneNo.substring(0, maxLength));
    }
  }, [phoneNo?.length]);
  React.useEffect(() => {
    AuthCheck();
    var { phoneNoError, userNameError, invalidPhoneNoError,passwordMismatchError } = validationError;
    if (!!userName && !!phoneNo) {
      if (!(phoneNoError || userNameError || invalidPhoneNoError|| passwordMismatchError)) {
        setDetailsConfirmModel(true);
      } else {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);
  const phoneNoExist = async () => {
    var flag = false;
    await directus.users
      .readByQuery({
        fields: ["id"],
        filter: {
          contact_number: { _eq: phoneNo },
          default_branch: { _eq: branch },
          default_company: { _eq: company },
        },
      })
      .then((res) => (res?.data[0] ? (flag = false) : (flag = true)))
      .catch((e) => console.log(e));
    return flag;
  };
  const updatehandle = async (e) => {
    AuthCheck();
    let noChange =
      userDetails?.first_name !== userName ||
      userDetails?.email !== email ||
      userDetails?.password !== password ||
      userDetails?.contact_number !== phoneNo;
    const updateFields = password?.trim()?.length
      ? {
          first_name: userName,
          email: email,
          password: password,
          password_copy: password,
          contact_number: phoneNo,
          is_manager_user: true,
        }
      : {
          first_name: userName,
          email: email,
          contact_number: phoneNo,
          is_manager_user: true,
        };
    if (noChange) {
      if (userName?.trim()?.length > 2) {
        if (phoneNo.trim()?.length === 10) {
          const passwordCheck =
            password?.trim().length > 1 || confirmPassword?.trim().length > 1
              ? password === confirmPassword
              : true;
          if (passwordCheck) {
            if (phoneNo !== defaultPhoneNo ? await phoneNoExist() : true) {
              await directus.users
                .updateOne(state, updateFields)
                .then((res) => {
                  navigate(
                    `/${companyDetails?.company}/${companyDetails?.branch}/users/manage`
                  );
                  toast.success("Updated successfully");
                })
                .catch((err) => {
                  navigate(".", { replace: true });
                  console.log(err);
                  toast.error(err?.message);
                });
            } else {
              toast.error("Contact number already exists");
            }
          } else {
            toast.error("Password mismatch");
          }
        } else {
          toast.error("Enter valid phone number");
        }
      } else {
        toast.error("Enter valid name");
      }
    } else {
      toast.error("Do some change");
    }
  };
  return (
    <LayoutComponent title={"EDIT MANAGER"} fixedFooter={true} center={true}>
      <Seo title={"Manager edit"} company={true} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <EditComponent
          userName={userName}
          setUserName={setUserName}
          email={email}
          phoneNo={phoneNo}
          setPhoneNo={setPhoneNo}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          validationError={validationError}
          setValidationError={setValidationError}
          setConfirmPassword={setConfirmPassword}
          userType={"Manager"}
          userDetails={userDetails}
        />
      )}
      {detailsConfirmModel ? (
        <ConfirmationModal
          openModel={detailsConfirmModel}
          setIsOpenModel={setDetailsConfirmModel}
          callFunction={updatehandle}
          content={
            "Are you sure the given user information is valid and to update the info?"
          }
        />
      ) : null}
    </LayoutComponent>
  );
}
