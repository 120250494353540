/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LayoutComponent from "../components/LayoutComponent";
import UserContext from "../contexts/userContext";
import { directus } from "../services/directus";
import Modal from "react-modal";
import Select from "react-select";
import Seo from "../components/Seo";
import { userPresent, valetNumberRegex, vehicleMinLetterCheck } from "../utils/CommonFunctions";
import InputMask from 'react-input-mask';

function Create() {
  const [userName, setUserName] = React.useState("");
  const [vehicleFormat, setVehicleFormat] = React.useState("");
  const vehicleNo = !!vehicleFormat ? vehicleFormat?.replaceAll('-', '')?.toUpperCase() : "";

  const [phoneNo, setPhoneNo] = React.useState("");
  const [select, setSelect] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [validationError, setValidationError] = React.useState({
    invalidPhoneNoError: false,
    emailError: false,
    vehicleNumberError: false,
    phoneNoError: false,
    termsError: false,
    invalidVehicleNoError: false,
  });
  const [assignedStaff, setAssignedStaff] = React.useState({
    value: "-1",
    label: "Select the staff",
  });
  const [otpNo, setOtpNo] = React.useState("");
  const [allUsers, setAllUsers] = React.useState([]);
  const { details, AuthCheck, companyDetails } = React.useContext(UserContext);
  const [editModalIsOpen, setEditIsOpen] = React.useState(false);
  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
  const [preventDouble, setPreventDouble] = React.useState(false);
  const navigate = useNavigate();
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;
  const isAlphaNumeric = (str) => valetNumberRegex.test(str);
  const createHandle = async (prop) => {
    setPreventDouble(true);
    AuthCheck();
    const LoggedCheck = await userPresent({ details: details });
    if (LoggedCheck?.status) {
      if (LoggedCheck?.present) {
        if (assignedStaff?.value === "-1" && details?.is_staff_user !== true) {
          setPreventDouble(false);
          return toast.error("Assigned Staff not provided!!!");
        }
        const bodyValue = {
          Customer_Name: userName,
          Vehicle_Number: vehicleNo,
          Customer_Phone_Number: phoneNo,
          OTP_Value: prop?.otp ? prop?.otp : otpNo,
          branch: branch,
          company: company,
          email: email,
          Assigned_Staff: {
            id: details?.is_staff_user ? details?.id : assignedStaff?.value,
          },
        };
        await directus
          .items("Valet_Information")
          .createOne(bodyValue)
          .then(async (res) => {
            await timeUpdate(res);
            navigate(
              `/${companyDetails?.company}/${companyDetails?.branch}/dashboard/?id=1`
            );
            toast.success(" Created successfully");
          })
          .catch((err) => {
            navigate(".", { replace: true });
            console.log(err);
            toast.error(err);
          });
      } else {
        toast.error("You are not logged in. Kindly contact the respective manager/admin.");
      }
    } else {
      toast.error("Your account was suspended, kindly contact the respective manager/admin.");
    }
    setPreventDouble(false);
  };

  React.useEffect(() => {
    AuthCheck();
    var {
      invalidVehicleNoError,
      invalidPhoneNoError,
      phoneNoError,
      termsError,
      vehicleNumberError,
      emailError,
    } = validationError;
    if (!!phoneNo && !!vehicleNo) {
      if (
        !(
          invalidVehicleNoError ||
          invalidPhoneNoError ||
          phoneNoError ||
          termsError ||
          vehicleNumberError ||
          (email?.trim()?.length > 0 ? emailError : false)
        )
      ) {
        // callback();
        setDetailsConfirmModel(true);
      } else {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);

  const usersDatasCall = async () => {
    const bodyValue = details?.is_admin_user
      ? {
        is_admin_user: { _eq: false },
        default_branch: { _eq: branch },
        default_company: { _eq: company },
        status: { _eq: "active" },
      }
      : {
        is_staff_user: { _eq: true },
        default_branch: { _eq: branch },
        default_company: { _eq: company },
        status: { _eq: "active" },
        in_out_flag: true,
      };
    await directus.users
      .readByQuery({
        fields: ["*"],
        filter: bodyValue,
      })
      .then((res) => {
        const PresetUsers = res?.data?.filter((e) => {
          if (details?.is_admin_user) {
            if (e?.is_manager_user === true) {
              return { value: e.id, label: e.first_name };
            } else {
              return (
                e?.in_out_flag === true && { value: e.id, label: e.first_name }
              );
            }
          } else {
            return { value: e.id, label: e.first_name };
          }
        });
        setAllUsers([
          ...allUsers,
          ...PresetUsers?.map((e) => ({ value: e.id, label: e.first_name })),
        ]);
      })
      .catch((e) => console.log(e));
    details?.is_staff_user !== true &&
      setAllUsers((e) => [
        ...e,
        { value: details?.id, label: details?.first_name },
      ]);
  };
  React.useEffect(() => {
    branch && usersDatasCall();
  }, [branch]);
  React.useEffect(() => {
    const maxLength = 10;
    if (phoneNo?.length > maxLength) {
      setPhoneNo(phoneNo.substring(0, maxLength));
    }
  }, [phoneNo?.length]);
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 24, 27, 0.4)",
    },
    content: {
      top: "50%",
      left: "50%",
      display: "flex",
      justifySelf: "center",
      right: "auto",
      background: "transparant",
      elevation: 0,
      border: "none",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
    },
  };
  const styles = {
    menuList: (base) => ({
      ...base,
      height: "110px",

      "::-webkit-scrollbar": {
        width: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "#2684FF",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };
  async function fieldValidation() {
    if (vehicleNo?.trim()?.length < 1) {
      updateValidationError("vehicleNumberError", true);
    } else {
      if (
        vehicleNo?.trim()?.length > 0 &&
        (vehicleNo?.replaceAll(/\s/g, "")?.length < 5 || !vehicleMinLetterCheck.test(vehicleNo))
      ) {
        updateValidationError("invalidVehicleNoError", true);
      }
    }
    if (!!!phoneNo?.trim()) updateValidationError("phoneNoError", true);
    else {
      if (phoneNo.trim()?.length < 10) {
        updateValidationError("invalidPhoneNoError", true);
      }
    }
    if (email?.trim()?.length > 0) {
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        ? updateValidationError("emailError", false)
        : updateValidationError("emailError", true);
    }
    if (!select) updateValidationError("termsError", true);
    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }

  const handleModal = async () => {
    setValidationError({
      invalidPhoneNoError: false,
      emailError: false,
      vehicleNumberError: false,
      phoneNoError: false,
      termsError: false,
      invalidVehicleNoError: false,
    });
    await fieldValidation();
  };
  const timeUpdate = async (prop) => {
    await directus
      .items("Valet_Information")
      .updateOne(Number(prop?.id), {
        started_time: prop?.date_updated,
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const vehicleNoExist = async () => {
    var flag = false;
    await directus
      .items("Valet_Information")
      .readByQuery({
        fields: ["status_id.sequence"],
        sort: ["-date_updated"],
        filter: {
          Vehicle_Number: { _eq: vehicleNo },
          company: { _eq: company },
          branch: { _eq: branch },
        },
      })
      .then((res) => {
        return res?.data[0]
          ? res?.data[0]?.status_id?.sequence !== 6
            ? (flag = true)
            : (flag = false)
          : (flag = false);
      })
      .catch((e) => console.log(e));
    return flag;
  };
  async function callback() {
    setPreventDouble(true);
    const PhoneNoValidation = phoneNo.trim()?.length >= 10 ? true : false;
    var {
      invalidVehicleNoError,
      phoneNoError,
      termsError,
      vehicleNumberError,
    } = validationError;
    if (
      !(
        invalidVehicleNoError ||
        phoneNoError ||
        termsError ||
        vehicleNumberError
      )
    ) {
      if (userName?.trim()?.length ? userName?.trim()?.length > 2 : true) {
        if (vehicleNo?.trim()?.length > 4 && isAlphaNumeric(vehicleNo)) {
          if (PhoneNoValidation) {
            var a = Math.floor(100000 + Math.random() * 900000);
            const randomOtp = a.toString().substring(0, 4);
            setOtpNo(randomOtp);
            var res = await vehicleNoExist();
            if (res) {
              toast.error("This vehicle is already in progress");
            } else {
              await directus
                .items("Valet_Information")
                .readByQuery({
                  fields: ["id"],
                  filter: {
                    OTP_Value: { _eq: randomOtp },
                    company: { _eq: company },
                    branch: { _eq: branch },
                  }, //default_branch  ,branch:{_eq:4}
                })
                .then((res) => {
                  return res?.data?.length > 0
                    ? (e) => handleModal(e)
                    : details?.is_staff_user === true
                      ? createHandle({ otp: randomOtp })
                      : setEditIsOpen(!editModalIsOpen);
                })
                .catch((e) => console.log(e, "err"));
            }
          } else {
            toast.error("Invalid phone number");
          }
        } else {
          toast.error("Invalid vehicle number");
        }
      } else {
        toast.error("Enter valid user name (minimum 3 character)");
      }
    } else {
      toast.error("Validation Failed!!!");
    }
    setPreventDouble(false);
  }
  const StaffSelection = () => {
    setAssignedStaff({ value: "-1", label: "Select the staff" });
    setEditIsOpen(false);
  };
  return (
    <LayoutComponent
      title={"CREATE NEW VEHICLE"}
      fixedFooter={true}
      center={true}
    >
      <Seo title={"Create valet"} company={true} />
      <div className="main-content-area px-6 max-sm:px-5 pt-0">
        <div className="mx-auto">
          <div className="">
            <div className="mt-1 block">
              <div className="space-y-6 bg-white py-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className={`col-span-6`}>
                    <label htmlFor="vehicle-number" className="block text-sm font-bold text-gray-700 mb-2">
                      Vehicle number *
                    </label>
                    <InputMask
                      id="vehicle-number"
                      mask="*-*-*-*-*-*-*-*-*-*"
                      maskChar=""
                      placeholder="A-A-0-0-A-A-0-0-0-0"
                      value={vehicleFormat?.toUpperCase()}
                      className={`appearance-none ${(validationError?.vehicleNumberError &&
                        vehicleNo?.length < 1) ||
                        (validationError?.invalidVehicleNoError &&
                          (vehicleNo?.trim()?.length < 5 || !vehicleMinLetterCheck.test(vehicleNo)))
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                        }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:ring-primary focus:border-primary placeholder:text-gray-300`}
                      onChange={(e) => setVehicleFormat(e?.target?.value)}
                    />
                    {(validationError?.vehicleNumberError ||
                      validationError?.invalidVehicleNoError) &&
                      vehicleNo?.length < 1 && (
                        <p className="text-xs text-right clear-both block text-red-500 mt-2">
                          *Vehicle number is required
                        </p>
                      )}
                    {validationError?.invalidVehicleNoError &&
                      ((vehicleNo?.length > 0 &&
                        vehicleNo?.length < 10) || !vehicleMinLetterCheck.test(vehicleNo)) && (
                        <p className="text-xs text-right clear-both block text-red-500 mt-2">
                          *Invalid Vehicle number
                        </p>
                      )}
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="phone-no" className="block text-sm font-bold text-gray-700 mb-2">
                      Phone number *
                    </label>
                    <input
                      className={`mt-1 pl-4 py-2 pr-9 block w-full rounded-md border text-sm shadow-sm  focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 ${(validationError?.phoneNoError &&
                          phoneNo?.length < 1) ||
                          (validationError?.invalidPhoneNoError &&
                            phoneNo?.trim()?.length < 10)
                          ? "border-red-300"
                          : "border-gray-300"
                        }`}
                      placeholder="Enter your phone number"
                      id="phone-no"
                      type="text"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      onChange={(e) =>
                        /^\d*\.?\d*$/.test(e.target.value) &&
                        setPhoneNo(e.target.value)
                      }
                      value={phoneNo || ""}
                    />
                    {(validationError?.phoneNoError ||
                      validationError?.invalidPhoneNoError) &&
                      phoneNo?.length < 1 && (
                        <p className="text-xs text-right text-red-500 mt-2">
                          *Phone number is required
                        </p>
                      )}
                    {validationError?.invalidPhoneNoError &&
                      phoneNo?.trim()?.length > 0 &&
                      phoneNo?.trim()?.length < 10 && (
                        <p className="text-xs text-right text-red-500 mt-2">
                          *Invalid Phone number
                        </p>
                      )}
                    <p className="text-xs text-slate-500 mt-2">
                      To get valet parking availability, offers, and about new
                      restaurants
                    </p>
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="grid-name" className="block text-sm font-bold text-gray-700 mb-2">
                      Name
                    </label>
                    <input
                      className={`mt-1 pl-4 py-2 pr-9 block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary
                        h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-slate-300`}
                      placeholder="Enter your name"
                      id="grid-name"
                      type="text"
                      onChange={(e) => setUserName(e.target.value)}
                      value={userName || ""}
                    />
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="u-email-01" className="block text-sm font-bold text-gray-700 mb-2">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email-address"
                      id="u-email-01"
                      placeholder="Enter your email"
                      autoComplete="email"
                      className={`mt-1 pl-4 py-2 pr-9 block w-full rounded-md border text-sm shadow-sm  h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300
                                             ${validationError?.emailError &&
                          email?.length > 1 &&
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            email
                          )
                          ? "border-red-300 focus:border-primary"
                          : "border-gray-300 focus:border-primary"
                        }`}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email || ""}
                    />
                    {email?.trim()?.length > 0 &&
                      validationError?.emailError &&
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                        email
                      ) && (
                        <p className="text-xs text-right text-red-500 mt-2">
                          *Enter a valid email address
                        </p>
                      )}
                  </div>
                  <div className="col-span-6 flex justify-start items-center text-sm gap-2">
                    <input
                      type="checkbox"
                      name="Terms and Conditions"
                      onChange={(e) => {
                        setSelect(!select);
                      }}
                      checked={select ? true : false}
                      className={`h-4 w-4 rounded ${validationError?.termsError && select === false
                          ? "border-red-500"
                          : "border-gray-300"
                        }  text-primary focus:ring-primary`}
                      id="u-terms-condi-01"
                    />
                    <label
                      htmlFor={`u-terms-condi-01 `}
                      className={`${validationError?.termsError && select === false
                          ? "text-red-500"
                          : ""
                        }`}
                    >
                      I accept the{" "}
                      <Link
                        to={`/customer/terms-of-service`}
                        target="_blank"
                        className="text-primary"
                      >
                        terms and conditions
                      </Link>
                    </label>
                  </div>
                  <div className="col-span-6 flex justify-center items-center pt-5">
                    <button
                      type="button"
                      onClick={() => handleModal()}
                      disabled={select ? "" : ""}
                      className={`inline-flex items-center justify-center w-1/2 rounded-md border h-12 border-transparent bg-primary px-4 py-2 text-sm font-semibold text-white shadow-md shadow-primary-100 hover:bg-vspurple focus:outline-none focus:ring-2 focus:ring-vspurple focus:ring-offset-2 sm:w-auto ${select ? "" : "opacity-100"
                        }`}
                    >
                      <span className="">Create Vehicle</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={detailsConfirmModel}
        onRequestClose={() => setDetailsConfirmModel(false)}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="relative transform overflow-hidden min-w-[300px] max-w-[540px] w-full rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:mx-5 sm:max-w-lg border border-slate-400 ...">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {" "}
            <p className="text-black">
              Are you sure the given vehicle information is valid? <br />
              <br />
              <strong>Vehicle number: </strong>
              {vehicleNo} <br />
              <strong>Phone number: </strong>
              {phoneNo}
              {userName?.length > 0 ? (
                <>
                  {" "}
                  <br />
                  <strong>Name: </strong>
                  {userName}
                </>
              ) : null}
              {email?.length > 0 ? (
                <>
                  {" "}
                  <br />
                  <strong>Email: </strong>
                  {email}
                </>
              ) : null}
            </p>
            <div className="flex-row flex mt-10 justify-between gap-5">
              <button
                type="button"
                className="inline-flex font-semibold w-auto justify-center border border-transparent bg-red-100 px-4 py-2 text-sm text-red-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm rounded-md"
                onClick={() => setDetailsConfirmModel(false)}
              >
                Cancel{" "}
              </button>

              <button
                type="button"
                onClick={() => preventDouble === false && callback()}
                className="inline-flex  items-center justify-center h-[40px] text-[18px] border bg-primary border-primary px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-vspurple focus:outline-none focus:ring-2 focus:ring-vspurple focus:ring-offset-2 rounded-md"
              >
                Ok{" "}
              </button>
            </div>
          </div>
        </div>
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={() => StaffSelection()}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <div className="create-popup transform overflow-hidden max-w-[540px] w-full rounded-lg bg-white text-left shadow-xl transition-all h-350">
            <div className="mx-auto">
              <div className="mt-1 block">
                <div className="text-center flex flex-col justify-center items-center my-8">
                  <span className="text-3xl text-gray-400 font-semibold">
                    OTP:{" "}
                  </span>
                  <span className="text-5xl text-black font-semibold mt-3">
                    {otpNo}
                  </span>
                </div>

                <div className="text-center flex justify-center items-center p-5">
                  <Select
                    classNamePrefix="react-select"
                    className=" react-select-container w-full inline-block rounded-md  text-sm focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary capitalize"
                    defaultMenuIsOpen={false}
                    maxMenuHeight={117}
                    onChange={(e) => setAssignedStaff(e)}
                    options={allUsers}
                    // styles={styles}
                    styles={{
                      styles,
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                      }),
                      placeholder: (base) => ({
                        ...base,
                        fontWeight: "bold",
                        color: "#9CA3AF",
                      }),
                      input: (base, state) => ({
                        ...base,
                        '[type="text"]': {
                          border: "none",
                          boxShadow: "none",
                          outline: "none",
                        },
                      }),
                    }}
                  />
                </div>
                <div className="col-span-6 flex justify-center items-end p-5">
                  <button
                    type="submit"
                    onClick={(e) => preventDouble === false && createHandle(e)}
                    className={`rounded-md inline-flex items-center justify-center border h-10 border-transparent bg-primary px-4 py-2 text-sm font-semibold text-white shadow-md shadow-primary-100 hover:bg-vspurple focus:outline-none focus:ring-2 focus:ring-vspurple focus:ring-offset-2 sm:w-auto`}
                  >
                    <span className="text-center">Assign</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Modal>
    </LayoutComponent>
  );
}

export default Create;
