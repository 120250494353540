/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LayoutComponent from "../components/LayoutComponent";
import LoadingSpinner from "../components/LoadingSpinner";
import Seo from "../components/Seo";
import UserContext from "../contexts/userContext";
import { directus } from "../services/directus";
import { toast } from "react-toastify";
import { userPresent } from "../utils/CommonFunctions";

function EditValetNum(props) {
  const maxLength = 10;
  const [isLoading, setIsLoading] = React.useState(true);
  const [valetPhoneNo, setValetPhoneNo] = React.useState();
  const [preventDouble, setPreventDouble] = React.useState(false);
  const { details, companyDetails,BranchDataUpdate } = React.useContext(UserContext);
  const branch = details?.default_branch?.id;
 
  React.useEffect(() => {
    if (companyDetails?.company !== undefined) {
      setValetPhoneNo(companyDetails?.branchValetNum);
      setIsLoading(false);
    }
  }, [companyDetails?.company]);

  const submitHandle = async (e) => {
    const bodyValue = {
      valet_number: valetPhoneNo,
    };
    if (valetPhoneNo?.length === 10 && branch) {
      const LoggedCheck = await userPresent({ details: details });
      if (LoggedCheck?.status) {
        setPreventDouble(true);
        await directus
          .items("Branch")
          .updateOne(Number(branch), bodyValue)
          .then((res) => {
            res?.id&&res?.company_id&&BranchDataUpdate({default_branch:{id:res?.id},default_company:{id:res?.company_id}});
            setPreventDouble(false);
            toast.success(" Updated successfully");
          })
          .catch((err) => {
            console.log(err);
            toast.error(err, {
              onClose: () => setPreventDouble(false),
            });
          });
      } else {
        toast.error(
          "Your account was suspended, kindly contact the respective manager/admin."
        );
      }
    } else {
      toast.error("Something went wrong", {
        onClose: () => setPreventDouble(false),
      });
    }
  };

  return (
    <LayoutComponent title={"Edit Valet Number"} fixedFooter={true} center={true}>
      <Seo title={"Edit Valet Number"} company={true} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="valet-num-section space-y-6 bg-white py-6 h-full">
          <div className="flex justify-center items-center flex-col">
            <div className="flex justify-center items-center flex-col gap-2">
              <label className="font-bold" htmlFor="admin-phone">Valet Number</label>
              <input
                type="text"
                name="user-name"
                id="admin-phone"
                pattern="[0-9]*" 
                inputMode="numeric"
                value={valetPhoneNo || ""}
                onChange={(e) =>
                  /^\d*\.?\d*$/.test(e.target.value) &&
                  setValetPhoneNo(e.target.value.substring(0, maxLength))
                }
                placeholder={"Enter your valet number"}
                className={`${
                  valetPhoneNo?.length < 1
                    ? "border-red-300 focus:border-red-300"
                    : "border-gray-300 focus:border-primary"
                } px-4 py-1.5 min-w-[315px] text-2xl block w-full  max-w-[315px] text-center rounded-md border shadow-sm border-gray-300 focus:border-primary h-12 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
              />
            </div>
            {valetPhoneNo?.length < 1 && (
              <p className="text-xs text-right text-red-500 mt-2 w-full max-w-[315px]">
                *Phone number is required
              </p>
            )}
            {valetPhoneNo?.trim()?.length > 0 &&
              valetPhoneNo?.trim()?.length < 10 && (
                <p className="text-xs text-right text-red-500 mt-2 w-full max-w-[315px]">
                  *Enter valid phone number
                </p>
              )}
          </div>
          <div className="form__actions flex justify-center items-center gap-5 mt-10">
            <button
              onClick={() => submitHandle()}
              disabled={preventDouble}
              className={`inline-flex items-center ${
                valetPhoneNo?.length < 10
                  ? "opacity-50 pointer-events-none"
                  : ""
              } justify-center rounded-md border-transparent h-10 border-2 bg-primary px-4 py-2 text-sm font-semibold text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto`}
            >
              {"Submit"}{" "}
            </button>
          </div>
        </div>
      )}
    </LayoutComponent>
  );
}

export default EditValetNum;
