import React from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import Seo from "../components/Seo";
import CustomerLayout from "../components/CustomerLayout";
import seoData from '../seoContent.json';

function CookiePolicy() {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <>
      <Seo title={seoData?.cookie_policy?.title} />
      <CustomerLayout head={true}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="p-6 lg:p-8 flex justify-center flex-col text-black-700">
            <b className="text-black-800">Cookie Policy :</b> <br />
            <span><b>Last updated:</b> 28 December 2023</span>
            <br />
            <ul className="list-decimal ml-[18px]">
              <li>
                <span className="font-bold">Introduction</span><br /><br />
                <span>This Cookie Policy explains how ValetSearch.com ("we," "us," or "our") uses cookies and similar
                  tracking technologies on our website to collect and store information. By using our website, you consent to
                  the use of cookies as described in this policy.</span>
              </li>
              <br />
              <li>
                <span className="font-bold">What Are Cookies?</span><br /><br />
                <span>Cookies are small text files that are placed on your device (e.g., computer, smartphone, tablet) when you visit
                  a website. They are widely used to make websites work more efficiently and to provide information to website owners.</span>
              </li>
              <br />
              <li>
                <span className="font-bold">Types of Cookies We Use</span><br /> <br />
                <span>We use the following types of cookies on our website:</span>
                <div className="mt-3">
                  <div>3.1 <span className="font-bold">Essential Cookies</span></div>
                  <div className="ml-[26px] mt-2">
                    These cookies are necessary for the operation of our website. They enable you to navigate the site and use
                    its features. Without these cookies, certain parts of our website may not function properly.
                  </div>
                </div>
                <div className="mt-3">
                  <div>3.2 <span className="font-bold">Analytical/Performance Cookies</span></div>
                  <div className="ml-[26px] mt-2">
                    These cookies help us understand how visitors interact with our website. They collect information about which pages are
                    visited most frequently, where users come from, and other user behaviors. This information helps us improve the performance
                    and user experience of our site.</div>
                </div>
                <div className="mt-3">
                  <div>3.3 <span className="font-bold">Functionality Cookies</span></div>
                  <div className="ml-[26px] mt-2">
                    Functionality cookies allow us to remember choices you make on our website (e.g., language preferences) and provide enhanced,
                    personalized features.</div>
                </div>
                <div className="mt-3">
                  <div>3.4 <span className="font-bold">Targeting/Advertising Cookies</span></div>
                  <div className="ml-[26px] mt-2">
                    These cookies are used to deliver content and advertisements that are more relevant to you and your interests. They may also
                    be used to limit the number of times you see an advertisement and help measure the effectiveness of advertising campaigns.</div>
                </div>
              </li>
              <br />
              <li>
                <span className="font-bold">How to Manage Cookies</span><br /><br />
                <span>You can control and manage cookies in several ways:</span>
                <div className="mt-3">
                  <div>4.1 <span className="font-bold">Browser Settings</span></div>
                  <div className="ml-[26px] mt-2">
                    Most web browsers allow you to manage cookies through your browser settings. You can usually find these settings in the "Options,
                    " "Tools," or "Preferences" menu of your browser. You can choose to block or delete cookies, as well as set your browser to notify
                    you before cookies are placed on your device. However, please note that disabling cookies may affect your ability to use certain
                    features of our website.</div>
                </div>
                <div className="mt-3">
                  <div>4.2 <span className="font-bold">Cookie Consent Banner</span></div>
                  <div className="ml-[26px] mt-2">
                    When you visit our website, you will be presented with a cookie consent banner that allows you to accept or decline the use of
                    cookies, except for essential cookies that are required for the site's basic functionality. Your choice will be stored in a cookie
                    for future visits.</div>
                </div>
              </li>
              <br />
              <li>
                <span className="font-bold">Changes to this Cookie Policy</span><br /><br />
                <span>We may update this Cookie Policy to reflect changes in our cookie practices or for other operational, legal, or regulatory
                  reasons. We will notify you of any material changes by posting the updated Cookie Policy on our website.</span>
              </li>
              <br />
              <li>
                <span className="font-bold">Contact Us</span><br /><br />
                <span>If you have any questions or concerns about our use of cookies or this Cookie Policy, please contact us at support[at]ewallsolutions.com.</span>
              </li>
            </ul>
          </div>
        )}
      </CustomerLayout>
    </>
  );
}

export default CookiePolicy;
