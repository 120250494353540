/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Link } from "react-router-dom";
import SocialShare from "./SocialShare";
import site_main_logo from "../assets/images/valetsearch.svg";
import site_main_ph from "../assets/images/valetsearch_small_logo.svg";
import { getAssetURL } from "../utils/get-asset-url";

const Footer = (props) => {
  const [fullFooter, setFullFooter] = React.useState(false);
  const path = window?.location?.pathname;
  const FooterLinks = [
    {
      title: "Company",
      Links: [
        { name: "Home", path: "/" },
        { name: "About us", path: "/about-us" },
        {
          name: "For business affiliation",
          path: "/contact-us-for-business-affiliation",
        },
      ],
    },
    {
      title: "Legal",
      Links: [
        { name: "Privacy policy", path: "/privacy-policy" },
        { name: "Cookie policy", path: "/cookie-policy" },
        { name: "Refund policy", path: "/refund-policy" },
      ],
    },
    {
      title: "Resources",
      Links: [
        { name: "Suggestions", path: "/suggestions" },
        { name: "How to use?", path: "/how-to-use" },
      ],
    },
  ];

  const footerContainer = ({ prop, index }) => {
    return (
      <div
        key={index}
        className="links-section flex gap-5 justify-left md:justify-center md:px-3"
      >
        <div className="main-links px-2">
          <div className="text-left title font-bold text-black-900 text-lg pb-1">
            {prop?.title}
          </div>
          <ul className="text-left">
            {prop?.Links?.map((e, i) => (
              <li key={i} className={`pb-2`}>
                <Link
                  to={e?.path}
                  className={`${
                    path === e?.path && "border-b border-vspurple text-black-800"
                  }`}
                >
                  {e?.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };
  return (
    <footer
      className={`${
        props?.len ? "max-w-[1200px]" : "max-w-[1200px]"
      } w-[100%] m-auto left-0 py-6 px-6 md:px-8 text-center relative bg-[#FAFAFBFF] bottom-0 right-0`}
    >
      {props?.isMobile && (
        <span
          onClick={() => setFullFooter(!fullFooter)}
          className={`${
            fullFooter
              ? "rotate-180 transition duration-300 ease-linear top-4"
              : "rotate-0 transition duration-300 ease-linear bottom-10"
          } absolute right-4 z-50 mt-2 border border-primary rounded-full p-1
          `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <title>{fullFooter ? "Open" : "Close"}</title>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </span>
      )}

      {props?.isMobile && !fullFooter ? (
        <>
          <div className="text-sm font-medium text-gray-900 relative justify-between items-center gap-4">
            <div className="footer-bottom flex flex-col md:flex-row gap-4 justify-between items-center py-2">
              <div className="text-sm font-medium text-gray-900 max-[420px]:px-[10px]">
                Copyright © 2023 ValetSearch. <br/> All Rights Reserved. Site owned by <br/><Link className="text-primary" target={"_blank"} to={"https://ewallsolutions.com"}>EWall Solutions Pvt. Ltd</Link> 
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-sm font-medium relative grid grid-cols-2 md:grid-cols-4 justify-between gap-4 pb-6">
          <Link to='/'>
            <div className="footer-logo px-2 md:px-3">
              <img
                src={
                  props?.siteLogo
                    ? getAssetURL(props?.siteLogo)
                    : props?.isMobile
                    ? site_main_ph
                    : site_main_logo
                }
                alt="logo"
                className={`w-full max-w-[40px] md:max-w-[200px]`}
              />
            </div>
            </Link>
            {FooterLinks?.map((e, i) => footerContainer({ prop: e, index: i }))}
          </div>
          <div className="footer-bottom flex flex-col md:flex-row gap-4 justify-between items-center py-2">
            {/* <div className="min-w-[120px] hidden md:flex invisible"></div> */}
            <div className="flex-col">
            <div className="text-sm text-left font-medium text-gray-900 max-[420px]:px-[10px]">
              By using this website you are accepting our <Link className="text-primary" to="/general/terms-of-service">Terms and Conditions</Link>
            </div>
            <div className="text-sm text-left font-medium text-gray-900 max-[420px]:px-[10px] block text-left mt-2">
              Copyright © 2023 ValetSearch. All Rights Reserved. Site owned by <Link className="text-primary" target={"_blank"}  to={"https://ewallsolutions.com"}>EWall Solutions Pvt. Ltd</Link> 
            </div>
            </div>
            <SocialShare />
          </div>
        </>
      )}
    </footer>
  );
};
export default Footer;
