import { directus } from "../services/directus";

export const userPresent = async ({ details }) => {
  const LoggedCheck =
    details?.is_admin_user === false  && details?.is_manager_user === false
      ? await directus.users
          .readByQuery({
            fields: ["*"],
            filter: { id: details?.id },
          })
          .then((res) => {
            return {
              present: res?.data[0]?.in_out_flag === true ? true : false,
              status: res?.data[0]?.status === "active" ? true : false,
            };
          })
          .catch((e) => console.log(e))
      : {
        present: true ,
        status: true 
      };
  return LoggedCheck;
};

// The below mentioned regex is validate the vehicle format. (without spaces)
// export const valetNumberRegex=/^([A-Z]{2}\d{2}[A-Z]{1,2}(\d)\1{2}|[A-Z]{2}\d{2}[A-Z]{2}(\d)\d\1|[A-Z]{2}\d{2}[A-Z]{2}[789]{1}\d{3}|[A-Z]{2}\d{2}([A-Z]{2})\d{4}|[A-Z]{2}\d{2}VIP\d{3})$/
export const valetNumberRegex=/^[A-Za-z0-9]*$/ 
export const vehicleMinLetterCheck= /^[a-zA-Z](?=.*[0-9].*[0-9])(?=.*[a-zA-Z].*[a-zA-Z]).*[0-9]$/