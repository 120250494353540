import React from "react";
import CustomerHeader from "./CustomerHeader";
import Footer from "./Footer";
import UserContext from "../contexts/userContext";

function CustomerLayout(props) {
  const [companyAndBranch, setCompanyAndBranch] = React.useState({});
  const [footerHeight, setFooterHeight] = React.useState();
  const [siteLogo, setSiteLogo] = React.useState();
  const { commonInfo } = React.useContext(UserContext);
  
  React.useEffect(() => {
    commonInfo&&setSiteLogo(commonInfo)
  },[commonInfo])
 
  React.useEffect(() => {
    const val = window.location.href.split("/");
    setCompanyAndBranch({ companyName: val[3], branchName: val[4] });
  }, [companyAndBranch?.branchName, footerHeight]);
  const isMobile = window.innerWidth <= 767;
  const headLogo = isMobile
    ? (siteLogo?.mobile_site_logo?.length > 0
      ? siteLogo?.mobile_site_logo
      :"")
    : (siteLogo?.site_logo?.length > 0
    ? siteLogo?.site_logo
    : '');
  return (
    <div className="page__wrapper flex justify-start items-center flex-col bg-[rgb(243,244,246)] font-primary">
      <div
        className={`${props?.containerView === false ? "max-w-[1200px]" : "max-w-[1200px]"
          } bg-white relative w-full inline-block h-screen firefox-scrollbar shadow-sm min-h-screen max-h-screen overflow-hidden overflow-y-auto`}
      >
        <CustomerHeader props={props} />
        <main className={`${props?.isCompany?"dt-body":"dt-main"} flex ${props?.noWrap ? "flex-col" : "items-center justify-center flex-wrap"}`}>{props?.children}</main>
        
        <Footer
          len={true}
          branch={companyAndBranch?.branchName}
          setFooterHeight={setFooterHeight}
          footer={props?.footer}
          fixedFooter={props?.fixedFooter}
          siteLogo={headLogo}
          isMobile={isMobile}
        />
      </div>
    </div>
  );
}

export default CustomerLayout;
