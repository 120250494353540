import React from "react";

function Pagination({ numPages, handleNavigation, data, page, pageBtnCount }) {
  var pagesCount = [];
  var maxButtonsToShow = pageBtnCount ? pageBtnCount : 3;

  let startPage = Math.max(1, page - Math.floor(maxButtonsToShow / 2));
  let endPage = Math.min(numPages, startPage + maxButtonsToShow - 1);

  if (endPage - startPage + 1 < maxButtonsToShow) {
    startPage = Math.max(1, endPage - maxButtonsToShow + 1);
  }

  const paginatationBtn = (i) => {
    return (
      <span
        onClick={() => handleNavigation(i)}
        aria-current="page"
        key={i}
        className={`pagination relative z-10 w-8 h-8 cursor-pointer rounded-sm flex justify-center items-center font-regular text-sm text-gray-500 border border-gray-300 focus:z-20 ${
          page === i ? " current" : ""
        }`}
      >
        {i}
      </span>
    );
  };

  if (startPage > 1) {
    pagesCount.push(
      <React.Fragment key={Math.random()}>
        {paginatationBtn(1)}
       { startPage!==2&&<span>{" ... "}</span>}
      </React.Fragment>
    );
  }
  for (let i = startPage; i <= endPage; i++) {
    pagesCount.push(<React.Fragment key={i}>{paginatationBtn(i)}</React.Fragment>);
  }
  if (endPage < numPages) {
    pagesCount.push(
      <React.Fragment key={Math.random()}>
        {endPage!==numPages-1&&<span>{" ... "}</span>}
        {paginatationBtn(numPages)}
      </React.Fragment>
    );
  }

  return (
    <div className="mt-6 mb-6 mx-auto  justify-center flex">
      <nav
        className="isolate inline-flex space-x-1 rounded-md gap-1"
        aria-label="Pagination"
      >
        <button
          onClick={
            data && !data?.length && page <= 1
              ? null
              : () => handleNavigation(page - 1)
          }
          disabled={page <= 1}
          data-nav-operation="previous"
          className="relative  bg-gray-50 w-8 h-8 flex justify-center items-center font-semibold text-sm text-gray-500 hover:bg-gray-100 focus:z-20"
        >
          <span className="sr-only">Previous</span>
          <svg
            className="h-5 w-5"
            strokeWidth="2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <title>Previous</title>
            <path
              fillRule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {pagesCount}
        <button
          onClick={
            data && page > numPages - 1
              ? null
              : () => handleNavigation(page + 1)
          }
          disabled={data && !data.length && page > numPages - 1}
          data-nav-operation="next"
          className="relative rounded-r-md bg-gray-50 w-8 h-8 flex justify-center items-center font-semibold text-sm text-gray-500 hover:bg-gray-100 focus:z-20"
        >
          <span className="sr-only">Next</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            strokeWidth="2"
            fill="currentColor"
            aria-hidden="true"
          >
            <title>Next</title>
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </nav>
    </div>
  );
}

export default Pagination;
