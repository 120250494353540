/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LayoutComponent from "../../components/LayoutComponent";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import UserContext from "../../contexts/userContext";
import { directus } from "../../services/directus";
import Pagination from "../../components/Pagination";
import Select from "react-select";
import moment from "moment";
import AssignAndConfirmModal from "../../components/popupModals/AssignAndConfirm";

function Payments() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [pagination, setPagination] = React.useState();
    const [activeIndex, setActiveIndex] = React.useState("0");
    const [page, setPage] = React.useState(1);
    const [valetData, setValetData] = React.useState([]);
    const [currentVehicleInfo, setCurrentVehicleInfo] = React.useState([]);
    const [dateFrom, setDateFrom] = React.useState(moment(new Date()).format("YYYY-MM-DD"));
    const [dateTo, setDateTo] = React.useState(moment(new Date()).format("YYYY-MM-DD"));
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [paymentMode, setPaymentMode] = React.useState({
        id: 1,
        status: "cash",
        name: "Cash",
    });
    const { details, AuthCheck } = React.useContext(UserContext);
    const branch = details?.default_branch?.id;
    const company = details?.default_company?.id;
    const paginationCount = 10;
    const numPages =
        pagination % paginationCount === 0
            ? Math.floor(pagination / paginationCount)
            : Math.floor(pagination / paginationCount) + 1;
    const valetDataCall = async (prop) => {
        AuthCheck();
        const filter = activeIndex !== "2" ? {
            branch: { _eq: branch ? branch : prop?.branch },
            company: { _eq: company ? company : prop?.company },
            payment_status: activeIndex === "1"
                ? {
                    _eq: 'not_paid'
                } : {
                    _eq: 'paid'
                },
            payment_mode: activeIndex !== "1" ? { _eq: paymentMode?.status } : { _null: true },
            date_created: dateFrom === dateTo ? { _between: [moment(new Date(dateFrom)).format('YYYY-MM-DD , 00:00'), moment(dateFrom).format('YYYY-MM-DD , 23:59')] } : (dateFrom && dateTo ? { _between: [moment(new Date(dateFrom)).format('YYYY-MM-DD , 00:00'), moment(new Date(dateTo)).format('YYYY-MM-DD , 23:59')] } : { _gte: moment(new Date()).format('YYYY-MM-DD, 00:00') })

        } : {
            branch: { _eq: branch ? branch : prop?.branch },
            company: { _eq: company ? company : prop?.company },
            valet_tip: { _gt: 0 },
            date_created: dateFrom === dateTo ? { _between: [moment(new Date(dateFrom)).format('YYYY-MM-DD , 00:00'), moment(dateFrom).format('YYYY-MM-DD , 23:59')] } : (dateFrom && dateTo ? { _between: [moment(new Date(dateFrom)).format('YYYY-MM-DD , 00:00'), moment(new Date(dateTo)).format('YYYY-MM-DD , 23:59')] } : { _gte: moment(new Date()).format('YYYY-MM-DD , 00:00') })

        }
        await directus
            .items("Valet_Information")
            .readByQuery({
                fields: ["*","status_id.name",
                        "status_id.sequence",
                        "Assigned_Staff.first_name",
                    "branch.valet_fee"],
                sort: ["date_created"],
                filter: filter,
                limit: paginationCount,
                page: page,
                meta: ["filter_count"],
            })
            .then((res) => {
                setPagination(Number(res?.meta?.filter_count));
                setValetData(res?.data);
                setIsLoading(true);
                return "";
            })
            .catch((e) => console.log(e));
    };
    React.useEffect(() => {
        AuthCheck();
        branch && valetDataCall();
        const handleBackPress = () => {
            window.location.reload();
        };
        window.onpopstate = handleBackPress;
    }, [branch, company, activeIndex, page, paymentMode]);
    const handleNavigation = (e) => {
        setPage(e); return "";
    };
    React.useEffect(() => {
        if (!!dateFrom && !!dateTo) {
            valetDataCall()
        }
    }, [dateFrom, dateTo])
    const statushandle = (prop) => {
        return (
            <li
                className={`border px-1 flex justify-center rounded-md gap-10 items-center bg-secondary bg-opacity-10 border-secondary`}
            >
                {prop?.map((e, i) => (
                    <button
                        key={i}
                        type="button"
                        onClick={() => { setPage(1); setActiveIndex(i?.toString()) }}
                        className={`px-1 border-b-4 py-2 min-h-[58px] text-[14px] font-medium uppercase relative uppercase ${activeIndex === i?.toString()
                            ? "border-secondary text-secondary"
                            : "border-b-transparent text-gray-600"
                            }`}
                    >
                        {e}
                        {e?.related_valet_information?.length > 0 && (
                            <div
                                className={`count-wrapper ${prop?.length === 2
                                    ? "bg-primary "
                                    : "bg-secondary count-show-down"
                                    }
                                    ${activeIndex === i?.toString()
                                        ? (activeIndex <= 2 ? "border-primary text-primary" : "border-secondary text-secondary")
                                        : "border-b-transparent text-gray-600"
                                    }`}
                            >
                                <div className="inside-color">
                                    {e?.related_valet_information?.length}
                                </div>
                            </div>
                        )}
                    </button>
                ))}
            </li>
        );
    };
    const selectOption = [
        { id: 1, status: "cash", name: "Cash" },
        { id: 1, status: "online", name: "Online" },
    ];
    const styles1 = {
        menuList: (base) => ({
            ...base,
            height: "110px",

            "::-webkit-scrollbar": {
                width: "6px",
            },
            "::-webkit-scrollbar-track": {
                background: "#2684FF",
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888",
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555",
            },
        }),
        control: (base, state) => ({
            ...base,
            cursor: "pointer",
            border: "none",
            marginTop: "4px",
        }),
    };
    const renderList = () => {
        const TableTitles = activeIndex === "0"
            ? ['Date', "Vehicle No", "Amount",
                // "Payment mode",
                "Transaction ID"] : activeIndex === "2"
                ? ['Date', "Vehicle No", "Tip Amount", "Last Assigned Staff"] : ['Date', "Vehicle No", "OTP", "Vehicle Status"]



        return (
            <div className="flex flex-col">
                <div className="mt-4 md:mt-6 overflow-x-auto">
                    <div className="inline-block w-full py-2 align-middle px-1">
                        <div
                            className={`overflow-hidden border shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg parking-status-table ${valetData?.length <= 0 ? "bordered" : "no-border"
                                }`}
                        >
                            {valetData?.length ? (
                                <table className="w-full divide-y divide-gray-300 table-fixed">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            {TableTitles?.map((e, i) =>
                                                <th
                                                    key={i}
                                                    scope="col"
                                                    className="text-left p-4 text-sm font-semibold text-gray-900"
                                                >
                                                    {e}
                                                </th>)}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white cursor-pointer">
                                        {valetData?.map((e, i) => {
                                            return (
                                                <tr
                                                    className="hover:bg-primary hover:bg-opacity-5 "
                                                    key={i}
                                                    onClick={() => { setIsOpen(true); setCurrentVehicleInfo(e) }}
                                                >
                                                    <td
                                                        className="whitespace-nowrap text-sm px-4 py-2 md:p-4 text-gray-500"
                                                        data-th="Date "
                                                    >
                                                        <span className="text-black text-base md:text-[20px] tracking-[1px]">
                                                            {moment(e?.date_created).format('DD/MM/YYYY')}
                                                        </span>
                                                    </td>
                                                    <td
                                                        className="whitespace-nowrap text-sm px-4 py-2 md:p-4 text-gray-500"
                                                        data-th="Vehicle No "
                                                    >
                                                        <span className="text-black text-base md:text-[20px] tracking-[1px]">
                                                            {e?.Vehicle_Number}
                                                        </span>
                                                    </td>
                                                    {(activeIndex === '1') && <td
                                                        className="whitespace-nowrap text-sm px-4 py-2 md:p-4 text-gray-500"
                                                        data-th="OTP "
                                                    >
                                                        <span className="text-black text-base md:text-[20px] tracking-[1px]">
                                                            {e?.OTP_Value}
                                                        </span>
                                                    </td>}
                                                    {activeIndex === '0' && <td
                                                        className="whitespace-nowrap text-sm px-4 py-2 md:p-4 text-gray-500"
                                                        data-th="Valet fee "
                                                    >
                                                        <span className="text-black text-base md:text-[20px] tracking-[1px]">
                                                            {e?.valet_fee ? Number(e?.valet_fee)?.toFixed(2) : '-'}
                                                        </span>
                                                    </td>}
                                                    {activeIndex === '2' && <td
                                                        className="whitespace-nowrap text-sm px-4 py-2 md:p-4 text-gray-500"
                                                        data-th="Valet tip "
                                                    >
                                                        <span className="text-black text-base md:text-[20px] tracking-[1px]">
                                                            {e?.valet_tip ? Number(e?.valet_tip)?.toFixed(2) : '-'}
                                                        </span>
                                                    </td>}
                                                    {/* {(activeIndex === "0") && <td
                                                        className="whitespace-nowrap text-sm px-4 py-2 md:p-4 text-gray-500"
                                                        data-th="Payment mode"
                                                    >
                                                        <span className="text-black text-base md:text-[20px] tracking-[1px] capitalize">
                                                            {e?.payment_mode}
                                                        </span>
                                                    </td>} */}

                                                    {(activeIndex === '0') && <td
                                                        className="text-sm px-4 py-2 md:p-4 text-gray-500"
                                                        data-th="Transaction Id"
                                                    >
                                                        <span className="text-black text-base tracking-[1px] break-words max-md:block w-full">
                                                            {e?.merchant_transaction_id ? e?.merchant_transaction_id : '-'}
                                                        </span>
                                                    </td>}
                                                    {(activeIndex === '1') && <td
                                                        className="whitespace-nowrap text-sm px-4 py-2 md:p-4 text-gray-500"
                                                        data-th="Vehicle status"
                                                    >
                                                        <span className="text-black text-base md:text-[20px] tracking-[1px]">
                                                            {e?.status_id?.name}
                                                        </span>
                                                    </td>}
                                                    {(activeIndex === '2') && <td
                                                        className="text-sm px-4 py-2 md:p-4 text-gray-500"
                                                        data-th="Assigned Staff"
                                                    >
                                                        <span className="text-black text-base md:text-[20px] tracking-[1px]">
                                                            {e?.Assigned_Staff?.first_name?e?.Assigned_Staff?.first_name:"-"}
                                                        </span>
                                                    </td>}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="whitespace-nowrap text-sm px-4 py-2 md:p-4 text-gray-500 flex justify-center">
                                    No data found
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <LayoutComponent title={"Payments"} fixedFooter={true} middleWidth={false}>
            <Seo title={"Payments"} company={true} />
            {!isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="main-content-area p-4 md:p-8 pt-4">
                    <div className="mx-auto">
                        <div className="">
                            <div className="inline-block w-full align-middle">
                                <div className="pb-4 flex justify-between gap-4 items-center">
                                    <div className="max-w-min"><label className=" font-bold" htmlFor="date-from">Date From: </label>
                                        <input
                                            type="date"
                                            name="date-from"
                                            id='date-from'
                                            value={dateFrom}
                                            onChange={e => setDateFrom(e.target.value)}
                                            max={dateTo ? dateTo : ''}
                                            className="max-xs:p-[5px]"
                                        /></div>
                                    <div className="max-w-min"><label className=" font-bold"  htmlFor="date-to">Date To: </label>
                                        <input
                                            type="date"
                                            name="date-to"
                                            id='date-to'
                                            value={dateTo}
                                            onChange={e => setDateTo(e.target.value)}
                                            min={dateFrom}
                                            className="max-xs:p-[5px]"
                                        /></div>
                                </div>
                                <ul className="justify-evenly items-center flex-wrap">
                                    {statushandle(["Paid", 'Not Paid', "Tips"])}
                                </ul>
                                {activeIndex === "0" && <div className="pt-4 flex justify-end gap-4 items-center">
                                    <Select
                                        id="Users"
                                        classNamePrefix="react-select"
                                        className=" react-select-container border border-primary-500  text-gray-900 rounded-md text-sm min-h-[42px] min-w-[120px] capitalize"
                                        defaultMenuIsOpen={false}
                                        maxMenuHeight={250}
                                        onChange={(e) => { setPaymentMode(e); setPage(1) }}
                                        options={selectOption}
                                        isSearchable={false}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.name}
                                        value={paymentMode}
                                        styles={{
                                            styles1,
                                            placeholder: (base) => ({
                                                ...base,
                                                fontWeight: "bold",
                                                color: "#9CA3AF",
                                            }),
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                border: "none",
                                                boxShadow: "none",
                                            }),
                                            input: (base, state) => ({
                                                ...base,
                                                '[type="text"]': {
                                                    border: "none",
                                                    boxShadow: "none",
                                                },
                                            }),
                                        }}
                                    />
                                </div>}
                                <div className="tab-contents">
                                    <div className="w-full">
                                        {renderList(activeIndex)}
                                    </div>
                                </div>
                                {/* pagination */}
                                {numPages > 1 ? (
                                    <Pagination
                                        numPages={numPages}
                                        data={[]}
                                        page={page}
                                        handleNavigation={handleNavigation}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {modalIsOpen ? (
                <AssignAndConfirmModal
                    setIsOpenModel={setIsOpen}
                    vehicleData={currentVehicleInfo}
                    allUsers={""}
                    assignHandle={""}
                    statusUpdateHandle={''}
                    recordCashPayment={''}
                    assignedStaff={""}
                    setAssignedStaff={""}
                    show={true}
                />
            ) : null}
        </LayoutComponent>
    );
}

export default Payments