import React from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import Seo from "../components/Seo";
import CustomerLayout from "../components/CustomerLayout";
import seoData from '../seoContent.json';
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <>
      <Seo title={seoData?.privacy_policy?.title} />
      <CustomerLayout head={true}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="p-6 lg:p-8 flex justify-center flex-col text-black-700">
            <b className="text-black-800">Privacy Policy :</b> <br />
            <span><b>Last updated:</b> 28 December 2023</span>
            <br />
            At ValetSearch.com, we are committed to protecting the privacy of our users and the data they provide us. This
            Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website or services.
            By accessing or using ValetSearch.com, you consent to the practices described in this Privacy Policy.
            <br />
            <br />
            <ul className="list-decimal ml-[18px]">
              <li>
                <span className="font-bold">Information We Collect</span><br /> <br />
                <span>We may collect the following types of information when you use our website and services:</span>
                <div className="mt-3">
                  <div>1.1 <span className="font-bold">Personal Information</span></div>
                  <ul className="list-disc ml-[36px] mt-2">
                    <li>Contact Information (e.g., name, email address, phone number)</li>
                    <li>Payment Information (e.g., credit card details)</li>
                    <li>Profile Information (e.g., username, profile picture)</li>
                    <li>Location Information (e.g., GPS data)</li>
                  </ul>
                </div>
                <div className="mt-3">
                  <div>1.2 <span className="font-bold">Non-Personal Information</span></div>
                  <ul className="list-disc ml-[36px] mt-2">
                    <li>Log Data (e.g., IP address, browser type, pages visited)</li>
                    <li>Cookies and Tracking Technologies (see our Cookie Policy below)</li>
                  </ul>
                </div>
              </li>
              <br />
              <li>
                <span className="font-bold">How We Use Your Information</span><br /><br />
                <span>We may use your information for the following purposes:</span>
                <div className="mt-3">
                  <div>2.1 <span className="font-bold">Providing Services</span></div>
                  <ul className="list-disc ml-[36px] mt-2">
                    <li>Facilitating valet parking reservations</li>
                    <li>Communicating with users</li>
                    <li>Processing payments</li>
                  </ul>
                </div>
                <div className="mt-3">
                  <div>2.2 <span className="font-bold">Improving Our Services</span></div>
                  <ul className="list-disc ml-[36px]  mt-2">
                    <li>Analyzing user behavior and preferences</li>
                    <li>Enhancing the user experience</li>
                    <li>Conducting research and surveys</li>
                  </ul>
                </div>
                <div className="mt-3">
                  <div>2.3 <span className="font-bold">Legal and Safety</span></div>
                  <ul className="list-disc ml-[36px]  mt-2">
                    <li>Complying with legal obligations</li>
                    <li>Investigating and preventing fraud or other illegal activities</li>
                    <li>Protecting the rights and safety of users and others</li>
                  </ul>
                </div>
              </li>
              <br />
              <li>
                <span className="font-bold">Information Sharing</span><br /><br />
                <span>We may share your information with the following parties:</span>
                <div className="mt-3">
                  <div>3.1 <span className="font-bold">Service Providers</span></div>
                  <ul className="list-disc ml-[36px]  mt-2">
                    <li>Third-party vendors and service providers who help us operate our services</li>
                  </ul>
                </div>
                <div className="mt-3">
                  <div>3.2 <span className="font-bold">Business Partners</span></div>
                  <ul className="list-disc ml-[36px]  mt-2">
                    <li>Valet parking service providers who fulfill your reservations</li>
                  </ul>
                </div>
                <div className="mt-3">
                  <div>3.3 <span className="font-bold">Legal Requirements</span></div>
                  <ul className="list-disc ml-[36px]  mt-2">
                    <li>Compliance with applicable laws, regulations, and legal processes</li>
                  </ul>
                </div>
                <div className="mt-3">
                  <div>3.4 <span className="font-bold">Consent</span></div>
                  <ul className="list-disc ml-[36px]  mt-2">
                    <li>With your consent, we may share your information with third parties for other purposes</li>
                  </ul>
                </div>
              </li>
              <br />
              <li>
                <span className="font-bold">Your Choices</span><br /><br />
                <span>You have the following choices regarding your information:</span>
                <div className="mt-3">
                  <div>4.1 <span className="font-bold">Access and Update</span></div>
                  <ul className="list-disc ml-[36px]  mt-2">
                    <li>You can access and update your account information through your profile settings</li>
                  </ul>
                </div>
                <div className="mt-3">
                  <div>4.2 <span className="font-bold">Opt-Out</span></div>
                  <ul className="list-disc ml-[36px]  mt-2">
                    <li>You can opt out of receiving promotional emails by following the instructions in the email</li>
                  </ul>
                </div>
                <div className="mt-3">
                  <div>4.3 <span className="font-bold">Cookies</span></div>
                  <ul className="list-disc ml-[36px]  mt-2">
                    <li>You can control cookies through your browser settings (see our Cookie Policy below)</li>
                  </ul>
                </div>
              </li>
              <br />
              <li>
                <span className="font-bold">Security</span><br /><br />
                <span>We employ security measures to protect your information. However, no method of transmission
                  over the Internet or electronic storage is completely secure. We cannot guarantee absolute security.</span>
              </li>
              <br />
              <li>
                <span className="font-bold">Children's Privacy</span><br /><br />
                <span>Our services are not intended for users under the age of 18. We do not knowingly collect or 
                  solicit personal information from minors.</span>
              </li>
              <br />
              <li>
                <span className="font-bold">Changes to This Privacy Policy</span><br /><br />
                <span>We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory 
                  reasons. We will notify you of any material changes by posting the updated Privacy Policy on our website.</span>
              </li>
              <br/>
              <li>
                <span className="font-bold">Contact Us</span><br /><br />
                <span>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at support[at]ewallsolutions.com.</span>
              </li>
            </ul>
            <br />
            <hr className="border-t-2 border-[#bbb]"></hr>
            <div className="bottom-text mt-4">
              <div className="font-bold">Cookie Policy</div>
              <br />
              <span>For information about how we use cookies and tracking technologies, please refer to our separate Cookie Policy, available <Link className="text-primary" to={"/cookie-policy"}>here</Link>.</span>
            </div>
          </div>
        )}
      </CustomerLayout>
    </>
  );
}

export default PrivacyPolicy;
