/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { directus } from "../services/directus";
import LayoutComponent from "../components/LayoutComponent";
import UserContext from "../contexts/userContext";
import LoadingSpinner from "../components/LoadingSpinner";
import QRCode from "react-qr-code";
import { FRONTEND_URL } from "../utils/utils";
import copyIcon from '../assets/images/SVG/copy.svg';

function Detail(prop) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const vehicleid = new URLSearchParams(window.location.search).get("id");
  const { AuthCheck, companyDetails, details } = React.useContext(UserContext);
  const [state, setState] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const moveHandle = (e) => {
    e.preventDefault();
    setIsOpen(false);
    toast.success("Data updated");
    navigate("/get-valet-details");
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const Urldate = (prop) => {
    const localDate = new Date(prop);
    const dateToTime = (date) =>
      date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
      });
    var d = localDate.getDate();
    var m = localDate.getMonth() + 1;
    var y = localDate.getFullYear();
    const date =
      (d <= 9 ? "0" + d : d) +
      "/" +
      (m <= 9 ? "0" + m : m) +
      "/" +
      y +
      " - " +
      dateToTime(localDate);
    return date;
  };
  React.useEffect(() => {
    companyDetails?.branch && vehicleData();
  }, [companyDetails?.branch]);
  const vehicleData = async () => {
    await AuthCheck();
    await directus
      .items("Valet_Information")
      .readByQuery({
        fields: ["*","status_id.name","valet_history.*","valet_history.user_updated.first_name",
      "valet_history.status_id.name"],
        filter: {
          id: { _eq: vehicleid },
          branch: { slug: { _eq: companyDetails?.branch } },
        },
      })
      .then((res) => {
        setState(res?.data[0]);
        return "";
      })
      .catch((e) => console.log(e));
    setIsLoading(true);
  };
  return (
    <LayoutComponent title={"Valet Details"} fixedFooter={true} middleWidth={false}>
      {!isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="main-content-area">
          <div className="page__wrap w-full flex justify-center items-start py-5">
            <div className="w-full px-6">
              <div className="mt-4 block p-3 bg-white border border-gray-200 rounded-lg shadow-md relative">
                {state?.id ? (
                  <div className="space-y-6 bg-white cursor-default	">

                    {/* New wrapper element */}
                    <div>
                      <div className="grid grid-cols-6 gap-2">
                        <div className="grid grid-cols-6 gap-2 col-span-5 max-md:col-span-6">
                          {state?.Customer_Name && (
                            <div className="col-span-6 grid grid-cols-2 p-1">
                              <div
                                htmlFor="user-name"
                                className="block text-gray-700 font-semibold"
                              >
                                Customer Name:
                              </div>
                              <div className="field">
                                {state ? state?.Customer_Name : ""}
                              </div>
                            </div>
                          )}
                          {state?.Vehicle_Number && (
                            <div className="col-span-6 grid grid-cols-2 p-1">
                              <div
                                htmlFor="user-name"
                                className="block text-gray-700 font-semibold"
                              >
                                Vehicle No:
                              </div>
                              <div className="field">
                                {state ? state?.Vehicle_Number : ""}
                              </div>
                            </div>
                          )}
                          {state?.Customer_Phone_Number && (
                            <div className="col-span-6 grid grid-cols-2 p-1">
                              <div
                                htmlFor="user-name"
                                className="block text-gray-700 font-semibold"
                              >
                                Phone No:
                              </div>
                              <div className="field">
                                {state ? state?.Customer_Phone_Number : ""}
                              </div>
                            </div>
                          )}
                          {state?.id && (
                            <div className="col-span-6 grid grid-cols-2 p-1">
                              <div
                                htmlFor="user-name"
                                className="block text-gray-700 font-semibold"
                              >
                                Token Id:
                              </div>
                              <div className="field">{state ? state?.id : ""}</div>
                            </div>
                          )}
                          {state?.status_id && (
                            <div className="col-span-6 grid grid-cols-2 p-1">
                              <div
                                htmlFor="user-name"
                                className="block text-gray-700 font-semibold"
                              >
                                Current Status:
                              </div>
                              <div className="field text-secondary rounded-lg font-bold">
                                {state ? state?.status_id?.name : ""}
                              </div>
                            </div>
                          )}
                        </div>
                        {Number(companyDetails?.branchValetFee) > 0 &&
                          <div className="qr-info grid justify-center max-md:col-span-6 max-md:mt-3">
                            <QRCode
                              size={150}
                              style={{ width: "100%" }}
                              value={`${FRONTEND_URL}/${companyDetails?.company}/${companyDetails?.branch}/customer/payment?id1=${state?.OTP_Value}&id2=${state?.Vehicle_Number?.slice(-4)}`}
                              viewBox={`0 0 150 150`}
                            />
                            <div className={`${state?.payment_status !== 'paid' ? "bg-red-600" : "bg-green-600"} p-3 text-center text-white mt-2 inline-block rounded text-lg font-semibold`}>{state?.payment_status !== 'paid' ? "Not paid" : "Paid"}</div>
                          </div>
                        }
                      </div>
                      {details?.is_admin_user && state?.payment_status === 'paid' && <div>
                        <div className="py-4 border-t rounded flex justify-between items-center mt-4">
                          <div className="flex items-center gap-2">
                            <h4 className="font-medium text-lg text-primary">
                              Payment Details
                            </h4>
                          </div>
                        </div>
                        <div className="bg-white">
                          <div className="w-full h-full">
                            <div className="relative overflow-hidden py-5 h-full grid grid-cols-2 justify-between gap-x-3 max-md:grid-cols-1 max-md:gap-y-4">
                              {/* <!-- Payment Info --> */}
                              <div>

                                <div className="pb-2"><span className="font-semibold mr-2">Valet Fee:</span>{Number(state?.valet_fee)?.toFixed(2)}</div>
                                {state?.payment_mode && <div className="capitalize pt-2"><span className="font-semibold mr-2">Payment Mode: </span>{state?.payment_mode}</div>}
                              </div>
                              <div>
                                {Number(state?.valet_tip)>0&&<div className="pb-2"><span className="font-semibold mr-2">Valet Tip: </span>{Number(state?.valet_tip)?.toFixed(2)}</div>}
                                {state?.merchant_transaction_id && 
                                  <div className="pt-2 whitespace-nowrap overflow-hidden text-ellipsis pr-[15px] relative max-w-[435px]">
                                    <span className="font-semibold mr-2">Transaction Id: </span>
                                    {state?.merchant_transaction_id} 
                                    <span className="inline-block copy_transaction-id pl-1 absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer" title="copy ID" onClick={() => {
                                      navigator?.clipboard?.writeText(state?.merchant_transaction_id)
                                      toast.success("Transaction id copied to clipboard")
                                    }}>
                                      <img src={copyIcon} alt="copy ID"/>
                                    </span>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>}
                      <div className="grid grid-cols-6 gap-2">
                        <div className="col-span-6 p-1 pb-0">
                          <div
                            x-data="{ open: false }"
                            className="bg-gray-50flex flex-col items-center justify-center relative overflow-hidden"
                          >
                            <div className="py-4 border-t rounded flex justify-between items-center mt-4">
                              <div className="flex items-center gap-2">
                                <h4 className="font-medium text-lg text-primary">
                                  Valet History
                                </h4>
                              </div>
                            </div>
                            {/* {historyOpen ? ( */}
                            <div className="bg-white">
                              <div className="container mx-auto w-full h-full">
                                <div className="relative wrap overflow-hidden p-5 h-full">
                                  <div className="border-2-2 absolute border-opacity-20 border-gray-700 border left-35 h-custom-line">
                                    {" "}
                                  </div>
                                  {/* <!-- right timeline --> */}
                                  {state?.valet_history?.map((e, i) => {
                                    return (
                                      <div
                                        className="mb-8 flex justify-between items-center w-full right-timeline"
                                        key={i}
                                      >
                                        <div className="z-1 flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full">
                                          <h1 className="mx-auto font-semibold text-lg text-white">
                                            {i + 1}
                                          </h1>
                                        </div>
                                        <div className="order-1 w-10/12 pr-0 px-6 py-4">
                                          {" "}
                                          {/* bg-gray-200 rounded-lg shadow-xl*/}
                                          <p className="date text-sm">
                                            <b>{Urldate(e?.date_created)}</b>
                                          </p>
                                          <div className="flex flex-col sm:inline-flex sm:flex-row">
                                            <p className="text-sm py-1 sm:py-2">
                                              <span className="">
                                                {e?.status_id?.name} <span className="hidden sm:inline-flex"> - </span>{" "}
                                              </span>
                                            </p>
                                            <span className="text-sm text-secondary py-0 sm:py-2 px-0 sm:px-1 whitespace-normal break-all">
                                              {e?.user_updated
                                                ? (e?.user_updated?.first_name?.length > 0 ? e?.user_updated?.first_name : "")
                                                : `${state?.Customer_Name?.length > 0 ? state?.Customer_Name : ""}`}
                                              {!e?.user_updated && (
                                                <span className=" text-[#808080] py-0 sm:py-2 px-0 sm:px-1 whitespace-normal break-all">
                                                  (Customer)
                                                </span>
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            {/* ) : null} */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                ) : (
                  <div className="whitespace-nowrap text-sm p-4 text-gray-500 flex justify-center">
                    No data found
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="p-5 ">
          <p className="text-black">Are you sure to move ?</p>
          <div className="flex-row flex mt-10 justify-between">
            <button
              type="button"
              className="inline-flex font-semibold w-full justify-center border border-transparent bg-red-100 px-4 py-2 text-sm text-red-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={(e) => moveHandle(e)}
              className="inline-flex  items-center justify-center h-[40px] text-[18px] border bg-primary border-primary px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-vspurple hover:border-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              {" "}
              Ok{" "}
            </button>
          </div>
        </div>
      </Modal>
    </LayoutComponent>
  );
}

export default Detail;
