/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LayoutComponent from "../../components/LayoutComponent";
import UserContext from "../../contexts/userContext";
import { toast } from "react-toastify";
import { directus } from "../../services/directus";
import LoadingSpinner from "../../components/LoadingSpinner";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { useNavigate } from "react-router-dom";
import Seo from "../../components/Seo";
import Pagination from "../../components/Pagination";
import { userPresent } from "../../utils/CommonFunctions";
function Attendances() {
  const { details, AuthCheck } = React.useContext(UserContext);
  const [allUsers, setAllUsers] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [search, setSearch] = React.useState("");
  const [pagination, setPagination] = React.useState();
  const [page, setPage] = React.useState(
    Number(new URLSearchParams(window.location.search).get("page")) || 1
  );
  const navigate = useNavigate();
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;
  React.useEffect(() => {
    AuthCheck();
    setIsLoading(true);
    usersDataCall();
    const handleBackPress = () => {
      // for handle back to reload page
      window.location.reload();
    };
    window.onpopstate = handleBackPress;
  }, [branch, page, search?.trim()?.length === 0]);
  const presentHandle = async (e) => {
    AuthCheck();
    const LoggedCheck = await userPresent({ details: details });
    if (LoggedCheck?.status) {
      setIsLoading(true);
      const updateFields = e?.in_out_flag
        ? { in_out_flag: false }
        : { in_out_flag: true };
      await directus.users
        .updateOne(e?.id, updateFields)
        .then((res) => {
          toast.success(" Updated successfully");
        })
        .catch((err) => {
          navigate(".", { replace: true });
          console.log(err);
          toast.error(err?.message);
        });
      usersDataCall();
    } else {
      toast.error(
        "Your account was suspended, kindly contact the respective manager/admin."
      );
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const usersDataCall = async () => {
    AuthCheck();
    await directus.users
      .readByQuery({
        fields: ["*"],
        filter: {
          is_staff_user: { _eq: true },
          default_branch: { _eq: branch },
          default_company: { _eq: company },
          status: { _eq: "active" },
        },
        sort: ["first_name"],
        limit: paginationCount,
        page: page,
        meta: ["filter_count"],
      })
      .then((res) => {
        setAllUsers(res?.data);
        setPagination(Number(res?.meta?.filter_count));
      })
      .catch((e) => console.log(e));
    setIsLoading(false);
  };

  const searchHandle = async (e) => {
    AuthCheck();
    setIsLoading(true);
    const refValue = search?.trim();
    const filterBody = /^[0-9]+$/gi.test(refValue)
      ? {
          is_staff_user: { _eq: true },
          default_branch: { _eq: branch },
          default_company: { _eq: company },
          contact_number: { _eq: refValue },
          status: { _eq: "active" },
        }
      : {
          is_staff_user: { _eq: true },
          default_branch: { _eq: branch },
          default_company: { _eq: company },
          first_name: { _icontains: refValue },
          status: { _eq: "active" },
        };
    if (search?.trim()?.length < 1) {
      setIsLoading(false);
      return "";
    } else {
      await directus.users
        .readByQuery({
          fields: ["*"],
          filter: filterBody,
          limit: paginationCount,
          page: page,
          meta: ["filter_count"],
        })
        .then((res) => {
          if (res?.data?.length) {
            setAllUsers(res?.data);
            setPagination(Number(res?.meta?.filter_count));
          } else {
            setAllUsers([]);
            setPagination("");

            toast.error("No match found");
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error("No match found");
        });
    }
    setIsLoading(false);
  };
  const userList = [];
  allUsers?.length &&
    allUsers?.filter((e, i) => {
      if (i < 10) {
        // ((e?.first_name?.toLowerCase().includes(search.toLowerCase())) ||
        //     (e?.contact_number?.toLowerCase().includes(search.toLowerCase()))) &&
        userList.push(
          <tr className="hover:bg-gray-50" key={e?.id}>
            <td
              className="px-5 py-3 text-lg font-medium text-gray-900"
              data-th="Name"
            >
              <span className="staff-name">{e?.first_name}</span>
            </td>
            <td
              className="whitespace-nowrap px-5 py-3 text-lg text-gray-500"
              data-th="Phone Number"
            >
              {e?.contact_number ? e?.contact_number : "---"}
            </td>
            <td
              className="whitespace-nowrap px-5 py-3 text-right text-lg text-gray-500 cursor-pointer"
              data-th="Present"
            >
              <Toggle
                defaultChecked={e?.in_out_flag}
                icons={false}
                onChange={() => presentHandle(e)}
              />
            </td>
          </tr>
        );
      }
    });
  const paginationCount = 10;

  const numPages =
    pagination % paginationCount === 0
      ? Math.floor(pagination / paginationCount)
      : Math.floor(pagination / paginationCount) + 1;

  return (
    <LayoutComponent title={"STAFF IN OUT STATUS BOARD"} fixedFooter={true}>
      <Seo title={"Attendances"} company={true} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="main-content-area py-6 md:py-10 px-6">
            <div
              id="search_form"
              className="w-full flex justify-start items-center gap-2"
            >
              <div className="relative w-full">
              <label
                htmlFor="search_box"
                className="hidden"
              >
                Search
              </label>
                <input
                  type="text"
                  name="search"
                  id="search_box"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search || ""}
                  // ref={searchRef}
                  onKeyUp={(e) => {
                    if (e?.keyCode === 13) {
                      searchHandle(e);
                    }
                  }}
                  className="pl-4 py-2 pr-4 block w-full rounded-md border text-sm shadow-sm border-gray-200 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary md:min-w-[310px]"
                  placeholder="Search the Staff by Phone Number / Staff Name..."
                />
              </div>
              <button
                type="button"
                id="search_button"
                title="Search"
                onClick={(e) => searchHandle(e)}
                className="whitespace-nowrap inline-flex items-center justify-center rounded-md border border-vsorangered shadow-sm bg-vswhite h-10 px-4 py-2 text-sm font-semibold text-sky-600 focus:outline-none focus:ring-2 focus:ring-vsorangered"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke="#d06861"
                    className="w-4 h-4"
                  >
                    <title>Search</title>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </span>
              </button>
            </div>
            <div className="mt-[40px] flex flex-col">
              <div className="">
                <div className="inline-block min-w-full align-middle">
                  <div
                    className={`parking-status-table relative border rounded-md border-gray-200 overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 md:rounded-lg ${
                      allUsers?.length <= 0
                        ? "bordered shadow-md ring-1 ring-black ring-opacity-5"
                        : "no-border"
                    }`}
                  >
                    {allUsers?.length ? (
                      <table className="min-w-full divide-y  divide-gray-300 staff-attendance-table">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="min-w-[12rem] px-5 py-3 text-left text-lg font-semibold text-gray-900"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-5 py-3 text-left text-lg font-semibold text-gray-900"
                            >
                              Phone Number
                            </th>
                            <th
                              scope="col"
                              className="px-5 py-3 text-right text-lg font-semibold text-gray-900"
                            >
                              Present
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {userList}
                        </tbody>
                      </table>
                    ) : (
                      <div className="whitespace-nowrap text-sm p-4 items-center text-gray-500 flex justify-center">
                        No data found
                      </div>
                    )}
                  </div>
                  {/* pagination */}
                  {numPages > 1 ? (
                    <Pagination
                      numPages={numPages}
                      data={allUsers}
                      page={page}
                      handleNavigation={(e)=> setPage(e)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </LayoutComponent>
  );
}

export default Attendances;
