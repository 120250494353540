import React from "react";
class VehicleOtp extends React.Component {
  constructor(props) {
    super(props);
    let otpArray =
      typeof this.props.numInputs === Number
        ? new Array(this.props.numInputs).fill("")
        : Array.from(this.props.numInputs);
    this.state = {
      otp: otpArray,
      read: this.props.readOnly ? this.props.readOnly : false,
      required: this.props.required ? false : true,
      hasError: this.props.hasError,
    };
    this.otpRefs = Array.from({ length: this.props.numInputs }, (_, index) =>
      React.createRef()
    );
  }
  handelChange(event, index) {
    let fieldValue;
    fieldValue = (this.props.inputType === "text" ? /^[A-Za-z0-9]*$/.test(event.target.value) : /^[0-9]{1,1}$/.test(event.target.value))
      ? event.target.value
      : "";

    let field = event.target;
    let otpFields = this.state.otp;
    otpFields[index] = fieldValue?.toUpperCase();
    this.setState({ otp: otpFields });
    this.props.handleChange(this.state.otp.join(""));
    if (fieldValue !== "") {
      event.preventDefault();
      let next = field.nextSibling;
      if (next && next.tagName === "INPUT") {
        next.focus();
      }
    }
  }
  handelClearChange() {
    this.setState({
      otp:
        typeof this.props.numInputs === Number
          ? new Array(this.props.numInputs).fill("")
          : new Array(10).fill(""),
    });
    this.props.setOtpNo("");
    this.otpRefs[0].current.focus();
  }
  handleKeyDown(event, index) {
    if (event.keyCode === 8 && event.target.value.length === 0) {
      event.preventDefault();
      let previous = event.target.previousSibling;
      if (previous && previous.tagName === "INPUT") {
        previous.focus();
      }
    }
  }
  onPaste(event) {
    const pasted = event.clipboardData.getData("text/plain");
    const clearSpaces = pasted.replaceAll(" ", "");
    for (let i = 0; i < clearSpaces?.length; i++) {
      let fieldValue;
      fieldValue = (this.props.inputType === "text" ? /^[A-Za-z0-9]*$/.test(+clearSpaces[i]) : /^[0-9]{1,1}$/.test(+clearSpaces[i])) ? clearSpaces[i] : "";
      let field = event.target;
      let otpFields = this.state.otp;
      otpFields[i] = fieldValue?.toUpperCase();
      this.setState({ otp: otpFields });
      this.props.handleChange(this.state.otp.join(""));
      if (fieldValue !== "") {
        event.preventDefault();
        let next = field.nextSibling;
        if (next && next.tagName === "INPUT") {
          next.focus();
        }
      }
    }
  }
  render() {
    const data =
      typeof this.props.numInputs === "number"
        ? [...Array(this.props.numInputs)]
        : this.state.otp;
    const validCheck = this?.state?.otp?.some((e) =>
      e?.trim()?.length ? true : false
    );
    return (
      <div className="col-span-6">
        <div
          className={
            this.props?.type === "phone"
              ? "mobile_no py-1 grid grid-cols-10 gap-1 pb-[4px]"
              : `pin_no py-1 grid grid-cols-4 gap-4`
          }
        >
          <label
            htmlFor={"otpField"}
            className="hidden"
          >
            OTP
          </label>
          {data.map((otp, index) => {
            return (<input
              id={"otpField"}
              type={"text"}
              key={index}
              onWheel={(e) => e?.target?.blur()}
              name={"otp" + index}
              maxLength={1}
              pattern="[0-9]*"
              inputMode={this.props.inputType === "text" ? "text" : "numeric"}
              readOnly={this.state.read === true ? "readOnly" : false}
              ref={this.otpRefs[index]}
              required={this.state.required === true ? "required" : false}
              placeholder="0"
              onKeyDown={(event) => this.handleKeyDown(event, index)}
              onChange={(event) => this.handelChange(event, index)}
              onPaste={(event) => this.onPaste(event)}
              value={this.state.otp[index] || ""}
              className={`  text-3xl  appearance-none block text-gray-700 shadow-sm border ${this?.props?.dynamicError === false ?
                this.props.hasError
                  ? !!this.state.otp[index]?.trim() > 0
                    ? "border-gray-200 focus:border-primary"
                    : "border-red-400 focus:border-red-500 "
                  : "border-gray-200 focus:border-primary"
                : validCheck &&
                  this?.props?.hasError &&
                  this?.state?.otp?.length >= 0
                  ? !!this.state.otp[index]?.trim()
                    ? "border-gray-200 focus:border-gray-500"
                    : "border-red-400 focus:border-red-500 "
                  : "border-gray-200 focus:border-gray-500"
                }  rounded text-center py-3 px-2 leading-tight focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary  placeholder:text-center placeholder:text-gray-300 w-16 h-16 md:w-full `}
            />
            );
          })}
        </div>
        {this?.state?.otp?.some((e) => e?.trim()) ? (
          <button
            type="button"
            className="float-right flex flex-end cursor-pointer text-primary self-end"
            onClick={() => this.handelClearChange()}
          >
            clear
          </button>
        ) : null}
      </div>
    );
  }
}
export default VehicleOtp;
