import React from "react";
import Seo from "../components/Seo";
import CustomerLayout from "../components/CustomerLayout";
import { getAssetURL } from "../utils/get-asset-url";
import LoadingSpinner from "../components/LoadingSpinner";
import seoData from '../seoContent.json';
import UserContext from "../contexts/userContext";

function AboutUs() {
  const [bannerImg, setBannerImg] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const { commonInfo } = React.useContext(UserContext);
  
  React.useEffect(() => {
    setLoading(false);
    commonInfo&&setBannerImg(commonInfo);
  },[commonInfo])
  
  return (
    <>
      <Seo title={seoData?.about_us?.title}/>
      <CustomerLayout head={true}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <div className="w-full">
              <img
                src={
                  bannerImg?.home_banner?.length > 0
                    ? getAssetURL(bannerImg?.home_banner)
                    : ""
                }
                alt="Valet-search-banner"
              />
            </div>
            <div className="w-full">
              <div className="p-6 lg:p-8 flex justify-center flex-col text-black-700">
                <b className="text-black-800">About us :</b><br/>
                <p className="pb-3">
                  Welcome to ValetSearch, the digital valet parking application
                  that's designed to save you time and eliminate the stress of
                  parking. We understand that waiting for your car to be parked
                  or retrieving it can be a frustrating and time-consuming
                  experience, which is why we've developed a platform that
                  simplifies the process from start to finish.
                </p>
                <p className="pb-3">
                  Our user-friendly interface allows you to easily get Valet,
                  all from the convenience of your smartphone. With Valet
                  Search, you can say goodbye to long lines and wasted time, and
                  hello to a stress-free parking experience.{" "}
                </p>
                <p className="pb-3">
                  {" "}
                  We're dedicated to providing exceptional service and support
                  to our users and partners. Our team is always looking for ways
                  to innovate and improve our platform to meet the changing
                  needs of the parking industry. Whether you're a frequent user
                  or a first-time visitor, we're here to help you save time and
                  money while enjoying a seamless parking experience.{" "}
                </p>
                <p className="pb-3">
                  {" "}
                  Thank you for choosing ValetSearch for your digital valet
                  parking needs. We're excited to help you save time before and
                  after waiting for your vehicle, so you can focus on what
                  matters most.
                </p>
              </div>
            </div>
          </div>
        )}
      </CustomerLayout>
    </>
  );
}

export default AboutUs;
