import React from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import Seo from "../components/Seo";
import CustomerLayout from "../components/CustomerLayout";
import seoData from '../seoContent.json';

function RefundPolicy() {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <>
      <Seo title={seoData?.refund_policy?.title}/>
      <CustomerLayout head={true}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="p-6 lg:p-8 flex justify-center flex-col text-black-700">
            <b className="text-black-800">Refund Policy :</b>
            <br />
            EWall Solutions Pvt. Ltd. (operating as ValetSearch) understands that unexpected situations can arise, and you may need to 
            cancel your valet parking reservation. We strive to be fair and flexible with our refund policy.
            <br />
            <br />
            <ul className="list-decimal ml-[18px]">
                <li>
                    <span className="font-bold">Cancellations made more than 24 hours before the service:</span><br/>
                    <span>You will receive a full refund of the booking fee.</span>
                </li>
                <br />
                <li>
                <span className="font-bold">Cancellations made within 24 hours of the service:</span><br/>
                    <span>You will receive a 50% refund of the booking fee.</span>
                </li>
                <br />
                <li>
                <span className="font-bold">Cancellations made after the service has begun:</span><br/>
                    <span>No refunds will be issued.</span>
                </li>
                <br />
                <li>
                    <span className="font-bold">No-shows:</span><br/>
                    <span>No refunds will be issued for no-shows.</span>
                </li>
                <br />
                <li>
                    <span className="font-bold">Provider cancellations:</span><br/>
                    <span>If a Provider cancels your reservation, you will receive a full refund of the booking fee.</span>
                </li>
                <br />
                <li>
                    <span className="font-bold">How to request a refund:</span><br/>
                    <span>To request a refund, please contact us through our website or by email at [email protected] Please include your 
                        booking confirmation number and the reason for your cancellation.</span>
                </li>
                <br />
                <li>
                    <span className="font-bold">Processing time for refunds:</span><br/>
                    <span>Refunds will be processed within 5 business days of receiving your request. Please note that it may take additional
                         time for the funds to appear in your account, depending on your bank or payment processor.</span>
                </li>
            </ul>
            <br/>
            <div className="bottom-text">
                <div className="font-bold">Additional notes:</div>
                <br/>
                <ul className="list-disc ml-[18px]">
                    <li>This refund policy applies to all bookings made through the ValetSearch website.</li>
                    <li>Some Providers may have refund policies that differ from ours. Please be sure to review the Provider's terms and conditions before booking.</li>
                    <li>We reserve the right to modify this refund policy at any time without prior notice.</li>
                </ul>
                <br/>
                <span>We hope this refund policy is clear and helpful. If you have any questions, please don't hesitate to contact us.</span><br/><br/>
                <span>We understand that plans can change, and we want to make sure you feel comfortable booking valet parking through ValetSearch. 
                    Our flexible refund policy gives you peace of mind knowing that you can get your money back if you need to cancel your reservation.</span><br/><br/>
                <span>We hope you have a smooth and enjoyable valet parking experience with ValetSearch!</span><br/>
            </div>
          </div>
        )}
      </CustomerLayout>
    </>
  );
}

export default RefundPolicy;
