/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
import QRCode from 'qrcode';
import { toast } from "react-toastify";
import CustomerLayout from "../../components/CustomerLayout";
import LoadingSpinner from "../../components/LoadingSpinner";
import { directus } from "../../services/directus";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import ChatModal from "../../components/chatModal";
import { PDFDownloadLink, Document, Page, Text, StyleSheet, View, Image } from '@react-pdf/renderer';
import { getAssetURL } from "../../utils/get-asset-url";
import site_main_ph from "../../assets/images/valetsearch_small_logo.png";
import { sha256 } from 'js-sha256';
import { PAY_STATUS_API_BASE_URL, PHONEPE_MERCHANT_KEY, STATUS_API_ENPOINT, FRONTEND_URL } from "../../utils/utils";
import call_icon from "../../assets/images/call.png";
import NotFoundPage from "../NotFoundPage";
import UserContext from "../../contexts/userContext";

function VehicleStatus() {
    // const [count, setCount] = React.useState(0);
    const oneTheWay_SequenceNo = 4;
    const navigate = useNavigate();
    const [queueCountData, setQueueCountData] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
    const [stateValue, setStateValue] = React.useState({});
    const [companyID, setCompanyID] = React.useState({});
    const [staffAvailable, setStaffAvailable] = React.useState(false);
    const [taxPercent, setTaxPercent] = React.useState(0);
    const branch_No = companyID?.branch;
    const company = companyID?.company;
    const otpOrPhone = new URLSearchParams(window.location.search).get("id1");
    const vehicleNo = new URLSearchParams(window.location.search).get("id2");
    const { commonInfo } = React.useContext(UserContext);
   
    React.useEffect(() => {
        commonInfo&&setTaxPercent(commonInfo?.tax_config_in_percent)
      },[commonInfo])

    const { companyName, branchName } = useParams();
    const usersDatasCall = async (prop) => {
        prop?.Assigned_Staff?.first_name &&
            (await directus
                .items("Valet_Information")
                .readByQuery({
                    fields: ["id","OTP_Value"],
                    sort: ["date_updated"],
                    filter: {
                        status_id: { sequence: { _eq: oneTheWay_SequenceNo } },
                        Assigned_Staff: {
                            first_name: { _eq: prop?.Assigned_Staff?.first_name },
                        },
                    },
                    meta: ["filter_count"],
                })
                .then((res) => {
                    setQueueCountData(
                        res?.data
                            ? res?.data?.findIndex((e) => e.OTP_Value === prop?.OTP_Value) + 1
                            : 4
                    );
                })
                .catch((e) => console.log(e)));
        setIsLoading(true);
    };
    const reFreshCall = async () => {
        if (otpOrPhone && branch_No && company) {
            let vehicleFilter = vehicleNo;
            const userData =
                otpOrPhone?.trim()?.length === 4
                    ? await directus
                        .items("Valet_Information")
                        .readByQuery({
                            fields: ["*", "status_id.next_status.id", "company.company_logo.id","status_id.name","status_id.sequence",
                                    "status_id.next_status.id" ,"company.name","branch.is_staff_available","branch.name",
                                     "branch.address","branch.valet_number","Assigned_Staff.first_name"],
                            sort: ["-date_updated"],
                            filter: {
                                OTP_Value: { _eq: otpOrPhone },
                                branch: { _eq: branch_No },
                                company: { _eq: company },
                            },
                        })
                        .then((res) => {
                            const filterValue = res?.data?.filter((e) => {
                                return e?.Vehicle_Number?.slice(-4) === vehicleFilter
                                    ? e
                                    : "";
                            });
                            return filterValue?.[0];
                        })
                        .catch((e) => console.log(e))
                    : await directus
                        .items("Valet_Information")
                        .readByQuery({
                            fields: ["*", "status_id.next_status.id", "company.company_logo.id","OTP_Value", "status_id.name",
                                    "status_id.sequence","status_id.next_status.id", "company.name",  "branch.is_staff_available",
                                    "branch.name","branch.address","branch.valet_number", "Assigned_Staff.first_name"],
                            sort: ["-date_updated"],
                            filter: {
                                Customer_Phone_Number: { _eq: otpOrPhone },
                                branch: { _eq: branch_No },
                                company: { _eq: company },
                            },
                        })
                        .then((res) => {
                            const filterValue = res?.data?.filter((e) => {
                                return e?.Vehicle_Number?.slice(-4) === vehicleFilter
                                    ? e
                                    : "";
                            });
                            return filterValue?.[0];
                        })
                        .catch((e) => console.log(e));
            setStateValue(userData);
            setStaffAvailable(userData?.branch?.is_staff_available)
            await usersDatasCall(userData);
            await paymentChecking(userData);
            setIsLoading(true);
        }
    };

    const paymentChecking = async (prop) => {
        if ((companyID?.valetFee > 0) && (prop?.merchant_id && prop?.merchant_transaction_id) && prop?.payment_status !== "paid") {
            const hash = sha256.create()
            hash.update(STATUS_API_ENPOINT + "/" + prop?.merchant_id + "/" + prop?.merchant_transaction_id + PHONEPE_MERCHANT_KEY)
            var checkoutSumValue = hash.hex() + "###1";
            await fetch(PAY_STATUS_API_BASE_URL, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    "token": checkoutSumValue,
                    "merchantId": prop?.merchant_id,
                    "merchantTransactionId": prop?.merchant_transaction_id
                }),
            })
                .then(async (response) => {
                    // get json response here
                    let data = await response.json();
                    let output = JSON.parse(data?.output);
                    var body = {}
                    if (output?.success) {
                        body = {
                            payment_status: "paid"
                        }
                    }
                    else {
                        body = {
                            merchant_id: '',
                            valet_fee: '',
                            valet_tip: 0.0,
                            merchant_transaction_id: ''
                        }
                    }
                    if (prop?.id) {
                        await directus
                            .items("Valet_Information")
                            .updateOne(Number(prop?.id), body)
                            .then((res) => {
                                console.log(res);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                })
                .catch((e) => {
                    console.log(e)
                });
        }
    }

  const CompanyBranchCheck = async () => {
    if (companyName && branchName) {
      await directus
        .items("Companies")
        .readByQuery({
          fields: ["id"],
          filter: {
            status: { _eq: "published" },
            slug: { _eq: companyName },
            branches: { slug: { _eq: branchName } },
          },
        })
        .then(async (res) => {
          if (res?.data[0]) {
            await directus
              .items("Branch")
              .readByQuery({
                fields: ["id","chat_room_id","valet_fee"],
                filter: {
                  status: { _eq: "published" },
                  slug: { _eq: branchName },
                },
              })
              .then((response) => {
                setCompanyID({
                  company: res?.data[0]?.id,
                  branch: response?.data[0]?.id,
                  chatRoomId: response?.data[0]?.chat_room_id,
                  valetFee: response?.data[0]?.valet_fee
                });
              });
          } else {
            toast.error("Invalid company / branch");
          }
        })
        .catch((e) => console.log(e));
    }
  };

    React.useEffect(() => {
        setIsLoading(false);
        CompanyBranchCheck();
        reFreshCall();
        const interval =
            setInterval(reFreshCall, 30000);
        return () => clearInterval(interval);
    }, [company]);

    const timeUpdate = async (prop) => {
        await directus.items("Valet_Information").updateOne(Number(prop?.id), {
            started_time: prop?.date_updated,
        });
    };
    const moveHandle = async () => {
        if (stateValue?.status_id?.sequence === 2) {
            const vehicleStatus = await vehicleCurrentStatuscheck();
            if (vehicleStatus) {
                await directus
                    .items("Valet_Information")
                    .updateOne(Number(stateValue?.id), {
                        status_id: stateValue?.status_id?.next_status?.id,
                    })
                    .then(async (res) => {
                        await timeUpdate(res);
                        toast.success("Request sent successfully");
                        setIsLoading(false);
                        usersDatasCall();
                        reFreshCall();
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error("Something went wrong");
                    });
            } else {
                toast.error(
                    "This vehicle status is already changed, kindly refresh your page"
                );
            }
        } else {
            toast.error("Please wait... status will update soon");
        }
        setDetailsConfirmModel(false);
    };
    const vehicleCurrentStatuscheck = async () => {
        var val = false;
        await directus
            .items("Valet_Information")
            .readByQuery({
                fields: ["*","status_id.*","status_id.next_status.*"],
                filter: {
                    id: { _eq: stateValue?.id },
                    branch: { _eq: branch_No },
                    company: { _eq: company },
                },
            })
            .then((res) => {
                val =
                    res?.data[0]?.status_id?.sequence === stateValue?.status_id?.sequence
                        ? true
                        : false;
            })
            .catch((e) => {
                console.log(e);
            });
        return val;
    };
    const waitingNo = queueCountData;

    const styles = StyleSheet.create({
        page: {
          flexDirection: 'row',
          backgroundColor: '#FFFF',
          padding: 2,
          position: 'relative',
        },
        section: {
          margin: 2,
          padding: 10,
          flexGrow: 1,
          // marginTop: stateValue?.payment_status === "paid" ? 6 : 2,
          // marginTop: 6,
        },
        mainheading: {
          fontSize: 8,
          fontWeight: 'bold',
          marginBottom: 2,
          textAlign: 'center',
          marginTop: 4,
        },
        heading: {
          fontSize: 7,
          marginBottom: 2,
          textAlign: 'center',
        },
        normaltext1: {
          fontSize: 6,
          marginBottom: 2,
          textAlign: 'center',
        },
        normaltext: {
          fontSize: 6,
          marginBottom: 2,
        },
        text: {
          fontSize: 3,
        },
        scantext: {
          fontSize: 3,
          fontWeight: 'bold',
          textAlign: 'center',
          color: '#4b5563',
          textTransform: "uppercase",
          marginTop: 2,
          marginLeft: 'auto',
          marginRight: 'auto'
        },
        tableview: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        },
        paymentribbon: {
          position: 'absolute',
          fontSize: 6,
          padding: 4,
          width: "70px",
          color: '#fff',
          textAlign: 'center',
          right: '-22',
          top: '4',
          transform: 'rotate(45deg)',
          zIndex: 5,
        },
        gstText: {
          fontSize: 5,
          width: '20%',
          textAlign: 'right',
        },
        totalAmonut: {
          paddingTop: 3,
          textAlign: 'right',
          fontSize: 6,
          fontWeight: 'extrabold'
        },
        netAmount: {
          paddingTop: 3,
          textAlign: 'right',
          fontSize: 7,
          fontWeight: 'extrabold'
        },
        tableview1:{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: '0.5px solid #000'  
        }
      });

    const generateQRCode = async (value) => {
        var opts = {
            errorCorrectionLevel: 'H',
            type: 'image/png',
            quality: 1,
            margin: 2,
        }
        try {
            const imageDataUrl = await QRCode.toDataURL(value, opts);
            return imageDataUrl;
        } catch (error) {
            console.error('Error generating QR code:', error);
            return null;
        }
    };

    // PDF document content
    const InvoiceBill = () => (
        <Document>
        <Page size={{ width: 3.11 * 72, height: 3.94 * 72 }} style={styles.page}>
            <Text style={[styles.paymentribbon, { backgroundColor: stateValue?.payment_status === "paid" ? '#14a44d' : '#ff0000' }]}>{stateValue?.payment_status === "paid" ? 'Paid' : 'Not Paid'}</Text>
            <View style={styles.section}>
            <View style={[styles.tableview, { marginTop: 7 }]}>
                <Image src={`${stateValue?.company?.company_logo?.id ? getAssetURL(stateValue?.company?.company_logo?.id) : site_main_ph}`}
                style={{ width: 50, height: 50, padding: 0 }}></Image>
                <View>
                    <Image src={generateQRCode(`${`${FRONTEND_URL}/${companyName}/${branchName}/customer/payment?id1=${otpOrPhone}&id2=${vehicleNo}`}`)} style={{ width: 50, height: 50, textAlign: 'center', margin: '0 auto' }} />
                    <Text style={styles.scantext}>Scan to pay</Text>
                </View>
            </View>
            <Text style={styles.mainheading}>{stateValue?.company?.name ? stateValue?.company?.name : "ValetSearch"}</Text>
            {stateValue?.branch?.name ? <Text style={styles.heading}>{stateValue?.branch?.name}</Text>:null}
            {stateValue?.branch?.address ? <Text style={[styles.normaltext1, { paddingLeft: 15, paddingRight: 15 }]}>{stateValue?.branch?.address}</Text>: null}
            {stateValue?.branch?.valet_number?
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '2' }}>
                <Image style={{ width: '4', height: '4', marginBottom: '2' }} src={call_icon}></Image>
                <Text style={styles.normaltext1}>{stateValue?.branch?.valet_number}</Text>
                </View>
                :null
            }
            <View style={styles.tableview}>
                {stateValue?.id ? <Text style={[styles.normaltext, { fontSize: 7, marginTop: 4, fontWeight: 600 }]}>{stateValue?.payment_status === "paid" ? "Bill" : "Draft"} #: {stateValue?.id}</Text> :null}
                {stateValue?.date_created ? <Text style={styles.normaltext}>Date : {moment(stateValue?.date_created).format("DD/MM/YYYY hh:mm A")}</Text> :null}
            </View>
            {(stateValue?.payment_mode && stateValue?.payment_status === "paid") ? <Text style={styles.normaltext}>Payment Mode #: {stateValue?.payment_mode === "cash" ? "Cash" : "Online"}</Text> :null}
            {stateValue?
                <>
                <View style={{ borderTop: '0.5px solid #000', borderBottom: '0.5px solid #000', marginBottom: 3, marginTop: 3 }}>
                    <Text style={[styles.normaltext1, { padding: 2, marginTop: 2, color: '#4b5563' }]}>VALET PARKING {stateValue?.payment_status !== "paid"?" DRAFT " :""}RECEIPT</Text>
                </View>
                <View>
                    {(Number(stateValue?.valet_fee) > 0 || Number(companyID?.valetFee) > 0) ?
                    <View style={styles.tableview}>
                        <Text style={styles.normaltext}>Valet Fee </Text>
                        {Number(stateValue?.valet_fee) > 0 ?
                        <Text style={styles.normaltext}>Rs.{(Number(stateValue?.valet_fee))?.toFixed(2)}</Text>
                        : <Text style={styles.normaltext}>Rs.{(Number(companyID?.valetFee))?.toFixed(2)}</Text>}
                    </View>
                    :null}
                </View>
                <View style={{ borderTop: '0.5px solid #000', marginTop: 3, paddingBottom: 3, borderBottom: '0.5px solid #000' }}>
                    {(Number(stateValue?.valet_fee) > 0 || Number(companyID?.valetFee) > 0) ?
                    <View style={styles.tableview}>
                        <Text style={styles.totalAmonut}>TOTAL</Text>
                        { Number(stateValue?.valet_fee) > 0 ? 
                        <Text style={styles.totalAmonut}>Rs.{(Number(stateValue?.valet_fee))?.toFixed(2)}</Text>
                        :
                        <Text style={styles.totalAmonut}>Rs.{(Number(companyID?.valetFee))?.toFixed(2)}</Text>
                        }
                    </View>
                    :null
                    }
                </View>

                <View style={{ width: '70%', marginTop: 0 }}>
                    <Text style={[{ paddingTop: 5, paddingBottom: 3, textAlign: 'left', fontSize: 7, fontWeight: 600 }]}>GST Breakup Details</Text>
                    {(Number(stateValue?.valet_fee) > 0 || Number(companyID?.valetFee) > 0) ?
                    <View>
                        {/* GST TABLE */}
                        <View>
                        <View style={[styles.tableview, { width: '100%', paddingTop: 3, paddingBottom: 3, borderTop: '0.5px solid #000', borderBottom: '0.5px solid #000' }]}>
                            <Text style={[styles.gstText, { textAlign: 'left' }]}>
                            GST %
                            </Text>
                            <Text style={styles.gstText}>
                            Price
                            </Text>
                            <Text style={styles.gstText}>
                            SGST
                            </Text>
                            <Text style={styles.gstText}>
                            CGST
                            </Text>
                            <Text style={styles.gstText}>
                            Amount
                            </Text>
                        </View>
                        <View style={[styles.tableview, { width: '100%', paddingTop: 3, paddingBottom: 3, marginBottom: 2, }]}>
                            <Text style={[styles.gstText, { textAlign: 'left' }]}>
                            {stateValue?.tax_in_percent ? stateValue?.tax_in_percent : taxPercent } %
                            </Text>
                            <Text style={styles.gstText}>
                                {(Number(Number(stateValue?.valet_fee ? stateValue?.valet_fee  : companyID?.valetFee)*(100/(100+Number(stateValue?.tax_in_percent ? stateValue?.tax_in_percent : taxPercent))))?.toFixed(2))}
                            </Text>
                            <Text style={styles.gstText}>
                            {( (Number(stateValue?.valet_fee ? stateValue?.valet_fee  : companyID?.valetFee) - Number(Number(stateValue?.valet_fee ? stateValue?.valet_fee  : companyID?.valetFee)*(100/(100+Number(stateValue?.tax_in_percent ? stateValue?.tax_in_percent : taxPercent))))?.toFixed(2))/2)?.toFixed(2)}
                            </Text>
                            <Text style={styles.gstText}>
                            {( (Number(stateValue?.valet_fee ? stateValue?.valet_fee  : companyID?.valetFee) - Number(Number(stateValue?.valet_fee ? stateValue?.valet_fee  : companyID?.valetFee)*(100/(100+Number(stateValue?.tax_in_percent ? stateValue?.tax_in_percent : taxPercent))))?.toFixed(2))/2)?.toFixed(2)}             
                            </Text>
                            <Text style={styles.gstText}>
                            { Number(companyID?.valetFee)?.toFixed(2)}
                            </Text>
                        </View>
                        </View>
                    </View>
                    :null
                    }
                </View>
                {stateValue?.valet_tip ?
                    <View style={styles.tableview1}>
                        <Text style={[styles.normaltext, {marginTop: 2}]}>Valet Tips </Text>
                        <Text style={[styles.normaltext, {marginTop: 2}]}>Rs.{(stateValue?.valet_tip)?.toFixed(2)}</Text>
                    </View> : null}
                {/* NET AMOUNT */}
                <View style={{ borderTop: '0.5px solid #000', borderBottom: '0.5px solid #000', paddingBottom: 3 }}>
                    {(Number(stateValue?.valet_fee) > 0  || Number(companyID?.valetFee) > 0 ) ?
                    <View style={styles.tableview}>
                        <Text style={styles.netAmount}>NET AMOUNT</Text>
                        <Text style={styles.netAmount}>Rs.{
                        Number(stateValue?.valet_fee) >0 ? (Number(stateValue?.valet_fee) + Number(stateValue?.valet_tip))?.toFixed(2):
                        (Number(companyID?.valetFee))?.toFixed(2)}</Text>
                    </View>
                    :null
                    }
                </View>

                <View style={{ textAlign: "center" }}>
                    <Text style={[{ paddingTop: 3, fontSize: 6, color: '#4b5563', textTransform: "uppercase" }]}>Thank you and Drive safely!</Text>
                </View>
                </>
                :null}
            </View>
        </Page>
        </Document>
    );

    return (
        <>
        {otpOrPhone && vehicleNo ?
        (<CustomerLayout
            home={true}
            goBack={true}
            fixedFooter={true}
            containerView={false}
            isCompany={true}
        >
            {!isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="relative  w-full inline-block  firefox-scrollbar  overflow-hidden overflow-y-auto">
                    {stateValue !== undefined && Object.keys(stateValue)?.length > 0 ? (
                        <main className="main-content-area px-6 py-8 flex justify-center items-center">
                            <div className="mx-auto">
                                <div className="">
                                    <div className="block">
                                        <form method="POST">
                                            <div className="space-y-6 bg-white py-6">
                                                <div className="grid grid-cols-6 gap-10 max-w-[700px] mx-auto">
                                                    <div className="col-span-6">
                                                        {stateValue?.status_id?.sequence === 3 &&
                                                            <div className="pt-5 pb-2 px-4 text-center font-semibold text-lg md:text-xl text-green-900">
                                                                Kindly wait for a moment. Our valet
                                                                executive will process your request.
                                                            </div>
                                                        }

                                                        {stateValue?.status_id?.sequence === 4 && (
                                                            <div className="text-center font-semibold justify-evenly text-lg md:text-xl text-green-900 px-2.5 py-2">
                                                                {waitingNo && (
                                                                    <span>
                                                                        Kindly wait for{" "}
                                                                        {waitingNo === 1
                                                                            ? "2"
                                                                            : (waitingNo - 1) * 5}{" "}
                                                                        to {waitingNo === 1 ? "5" : waitingNo * 5}{" "}
                                                                        mins our valet staff will be delivery the
                                                                        vehicle at the moment.
                                                                    </span>
                                                                )}
                                                            </div>
                                                        )}
                                                        {![6, 5, 2, 3, 4]?.includes(stateValue?.status_id?.sequence) && (
                                                            <div className="text-center font-semibold normal-case flex justify-center gap-4">
                                                                <div className="inline-block text-lg md:text-xl text-green-900 px-2.5 py-2">
                                                                    Kindly wait for a moment. Our valet staff will reach you shortly.
                                                                </div>
                                                            </div>
                                                        )}
                                                        {stateValue?.status_id?.sequence === 2 ? (
                                                            <div className="text-center font-semibold justify-evenly text-lg md:text-xl text-green-900">
                                                                If you are ready to leave. Please click the below <b>Ready to Leave</b> button or contact our valet executive.
                                                            </div>
                                                        ) : null}
                                                        {stateValue?.status_id?.sequence === 6 ? (
                                                            <>
                                                                <div className="text-center font-semibold justify-evenly text-lg md:text-xl text-green-900 p-2">
                                                                    Thank you for using our service. Visit again!
                                                                </div>
                                                                <div className="text-center font-normal justify-evenly text-base md:text-lg p-2">
                                                                    Now you can close the browser window.
                                                                </div>
                                                            </>
                                                        ) : null}
                                                        {stateValue?.status_id?.sequence === 5 ? (
                                                            <>
                                                                <div className="text-center font-semibold justify-evenly text-lg md:text-xl text-green-900 p-2">
                                                                    You can pick up your vehicle from the valet.
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </div>

                                                    {stateValue && (
                                                        <div className="col-span-6 border border-gray-300 rounded">
                                                            {stateValue?.status_id?.sequence === 6
                                                                ? null
                                                                : stateValue?.status_id?.sequence >= 2 && stateValue?.status_id?.sequence !== 5 && (
                                                                    <div className="col-span-6 flex justify-center items-center flex-col shadow-sm p-4">
                                                                        {stateValue?.status_id?.sequence > 3 ? (
                                                                            <button
                                                                                type="button"
                                                                                className="clay-button inline-flex cursor-default items-center uppercase text-white justify-center px-3 rounded-full h-[160px] w-[160px] text-lg font-semibold focus:outline-none pointer-events-none"
                                                                            >
                                                                                <span>
                                                                                    You <br />
                                                                                    are in <br />
                                                                                    queue
                                                                                </span>{" "}
                                                                            </button>
                                                                        ) : stateValue?.status_id?.sequence === 3 ? (
                                                                            <button
                                                                                type="button"
                                                                                className="clay-button inline-flex cursor-default items-center uppercase text-white justify-center px-3 rounded-full h-[160px] w-[160px] text-lg font-semibold focus:outline-none pointer-events-none"
                                                                            >
                                                                                <span>
                                                                                    Your <br />
                                                                                    request <br />
                                                                                    in queue <br />
                                                                                </span>{" "}
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                type="button"
                                                                                onClick={() =>
                                                                                    stateValue?.status_id?.sequence === 2
                                                                                        ? setDetailsConfirmModel(true)
                                                                                        : ""
                                                                                }
                                                                                className="clay-button inline-flex items-center uppercase text-white justify-center px-3 rounded-full h-[160px] w-[160px] text-lg font-semibold focus:outline-none"
                                                                            >
                                                                                <span>
                                                                                    Ready <br />
                                                                                    to
                                                                                    <br />
                                                                                    Leave
                                                                                </span>
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            {stateValue?.status_id?.sequence === 4 ? (
                                                                <div className="text-center text-slate-500 text-lg md:text-xl font-semibold capitalize flex items-center justify-center px-2.5 p-4 shadow-sm">
                                                                    <span>Waiting List Number : </span>
                                                                    {stateValue && (
                                                                        <span className="bg-red-100 inline-block text-red-800 text-lg md:text-xl font-medium ml-2 px-2.5 pt-0.5 rounded border border-red-400">
                                                                            {waitingNo ? waitingNo : "--"}{" "}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            ) : null}
                                                            {stateValue?.status_id?.name && (
                                                                <div className="text-center text-slate-500 text-xl font-medium capitalize flex max-xs:flex-col gap-4 items-center justify-center shadow-sm p-4">
                                                                    <div>Current Status :</div>
                                                                    <div className="bg-red-100 inline-block  text-red-800 text-lg font-medium  px-2.5 py-0.5 rounded border border-red-400">
                                                                        {stateValue
                                                                            ? stateValue?.status_id?.name
                                                                            : ""}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {stateValue?.OTP_Value && (
                                                                <div className="text-center text-slate-500 text-xl font-medium capitalize flex max-xs:flex-col items-center justify-center  gap-4 shadow-sm p-4">
                                                                    <div>OTP :</div>
                                                                    {stateValue && (
                                                                        <div className="bg-vswhite inline-block text-primary text-5xl font-medium  px-2.5 pt-2 rounded border border-primary">
                                                                            {stateValue ? stateValue?.OTP_Value : ""}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {Number(companyID?.valetFee) > 0 && (
                                                                <div className="text-center font-normal normal-case flex max-xs:flex-col justify-center items-center gap-4 shadow-sm p-4">
                                                                    <span className="text-center text-slate-500 text-xl font-medium capitalize flex items-center justify-center  gap-4">Payment Status: </span><span className={`text-lg font-medium border px-2.5 py-0.5 rounded ${stateValue?.payment_status === "paid" ? 'text-green-800 border-green-400 bg-green-100' : 'text-red-800 border-red-400 bg-red-100'}`}>{stateValue?.payment_status === "paid" ? "Paid" : "Not paid"}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {Number(companyID?.valetFee) > 0 &&
                                                        <>
                                                            <div className="col-span-6">
                                                                <div className={`w-full flex justify-center ${stateValue?.payment_status !== "paid" && stateValue?.merchant_id && stateValue?.merchant_transaction_id ? 'max-sm:flex-col' : ''}`}>
                                                                    {(stateValue?.payment_status !== "paid" || Number(stateValue?.valet_tip) <= 0) &&
                                                                        <div className="text-center font-normal normal-case flex justify-center gap-1 m-4">
                                                                            <button type="button"
                                                                                onClick={() => navigate(`/${companyName}/${branchName}/customer/payment?id1=${otpOrPhone}&id2=${vehicleNo}`)}
                                                                                className={`${stateValue?.payment_status !== "paid" && stateValue?.merchant_id && stateValue?.merchant_transaction_id ? "pointer-events-none cursor-not-allowed  opacity-50" : ""} bg-primary border-vspurple text-[12px] cursor-pointer w-15 inline-block p-3 rounded-md border text-white text-md text-center focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 font-medium flex gap-1 items-end`}>
                                                                                {stateValue?.payment_status !== "paid" && stateValue?.merchant_id && stateValue?.merchant_transaction_id ? "Processing payment status. Please wait ..." : (stateValue?.payment_status !== "paid" ? "Pay Now" : "Add a Tip")}
                                                                                {stateValue?.payment_status === 'paid' &&
                                                                                    <svg className="self-baseline" fill="#ffffff" width="18" height="18" viewBox="0 0 24 24" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M12 10.375c0-2.416-1.959-4.375-4.375-4.375s-4.375 1.959-4.375 4.375c0 1.127.159 2.784 1.75 4.375l7 5.25s5.409-3.659 7-5.25 1.75-3.248 1.75-4.375c0-2.416-1.959-4.375-4.375-4.375s-4.375 1.959-4.375 4.375" />
                                                                                    </svg>
                                                                                }
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                    <div className="text-center font-normal normal-case flex justify-center gap-1 m-4">
                                                                        <PDFDownloadLink className="bg-[#FFFFFF] border-[#1a1a1a] text-[12px] cursor-pointer w-25 inline-block p-3 rounded-md border text-[#1a1a1a] text-md text-center focus:outline-none focus:ring-2 focus:ring-[#808080] focus:ring-offset-2 font-medium" document={<InvoiceBill />} fileName={`${stateValue?.payment_status !== "paid" ? 'draft' : 'valet'}_receipt.pdf`}>
                                                                            <span className="inline-flex items-center gap-1">{(stateValue?.payment_status !== "paid" ?
                                                                                'Draft Receipt'
                                                                                : 'Receipt')

                                                                            }
                                                                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0,0,256,256">
                                                                                    <g fill="#1a1a1a" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
                                                                                        <g transform="scale(10.66667,10.66667)">
                                                                                            <path d="M10.5,2c-0.81706,0 -1.5,0.68294 -1.5,1.5v6.5h-4.41406l7.41406,7.41406l7.41406,-7.41406h-4.41406v-6.5c0,-0.81706 -0.68294,-1.5 -1.5,-1.5zM11,4h2v8h1.58594l-2.58594,2.58594l-2.58594,-2.58594h1.58594zM2,20v2h20v-2z">
                                                                                            </path>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </PDFDownloadLink>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="col-span-6">
                                                        {stateValue?.status_id?.sequence !== 6 && (
                                                            <div className="text-center font-normal normal-case flex justify-center gap-4">
                                                                <div className="inline-block text-base md:text-lg px-2.5 py-2">
                                                                    Please do not close the browser window. If you close the browser, you will need to scan the QR code again and re-login using the OTP and
                                                                    the last 4 value of your vehicle number, or your registered phone number along with the last 4 value of your vehicle number.
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <ChatModal roomId={companyID?.chatRoomId} vehicleOtp={stateValue?.OTP_Value} staffAvailable={staffAvailable} customerName={stateValue?.Customer_Name} />

                        </main>
                    ) : (
                        <div
                            className={`border rounded-md border-gray-200 whitespace-nowrap text-sm m-10 justify-items-center p-4 text-gray-500 flex justify-center text-center mt-10 shadow-md ring-1 ring-black ring-opacity-5`}
                        >
                            <p className="text-center place-self-center">No data found</p>
                        </div>
                    )}
                </div>
            )}
            {detailsConfirmModel ? (
                <ConfirmationModal
                    openModel={detailsConfirmModel}
                    setIsOpenModel={setDetailsConfirmModel}
                    callFunction={moveHandle}
                    content={"Are you sure to leave now?"}
                />
            ) : null}

        </CustomerLayout>)
        : (
            <NotFoundPage />
        )
        }
        </>
    );
}

export default VehicleStatus;
