/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LayoutComponent from "../../components/LayoutComponent";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import UserContext from "../../contexts/userContext";
import { getAssetURL } from "../../utils/get-asset-url";
import { toast } from "react-toastify";
import CompanyInfoEdit from "../../components/companyRegistration/CompanyInfoEdit";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import { directus } from "../../services/directus";

function EditCompanyInfo() {
  const { details, UserBranchDataUpdate } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [branchData, setBranchData] = React.useState(null);
  const [info, setInfo] = React.useState({
    logo: "",
    Address: "",
    Tags: [],
    State: "",
    City: "",
  });
  const [validationError, setValidationError] = React.useState({
    logoError: false,
    AddressError: false,
    StateError: false,
    CityError: false,
    TagsError: false,
  });
  const company = details?.default_company?.id;
  const branch = details?.default_branch?.id;

  function updateFields(field, state) {
    setInfo((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }

  async function fieldValidation() {
    if (info?.Address?.trim()?.length < 1) {
      updateValidationError("AddressError", true);
    }
    if (!!!info?.State?.id) {
      updateValidationError("StateError", true);
    }
    if (!!!info?.City?.id) {
      updateValidationError("CityError", true);
    }
    if (info?.Tags?.length < 1) {
      updateValidationError("TagsError", true);
    }
    if (typeof info?.docs1 === "string") {
      updateValidationError("docs1Error", true);
    }
    if (
      !!info?.Address?.trim() &&
      !!info?.Tags?.length > 0 &&
      !!info?.State?.id &&
      !!info?.City?.id
    ) {
      setValidationError({
        logoError: false,
        AddressError: false,
        TagsError: false,
        StateError: false,
        CityError: false,
      });
    }
    return validationError;
  }

  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }

  const handleUpdate = async () => {
    await fieldValidation();
  };

  const createImageHandle = async (props) => {
    const formData = new FormData();
    formData.append("folder", props);
    const val = await directus.files
      .createOne(formData)
      .then((res) => {
        return res?.id;
      })
      .catch((err) => {
        toast.error("Error while uploading the image", err?.message);
      });
    return val;
  };

  const tagsIds = async (prop) => {
    const updateIds = [];
    const createIds = [];
    const removeIds = [];
    info?.Tags?.map((e) => {
      const val = details?.default_branch?.tags?.filter(
        (val) => val?.Tags_Category_id === e?.Tags_Category_id
      );
      val?.[0]?.id > 0
        ? updateIds?.push(val[0]?.Tags_Category_id)
        : createIds?.push({ Branch_id: prop, Tags_Category_id: e?.Tags_Category_id });
      return "";
    });
    details?.default_branch?.tags?.map(
      (val) =>
        updateIds?.includes(val?.Tags_Category_id) ? '' : removeIds?.push(val?.id)
    );
    createIds?.length > 0 && await directus
      .items("Branch_Tags_Category")
      .createMany(createIds)
      .then(async (res) => { })
      .catch((e) => toast.error("Error while updating tags"));
    removeIds?.length > 0 && await directus
      .items("Branch_Tags_Category")
      .deleteMany(removeIds).catch(e => toast.error("Error while updating tags"));
  };

  const handleBranchUpdate = async () => {
    const ImageLogoId = info?.logo ? info?.logo?.preview?.imageId ? info?.logo?.preview?.imageId : await createImageHandle(info?.logo) : null;

    const branchValues = {
      address: info?.Address?.trim(),
      state_id: info?.State?.id,
      city_id: info?.City?.id,
    };

    await directus
      .items("Branch")
      .updateOne(Number(branch), branchValues)
      .then(async (res) => {
        await directus
          .items("Companies")
          .updateOne(Number(company), { company_logo: ImageLogoId })
          .then(async () => {
            await tagsIds(branch)
            toast.success("Company info updated successfully");
          })
          .catch((err) => {
            toast.error("Failed to update company info");
          });
            res?.id&&res?.company_id&&UserBranchDataUpdate({default_branch:{id:res?.id},default_company:{id:res?.company_id}});
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update company info");
      });
  };

  React.useEffect(() => {
    if (
      !!info?.Address?.trim() &&
      !!info?.Tags?.length > 0 &&
      !!info?.State?.id &&
      !!info?.City?.id
    ) {
      setConfirmModal(true);
    }

    var {
      companyNameError,
      logoError,
      AddressError,
      TagsError,
      StateError,
      CityError,
    } = validationError;
    if (
      companyNameError ||
      logoError ||
      AddressError ||
      TagsError ||
      StateError ||
      CityError
    ) {
      toast.error("Validation Failed !");
    }
  }, [validationError]);

  React.useEffect(() => {
    // Loading prefetched data
    setIsLoading(false)
    setBranchData(details?.default_branch)
    if (details?.default_company?.company_logo) {
      updateFields('logo', {
        preview: {
          type: "image",
          url: getAssetURL(details?.default_company?.company_logo),
          imageId: details?.default_company?.company_logo,
        }
      })
    }
  }, [])

  return (
    <LayoutComponent
      title={"Edit Company Info"}
      fixedFooter={true}
      center={true}
    >
      <Seo title={"Edit Company Info"} company={true} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="col-span-6 p-6 lg:p-8">
            <CompanyInfoEdit
              info={info}
              setInfo={setInfo}
              validationError={validationError}
              logo={true}
              allFieldEdit={true}
              branchData={branchData}
            />
            <div className="col-span-6 flex justify-center items-end">
              <button
                type="button"
                onClick={(e) => handleUpdate()}
                className={`inline-flex items-center justify-center border border-transparent rounded-md hover:border-vspurple bg-primary px-4 py-2 text-sm font-semibold text-white shadow-md shadow-green-100 hover:bg-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto`}
              >
                <span>Save</span>
              </button>
            </div>
          </div>
          {confirmModal ? (
            <ConfirmationModal
              openModel={confirmModal}
              setIsOpenModel={setConfirmModal}
              callFunction={handleBranchUpdate}
              content={"Are you sure to update the following company details?"}
            />
          ) : null}
        </>
      )}
    </LayoutComponent>
  );
}

export default EditCompanyInfo;
