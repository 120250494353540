/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import Modal from "react-modal";
import { API_TOKEN, BACKEND_URL, CHAT_IP } from '../utils/utils';
import { toast } from 'react-toastify';
import ConfirmationModal from './popupModals/ConfirmationModal';

const ChatModal = ({ roomId, vehicleOtp, staffAvailable, customerName }) => {
    const [socket, setSocket] = useState(null);
    const [username, setUsername] = useState('');
    const [userPresent, setUserPresent] = useState(false);
    const [room, setRoom] = useState();
    const [started, setStarted] = useState(false);
    const [subroom, setSubroom] = useState('');
    const [message, setMessage] = useState('');
    const [notification, setNotification] = useState([]);
    const [messages, setMessages] = useState([]);
    const [openModal, setOPenModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const lastMessageRef = useRef(null);
    let fullName = username + "-" + vehicleOtp

    useEffect(() => {
        const localOtp = localStorage.getItem('vcOtp')
        const storedUsername = !!customerName?.trim()?.length > 0 ? customerName?.trim() : localOtp === vehicleOtp ? localStorage.getItem('username') : "";
        if (storedUsername) {
            setUsername(storedUsername);
        }
        const presentUser = storedUsername?.trim()?.length > 0 ? true : false
        const storedRoom = presentUser && roomId;
        if (storedRoom) {
            setRoom(storedRoom);
        }
        const storedSubroom = !!customerName?.trim()?.length > 0 && customerName?.trim() + "-" + vehicleOtp;
        if (storedSubroom) {
            setSubroom(storedSubroom);
        }
        storedUsername && storedRoom && storedSubroom && setUserPresent(true)

        const newSocket = io(CHAT_IP)
        setSocket(newSocket);

        return () => {
            if (!presentUser) {
                newSocket.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on('connect', () => {
                localStorage.setItem("socketId", socket?.id)

                if (username && room && subroom) {
                    socket.emit('joinRoom', { username: username, room, subroom: fullName, type: 'customer' });
                }
            });
            socket.on('chatMessage', (msg) => {
                setMessages([...messages, msg]);
            });

            socket.on('subroomMessage', (msg) => {
                if (msg.subroom === subroom) {
                    setMessages([...messages, msg]);
                }
            });
            socket.on('subroomMessages', (subroomMsgs) => {
                setMessages(subroomMsgs);
            });
            socket.on('getNotification', (res) => {
                const storedSubroom = localStorage.getItem('subroom');
                const userName = localStorage.getItem('username');
                const subroomIndex = notification?.length > 0 && notification?.findIndex(e => e?.roomId === res?.subroom)
                if (userName !== res?.username) {
                    const isChatOpen = openModal ? true : false
                    const unReadCount = notification?.[subroomIndex]?.count
                    const value1 = { roomId: res?.subroom, isRead: false, count: !!unReadCount > 0 ? unReadCount + 1 : 1 }
                    const Initial = notification?.map(e => e?.roomId)
                    if (isChatOpen) {
                        setNotification([{ roomId: subroom, isRead: true, count: 0 }])
                    }
                    else {
                        if (Initial?.includes(res?.subroom) === false) {
                            setNotification([...notification, { roomId: storedSubroom, ...value1 }])
                        } else {
                            const innerInitial = notification?.filter(e => e?.roomId !== res?.subroom)
                            setNotification([...innerInitial, value1])
                        }
                    }

                }
            });

        }
    }, [socket, messages, subroom, room]);
    useEffect(() => {
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);
    const joinRoom = () => {
        if (socket) {
            const currentRoomId = !!room ? room : roomId
            userPresent === false && localStorage.setItem('username', username);
            userPresent === false && localStorage.setItem('subroom', fullName);
            userPresent === false && localStorage.setItem('vcOtp', vehicleOtp);
            userPresent === false && setSubroom(fullName)
            userPresent === false && localStorage.setItem('room', currentRoomId);
            userPresent === false && setRoom(currentRoomId)
            socket.emit('joinRoom', { username: username, room: currentRoomId, subroom: fullName, type: 'customer' });
            setStarted(true);
        }
    };
    const sendSubroomMessage = () => {
        if (socket) {
            socket.emit('sendSubroomMessage', { message: message?.trim(), room, subroom });
            setMessage('');
        }
    };

    const leaveRoom = () => {
        if (socket) {
            socket.emit('leaveRoom', { API_URL: BACKEND_URL + "/items/Chat_Section", token: API_TOKEN, status: 'published', subroom: subroom });
            setMessages([]);
            setStarted(false);
            setUserPresent(true);
            closeModal()
        }
    };
    const customStyles = {
        overlay: {
            backgroundColor: "rgba(24, 24, 27, 0.4)",
            zIndex: 25,
        },
        content: {
            top: "50%",
            left: "50%",
            display: "flex",
            justifySelf: "center",
            right: "auto",
            background: "transparant",
            elevation: 0,
            border: "none",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
        },
    };
    const closeModal = () => {
        setOPenModal(false);
    };
    useEffect(() => {
        if (socket) {
            const handleBeforeUnload = () => {
                // Make the API call before the page is unloaded
                socket.emit('saveMessages', { API_URL: BACKEND_URL + "/items/Chat_Section", token: API_TOKEN, status: 'active' })
                localStorage.removeItem('username')
                localStorage.removeItem('room')
                localStorage.removeItem('subroom')
                localStorage.removeItem('socketId')
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            // Cleanup the event listener when the component is unmounted
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [socket])
    const joinHandle = () => {
        if (!!username?.trim()) {
            staffAvailable ? joinRoom() : toast.error("Sorry for the inconvenience, our staff are not available at this moment. Kindly check some other time!")

        } else { toast.error("User name is required") }
        return ""
    }
    const handleKeypress = e => {
        if (e?.key === 'Enter' && e?.shiftKey) {
            setMessage((prevMessage) => prevMessage + '\n');
        }
        else {
            if (e?.keyCode === 13) {
                e?.preventDefault();
                !!message?.trim() && staffAvailable && sendSubroomMessage()
            }
        }
    };
    const chatOpenHandle = () => {
        if (!staffAvailable) {
            return toast.error("Sorry for the inconvenience, our staff are not available at this moment. Kindly check some other time!")
        } else {
            if (username?.trim()?.length > 0 ? false : true) {
                setOPenModal(true)
            } else {
                setMessages([])
                setStarted(true)
                setOPenModal(true)
                joinHandle()
            }
            setNotification([{ roomId: subroom, isRead: true, count: 0 }])
        }
    }
    const unReadCount = notification?.[0]?.count
    const msgSendHandle = () => {
        if (!!message && message?.trim() && staffAvailable) {
            message?.trim()?.length < 4096 ? sendSubroomMessage() : toast.error("Your message is too long. Please edit it down to 4096 characters or split over multiple messages.")
        }
        return
    }
    return (
        <>
            <div className=' bg-black min-h-5 min-w-5 absolute bottom-0 right-10' onClick={() => chatOpenHandle()}>
                <div className='relative flex items-center gap-2 bg-primary p-2 rounded-full px-3 cursor-pointer'>
                    <svg fill="#fff" width="35px" height="35px" viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg" ><title>chat</title>
                        <path d="M26,4C12.7,4,2.1,13.8,2.1,25.9c0,3.8,1.1,7.4,2.9,10.6c0.3,0.5,0.4,1.1,0.2,1.7l-3.1,8.5 c-0.3,0.8,0.5,1.5,1.3,1.3l8.6-3.3c0.5-0.2,1.1-0.1,1.7,0.2c3.6,2,7.9,3.2,12.5,3.2C39.3,48,50,38.3,50,26.1C49.9,13.8,39.2,4,26,4z M14,30c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S16.2,30,14,30z M26,30c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S28.2,30,26,30z M38,30 c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S40.2,30,38,30z" />
                    </svg>
                    <span className='text-white font-bold'>Chat with us</span>
                    {unReadCount > 0 ? <div className=' bg-[#FF0000] absolute inline-block rounded-full w-6 h-6 text-white text-center right-0 top-[-8px] font-bold'>
                        {unReadCount > 0 ? unReadCount : ''}
                    </div> : ""}
                </div>

            </div>
            <Modal
                isOpen={openModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Example Modal"
            >
                <div className="chatbox__modal relative transform min-w-[320px] md:min-w-[400px] max-w-[540px] w-full rounded-[10px] bg-white text-left transition-all sm:my-8 sm:w-full sm:mx-5 sm:max-w-lg">
                    <div className="bg-white">
                        <div className='bg-primary flex items-center flex-col p-3 text-white chatbox-header'>
                            <div className='flex justify-end w-full gap-2'>
                                <div className='cursor-pointer' onClick={closeModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </div>
                                <div className='cursor-pointer' onClick={() => setConfirmModal(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </div>
                            </div>
                            <span className='flex font-bold'> Chat With US </span>
                            <span className='flex font-medium'>We typically reply in few minutes </span>
                        </div>
                        <div className="modal-content flex flex-col  px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

                            {!started && <>
                                <label
                                    htmlFor="user-name"
                                    className="block text-sm font-bold text-gray-700 mb-2"
                                >
                                    Username *
                                </label>
                                <input
                                    name='user-name'
                                    id='user-name'
                                    type="text"
                                    placeholder="Enter your username"
                                    value={username}
                                    disabled={!!customerName?.trim()?.length > 0 ? true : false}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className=' pl-4 py-2 pr-9 min-w-[310px] block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0'
                                />
                                <button onClick={joinHandle}
                                    className='mt-5 min-h-[40px] min-w-[140px] inline-flex items-center justify-center p-4 px-7 border border-primary bg-primary rounded-md text-sm font-medium text-white shadow-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto'
                                >{userPresent ? "Continue to" : "Proceed to"} Chat</button>
                            </>}

                            {started && <>

                                <div className="chat-messages min-h-[230px] max-h-[230px] overflow-auto">
                                    {messages.map((msg, index) => (
                                        <div key={index} style={{ whiteSpace: 'pre-line', lineHeight: '0.8' }} className={`flex m-1 mb-2 text-white ${msg.username === username ? "justify-end" : 'justify-start'}`}>

                                            <div className={`relative flex flex-col max-w-[90%] ${msg?.username === username ? "text-right right-0  justify-end items-end" : "text-left"} `}>
                                                {msg?.username !== username && <span className='flex text-xs text-black-500 justify-start w-full text-[10px] font-bold pb-[3px]'>
                                                    {msg?.username}
                                                </span>}
                                                <span className={`msg__txt text-left p-[8px] text-[14px] rounded leading-normal break-words text-[#1a1a1a] ${msg?.username === username ? 'bg-[#f0eafb]' : 'bg-[#fcf0e6]'}`}>
                                                    {msg?.text}
                                                </span>
                                                <span className={`flex text-xs text-black-500 ${msg?.username === username ? "justify-end" : "justify-start"} w-full text-[10px] font-bold pt-[5px]`}>
                                                    {msg?.time}</span>
                                            </div>
                                        </div>
                                    ))}
                                    <div ref={lastMessageRef} />
                                </div>
                                {staffAvailable ? <div className='flex justify-between items-end chat__input__box gap-3 pt-4 pb-1'>
                                    <textarea
                                        type="text"
                                        placeholder="Type your message..."
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        onKeyDown={handleKeypress}
                                        className='resize-none h-[42px] w-full'
                                    />
                                    <button onClick={msgSendHandle}
                                        className={`${staffAvailable ? "" : " opacity-40 pointer-events-none"}${message?.trim()?.length > 0 ? "" : 'opacity-70'} min-h-[20px] inline-flex items-center justify-center p-2  border border-primary bg-primary rounded-full text-sm font-medium text-white shadow-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto`}
                                    ><svg fill="#fff" width="25px" height="25px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" ><title>chat</title>
                                            <path d="M2,3v7.8L18,12L2,13.2V21l20-9L2,3z" />
                                        </svg>
                                    </button>
                                </div> : <div className='p-2'>
                                    Sorry for the inconvenience, our staff are not available at this moment. Kindly check some other time!
                                </div>}
                            </>}
                        </div>
                    </div>
                </div>
            </Modal>
            {confirmModal ? (
                <ConfirmationModal
                    openModel={confirmModal}
                    setIsOpenModel={setConfirmModal}
                    callFunction={leaveRoom}
                    content={"Are you sure to end the chat?"}
                />
            ) : null}
        </>

    );
};

export default ChatModal;