/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomerLayout from "../components/CustomerLayout";
import { directus } from "../services/directus";
import { EMAIL_SENDER_ADDRESS, TRANS_MAIL_API_BASE_URL } from "../utils/utils";
import Email_template from "../utils/Email_template";
import UserContext from "../contexts/userContext";

function ForgetPassword() {
  const [emaildata, setEmaildata] = React.useState("");
  const [alertshow, setAlertshow] = React.useState(false);
  const [preventDouble, setPreventDouble] = React.useState(false);
  const navigate = useNavigate();
  const { commonInfo } = React.useContext(UserContext);
  const { state } = useLocation();
  var flag = 1;

  React.useEffect(() => {
    const val = window.location.href.split("/");
    flag === 1 &&
      CompanyBranchCheck({ companyName: val[3], branchName: val[4] });
    flag = 5;
  }, [state === null || state === undefined]);

  const CompanyBranchCheck = async (props) => {
    await directus
      .items("Companies")
      .readByQuery({
        fields: ["id"],
        filter: {
          status: { _eq: "published" },
          slug: { _eq: props?.companyName },
          branches: { slug: { _eq: props?.branchName } },
        },
      })
      .then(async (res) => {
        if (res?.data[0]) {
          await directus
            .items("Branch")
            .readByQuery({
              fields: ["id"],
              filter: {
                status: { _eq: "published" },
                slug: { _eq: props?.branchName },
              },
            })
            .then((response) => {});
        } else {
          navigate("/NotFoundPage");
          toast.error("Invalid company / branch");
        }
      })
      .catch((e) => console.log(e));
    flag = 5;
  };
  const forgetHandle = async () => {
    if (
      emaildata?.trim()?.length > 4 &&
      emaildata?.trim()?.includes("@") &&
      emaildata?.trim()?.includes(".")
    ) {
      setPreventDouble(true);
      await directus.users
        .readByQuery({
          fields: ["id","default_company","default_branch"],
          filter: { email: { _eq: emaildata } },
        })
        .then(async (e) =>
          e?.data[0]?.id
            ? await updateHandle({
                id: e?.data[0]?.id,
                CompanyName: e?.data[0]?.default_company,
                BranchName: e?.data[0]?.default_branch,
              })
            : toast.error("No data found", {
               onClose: () => setPreventDouble(false),
              })
        );
    } else {
      toast.error("Enter valid email");
    }
  };

  async function postData({ url, token, name, emailTo, company, branch }) {

    let template = Email_template({
      forget: true,
      name: name,
      token: token,
      company: company,
      branch: branch,
      siteLogo: commonInfo?.site_logo,
    });
    var msg = {
      to: emailTo,
      from: EMAIL_SENDER_ADDRESS,
      subject: "Your newly generated password",
      name: name,
      html: template,
    };
    await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(msg),
    })
      .then((e) =>{
      if(e?.status !==200){
        console.error(e)
        toast.error("Something went wrong while sending a mail please try again later", {
          onClose: () => setPreventDouble(false),
        })
      }
      else{
        toast.success("we sent you a secure password to your mail")
      }
    })
      .catch((e) =>
        toast.error("Try again some other time", {
          onClose: () => setPreventDouble(false),
        })
      );
    return "";
  }

  const updateHandle = async (prop) => {
    const randomValue = Math.random().toString(36).slice(2, 7);
    await directus.users
      .updateOne(prop?.id, {
        password: randomValue,
        password_copy: randomValue,
      })
      .then(async (res) => {
        setAlertshow(true);
        setPreventDouble(false);
        await postData({
          url: TRANS_MAIL_API_BASE_URL,
          token: res?.password_copy,
          name: res?.first_name,
          emailTo: res?.email,
          company: prop?.CompanyName?.slug,
          branch: prop?.BranchName?.slug,
        });
        return res;
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", {
          onClose: () => setPreventDouble(false),
        });
      });
  };
  return (
    <CustomerLayout containerView={false} isCompany={true} goBack={true}>
      <div className="main-content-area">
        <div className="flex justify-center w-full items-center px-5 py-10">
          <div className="max-w-[400px] w-full flex flex-col items-center text-[14px] ">
            <h1 className="text-center text-[32px] font-bold text-black mb-8">
              RESET PASSWORD
            </h1>
            <div className="mb-3 w-full">
              <label
                htmlFor="u-mail-id1"
                className="block text-sm font-bold text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="u-mail-id1"
                placeholder="Your@email.com"
                value={emaildata}
                onChange={(e) => setEmaildata(e.target.value)}
                required
                className="mt-2 w-full px-4 py-3 flex justify-center items-center shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 placeholder:font-bold placeholder:text-[#bbb] placeholder:text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm"
              />
            </div>
            {alertshow ? (
              <div className="mb-8  border-l-lime-500 ... bg-green-100 px-3 py-3 rounded-md">
                Your password has been successfully changed. Please check your
                registered email inbox for the new password. If you do not
                receive an email, kindly check your spam folder, and then log in
                to your account with the new password sent via email.
              </div>
            ) : null}

            <div className="inline-flex w-full flex-row justify-between mt-5">
              <div className="">
                <button
                  type="button"
                  onClick={() => !alertshow && forgetHandle()}
                  disabled={preventDouble}
                  className="inline-flex  items-center justify-center h-[50px] text-[18px] rounded border bg-primary border-primary hover:border-vspurple px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-vspurple focus:outline-none focus:ring-2 focus:ring-vspurple focus:ring-offset-2"
                >
                  Reset
                </button>
              </div>
              <div className=" self-center">
                <button
                  className="self-end text-right font-semibold text-black hover:text-vspurple"
                  type="button"
                  onClick={() =>
                    navigate(
                      `/${state?.companyName}/${state?.branchName}/login`,
                      {
                        state: {
                          companyName: state?.companyName,
                          branchName: state?.branchName,
                        },
                      }
                    )
                  }
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomerLayout>
  );
}

export default ForgetPassword;
