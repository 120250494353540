/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LayoutComponent from "../../components/LayoutComponent";
import UserContext from "../../contexts/userContext";
import { toast } from "react-toastify";
import { directus } from "../../services/directus";
import LoadingSpinner from "../../components/LoadingSpinner";
import moment from "moment";
import Seo from "../../components/Seo";
import { valetNumberRegex, vehicleMinLetterCheck } from "../../utils/CommonFunctions";
function MasterVehicleSearch() {
  const [search, setSearch] = React.useState("");
  const [vehicleData, setVehicleData] = React.useState([]);
  const [info, setInfo] = React.useState();
  const [infoShow, setInfoShow] = React.useState(false);
  const { details, AuthCheck } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;

  const isNumeric = (str) => /^[0-9]+$/gi.test(str);
  const serachHandle = async (e) => {
    AuthCheck();
    setIsLoading(false);
    setVehicleData([]);
    const filterBody = isNumeric(search?.trim())
      ? {
          Customer_Phone_Number: { _eq: search },
          company: { _eq: company },
          branch: { _eq: branch },
        }
      : {
          Vehicle_Number: { _eq: search },
          company: { _eq: company },
          branch: { _eq: branch },
        };
    if (search?.trim()?.length < 1) {
      setIsLoading(true);
      return "";
    } else {
      var phoneNoValid = /^\d+$/;
      // Validate numbers
      const vehicleValid =valetNumberRegex;

      if (
      ((phoneNoValid?.test(search)&&search?.trim()?.length===10) || (vehicleValid.test(search)&&vehicleMinLetterCheck).test(search))
      ) {
        await directus
          .items("Valet_Information")
          .readByQuery({
            fields: ["date_created", "Vehicle_Number", "id", "Customer_Phone_Number", "status_id","status_id.name","Customer_Name","date_updated",
              "Assigned_Staff","Assigned_Staff.first_name"],
            filter: filterBody,
            sort: ["-date_updated"],
            meta: ["filter_count"],
          })
          .then((res) => {
            if (res?.data?.length) {
              setVehicleData(res);
              return "";
            } else {
              toast.error("No match found");
            }
          })
          .catch((e) => console.log(e, "err"));
      } else {
        toast.error(`${"Enter valid phone number  or vehicle number"}`);
      }
    }
    setIsLoading(true);
  };
  const Urldate = (prop) => {
    const localDate = new Date(prop);
    var d = localDate.getDate();
    var m = localDate.getMonth() + 1;
    var y = localDate.getFullYear();
    const date =
      (d <= 9 ? "0" + d : d) + "/" + (m <= 9 ? "0" + m : m) + "/" + y;
    return date;
  };
  const timeDifference = ({ create, update }) => {
    var dt1 = moment(create).format("DD/MM/YYYY HH:mm:ss");
    var dt2 = moment(update).format("DD/MM/YYYY HH:mm:ss");
    var ms = moment(dt2, "DD/MM/YYYY HH:mm:ss").diff(
      moment(dt1, "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);
    const val =
      d.days() > 0
        ? d.days() +
          (d.days() > 1 ? " days" : " day") +
          " - " +
          d.hours() +
          (d.hours() > 1 ? "hours" : "hour") +
          " - " +
          d.minutes() +
          (d.minutes() > 1 ? "mins" : "min")
        : d.hours() > 0
        ? d.hours() +
          (d.hours() > 1 ? " hours" : " hour") +
          " - " +
          d.minutes() +
          (d.minutes() > 1 ? "mins" : "min")
        : d.minutes() + (d.minutes() > 1 ? " mins" : " min");
    return val;
  };
  const openHandle = (props) => {
    if (info?.id === props?.id) {
      setInfo("");
      setInfoShow(false);
    } else {
      setInfo(props);
      setInfoShow(true);
    }
  };
  const renderList = () => {
    const SameDate = vehicleData?.data?.reduce(
      (
        date,
        {
          date_created,
          Vehicle_Number,
          id,
          Customer_Phone_Number,
          status_id,
          Customer_Name,
          date_updated,
          Assigned_Staff,
        }
      ) => {
        if (!date[Urldate(date_created)]) date[Urldate(date_created)] = [];
        date[Urldate(date_created)].push({
          id,
          status_id,
          Customer_Phone_Number,
          Vehicle_Number,
          date_created,
          Customer_Name,
          date_updated,
          Assigned_Staff,
        });
        return date;
      },
      {}
    );
    return (
      <>
        {Object.values(SameDate)?.map((e, i) => {
          e?.map((e, i) => {});
          return (
            <div
              className="mt-4 block p-3 bg-white border border-gray-200 rounded-lg relative cursor-pointer"
              key={i}
              onClick={() => openHandle(e[0])}
            >
              <div className="col-span-6 p-1 pb-0">
                <div className="flex flex-col items-start justify-between relative overflow-hidden">
                  <div className="rounded flex justify-between items-center w-full select-none">
                    <div className="flex items-center gap-2">
                      <h5 className="font-medium text-lg text-primary">
                        {Urldate(e[0]?.date_created)}
                      </h5>
                    </div>
                    <span
                      className={
                        info?.id === e[0]?.id && infoShow
                          ? "rotate-180 transition duration-300 ease-linear"
                          : "rotate-0 transition duration-300 ease-linear"
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <title>{ info?.id === e[0]?.id && infoShow?"Open":"Close"}</title>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </span>
                  </div>
                  {info?.id === e[0]?.id && infoShow
                    ? e?.map((e, i) => {
                        return (
                          <div
                            key={i}
                            className="relative transform overflow-hidden max-w-[540px] w-full rounded-lg  text-left  transition-all "
                          >
                            <div className="bg-white pt-3 relative">
                              {e?.Customer_Name?.trim()&&<div className="grid grid-cols-2 mb-2">
                                <div
                                  htmlFor="user-name"
                                  className="block text-gray-500 font-medium text-sm"
                                >
                                  Customer Name:
                                </div>
                                <div className="field text-sm font-semibold">
                                  {e?.Customer_Name}
                                </div>
                              </div>}
                              {!isNumeric(search)
                                ? e?.Customer_Phone_Number?.length && (
                                    <div className="grid grid-cols-2 mb-2">
                                      <div
                                        htmlFor="user-name"
                                        className="block text-gray-500 font-medium text-sm"
                                      >
                                        Phone No:{" "}
                                      </div>
                                      <div className="field text-sm font-semibold">
                                        {e?.Customer_Phone_Number}
                                      </div>
                                    </div>
                                  )
                                : e?.Vehicle_Number?.length && (
                                    <div className="grid grid-cols-2 mb-2">
                                      <div
                                        htmlFor="user-name"
                                        className="block text-gray-500 font-medium text-sm"
                                      >
                                        Vehicle No:{" "}
                                      </div>
                                      <div className="field text-sm font-semibold">
                                        {e?.Vehicle_Number}
                                      </div>
                                    </div>
                                  )}
                              <div className="grid grid-cols-2 mb-2">
                                <div
                                  htmlFor="user-name"
                                  className="block text-gray-500 font-medium text-sm"
                                >
                                  Vehicle Status:{" "}
                                </div>
                                <div className="field text-sm font-semibold">
                                  {e?.status_id?.name}
                                </div>
                              </div>
                              {e?.Assigned_Staff && (
                                <div className="grid grid-cols-2 mb-2">
                                  <div
                                    htmlFor="user-name"
                                    className="block text-gray-500 font-medium text-sm"
                                  >
                                    Last updated by:{" "}
                                  </div>
                                  <div className="field text-sm font-semibold">
                                    {e?.Assigned_Staff?.first_name}
                                  </div>
                                </div>
                              )}
                             { e?.date_created&&e?.date_updated&&<div className="grid grid-cols-2 mb-2">
                                <div
                                  htmlFor="user-name"
                                  className="block text-gray-500 font-medium text-sm"
                                >
                                  Time:{" "}
                                </div>
                                <div className="field text-sm font-semibold">
                                  {timeDifference({
                                    create: e?.date_created,
                                    update: e?.date_updated,
                                  })}
                                </div>
                              </div>}
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };
  return (
    <LayoutComponent title={"MASTER VEHICLE SEARCH"} fixedFooter={true}>
      <Seo title={"Vehicle search"} company={true} />
      <div className="px-6 py-10">
        <div
          id="search_form"
          className="w-full flex justify-start items-center gap-2"
        >
          <div className="relative w-full">
            <label
              htmlFor="search_box"
              className="hidden"
            >
              Search
            </label>
            <input
              type="text"
              name="search"
              id="search_box"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              maxLength={10}
              onKeyUp={(e) => {
                if (e?.keyCode === 13) {
                  serachHandle(e);
                }
              }}
              className="pl-4 py-2 pr-4 block w-full rounded-md border text-sm shadow-sm border-gray-200 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary md:min-w-[310px]"
              placeholder="Search the Vehicle by Customer Phone Number / Vehicle Number..."
            />
          </div>
          <button
            type="button"
            id="search_button"
            title="Search"
            onClick={(e) => serachHandle(e)}
            className="whitespace-nowrap inline-flex items-center justify-center rounded-md border border-vsorangered shadow-sm bg-vswhite h-10 px-4 py-2 text-sm font-semibold text-sky-600 focus:outline-none focus:ring-2 focus:ring-vsorangered"
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="3"
                stroke="#d06861"
                className="w-4 h-4"
              >
                <title>Search</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </span>
          </button>
        </div>
        {!isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {" "}
            <div className="w-full mt-4">
              {vehicleData?.meta?.filter_count && (
                <div className="flex items-center">
                  <span>Number of Occurence: </span>{" "}
                  <span className="ml-1 font-bold">
                    {vehicleData?.meta?.filter_count}
                  </span>
                </div>
              )}
              {vehicleData?.meta?.filter_count && (
                <div>
                  <span>Search results for: </span>{" "}
                  <span className="font-bold">{search}</span>
                </div>
              )}
              {vehicleData?.data ? (
                renderList()
              ) : (
                <div className="flex justify-center items-center p-5 shadow-md border rounded-md border-gray-200 text-sm my-10  text-gray-500 text-center">
                  <p>
                    {" "}
                    Enter the valid vehicle number or registered customer mobile
                    number
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </LayoutComponent>
  );
}

export default MasterVehicleSearch;
