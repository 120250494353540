/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LayoutComponent from "../../components/LayoutComponent";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import UserContext from "../../contexts/userContext";
import { directus } from "../../services/directus";
import ManageComponent from "../../components/usersForm/ManageComponent";

function ManageUsers() {
  const [allUsers, setAllUsers] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [selected, setSelected] = React.useState([]);
  const [userStatus, setUserStatus] = React.useState({
    id: 1,
    status: "active",
    name: "Active",
  });

  const { details, AuthCheck, companyDetails } = React.useContext(UserContext);
  const [pagination, setPagination] = React.useState();
  const [page, setPage] = React.useState(
    Number(new URLSearchParams(window.location.search).get("page")) || 1
  );
  const branch = details?.default_branch?.id;
  const company=details?.default_company?.id
  const paginationCount = 10;
  React.useEffect(() => {
    AuthCheck();
    setIsLoading(true);
    const usersDatasCall = async () => {
      await directus.users
        .readByQuery({
          fields: ["*"],
          filter: {
            is_manager_user: { _eq: true },
            default_branch: { _eq: branch },
            default_company: { _eq: company },
            status: userStatus?.status,
          },
          limit: paginationCount,
          page: page,
          sort: ["first_name"],
          meta: ["filter_count"],
        })
        .then((res) => {
          setAllUsers(res?.data);
          setPagination(Number(res?.meta?.filter_count));
        })
        .catch((e) => console.log(e));
      setIsLoading(false);
    };
    usersDatasCall();
    const handleBackPress = () => {
      // for handle back to reload page
      window.location.reload();
    };
    window.onpopstate = handleBackPress;
  }, [branch, page, userStatus,company]);

  return (
    <LayoutComponent
      title={"MANAGE MANAGER"}
      create={"users"}
      fixedFooter={true}
    >
      <Seo title={"Manager"} company={true} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ManageComponent
          allUsers={allUsers}
          userType={"users"}
          pagination={pagination}
          page={page}
          setPage={setPage}
          companySlug={companyDetails?.company}
          branchSlug={companyDetails?.branch}
          selected={selected}
          setSelected={setSelected}
          userStatus={userStatus}
          setUserStatus={setUserStatus}
          details={details}
        />
      )}
    </LayoutComponent>
  );
}

export default ManageUsers;
