/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useReducer } from "react";
import { BrowserRouter} from "react-router-dom";
import UserContext from "./contexts/userContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { directus } from "./services/directus";
import Modal from "react-modal";
import AllRoutes from "./pages/Routes/AllRoutes";
import closeIcon from "./assets/images/closeIcon.png";
import pwaImage from "./assets/images/pwa-image.webp";
import pwaImageAndroid from  "./assets/images/pwa-android-1.webp";
import pwaImageiOS1 from  "./assets/images/pwa-ios-1.webp";
import pwaImageiOS2 from  "./assets/images/pwa-ios-2.webp";
const INITIAL_STATE = {
  user: null,
  hasLoginError: false,
  currentUserDetails: null,
  details: null,
  TokenExpire: false,
  companyDetails: null,
  commonInfo:null
};

const reducer = (state, action) => {
  switch (action.type) {
    case "login": {
      const { prop } = action.payload;
      if (!prop?.token) {
        return {
          ...state,
          hasLoginError: true,
          user: null,
        };
      } else {
       return {
          ...state,
          hasLoginError: false,
          user: {
            token: prop?.token,
         },
          companyDetails: prop?.company,
          details: prop?.company?.details,
        };
      }
    }
    case "currentUserDetails":
      const { user } = action.payload;
     if (!user) {
        return {
          ...state,
          details: null,
        };
      } else {
        return {
          ...state,
          hasLoginError: false,
          details: user,
        };
      }
    case "AuthCheck":
      const tokenExpiration = new Date(
        parseInt(localStorage.getItem("auth_expires_at"))
      );
      const now = new Date();
      if (
        now > tokenExpiration ||
        localStorage.getItem("auth_expires_at") === null
      ) {
        const isToken = localStorage.getItem("isToken");
        !isToken &&
          toast.error("Session is expired kindly login again");
        localStorage.clear();
        localStorage.setItem("isToken", `${true}`);
        window.location.reload();
      }
      return {
        ...state,
        TokenExpire: false,
      };
    case "companyDetails":
      return {
        ...state,
        companyDetails: action.payload,
      };
    case "commonInfo":
      if(action?.payload?.data?.id){
        return {
        ...state,
        commonInfo:action.payload.data
        };
      }
      return {
        ...state
      }
    case "logout":
      localStorage.clear();
      localStorage.setItem("logout", true);
      return {
        ...state,
        user: null,
        companyDetails: null,
        details: null,
      };
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [openPWAModal,setOpenPWAModal] = useState(false);
  const isPWA = window.matchMedia('(display-mode: standalone)').matches;
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isMobile =   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent);
  // const isAndroid =   /Android/i.test(navigator.userAgent);
  const isiOS =   /iPhone|iPad/i.test(navigator.userAgent);
  const closeModal = () => {
    setOpenPWAModal(false);
  };
  const currentValue = {
    user: state.user,
    hasLoginError: state.hasLoginError,
    details: state.details,
    TokenExpire: state.TokenExpire,
    companyDetails: state.companyDetails,
    commonInfo:state.commonInfo,
    login: (prop) => {
      return dispatch({
        type: "login",
        payload: { prop },
      });
    },
    logout: () => dispatch({ type: "logout" }),
    currentUserDetails: (user) =>
      dispatch({ type: "currentUserDetails", payload: { user } }),
    AuthCheck: (prop) => {
      return dispatch({ type: "AuthCheck" });
    },
    BranchDataUpdate:(prop)=>{
       return companyDetails(prop)},
    UserBranchDataUpdate:(prop)=>{
        return currentUser(prop)}
  };
  React.useEffect(() => {
    if(!isPWA && sessionStorage.getItem('openPWAModal') === null && !isFirefox && (!isSafari || isMobile)){ 
        setOpenPWAModal(true)
        sessionStorage.setItem('openPWAModal',true);
    }
    const isToken = localStorage.getItem("isToken");
    if (isToken?.trim()) {
      isToken &&
        toast.error("Session is expired kindly login again");
      localStorage.clear();
    }
    const refreshCall = async () => {
     const token = localStorage.getItem("auth_token");
      if (token) {
        dispatch({
          type: "login",
          payload: { prop: { token: token } },
        });
        state?.details && companyDetails(state?.details);
      }
      if (state?.details === null && token) {
        currentUser();
      }
    };
    commonInfo()
    refreshCall();
  }, []);

  const companyDetails = async (prop) => {
    await directus
      .items("Companies")
      .readByQuery({
        fields: ["name","slug","default_branch.valet_fee","company_logo.id"],
        filter: {
          status: { _eq: "published" },
          id: { _eq: prop?.default_company?.id },
          branches: { id: { _eq: prop?.default_branch?.id } },
        },
      })
      .then(async (res) => {
        if (res?.data[0]) {
          await directus
            .items("Branch")
            .readByQuery({
              fields: ["name","slug","valet_number","platform_charges","is_staff_available","chat_room_id"],
              filter: {
                status: { _eq: "published" },
                id: { _eq: prop?.default_branch?.id },
              },
            })
            .then((response) => {
              const payLoad = {
                companyName: res?.data[0]?.name,
                company: res?.data[0]?.slug,
                branchName: response?.data[0]?.name,
                branchValetNum:response?.data[0]?.valet_number,
                branchPlatformCharges:response?.data[0]?.platform_charges,
                branchValetFee: res?.data[0]?.default_branch?.valet_fee,
                chatStaffAvailable:response?.data[0]?.is_staff_available,
                branch: response?.data[0]?.slug,
                logo: res?.data[0]?.company_logo?.id,
                chatRoomId:   response?.data[0]?.chat_room_id,
                isPublished:res?.data[0]?.status==='published'&&res?.data[0]?.status==='published'?true:false
              };

              dispatch({
                type: "companyDetails",
                payload: payLoad,
              });
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  const currentUser = async () => {
    await directus.users.me
      .read({
        fields: ["*", "default_company.*","default_branch.*","default_branch.agreement_id.*","default_branch.document_1.*","default_branch.document_2.*","default_branch.city_id.*", "default_branch.state_id.*","default_branch.tags.*"] 
      })
      .then((user) => {
        companyDetails(user);
        dispatch({ type: "currentUserDetails", payload: { user } });
      })
      .catch((e) => console.log(e));
  };
  const commonInfo=async()=>{
    await directus
    .items("Configuration_Settings")
    .readByQuery({
      fields: ["*"],
    })
    .then((res) => {
      dispatch({
        type: "commonInfo",
        payload: { data:res?.data},
      });
      return "";
    })
    .catch((e) => console.log(e));
  }
  const AdminUser =
    currentValue?.details?.is_admin_user === true ? true : false;
  const Manager =
    currentValue?.details?.is_manager_user === true ? true : false;
  const isCompanyAndBranchPublished= currentValue?.details?.default_branch?.status==="published"&&currentValue?.details?.default_company?.status==="published"?true:false
    const customStyles = {
      overlay: {
        backgroundColor: "rgba(24, 24, 27, 0.6)",
        zIndex: 99,
      },
      content: {
        top: "50%",
        left: "50%",
        display: "flex",
        justifySelf: "center",
        right: "auto",
        background: "transparant",
        elevation: 0,
        border: "none",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        maxHeight: "90vh",
      },
    };
  
   return (
    <UserContext.Provider value={currentValue}>
      <BrowserRouter>
      <Modal
      isOpen={openPWAModal}
      onRequestClose={() => closeModal()}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Example Modal"
    > 
       <div className="min-w-[320px] sm:min-w-[450px] pwa-alert-popup relative transform overflow-hidden max-w-[540px] w-full rounded-lg bg-white text-left shadow-xl transition-all border border-slate-400 ... ">
          <div className="p-6 relative running-border bg-white text-primary z-[99]">
            <div className="close-div relative top-[-12px] right-[-12px]">
              <button
                onClick={() => closeModal()}
                className="float-right w-8 h-8 "
              >
                <img src={closeIcon} alt="close-icon" />{" "}
              </button>
            </div>
            <div className="border-2 border-dashed border-vsorangered p-5 rounded-md">
              <div className="heading-content text-xl text-primary font-bold">
                 Alert!
              </div>
              <div className="content my-5 flex flex-col sm:flex-row gap-3">
                <span className="w-full sm:w-1/2">If needed, you can create the <strong>ValetSearch</strong> application shortcut on your device by following the screenshot below.</span>
                <div className="image-content w-full sm:w-1/2">
                  {!isMobile? 
                      <img src={pwaImage} alt="shortcut-install" className="max-w-[100%]"/>
                      : ( isiOS?
                        <>
                        <img src={pwaImageiOS1} alt="shortcut-install" className="max-w-[100%] mb-4"/>
                        <img src={pwaImageiOS2} alt="shortcut-install" className="max-w-[100%]"/>
                        </>
                        :
                        <img src={pwaImageAndroid} alt="shortcut-install" className="max-w-[100%]"/>
                      )}
                </div>
              </div>
              </div>
              </div>
              </div>
      </Modal>
      <AllRoutes AdminUser={AdminUser} Manager={Manager} auth={state.user} IsPublished={isCompanyAndBranchPublished}/>
      </BrowserRouter>
      <ToastContainer autoClose={2000} 
      position="bottom-center"
      />
    </UserContext.Provider>
  );
}

export default App;
