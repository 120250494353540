import React from "react";
import Modal from "react-modal";

function ConfirmationModal(props) {
  const { setIsOpenModel, callFunction, content, openModel } = props;
  const [preventDouble, setPreventDouble] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(openModel=== true ? true : false);
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 24, 27, 0.4)",
      zIndex: 25,
    },
    content: {
      top: "50%",
      left: "50%",
      display: "flex",
      justifySelf: "center",
      right: "auto",
      background: "transparant",
      elevation: 0,
      border: "none",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
    },
  };
  const closeModal = () => {
    setIsOpenModel(false);
    setIsOpen(false);
  };
  const Handle = async () => {
    setPreventDouble(true);
    await callFunction();
    setIsOpen(false);
    setPreventDouble(false);
    setIsOpenModel(false);
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => closeModal(false)}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Example Modal"
    >
      <div className="relative transform overflow-hidden min-w-[320px] max-w-[540px] w-full rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:mx-5 sm:max-w-lg border border-slate-400 ...">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <p className="text-black" dangerouslySetInnerHTML={{ __html: content }}/>
          <div className={`flex-row flex mt-10 gap-5 ${props?.cancelBtn!==true?"justify-between":"justify-end"}`}>
           {props?.cancelBtn!==true&& <button
              type="button"
              className="inline-flex font-semibold w-auto justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm text-red-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
              onClick={() => closeModal(false)}
            >
              Cancel
            </button>}
            <button
              type="button"
              onClick={() =>preventDouble === false && Handle()}
              className="inline-flex rounded-md items-center justify-center h-[40px] border bg-primary border-primary px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-vspurple hover:border-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
