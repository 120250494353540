/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import Seo from "../components/Seo";
import CustomerLayout from "../components/CustomerLayout";
import ConfirmationModal from "../components/popupModals/ConfirmationModal";
import { toast } from "react-toastify";
import { EMAIL_SENDER_ADDRESS, MAIL_API_BASE_URL, RECAPTCHA_SITE_KEY } from "../utils/utils";
import Email_template from "../utils/Email_template";
import Files from "react-files";
import ReCAPTCHA from "react-google-recaptcha";
import seoData from '../seoContent.json';
import UserContext from "../contexts/userContext";


function SupportRequest() {
    const captchaRef = useRef(null)
    const [loading, setLoading] = React.useState(true);
    const [name, setName] = React.useState("");
    const [phoneNo, setPhoneNo] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [docsSize, setDocsSize] = React.useState("");
    const [files, setFiles] = React.useState();
    const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
    const { commonInfo } = React.useContext(UserContext);
    const [validationError, setValidationError] = React.useState({
        phoneNoError: false,
        nameError: false,
        emailError: false,
        messageError: false,
        lenPhoneNoError: false,
        emailFormatError: false,
        captchaError: false,
        attachmentError:false
    });
    React.useEffect(() => {
        const maxLength = 10;
        if (phoneNo?.length > maxLength) {
            setPhoneNo(phoneNo.substring(0, maxLength));
        }
    }, [phoneNo?.length]);
    React.useEffect(() => {
        var {
            emailError,
            messageError,
            phoneNoError,
            nameError,
            lenPhoneNoError,
            emailFormatError,
            captchaError,
            attachmentError
        } = validationError;
        if (!!name && !!email && !!phoneNo && !!message) {
            if (
                !(
                    emailError ||
                    phoneNoError ||
                    messageError ||
                    nameError ||
                    lenPhoneNoError ||
                    emailFormatError ||
                    captchaError ||
                    attachmentError
                )
            ) {
                setDetailsConfirmModel(true);
            } else {
                toast.error("Validation Failed!!!");
            }
        }
    }, [validationError]);
    React.useEffect(() => {
        setLoading(false);
    }, []);

    const doc1ChangedHandler = (event) => {
        let file_size = event[0]?.size;
        if (file_size / 1000000 < 2) {
            setDocsSize("");
            setFiles(event[0]);
        } else {
            setDocsSize(
                "* Please upload the attachment less than 2MB. / img/*(except .gif)  .doc, .pdf, .docx"
            );
        }
    };
    async function fieldValidation() {
        if (!!!name?.trim()) {
            updateValidationError("nameError", true);
        }
        if (name?.trim()) {
            updateValidationError("nameError", false);
        }
        if (!!!phoneNo?.trim()) {
            updateValidationError("phoneNoError", true);
        }
        if (phoneNo?.trim()?.length < 10 && phoneNo?.trim()?.length >= 1) {
            updateValidationError("phoneNoError", false);
            updateValidationError("lenPhoneNoError", true);
        }
        if (phoneNo?.trim()?.length === 10) {
            updateValidationError("phoneNoError", false);
            updateValidationError("lenPhoneNoError", false);
        }
        if (!!!email?.trim()?.length > 0) {
            updateValidationError("emailError", true);
        }
        if (
            !!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&
            email?.trim()?.length >= 1
        ) {
            updateValidationError("emailError", false);
            updateValidationError("emailFormatError", true);
        }
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            email?.trim()?.length > 0 && updateValidationError("emailError", false);
            updateValidationError("emailFormatError", false);
        }
        if (!message) updateValidationError("messageError", true);
        if (message?.trim()) updateValidationError("messageError", false);

        const token = captchaRef?.current?.getValue();
        if (!token) {
            updateValidationError("captchaError", true);
        }
        else {
            updateValidationError("captchaError", false);
        }
        captchaRef?.current?.reset();
        if(docsSize?.trim()?.length>1){
            updateValidationError("attachmentError", true);
        }
        else{
            updateValidationError("attachmentError", false);
        }
        return validationError;
    }
    function updateValidationError(field, state) {
        setValidationError((prevState) => ({
            ...prevState,
            [field]: state,
        }));
    }

    const MailFetcher = async ({ msg }) => {
        await fetch(MAIL_API_BASE_URL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(msg),
        })
            .then((e) => {
                setSuccessMessage(
                    "Thank you for your feedback. Your feedback has been forwarded to our customer support team."
                );
                setErrorMessage("");
                setTimeout(() => {
                    setSuccessMessage("");
                }, 5000);
            })
            .catch((e) => {
                setErrorMessage("Something went wrong. Try again later!");
                setSuccessMessage("");
                setTimeout(() => {
                    setErrorMessage("");
                }, 5000);
            });
        setName("");
        setPhoneNo("");
        setEmail("");
        setMessage("");
        setFiles("")
        setDetailsConfirmModel(false);
    };

    const handleSubmit = async () => {
        await fieldValidation();
    };

    const readFileAsync = async (file) => {
        return new Promise((resolve, reject) => {
            var filereader = new FileReader();
            filereader.readAsDataURL(file);
            var base64;
            filereader.onload = () => {
                base64 = resolve(filereader.result);
            };
            filereader.onerror = reject;
            return base64
        })
    }

    const callback = async () => {
        console.log("called callback");
        const AdminMailList = commonInfo
        
        AdminMailList?.support_email_addresses?.split(",")?.map(async (e) => {
            var attachmentFiles = files? await readFileAsync(files) : ""
            let template = Email_template({
                affilation: true,
                siteLogo: AdminMailList?.site_logo,
                name: name,
                phoneNo: phoneNo,
                email: email,
                message: message,
            });
            var msg = {
                to: e,
                from: EMAIL_SENDER_ADDRESS,
                subject: `Suggestion from ${name}.`,
                name: "ValetSearch",
                html: template,
                attachments: files ? ([
                    {   // data uri as an attachment  
                        filename: files?.name,
                        path: attachmentFiles
                    }
                ]):[],
            };
            return await MailFetcher({ msg });
        });
    };
    const preview = (props) => {
        return (
            props?.value && (
                <div className="files-list">
                    <ul>
                        <li
                            key={props?.value?.id}
                            className="flex flex-row justify-evenly text-center place-items-center"
                        >
                            <div>
                                <div className="">
                                    {props?.value?.preview?.type === "image" ? (
                                        <img
                                            className=""
                                            src={props?.value?.preview?.url}
                                            alt="Preview"
                                        />
                                    ) : (
                                        <div className="">{props?.value?.extension}</div>
                                    )}
                                </div>
                                <div>
                                    <div className="text-sm font-medium text-gray-900 min-w-[150px] ">
                                        {props?.value?.name}
                                    </div>
                                </div>
                            </div>
                        </li>
                        <div
                            className="list-item-remove"
                            onClick={() => setFiles()}
                        >
                            <svg
                                viewBox="0 0 24 24"
                                width="20"
                                height="20"
                                stroke="#ff0000"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1 float-right"
                            >
                                <title>Delete</title>
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                        </div>
                    </ul>
                </div>
            )
        );
    };
    return (
        <>
            <Seo title={seoData?.suggestions?.title} />
            <CustomerLayout head={true}>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <div className="p-6 lg:p-8 flex justify-center flex-col text-black-700 w-1/2 max-[767px]:w-9/12 max-[580px]:w-full">
                        <h1 className="text-left text-[24px] font-bold text-black-800 mb-6">
                            For any Improvements or issue on this websites
                        </h1>
                        <span>Share your ValetSearch experience with us. Your feedback drives our innovation.</span>

                        <div className="space-y-6 bg-white py-6">
                            <div className="flex flex-col justify-start items-start md:flex-row gap-2">
                                <label
                                    htmlFor="name-1"
                                    className="hidden "
                                    >
                                    Name*
                                </label>
                                <input
                                    type="text"
                                    name="user-name"
                                    id="name-1"
                                    value={name || ""}
                                    onChange={(e) => setName(e.target.value)}
                                    autoComplete="given-name"
                                    placeholder="Name"
                                    className={`${validationError?.nameError && name?.trim()?.length < 1
                                        ? "border-red-300 focus:border-red-300"
                                        : "border-gray-300 focus:border-primary"
                                        } mt-2 w-full px-4 py-3 flex justify-center items-center shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 placeholder:font-bold placeholder:text-[#bbb] placeholder:text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm `}
                                />
                            </div>
                            {validationError?.nameError && name?.trim()?.length < 1 && (
                                <p className="text-xs text-right text-red-500 error-msg mt-0">
                                    *Name is required
                                </p>
                            )}{" "}
                            <div className="flex flex-col justify-start items-start md:flex-row gap-2">
                                <label
                                    htmlFor="phone-number"
                                    className="hidden"
                                    >
                                    Phone Number*
                                    </label>
                                <input
                                    type="text"
                                    name="user-phone-number"
                                    id="phone-number"
                                    value={phoneNo || ""}
                                    placeholder="Phone number"
                                    pattern="[0-9]*" 
                                    inputMode="numeric"
                                    onChange={(e) =>
                                        /^\d*\.?\d*$/.test(e.target.value) &&
                                        setPhoneNo(e.target.value)
                                    }
                                    autoComplete="given-name"
                                    className={`${(validationError?.phoneNoError && phoneNo?.length < 1) ||
                                        validationError?.lenPhoneNoError
                                        ? "border-red-300 focus:border-red-300"
                                        : "border-gray-300 focus:border-primary"
                                        } mt-2 w-full px-4 py-3 flex justify-center items-center shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 placeholder:font-bold placeholder:text-[#bbb] placeholder:text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm `}
                                />
                            </div>
                            {validationError?.phoneNoError && phoneNo?.trim()?.length < 1 && (
                                <p className="text-xs text-right text-red-500 error-msg mt-0">
                                    *Phone number is required
                                </p>
                            )}{" "}
                            {((validationError?.phoneNoError === false &&
                                validationError?.lenPhoneNoError) ||
                                (phoneNo?.trim()?.length > 0 &&
                                    phoneNo?.trim()?.length < 10)) && (
                                    <p className="text-xs text-right text-red-500 error-msg mt-0">
                                        *Enter valid phone Number
                                    </p>
                                )}
                            <div className="flex flex-col justify-start items-start md:flex-row gap-2">
                                <label
                                    htmlFor="email"
                                    className="hidden"
                                    >
                                    Email*
                                    </label>
                                <input
                                    type="text"
                                    name="user-email"
                                    id="email"
                                    value={email || ""}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email"
                                    autoComplete="given-name"
                                    className={`${(validationError?.emailError && email?.length < 1) ||
                                        validationError?.emailFormatError
                                        ? "border-red-300 focus:border-red-300"
                                        : "border-gray-300 focus:border-primary"
                                        } mt-2 w-full px-4 py-3 flex justify-center items-center shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 placeholder:font-bold placeholder:text-[#bbb] placeholder:text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm `}
                                />
                            </div>
                            {validationError?.emailError && email?.length < 1 && (
                                <p className="text-xs text-right text-red-500 error-msg mt-0">
                                    *Email is required
                                </p>
                            )}{" "}
                            {validationError?.emailFormatError &&
                                email?.trim()?.length > 0 && (
                                    <p className="text-xs text-right text-red-500 error-msg mt-0">
                                        *Enter valid email address
                                    </p>
                                )}
                            <div className="flex flex-col justify-start items-start md:flex-row gap-2">
                                {/* <label
                                    htmlFor="message"
                                    className="text-sm font-medium text-gray-900 min-w-[165px] "
                                    >
                                    Leave your message*
                                    </label> */}
                                <textarea
                                    type="text"
                                    name="user-message"
                                    id="message"
                                    rows="5"
                                    placeholder="Leave your message"
                                    value={message || ""}
                                    onChange={(e) => setMessage(e.target.value)}
                                    autoComplete="given-name"
                                    className={`${validationError?.messageError && message?.trim()?.length < 1
                                        ? "border-red-300 focus:border-red-300"
                                        : "border-gray-300 focus:border-primary"
                                        } mt-2 w-full px-4 py-3 flex justify-center items-center shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 placeholder:font-bold placeholder:text-[#bbb] placeholder:text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm `}
                                />
                            </div>
                            {validationError?.messageError && message?.trim()?.length < 1 && (
                                <p className="text-xs text-right text-red-500 error-msg mt-0">
                                    *Message is required
                                </p>
                            )}{" "}
                            <div className="block w-full">
                                <Files
                                    className={`border-gray-300 mt-2 w-full px-4 py-3 shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 font-bold text-[#bbb] text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm`}
                                    onChange={(e) => doc1ChangedHandler(e)}
                                    // onError={console.log("first")}
                                    accepts={["image/*", ".pdf", ".doc", ".docx"]}
                                    multiple={false}
                                    maxFileSize={2 * 1024 * 1024}
                                    minFileSize={0}
                                    clickable
                                >
                                    Drop files here or click to upload
                                </Files>
                                {files && preview({ value: files, set: "files" })}
                            </div>
                            {docsSize?.trim()?.length > 1 && (
                                <p className="text-xs text-right text-red-500 error-msg mt-0">
                                {docsSize?.trim()}
                                </p>
                            )}
                            {RECAPTCHA_SITE_KEY &&<ReCAPTCHA
                                sitekey={RECAPTCHA_SITE_KEY?RECAPTCHA_SITE_KEY:undefined}
                                ref={captchaRef}
                                className="flex flex-col justify-center items-center md:flex-row gap-2"
                            />}
                            {validationError?.captchaError ? <p className="text-xs text-center text-red-500 error-msg mt-0">
                                Invalid Captcha
                            </p> : ""}
                            <div className="flex flex-col justify-center items-center md:flex-row gap-2">
                                <button
                                    type="button"
                                    onClick={handleSubmit}
                                    className="flex items-center justify-center border w-1/2 h-10 border-transparent rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white hover:bg-vspurple hover:border-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                                >
                                    <span>Submit</span>
                                </button>
                            </div>
                            {successMessage ? (
                                <div className="text-green-600 success-message mt-2 mb-2">
                                    {successMessage}
                                </div>
                            ) : null}
                            {errorMessage ? (
                                <div className="text-red-600 error-message mt-2 mb-2">
                                    {errorMessage}
                                </div>
                            ) : null}
                        </div>
                    </div>
                )}
                {detailsConfirmModel ? (
                    <ConfirmationModal
                        openModel={detailsConfirmModel}
                        setIsOpenModel={setDetailsConfirmModel}
                        callFunction={callback}
                        content={"Are you sure to submit the form?"}
                    />
                ) : null}
            </CustomerLayout>
        </>
    );
}

export default SupportRequest;
