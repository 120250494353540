import React from "react";
import { useNavigate } from "react-router-dom";
import { directus } from "../../services/directus";
import { toast } from "react-toastify";
import Select from "react-select";
import ConfirmationModal from "../popupModals/ConfirmationModal";
import Pagination from "../Pagination";
import { userPresent } from "../../utils/CommonFunctions";

function ManageComponent({
  allUsers,
  userType,
  pagination,
  page,
  setPage,
  companySlug,
  branchSlug,
  selected,
  setSelected,
  userStatus,
  setUserStatus,
  details,
}) {
  const navigate = useNavigate();
  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
  const [messageConfirmModel, setMessageConfirmModel] = React.useState(false);
  const [activeMessage, setActiveMessage] = React.useState("");

  const paginationCount = 10;
  const userList = [];
  const selectHandle = (props) => {
    return selected.includes(props)
      ? setSelected(selected.filter((word) => word !== props))
      : setSelected([...selected, props]);
  };

  for (let i = 0; i < allUsers?.length; i++) {
    if (i < 10) {
      userList.push(
        <tr className="hover:bg-gray-50" key={allUsers[i]?.id}>
          <td className="px-5 py-3 text-left font-semibold text-gray-900 remove-col">
            <input
              type="checkbox"
              title="single-record"
              // value={data[i]?.id}
              checked={
                !selected.includes(allUsers[i]?.id) === true ? false : true
              }
              onClick={() => selectHandle(allUsers[i]?.id)}
              onChange={(e) => {}}
              name="chk"
              className=" h-4 w-4 rounded border-gray-300 sm:left-6 text-primary"
            />
          </td>
          <td
            className="px-5 py-3 text-lg font-medium text-gray-900"
            data-th="Name"
          >
            <span className="manager-name">{allUsers[i]?.first_name}</span>
          </td>
          <td
            className="whitespace-nowrap px-5 py-3 text-lg text-gray-500"
            data-th="Phone Number"
          >
            {allUsers[i]?.contact_number ? allUsers[i]?.contact_number : "---"}
          </td>
          <td
            className="whitespace-nowrap px-5 py-3 text-right text-lg text-gray-500 cursor-pointer"
            data-th="Edit"
            onClick={() =>
              navigate(`/${companySlug}/${branchSlug}/${userType}/edit`, {
                state: allUsers[i]?.id,
              })
            }
          >
            <span className="inline-block f-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
              >
               <title>Edit</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            </span>
          </td>
        </tr>
      );
    }
  }
  const handleNavigation = (e) => {
    setPage(e);
    setSelected([]);
    return "";
  };
  const numPages =
    pagination % paginationCount === 0
      ? Math.floor(pagination / paginationCount)
      : Math.floor(pagination / paginationCount) + 1;

  const updateStatus = userStatus?.status === "active" ? "In Active" : "Active";
  const deActive = async (props) => {
    const updateFields = {
      status: userStatus?.status === "active" ? "archived" : "active",
      in_out_flag: false,
    };
    if (selected?.length) {
      await directus.users
        .updateMany(selected, updateFields)
        .then((res) => {
          // navigate(`/${companySlug}/${branchSlug}/${userType}/manage`);
          setDetailsConfirmModel(false);
          window.location.reload();
          toast.success(" Updated successfully");
        })
        .catch((err) => {
          navigate(".", { replace: true });
          console.log(err);
          toast.error(err?.message);
        });
      setSelected([]);
    }
  };
  const selectOption = [
    { id: 1, status: "active", name: "Active" },
    { id: 1, status: "archived", name: "In Active" },
  ];
  const styles1 = {
    menuList: (base) => ({
      ...base,
      height: "110px",

      "::-webkit-scrollbar": {
        width: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "#2684FF",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    control: (base, state) => ({
      ...base,
      cursor: "pointer",
      border: "none",
      marginTop: "4px",
    }),
  };
  const userStatushandle = (e) => {
    setSelected([]);
    setUserStatus(e);
  };
  const activeChange = async () => {
    const LoggedCheck = await userPresent({ details: details });
    if (LoggedCheck?.status) {
      if (selected?.length) {
        await directus.users
          .readByQuery({
            fields: ["first_name","contact_number","Pickup_request_count"],
            filter: {
              id: { _in: selected },
            },
            deep: {
              Pickup_request_count: {
                _filter: { status_id: { sequence: { _neq: 6 } } },
              },
            },
          })
          .then((e) => {
            const userName = [];
            e?.data?.map((e) => {
              if (e?.Pickup_request_count?.length > 0) {
                userName.push({
                  name: e?.first_name,
                  mobile: e?.contact_number,
                });
              }
              return e?.Pickup_request_count?.length > 0;
            });
            if (userName?.length > 0) {
              setActiveMessage(`
            The  ${userName?.length === 1 ? "user" : "users"} ${userName?.map(
                (e) => ` <strong key={i}>${e?.name}(${e?.mobile})</strong>`
              )} have some assigned vehicles, so now not able to in-active the following users, Kindly unassign the vehicle from the users and try again.`);
              setMessageConfirmModel(!messageConfirmModel);
            } else {
              setDetailsConfirmModel(!detailsConfirmModel);
            }
          })
          .catch((e) => console.log(e));
      }
    } else {
      toast.error(
        "Your account was suspended, kindly contact the respective manager/admin."
      );
    }
  };
  return (
    <div className="main-content-area px-6 max-sm:px-5 pt-0">
      <div className="py-8 flex flex-col">
        <div className="">
          <div className="inline-block min-w-full align-middle">
            <div className="pb-4 flex justify-end gap-4 items-center">
              {selected?.length > 0 && (
                <button
                  className="w-full inline-flex items-center justify-center text-sm font-medium rounded-md border p-2 border-primary text-primary focus:outline-none  sm:w-auto left-4 top-[62px]"
                  type="button"
                  onClick={() => activeChange()}
                >
                  Change Status
                </button>
              )}
              <button
                className="user-select-all w-full inline-flex items-center justify-center text-sm font-medium border p-2 border-primary text-primary focus:outline-none  sm:w-auto left-4 top-[62px]"
                type="button"
                onClick={() =>
                  setSelected(
                    selected?.length === allUsers?.length
                      ? []
                      : allUsers?.map((e) => e?.id)
                  )
                }
              >
                Mark All
              </button>
              <Select
                id="Users"
                classNamePrefix="react-select"
                className=" react-select-container border border-primary-500  text-gray-900 rounded-md text-sm min-h-[42px] min-w-[120px] capitalize"
                defaultMenuIsOpen={false}
                maxMenuHeight={250}
                onChange={(e) => userStatushandle(e)}
                options={selectOption}
                isSearchable={false}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                value={userStatus}
                styles={{
                  styles1,
                  placeholder: (base) => ({
                    ...base,
                    fontWeight: "bold",
                    color: "#9CA3AF",
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    border: "none",
                    boxShadow: "none",
                  }),
                  input: (base, state) => ({
                    ...base,
                    '[type="text"]': {
                      border: "none",
                      boxShadow: "none",
                    },
                  }),
                }}
              />
            </div>
            <div
              className={`parking-status-table relative border rounded-md border-gray-200 overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 md:rounded-lg ${
                allUsers?.length <= 0 ? "bordered" : "no-border"
              }`}
            >
              {allUsers?.length ? (
                <table className="min-w-full divide-y  divide-gray-300 manager-list-table">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-5 py-3 text-left font-semibold text-gray-900"
                      >
                        <input
                          type="checkbox"
                          title="single-record"
                          checked={selected?.length === allUsers?.length}
                          onChange={(e) => {
                            setSelected(
                              selected?.length === allUsers?.length
                                ? []
                                : allUsers?.map((e) => e?.id)
                            );
                          }}
                          name="chk"
                          className=" h-4 w-4 rounded border-gray-300 sm:left-6 text-primary"
                        />
                      </th>
                      <th
                        scope="col"
                        className="min-w-[12rem] px-5 py-3 text-left text-lg font-semibold text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-5 py-3 text-left text-lg font-semibold text-gray-900"
                      >
                        Phone Number
                      </th>
                      <th
                        scope="col"
                        className="px-5 py-3 text-right text-lg font-semibold text-gray-900"
                      >
                        Edit
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {userList}
                  </tbody>
                </table>
              ) : (
                <div className="whitespace-nowrap text-sm p-4 text-gray-500 flex justify-center">
                  {" "}
                  No data found
                </div>
              )}
            </div>
            {/* pagination */}
            {numPages > 1 ? (
              <Pagination
                numPages={numPages}
                data={allUsers}
                page={page}
                handleNavigation={handleNavigation}
              />
            ) : null}
          </div>
        </div>
      </div>
      {detailsConfirmModel ? (
        <ConfirmationModal
          openModel={detailsConfirmModel}
          setIsOpenModel={setDetailsConfirmModel}
          callFunction={deActive}
          content={` Are you sure to update given ${
            selected?.length === 1 ? "user" : "user's"
          } to ${updateStatus} state?`}
        />
      ) : //
      null}
      {messageConfirmModel ? (
        <ConfirmationModal
          openModel={messageConfirmModel}
          setIsOpenModel={setMessageConfirmModel}
          callFunction={() => setSelected([])}
          content={activeMessage}
          cancelBtn={true}
        />
      ) : //
      null}
    </div>
  );
}

export default ManageComponent;
