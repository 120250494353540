import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import UserContext from "../contexts/userContext";

function Seo(prop) {
  const { companyDetails } = React.useContext(UserContext);
  const TitleValue =
    (prop?.company === true && (companyDetails?.companyName !== undefined))
      ? `${prop?.title} | ${companyDetails?.companyName} - ${companyDetails?.branchName}`
      : prop?.title;
  return (
    <HelmetProvider>
      <Helmet>
        <title>{TitleValue}</title>
      </Helmet>
    </HelmetProvider>
  );
}

export default Seo;
