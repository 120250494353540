/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import CustomerLayout from "../../components/CustomerLayout";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import { toast } from "react-toastify";
import { EMAIL_SENDER_ADDRESS, MAIL_API_BASE_URL, RECAPTCHA_SITE_KEY } from "../../utils/utils";
import Email_template from "../../utils/Email_template";
import ReCAPTCHA from "react-google-recaptcha"
import seoData from '../../seoContent.json';
import UserContext from "../../contexts/userContext";

function BusinessAffiliation() {
  const captchaRef = useRef(null)
  const [loading, setLoading] = React.useState(true);
  const [name, setName] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
  const [validationError, setValidationError] = React.useState({
    phoneNoError: false,
    nameError: false,
    emailError: false,
    messageError: false,
    lenPhoneNoError: false,
    emailFormatError: false,
    captchaError:false
  });
  const [mailInfo,setMailInfo]=React.useState('')
  const { commonInfo } = React.useContext(UserContext);
  
  React.useEffect(() => {
    setMailInfo(commonInfo)
  },[commonInfo])
  React.useEffect(() => {
    const maxLength = 10;
    if (phoneNo?.length > maxLength) {
      setPhoneNo(phoneNo.substring(0, maxLength));
    }
  }, [phoneNo?.length]);
  React.useEffect(() => {
    var {
      emailError,
      messageError,
      phoneNoError,
      nameError,
      lenPhoneNoError,
      emailFormatError,
      captchaError
    } = validationError;
    if (!!name && !!email && !!phoneNo && !!message) {
      if (
        !(
          emailError ||
          phoneNoError ||
          messageError ||
          nameError ||
          lenPhoneNoError ||
          emailFormatError ||
          captchaError
        )
      ) {
        setDetailsConfirmModel(true);
      } else {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);
  React.useEffect(() => {
    setLoading(false);
  }, []);
  async function fieldValidation() {
    if (!!!name?.trim()) {
      updateValidationError("nameError", true);
    }
    if (name?.trim()) {
      updateValidationError("nameError", false);
    }
    if (!!!phoneNo?.trim()) {
      updateValidationError("phoneNoError", true);
    }
    if (phoneNo?.trim()?.length < 10 && phoneNo?.trim()?.length >= 1) {
      updateValidationError("phoneNoError", false);
      updateValidationError("lenPhoneNoError", true);
    }
    if (phoneNo?.trim()?.length === 10) {
      updateValidationError("phoneNoError", false);
      updateValidationError("lenPhoneNoError", false);
    }
    if (!!!email?.trim()?.length > 0) {
      updateValidationError("emailError", true);
    }
    if (
      !!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&
      email?.trim()?.length >= 1
    ) {
      updateValidationError("emailError", false);
      updateValidationError("emailFormatError", true);
    }
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      email?.trim()?.length > 0 && updateValidationError("emailError", false);
      updateValidationError("emailFormatError", false);
    }
    if (!message) updateValidationError("messageError", true);
    if (message?.trim()) updateValidationError("messageError", false);

    const token = captchaRef?.current?.getValue();
    if(!token){
      updateValidationError("captchaError", true);
    }
    else{
      updateValidationError("captchaError", false);
    }
    captchaRef?.current?.reset();

    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }

  const MailFetcher = async ({ msg }) => {
    await fetch(MAIL_API_BASE_URL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(msg),
    })
      .then((e) => {
        setSuccessMessage(
          "Your message has been forwarded to the customer support team, who will contact you shortly. Thank you!"
        );
        setErrorMessage("");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      })
      .catch((e) => {
        setErrorMessage("Something went wrong. Try again later!");
        setSuccessMessage("");
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });
    setName("");
    setPhoneNo("");
    setEmail("");
    setMessage("");
    setDetailsConfirmModel(false);
  };

  const handleSubmit = async () => {
    await fieldValidation();
  };

  const callback = async () => {
    captchaRef.current.reset();
    console.log("called callback");
    const AdminMailList = mailInfo

    AdminMailList&&AdminMailList?.contact_email_addresses?.split(",")?.map(async (e) => {
      let template = Email_template({
        affilation: true,
        siteLogo: AdminMailList?.site_logo,
        name: name,
        phoneNo: phoneNo,
        email: email,
        message: message,
      });
      var msg = {
        to: e,
        from: EMAIL_SENDER_ADDRESS,
        subject: `Received business affiliation from ${name}.`,
        name: "ValetSearch",
        html: template,
      };
      return await MailFetcher({ msg });
    });
  };

  return (
    <>
      <Seo title={seoData?.business_affiliation?.title} />
      <CustomerLayout head={true}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="p-6 lg:p-8 flex justify-center flex-col text-black-700 w-1/2 max-[767px]:w-9/12	max-[580px]:w-full">
            <h1 className="text-left text-[32px] font-bold text-black-800 mb-6">
              For Business Affiliation
            </h1>
            <span>Join our digital Valet Parking Search Network and elevate your parking experience. Register now for seamless, tech-driven affiliation.</span>
            <div className="space-y-6 bg-white py-6">
              <div className="flex flex-col justify-start items-start md:flex-row gap-2">
                <label
                  htmlFor="name-1"
                  className="hidden"
                >
                  Name*
                </label>
                <input
                  type="text"
                  name="user-name"
                  id="name-1"
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="given-name"
                  placeholder="Name"
                  className={`${validationError?.nameError && name?.trim()?.length < 1
                      ? "border-red-300 focus:border-red-300"
                      : "border-gray-300 focus:border-primary"
                    } mt-2 w-full px-4 py-3 flex justify-center items-center shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 placeholder:font-bold placeholder:text-[#bbb] placeholder:text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm `}
                />
              </div>
              {validationError?.nameError && name?.trim()?.length < 1 && (
                <p className="text-xs text-right text-red-500 error-msg mt-0">
                  *Name is required
                </p>
              )}{" "}
              <div className="flex flex-col justify-start items-start md:flex-row gap-2">
                <label
                  htmlFor="phone-number"
                  className="hidden"
                >
                  Phone Number*
                </label>
                <input
                  type="text"
                  name="user-phone-number"
                  id="phone-number"
                  value={phoneNo || ""}
                  pattern="[0-9]*" 
                  inputMode="numeric"
                  placeholder="Phone number"
                  onChange={(e) =>
                    /^\d*\.?\d*$/.test(e.target.value) &&
                    setPhoneNo(e.target.value)
                  }
                  autoComplete="given-name"
                  className={`${(validationError?.phoneNoError && phoneNo?.length < 1) ||
                      validationError?.lenPhoneNoError
                      ? "border-red-300 focus:border-red-300"
                      : "border-gray-300 focus:border-primary"
                    } mt-2 w-full px-4 py-3 flex justify-center items-center shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 placeholder:font-bold placeholder:text-[#bbb] placeholder:text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm `}
                />
              </div>
              {validationError?.phoneNoError && phoneNo?.trim()?.length < 1 && (
                <p className="text-xs text-right text-red-500 error-msg mt-0">
                  *Phone number is required
                </p>
              )}{" "}
              {((validationError?.phoneNoError === false &&
                validationError?.lenPhoneNoError) ||
                (phoneNo?.trim()?.length > 0 &&
                  phoneNo?.trim()?.length < 10)) && (
                  <p className="text-xs text-right text-red-500 error-msg mt-0">
                    *Enter valid phone Number
                  </p>
                )}
              <div className="flex flex-col justify-start items-start md:flex-row gap-2">
                <label
                  htmlFor="email"
                  className="hidden"
                >
                  Email*
                </label>
                <input
                  type="text"
                  name="user-email"
                  id="email"
                  value={email || ""}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  autoComplete="given-name"
                  className={`${(validationError?.emailError && email?.length < 1) ||
                      validationError?.emailFormatError
                      ? "border-red-300 focus:border-red-300"
                      : "border-gray-300 focus:border-primary"
                    } mt-2 w-full px-4 py-3 flex justify-center items-center shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 placeholder:font-bold placeholder:text-[#bbb] placeholder:text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm `}
                />
              </div>
              {validationError?.emailError && email?.length < 1 && (
                <p className="text-xs text-right text-red-500 error-msg mt-0">
                  *Email is required
                </p>
              )}{" "}
              {validationError?.emailFormatError &&
                email?.trim()?.length > 0 && (
                  <p className="text-xs text-right text-red-500 error-msg mt-0">
                    *Enter valid email address
                  </p>
                )}
              <div className="flex flex-col justify-start items-start md:flex-row gap-2">
                {/* <label
                  htmlFor="message"
                  className="text-sm font-medium text-gray-900 min-w-[165px] "
                >
                  Leave your message*
                </label> */}
                <textarea
                  type="text"
                  name="user-message"
                  id="message"
                  rows="5"
                  placeholder="Leave your message"
                  value={message || ""}
                  onChange={(e) => setMessage(e.target.value)}
                  autoComplete="given-name"
                  className={`${validationError?.messageError && message?.trim()?.length < 1
                      ? "border-red-300 focus:border-red-300"
                      : "border-gray-300 focus:border-primary"
                    } mt-2 w-full px-4 py-3 flex justify-center items-center shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 placeholder:font-bold placeholder:text-[#bbb] placeholder:text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm `}
                />
              </div>
              {validationError?.messageError && message?.trim()?.length < 1 && (
                <p className="text-xs text-right text-red-500 error-msg mt-0">
                  *Message is required
                </p>
              )}{" "}
              {RECAPTCHA_SITE_KEY &&<ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY?RECAPTCHA_SITE_KEY:undefined}
                ref={captchaRef}
                className="flex flex-col justify-center items-center md:flex-row gap-2"
              />}
              {validationError?.captchaError ?  <p className="text-xs text-center text-red-500 error-msg mt-0">
                  Invalid Captcha
                </p>:""}
              <div className="flex flex-col justify-center items-center md:flex-row gap-2">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="flex items-center justify-center border w-1/2 h-10 border-transparent rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white hover:bg-vspurple hover:border-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                >
                  <span>Submit</span>
                </button>
              </div>
              {successMessage ? (
                <div className="text-green-600 success-message mt-2 mb-2">
                  {successMessage}
                </div>
              ) : null}
              {errorMessage ? (
                <div className="text-red-600 error-message mt-2 mb-2">
                  {errorMessage}
                </div>
              ) : null}
            </div>
          </div>
        )}
        {detailsConfirmModel ? (
          <ConfirmationModal
            openModel={detailsConfirmModel}
            setIsOpenModel={setDetailsConfirmModel}
            callFunction={callback}
            content={"Are you sure to submit the form?"}
          />
        ) : null}
      </CustomerLayout>
    </>
  );
}

export default BusinessAffiliation;
