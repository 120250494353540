import React from "react";
import "../assets/spinner.css";

export default function LoadingSpinner(prop) {
  return (
    <div className={`spinner-container spinner-loader p-4 ${prop?.chatLoader === true ? "chat-loader" : ""} `}>
      <div className="loading-spinner"></div>
      <span>Loading.....</span>
    </div>
  );
}
