export const MANAGER_ROLE_ID = process.env.REACT_APP_MANAGER_ROLE_ID;
export const STAFF_ROLE_ID = process.env.REACT_APP_STAFF_ROLE_ID;
export const BACKEND_URL = process.env.REACT_APP_DIRECTUS_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const EMAIL_SENDER_ADDRESS = process.env.REACT_APP_EMAIL_SENDER_ADDRESS;
export const MAIL_API_BASE_URL = process.env.REACT_APP_MAIL_API_BASE_URL;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_SITE_KEY;
export const RECAPTCHA_SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const TRANS_MAIL_API_BASE_URL = process.env.REACT_APP_TRANS_MAIL_API_BASE_URL;
export const API_TOKEN = process.env.REACT_APP_API_TOKEN;
export const CHAT_IP = process.env.REACT_APP_CHAT_IP;
export const PORT = process.env.REACT_APP_PORT;
export const PAY_API_BASE_URL = process.env.REACT_APP_PAY_API_BASE_URL;
export const PAY_STATUS_API_BASE_URL = process.env.REACT_APP_PAY_STATUS_API_BASE_URL;
export const PHONEPE_MERCHANT_ID = process.env.REACT_APP_PHONEPE_MERCHANT_ID;
export const PHONEPE_MERCHANT_KEY = process.env.REACT_APP_PHONEPE_MERCHANT_SALT_KEY;
export const PAY_API_ENPOINT = process.env.REACT_APP_PAY_API_ENPOINT;
export const STATUS_API_ENPOINT = process.env.REACT_APP_STATUS_API_ENPOINT;
export const STAFF_USER_PASSWORD = process.env.REACT_APP_STAFF_USER_PASSWORD
export const EMAIL_DOMAIN = process.env.REACT_APP_SITE_EMAIL