import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { FRONTEND_URL } from "../utils/utils";
function SocialShare() {
  const shareUrl = window.location.href ?? FRONTEND_URL;
  const title = "" ?? "ValetSearch";

  return (
    <div className="flex gap-2 items-center justify-center">
      <div className="Demo__some-network flex font-bold">Share</div>
      <div className="Demo__some-network flex">
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className="Demo__some-network__share-button bg-black"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>
      <div className="Demo__some-network flex">
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
        >
          {/* <TwitterIcon size={32} round /> */}
          <svg viewBox="0 0 64 64" width="32" height="32">
            <title>Twitter</title>
            <circle cx="32" cy="32" r="31" fill="#000000"></circle>
            <path
              d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z"
              fill="white"
            ></path>
          </svg>
        </TwitterShareButton>
      </div>
      <div className="Demo__some-network flex">
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="Demo__some-network__share-button"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
      <div className="Demo__some-network flex">
        <LinkedinShareButton
          url={shareUrl}
          className="Demo__some-network__share-button"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
    </div>
  );
}

export default SocialShare;
