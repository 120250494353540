import React from "react";
import Modal from "react-modal";
import UserContext from "../../contexts/userContext";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import closeIcon from "../../assets/images/closeIcon.png";
import { directus } from "../../services/directus";
import Select from "react-select";

function AssignAndConfirmModal(props) {
  const {
    setIsOpenModel,
    vehicleData,
    allUsers,
    assignHandle,
    setAssignedStaff,
    statusUpdateHandle,
    recordCashPayment
  } = props;
  const [modalIsOpen, setIsOpen] = React.useState(
    setIsOpenModel ? true : false
  );
  const [editModalIsOpen, setEditIsOpen] = React.useState(false);
  const [editPaymentConfirmModalIsOpen, setEditPaymentConfirmIsOpen] = React.useState(false);
  const [assignModalIsOpen, setAssignIsOpen] = React.useState(false);
  const { details, companyDetails } = React.useContext(UserContext);
  const navigate = useNavigate();
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 24, 27, 0.4)",
      zIndex: 9,
    },
    content: {
      top: "50%",
      left: "50%",
      display: "flex",
      justifySelf: "center",
      right: "auto",
      background: "transparant",
      elevation: 0,
      border: "none",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
    },
  };

  const styles = {
    menuList: (base) => ({
      ...base,
      height: "110px",

      "::-webkit-scrollbar": {
        width: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "#2684FF",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };
  
  const closeModal = () => {
    setIsOpenModel(false);
    setIsOpen(false);
  };

  const closeModal1 = ()=>{
    setAssignIsOpen(false)
  }
  
  const vehicleCurrentStatuscheck = async () => {
    var val = false;
    await directus
      .items("Valet_Information")
      .readByQuery({
        fields: ["id","status_id.sequence"],
        filter: {
          id: { _eq: vehicleData?.id },
          company: { _eq: company },
          branch: { _eq: branch },
        },
      })
      .then((res) => {
        val =
          res?.data[0]?.status_id?.sequence === vehicleData?.status_id?.sequence
            ? true
            : false;
      })
      .catch((e) => {
        console.log(e);
      });
    return val;
  };
  const staffCheckHandle = async () => {
    var val = false;
    await directus
      .items("Valet_Information")
      .readByQuery({
        fields: ["id","Assigned_Staff.first_name"],
        filter: {
          OTP_Value: { _eq: vehicleData?.OTP_Value },
          company: { _eq: company },
          branch: { _eq: branch },
        },
      })
      .then((res) => {
        val =
          res?.data[0]?.Assigned_Staff?.first_name === details?.first_name
            ? true
            : false;
      })
      .catch((e) => {
        console.log(e);
      });
    return val;
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => closeModal()}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Example Modal"
    >
      <>
        <div className="vehicle-status-popup relative transform overflow-hidden max-w-[540px] w-full rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:mx-5 sm:max-w-lg border border-slate-400 ... ">
          <div className="bg-white p-6 relative">
            <div className="close-div">
              <button
                onClick={() => closeModal()}
                className="float-right w-8 h-8 "
              >
                <img src={closeIcon} alt="close-icon" />
              </button>
            </div>
            <div className="pb-8">
              <h3 className="leading-none text-lg font-semibold text-secondary flex justify-start items-center gap-4">
                <span>
                  <svg width="35" height="35" x="0" y="0" viewBox="0 0 436 436">
                    <title>Valet detail</title>
                    <g>
                      <g>
                        <g>
                          <path
                            d="M412.5,280.457l-34.6-15.7l-9.9-16c38.6-24.4,62-66.8,62-112.5v-65.7c-0.1-3.7-2.2-7.1-5.5-8.8l-88.9-47.3
                                                                c-3-1.6-6.6-1.6-9.5,0.1l-86.1,47.2c-3.2,1.8-5.1,5.1-5,8.8v67c-0.1,19,3.8,37.9,11.6,55.3h-72.1c-15.5,0-30.2,7.3-39.7,19.6
                                                                l-39.3,51.5l-66,18c-17.4,4.7-29.4,20.5-29.5,38.5v25.2c0.1,22.1,18.1,40,40.2,40.2H45c7.4,26.8,35.2,42.5,62,35
                                                                c17-4.7,30.3-18,35-35h147c7.4,26.8,35.2,42.5,62,35c17-4.7,30.3-18,35-35h9.8c22.1-0.1,40.1-18,40.2-40.2v-28.9
                                                                C435.9,301.057,426.7,286.957,412.5,280.457z M255,76.457l76.3-41.9l78.7,42v59.7c-0.1,45.7-27.5,86.8-69.6,104.5c0,0-0.1,0-0.2,0
                                                                l-9.6,4.1l-7.8-3.5c-41.2-18.1-67.8-58.8-67.8-103.9V76.457z M93.5,402.357c-16.8,0-30.3-13.6-30.3-30.3
                                                                c0-16.8,13.6-30.3,30.3-30.3c16.8,0,30.3,13.6,30.3,30.3S110.2,402.257,93.5,402.357z M337.5,402.357c-16.8,0-30.3-13.6-30.3-30.3
                                                                s13.6-30.3,30.3-30.3c16.8,0,30.3,13.6,30.3,30.3C367.9,388.757,354.3,402.257,337.5,402.357z M416.1,345.557H416
                                                                c-0.1,11.1-9.1,20.1-20.2,20.2h-8.4c-3.6-27.6-28.8-47-56.4-43.4c-22.6,2.9-40.5,20.8-43.4,43.4H143.4
                                                                c-3.6-27.6-28.8-47-56.4-43.4c-22.6,2.9-40.5,20.8-43.4,43.4h-3.4c-11.1-0.1-20.1-9.1-20.2-20.2v-25.2c0.1-9,6.1-16.8,14.8-19.2
                                                                l69.2-18.9c2.1-0.6,4-1.8,5.4-3.6l41.4-54.2c5.7-7.4,14.5-11.7,23.8-11.7H258c14.2,20.6,33.9,36.9,56.8,47l12,5.2
                                                                c2.5,1.1,5.4,1.1,8,0l13.5-5.8c0.7-0.3,1.4-0.6,2-0.9l12.3,19.7c1,1.7,2.5,3,4.3,3.8l37.4,16.9c7.1,3.2,11.7,10.3,11.8,18.1
                                                                V345.557z"
                            fill="currentColor"
                            data-original="currentColor"
                            className=""
                          ></path>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M380.6,114.257c-3.5-4.3-9.7-5-14.1-1.6l-0.1,0.1l-46.8,38.1l-17-19.9c-3.6-4.2-9.9-4.8-14.1-1.2
                                                                c-4.2,3.6-4.8,9.9-1.2,14.1l0.1,0.1l23.3,27.3c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.3,0.3,0.4,0.4
                                                                c0.1,0.1,0.3,0.2,0.4,0.3s0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.3,0.2,0.4,0.2
                                                                c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.4,0.1,0.6,0.1
                                                                c0.1,0,0.3,0.1,0.4,0.1c0.3,0,0.5,0,0.8,0c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0,0.9,0c0.1,0,0.1,0,0.2,0c0.2,0,0.5-0.1,0.7-0.1
                                                                c0.1,0,0.2,0,0.3-0.1c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3-0.1,0.5-0.2c0.1,0,0.2-0.1,0.4-0.1
                                                                c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.2,0.4-0.2s0.2-0.2,0.4-0.2
                                                                c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.1,0.3-0.2l54.3-44.2C383.3,124.857,384,118.657,380.6,114.257z"
                            fill="currentColor"
                            data-original="currentColor"
                            className=""
                          ></path>
                        </g>
                      </g>
                      <g></g> <g> </g> <g> </g> <g> </g> <g></g>
                      <g> </g> <g> </g>
                      <g></g>
                      <g> </g>
                      <g></g>
                      <g> </g> <g> </g>
                      <g></g>
                      <g> </g> <g></g>
                    </g>
                  </svg>
                </span>
                <span className="inline-block relative top-[2px]">
                  {vehicleData?.Vehicle_Number}
                </span>
                <span className="inline-block relative top-[2px]">
                  {vehicleData?.status_id?.name}
                </span>
              </h3>
            </div>
            {vehicleData?.Customer_Name?.trim() && <div className="grid grid-cols-2 mb-2">
              <div
                htmlFor="user-name"
                className="block text-gray-500 font-medium text-sm"
              >
                Customer Name:
              </div>
              <div className="field text-sm font-semibold break-words">
                {vehicleData?.Customer_Name}
              </div>
            </div>}
            {vehicleData?.Customer_Phone_Number && (
              <div className="grid grid-cols-2 mb-2">
                <div
                  htmlFor="user-name"
                  className="block text-gray-500 font-medium text-sm"
                >
                  Phone No:{" "}
                </div>
                <div className="field text-sm font-semibold">
                  {vehicleData?.Customer_Phone_Number}
                </div>
              </div>
            )}
            <div className="grid grid-cols-2 mb-2">
              <div
                htmlFor="user-name"
                className="block text-gray-500 font-medium text-sm"
              >
                Token Id:{" "}
              </div>
              <div className="field text-sm font-semibold">
                {vehicleData?.id}
              </div>
            </div>
            {vehicleData?.Assigned_Staff && (
              <div className="grid grid-cols-2 mb-2">
                <div
                  htmlFor="user-name"
                  className="block text-gray-500 font-medium text-sm"
                >
                  Assigned Staff:{" "}
                </div>
                <div className="field text-sm font-semibold break-words">
                  {vehicleData?.Assigned_Staff?.first_name}
                </div>
              </div>
            )}
            {Number(vehicleData?.branch?.valet_fee) > 0 && (
              <div className="grid grid-cols-2 mb-2">
                <div
                  htmlFor="user-name"
                  className="block text-gray-500 font-medium text-sm"
                >
                  Payment Status:
                </div>
                <div className={`field text-sm font-semibold break-words ${vehicleData?.payment_status === 'paid' ? "text-green-600" : "text-red-600"}`}>
                  {vehicleData?.payment_status === 'paid' ? "Paid" : "Not paid"}
                </div>
              </div>
            )}
            <div className="mt-6">
              <div className="text-gray-500 font-medium text-sm w-full"> <Link to={`/${companyDetails?.company}/${companyDetails?.branch}/valet-detail?id=${vehicleData?.id}`}
                className=" inline-block text-primary"
              >
                Click here to see Valet Details
              </Link>
              </div>
            </div>

            {props?.show !== true && <div
              className={`${vehicleData?.status_id?.sequence === 1 &&
                (vehicleData?.status_id?.sequence === 1 ||
                  vehicleData?.status_id?.sequence === 3) &&
                !details?.is_staff_user &&
                vehicleData?.status_id?.sequence !== 6
                ? "justify-center flex mt-6 received-btns items-center gap-3"
                : "flex mt-6 justify-center gap-3 items-center"
                }`}
            // className='justify-center flex mt-4 received-btns items-center'
            >
              {vehicleData?.status_id?.sequence === 1 ? (
                <div
                  onClick={() =>
                    navigate(
                      `/${companyDetails?.company}/${companyDetails?.branch}/valet-edit`,
                      { state: vehicleData }
                    )
                  }
                  title="Valet Edit"
                  className="inline-btn min-w-[26px] min-h-[26px] inline-flex items-center justify-center rounded-sm border border-transparent text-sm font-medium text-white focus:outline-none sm:w-auto valet-edit-btn"
                >
                  <button
                    type="button"
                    className="inline-flex font-semibold w-full justify-center  border border-transparent bg-primary rounded-md px-4 py-2 text-sm text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto sm:text-sm"
                  >
                    Edit
                  </button>
                </div>
              ) : (
                ""
              )}
              {(vehicleData?.status_id?.sequence === 1 ||
                vehicleData?.status_id?.sequence === 3) &&
                !details?.is_staff_user ? (
                <div
                  title="Valet Assign"
                  className=" min-w-[26px] min-h-[26px] inline-flex items-center justify-center rounded-sm border border-transparent text-sm font-medium text-white focus:outline-none sm:w-auto valet-asign-btn"
                >
                  <button
                    type="button"
                    onClick={() => setAssignIsOpen(true)}
                    className="inline-flex font-semibold w-full justify-center  border border-transparent bg-green-600 px-4 py-2 text-sm text-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 sm:w-auto sm:text-sm"
                  >
                    Assign
                  </button>
                </div>
              ) : (
                <div></div>
              )}
              <div className="update inline-btn valet-update-btn">
                {vehicleData?.status_id?.sequence !== 6 &&
                  (details?.is_staff_user === true
                    ? details?.in_out_flag === true
                      ? true
                      : false
                    : true) && (
                    <button
                      type="button"
                      onClick={async () =>
                        (await vehicleCurrentStatuscheck())
                          ? vehicleData?.Assigned_Staff
                            ? vehicleData?.Assigned_Staff?.first_name ===
                              details?.first_name
                              ? details?.in_out_flag === true
                                ? (await staffCheckHandle()) === true
                                  ? setEditIsOpen(true)
                                  : toast.error(
                                    "The vehicle is assigned to someother")
                                : details?.is_staff_user !== true
                                  ? setEditIsOpen(true)
                                  : toast.error(
                                    "You are not logged in. Kindly contact the respective manager/admin.")
                              : vehicleData?.pickup_request_assigned === true ||
                                vehicleData?.status_id?.sequence !== 3
                                ? setEditIsOpen(true)
                                : toast.error(
                                  "Please assign staff/manager to proceed further")
                            : toast.error(
                              "Please assign staff/manager to proceed further")
                          : toast.error(
                            "This vehicle status is already changed, kindly refresh your page")
                      }
                      className="inline-flex font-semibold w-full justify-center border border-transparent bg-primary px-2 py-2 text-sm text-white shadow-sm rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:text-sm"
                    >
                      Update status
                    </button>
                  )}
              </div>
              <div className="update inline-btn valet-update-btn">
                {(Number(vehicleData?.branch?.valet_fee) > 0 && vehicleData?.payment_status !== 'paid') &&
                  (details?.is_staff_user === true
                    ? details?.in_out_flag === true
                      ? true
                      : false
                    : true) && (
                    <button
                      type="button"
                      onClick={() => {
                        setEditIsOpen(true)
                        setEditPaymentConfirmIsOpen(true)
                      }}
                      className="inline-flex font-semibold w-full justify-center border border-transparent bg-primary px-2 py-2 text-sm text-white shadow-sm rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:text-sm"
                    >
                      Collected Fee via Cash
                    </button>
                  )}
              </div>
            </div>}
          </div>
        </div>
      </>
      {props?.show !== true && <Modal
        isOpen={assignModalIsOpen}
        onRequestClose={() => setAssignIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="popup-width transform overflow-hidden max-w-[540px] w-full rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:mx-5 sm:max-w-lg h-300 flex h-full items-center relative">
          <div className="mx-auto">
            <div className="close-div absolute top-6 right-6">
              <button
                onClick={() => closeModal1()}
                className="w-8 h-8 "
              >
                <img src={closeIcon} alt="close-icon" />
              </button>
            </div>
            <div className="mt-1 block">
              <div className="text-center flex justify-center items-center p-5 min-w-400 max-[480px]:min-w-[300px]">
                <Select
                  classNamePrefix="react-select"
                  className="assign-confirm react-select-container  w-full inline-block rounded-md  text-sm focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary capitalize"
                  defaultValue={allUsers?.map((user, index) =>
                    vehicleData?.Assigned_Staff?.id === user?.value ? user : ""
                  )}
                  defaultMenuIsOpen={false}
                  maxMenuHeight={155}
                  onChange={(e) => setAssignedStaff(e)}
                  options={allUsers}
                  styles={{
                    styles,
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: 'bold',
                      color: '#9CA3AF'
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                    }),
                    input: (base, state) => ({
                      ...base,
                      '[type="text"]': {
                        border: "none",
                        boxShadow: "none",
                        outline: "none"
                      }
                    })
                  }}
                />
              </div>
              <div className="col-span-6 flex justify-center items-end p-5">
                <button
                  type="submit"
                  onClick={(e) => assignHandle(e)}
                  className={`inline-flex items-center justify-center  border h-10 rounded-md border-transparent bg-green-600 px-4 py-2 text-sm font-semibold text-white shadow-md shadow-green-100 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 sm:w-auto`}
                >
                  <span className="text-center">Assign</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>}
      {props?.show !== true && <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="relative transform overflow-hidden min-w-[320px] max-w-[540px] w-full rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:mx-5 sm:max-w-lg border border-slate-400 ...">
          <div className="close-div">
            <button
              onClick={() => setEditIsOpen(false)}
              className="float-right w-8 h-8 p-1"
            >
              <img src={closeIcon} alt="close-icon" />{" "}
            </button>
          </div>

          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <title>Confirm</title>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg font-medium leading-6 text-gray-900"
                  id="modal-title"
                >
                  Are you sure ?
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {!editPaymentConfirmModalIsOpen ?
                      "Do you want to change your vehicle status? and this action cannot be undone."
                      : `Do you want to collect the vehicle Fee(Rs.${Number(vehicleData?.branch?.valet_fee)}) via cash from the customer and this action cannot be undone?`}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 justify-between items-center">
            {!editPaymentConfirmModalIsOpen &&
              <div className="order-1 text-center sm:text-left sm:pt-0">
                <span className="text-sm font-medium text-gray-900">
                  Next status:{" "}
                </span>
                <span className="text-sm font-medium text-gray-900">
                  {vehicleData?.status_id?.next_status?.name}
                </span>
              </div>
            }
            <div className="gap-1 flex justify-between">
              <button
                type="button"
                rol="button"
                onClick={() => !editPaymentConfirmModalIsOpen ? statusUpdateHandle() : recordCashPayment()}
                className="mt-3 inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
              >
                Yes
              </button>
              <button
                type="button"
                rol="button"
                onClick={() => {
                  setEditIsOpen(false);
                }}
                className="mt-3 inline-flex w-full justify-center border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>}
    </Modal>
  );
}

export default AssignAndConfirmModal;
