/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { directus } from "../../services/directus";
import LayoutComponent from "../../components/LayoutComponent";
import UserContext from "../../contexts/userContext";
import { EMAIL_DOMAIN, STAFF_ROLE_ID, STAFF_USER_PASSWORD } from "../../utils/utils";
import Seo from "../../components/Seo";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import { userPresent } from "../../utils/CommonFunctions";

export default function StaffCreate() {
  const navigate = useNavigate();
  const [userName, setUserName] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [validationError, setValidationError] = React.useState({
    userNameError: false,
    phoneNoError: false,
    passwordError: false,
    invalidPhoneNoError: false,
  });
  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
  const { details, AuthCheck, companyDetails } = React.useContext(UserContext);
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;
  const staffKey = STAFF_ROLE_ID;
  React.useEffect(() => {
    const maxLength = 10;
    if (phoneNo?.length > maxLength) {
      setPhoneNo(phoneNo.substring(0, maxLength));
    }
  }, [phoneNo?.length]);

  React.useEffect(() => {
    AuthCheck();
    var {
      invalidPhoneNoError,
      phoneNoError,
      passError,
      userNameError,
    } = validationError;
    if (!!userName && !!phoneNo && !!password) {
      if (
        !(
          phoneNoError ||
          passError ||
          userNameError ||
          invalidPhoneNoError
        )
      ) {
        setDetailsConfirmModel(true);
      } else {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);
  async function fieldValidation() {
    if (!!!userName?.trim()) {
      updateValidationError("userNameError", true);
    }
    if (!!!phoneNo?.trim()) {
      updateValidationError("phoneNoError", true);
    } else {
      if (phoneNo.trim()?.length < 10) {
        updateValidationError("invalidPhoneNoError", true);
      }
    }

    if (!!!password?.trim()) {
      updateValidationError("passError", true);
    }

    if (
      phoneNo?.trim()?.length === 10 &&
      password?.trim() &&
      userName?.trim()
    ) {
      setValidationError({
        userNameError: false,
        phoneNoError: false,
        passError: false,
      });
    }

    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }

  const handleModal = async (e) => {
    setValidationError({
      userNameError: false,
      phoneNoError: false,
      passError: false,
    });
    await fieldValidation();
  };

  const UserCreateHandle = async () => {
    AuthCheck();
    if (userName?.trim()?.length > 2) {
      if (phoneNo.trim()?.length >= 10) {
        if (password?.length > 0) {
          const LoggedCheck = await userPresent({ details: details });
          if (LoggedCheck?.status) {
            var flagEmail = false;
            await directus.users
              .readByQuery({
                fields: ["*"],
                filter: {
                  pin_number: { _eq: password },
                  default_branch: { _eq: branch },
                  default_company: { _eq: company },
                },
              })
              .then((res) => {
                res?.data?.length
                  ? toast.error("Pin number already exists. So, kindly regenerate again!")
                  : (flagEmail = true);
              })
              .catch((e) => console.log(e));
            if (flagEmail) {
              var flag = false;
              await directus.users
                .readByQuery({
                  fields: ["*"],
                  filter: {
                    contact_number: { _eq: phoneNo },
                    default_branch: { _eq: branch },
                    default_company: { _eq: company },
                  },
                })
                .then((res) => {
                  res?.data?.length
                    ? toast.error("Contact number already exists")
                    : (flag = true);
                })
                .catch((e) => console.log(e));
              if (flag) {
                const RandomEmail = userName?.replaceAll(' ','_') + "." + details?.default_branch?.slug + "." + password + EMAIL_DOMAIN
                await directus.users
                  .createOne({
                    first_name: userName,
                    email: RandomEmail,
                    password: STAFF_USER_PASSWORD,
                    password_copy: STAFF_USER_PASSWORD,
                    contact_number: phoneNo,
                    is_staff_user: true,
                    default_branch: branch,
                    default_company: company,
                    role: staffKey,
                    pin_number: password
                  })
                  .then((res) => {
                    navigate(
                      `/${companyDetails?.company}/${companyDetails?.branch}/staff/manage`
                    );
                    toast.success(" Created successfully");
                  })
                  .catch((err) => {
                    navigate(".", { replace: true });
                    console.log(err);
                    toast.error(err?.message);
                  });
              }
            }
          } else {
            toast.error(
              "Your account was suspended, kindly contact the respective manager/admin."
            );
          }
        } else {
          toast.error("Pin is required");
        }
      } else {
        toast.error("Invalid phone number");
      }
    } else {
      toast.error("Enter valid user name (minimum 3 character)");
    }
  }
  return (
    <LayoutComponent title={"CREATE STAFF"} fixedFooter={true} center={true}>
      <Seo title={"Staff create"} company={true} />
      <div className="main-content-area px-6 max-sm:px-5 pt-0">
        <div className="mx-auto">
          <div className="">
            <div className="mt-1 mb-1 block">
              <div className="space-y-6 bg-white py-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700 mb-2">
                      Name *
                    </label>
                    <input
                      className={`appearance-none ${validationError.userNameError && userName?.length < 1
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                        }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                      placeholder="Enter the name"
                      id="grid-name"
                      type="text"
                      onChange={(e) => setUserName(e.target.value)}
                      value={userName || ""}
                    />
                    {validationError.userNameError && userName?.length < 1 && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Username is required
                      </p>
                    )}
                  </div>
                  <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700 mb-2">
                      Phone number *
                    </label>
                    <input
                      className={`appearance-none ${(validationError?.phoneNoError && phoneNo?.length < 1) ||
                        (validationError.invalidPhoneNoError &&
                          phoneNo.trim()?.length < 10)
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                        }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                      placeholder="Enter the phone number"
                      id="phone-no"
                      type="text"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      maxLength={10}
                      onChange={(e) =>
                        /^\d*\.?\d*$/.test(e.target.value) &&
                        setPhoneNo(e.target.value)
                      }
                      value={phoneNo || ""}
                    />
                    {validationError?.phoneNoError && phoneNo?.length < 1 && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Phone number is required
                      </p>
                    )}
                    {validationError?.invalidPhoneNoError &&
                      phoneNo?.length < 10 && (
                        <p className="text-xs text-right text-red-500 mt-2">
                          *Invalid Phone number
                        </p>
                      )}
                  </div>
                  <div className="col-span-6 relative">
                    <label className="block text-sm font-bold text-gray-700 mb-2">
                      PIN *
                    </label>
                    <div className="relative">
                      <input
                        className={`appearance-none ${validationError.passError && password?.length < 1
                          ? "border-red-300 focus:border-red-300"
                          : "border-gray-300 focus:border-primary"
                          } cursor-default block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                        placeholder="Generate the PIN"
                        id="password"
                        type={"text"}
                        readOnly
                        onChange={null}
                        value={password || ""}
                      />
                      <span className="absolute text-center right-5 top-1/2 bottom-0 -translate-y-1/2">
                        <button
                          type="button"
                          onClick={(e) => {
                            setPassword(Math.floor(100000 + Math.random() * 900000).toString().substring(0, 4))
                          }
                          }
                          className="inline-flex items-center justify-center text-sm font-semibold text-primary "
                        >
                          <span className="ml-1.5">{password?.trim()?.length > 0 ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                          </svg>
                            : "Generate PIN"}</span>
                        </button>
                      </span>
                    </div>
                    {validationError.passError && password?.length < 1 && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Pin is required
                      </p>
                    )}
                  </div>
                  <div className="col-span-6 flex justify-end items-end pt-5">
                    <button
                      type="button"
                      onClick={(e) => handleModal(e)}
                      className="inline-flex items-center justify-center  border h-10 border-transparent rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-md  hover:bg-vspurple hover border-vspurple focus:outline-none focus:ring-2 focus:ring-vspurple focus:ring-offset-2 sm:w-auto"
                    >
                      <span className="ml-1.5">Create Staff</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {detailsConfirmModel ? (
        <ConfirmationModal
          openModel={detailsConfirmModel}
          setIsOpenModel={setDetailsConfirmModel}
          callFunction={UserCreateHandle}
          content={"Are you sure the given user information is valid?"}
        />
      ) : null}
    </LayoutComponent>
  );
}
