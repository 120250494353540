import React from "react";
import { getAssetURL } from "../../utils/get-asset-url";
import UserContext from "../../contexts/userContext";
import QRCode from "react-qr-code";
import { FRONTEND_URL } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import palceHolderImg from "../../assets/images/placeHolder.png";

function ScanQr(props) {
  const {
    companyDetails,
  } = React.useContext(UserContext);
  const [loading, setLoding] = React.useState(true);
  const navigate = useNavigate();
  const printCall = () => {
    let printContents = document.getElementById("printContent").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };
  React.useEffect(() => {
    companyDetails?.company !== undefined && setLoding(false);
  }, [companyDetails?.company]);
  return (
    <div className="page__wrapper qr_page_wrapper flex justify-start items-center flex-col bg-[rgb(243,244,246)] font-primary">
      <div
        className={`${
          props?.containerView !== false ? "max-w-[1200px]" : "max-w-[1200px]"
        } bg-white relative w-full inline-block h-screen firefox-scrollbar shadow-sm min-h-screen max-h-screen overflow-hidden overflow-y-auto`}
      >
        <Seo title={"QR code"} company={true} />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="scan_main min-h-100vh  h-full w-full flex flex-col items-center text-center capitalize justify-center">
            <div className="print_con min-h-100vh items-center justify-center">
              <div className="flex justify-center mb-[40px] flex-col">
                <img
                  src={companyDetails?.logo?getAssetURL(companyDetails?.logo):palceHolderImg}
                  style={{
                    maxWidth: "95px",
                    maxHeight: "50%",
                    margin: "0 auto",
                  }}  
                  alt="LOGO-1"
                />
                <p className="text-gray-400 text-[12px] font-bold pt-1 uppercase text-center">
                      {companyDetails?.branchName}{" "}
                </p>
              </div>
              <div className="text-center uppercase text-xl font-medium mb-2">Valet Parking</div>
              <div className="text-center uppercase text-xl font-medium mb-2">QR Code</div>
              <div className="text-center uppercase text-xl font-medium mb-2">Scan</div>
              <div
                className="qr_con  flex items-center justify-center mb-2"
                id="qr_con"
              >
                <QRCode
                  size={256}
                  style={{ maxHeight: "70%", maxWidth: "70%", width: "70%" }}
                  value={`${FRONTEND_URL}/${companyDetails?.company}/${companyDetails?.branch}/customer/login`}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <p className="text-center uppercase text-xl font-medium mb-2">To check vehicle status</p>
            </div>
            <div className="print_con !bg-white block text-center hidden w-full" id="printContent">
              <div className="block relative top-[180px]">
                <img
                  src={companyDetails?.logo?getAssetURL(companyDetails?.logo):palceHolderImg}
                  style={{
                    maxWidth: "95px",
                    maxHeight: "95px",
                    margin: "0 auto",
                  }}  
                  className="block"
                  alt="LOGO-1"
                />
                <p className="text-gray-400 text-[12px] font-bold pt-1 uppercase block text-center w-full ">
                      {companyDetails?.branchName}{" "}
                </p>
              </div>
              <div className="text-center uppercase text-xl font-medium mb-2 relative top-[190px]">Valet Parking</div>
              <div className="text-center uppercase text-xl font-medium mb-2 relative top-[200px]">QR Code</div>
              <div className="text-center uppercase text-xl font-medium mb-2 relative top-[210px]">Scan</div>
              <div
                className="qr_con flex items-center justify-center mb-2 relative top-[220px]"
                id="qr_con"
              >
                <QRCode
                  size={256}
                  style={{ maxHeight: "70%", maxWidth: "70%", width: "70%" }}
                  value={`${FRONTEND_URL}/${companyDetails?.company}/${companyDetails?.branch}/customer/login`}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <p className="text-center uppercase text-xl font-medium mb-2 relative top-[240px]">To check vehicle status</p>
            </div>
            <div className="w-[500px] flex items-center justify-evenly">
              <button
                className="min-w-[100px]  bg-primary text-white inline-flex items-center justify-center text-sm font-medium border rounded-md p-2 border-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2  sm:w-auto left-4 top-[62px]"
                type="button"
                onClick={() => printCall()}
              >
                Print
              </button>
              <button
                className="min-w-[100px] inline-flex items-center justify-center text-sm font-medium border p-2 border-primary rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto left-4 top-[62px]"
                type="button"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ScanQr;
