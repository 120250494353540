import React from "react";
import Modal from "react-modal";
import UserContext from "../../contexts/userContext";
import { useNavigate } from "react-router-dom";

function LogoutModel(props) {
  const { setIsOpenModel } = props;
  const [modalIsOpen, setIsOpen] = React.useState(
    setIsOpenModel ? true : false
  );
  const { companyDetails, logout } = React.useContext(UserContext);
  const navigate = useNavigate();

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 24, 27, 0.4)",
      zIndex: 5,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      background: "transparant",
      justifySelf: "center",
      display: "flex",
      elevation: 0,
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "none",
      zIndex: 15,
    },
  };
  const closeModal = () => {
    setIsOpenModel(false);
    setIsOpen(false);
  };
  const logoutHandle = async () => {
    setIsOpen(false);
    setIsOpenModel(false);
    localStorage.clear();
    logout();
    localStorage.setItem("logout", true);
    navigate(`/${companyDetails?.company}/${companyDetails?.branch}/login`, {
      state: {
        companyName: companyDetails?.company,
        branchName: companyDetails?.branch,
      },  
    });
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => closeModal(false)}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Example Modal"
    >
      <div className="relative transform overflow-hidden max-w-[540px] w-full rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:mx-5 sm:max-w-lg border border-slate-400 ...">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          {" "}
          <p className="text-black">Are you sure to logout ?</p>
          <div className="flex-row gap-5 flex mt-10 justify-between">
            <button
              type="button"
              className="inline-flex font-semibold w-auto justify-center border border-transparent rounded-md bg-red-100 px-4 py-2 text-sm text-red-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm"
              onClick={() => closeModal(false)}
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={() => logoutHandle()}
              className="inline-flex  items-center justify-center h-[40px] text-[18px] border rounded-md  bg-primary border-primary px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-vspurple hover:border-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              {" "}
              Ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LogoutModel;
