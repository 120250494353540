/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomerLayout from "../components/CustomerLayout";
import Seo from "../components/Seo";
import UserContext from "../contexts/userContext";
import { directus } from "../services/directus";
import PasswordShowHide from "../components/PasswordShowHide";
import VehicleOtp from "../components/VehicleOtp";
import Select from "react-select";

const Login = () => {
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [preventDouble, setPreventDouble] = React.useState(false);
  const [companyAndBranch, setCompanyAndBranch] = React.useState({});
  const [phoneNo, setPhoneNo] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [userType, setUserType] = React.useState({ id: 1, name: "Admin / Manager" });
  const [validationError, setValidationError] = React.useState({
    userEmailError: false,
    phoneNoError: false,
    passwordError: false,
    invalidPhoneNoError: false,
    pinNoError: false,
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const { login } = React.useContext(UserContext);
  var flag = 1;
  useEffect(() => {
    flag === 1 && alreadyExistLogin();
    const val = window.location.href.split("/");
    setCompanyAndBranch({ companyName: val[3], branchName: val[4] });
    const logOut = localStorage.getItem("logout");
    if (logOut === "true") {
      toast.success("Logout Successfully");
      localStorage.clear();
    }
    flag === 1 &&
      CompanyBranchCheck({ companyName: val[3], branchName: val[4] });
    flag = 5;
  }, []);
  const alreadyExistLogin = async () => {
    const company = localStorage.getItem("company");
    const branch = localStorage.getItem("branch");
    const auth_token = localStorage.getItem("auth_token");
    if (
      auth_token !== undefined &&
      auth_token?.length > 0 &&
      state?.companyName === company &&
      state?.branchName === branch
    ) {
      return navigate(`/${company}/${branch}/dashboard `);
    } else if (auth_token !== undefined && auth_token?.length > 0) {
      toast.error(`You are already logged in ${company} / ${branch}, kindly change your company/branch and continue the login.`);
    }
  };

  const CompanyBranchCheck = async (props) => {
    await directus
      .items("Companies")
      .readByQuery({
        fields: ["id"],
        filter: {
          slug: { _eq: props?.companyName },
          branches: { slug: { _eq: props?.branchName } },
        },
      })
      .then(async (res) => {
        if (res?.data[0]) {
          await directus
            .items("Branch")
            .readByQuery({
              fields: ["id"],
              filter: {
                slug: { _eq: props?.branchName },
              },
            })
            .then((response) => {
              if (!response?.data[0]) {
                navigate("/NotFoundPage");
                toast.error("Invalid company / branch");
              }
            });
        } else {
          navigate("/NotFoundPage");
          toast.error("Invalid company / branch");
        }
      })
      .catch((e) => console.log(e));
    flag = 5;
  };
  const handleSubmit = async (e) => {
    setPreventDouble(true);
    if (preventDouble) {
      return "";
    }
    else {
      const toastClose = {
        onClose: () => setPreventDouble(false),
      }
      var filterCompanyAndBranchLogin = false;
      const filterBody = userType?.id === 1 ? { email: { _eq: emailInput?.trim() } } : {
        pin_number: { _eq: pin }, contact_number: { _eq: phoneNo },
        default_branch: { slug: { _eq: companyAndBranch?.branchName } },
        default_company: { slug: { _eq: companyAndBranch?.companyName } }
      }
      const userResponse = await directus.users
        .readByQuery({
          fields: ["email","password_copy","default_branch.id"],
          filter: filterBody,
        })
        .then((e) => {
          return {
            userEmail: userType?.id === 1 ? emailInput?.trim() : e?.data[0]?.email,
            userPassword: userType?.id === 1 ? passwordInput : e?.data[0]?.password_copy,
            branch: e?.data[0]?.default_branch?.id
          };
        });

      const userEmail = userResponse?.userEmail
      const userPassword = userResponse?.userPassword
      const branchData = await directus
        .items("Branch")
        .readByQuery({
          fields: ["id", "company_id.status", "status", "company_id.slug", "slug"],
          filter: {
            slug: { _eq: companyAndBranch?.branchName },
            company_id: { slug: { _eq: companyAndBranch?.companyName } },
          },
        })
        .then((res) => {
          if (res?.data[0]?.id === userResponse?.branch) {
            filterCompanyAndBranchLogin = true
          }
          return res?.data[0]
        })
        .catch((e) => {
          console.log(e);
        });
      if (filterCompanyAndBranchLogin) {
        await directus.auth
          .login({
            email: userEmail,
            password: userPassword,
          })
          .then(async (e) => {
            const val = await directus.users
              .readByQuery({
                fields: ["*", "default_company.*", "default_branch.*", "default_branch.city_id.*","default_branch.agreement_id.*", "default_branch.state_id.*",
                  "default_branch.tags.*", "default_branch.company_id.company_logo","default_branch.document_1.*","default_branch.document_2.*",],
                filter: {
                  email: { _eq: userEmail },
                },
              })
              .then((res) => {
                localStorage.setItem(
                  "company",
                  res?.data[0]?.default_company?.slug
                );
                localStorage.setItem(
                  "branch",
                  res?.data[0]?.default_branch?.slug
                );
                return {
                  company: res?.data[0]?.default_company?.slug,
                  branch: res?.data[0]?.default_branch?.slug,
                  companyName: res?.data[0]?.default_company?.name,
                  branchName: res?.data[0]?.default_branch?.name,
                  branchValetNum: res?.data[0]?.default_branch?.valet_number,
                  branchPlatformCharges: res?.data[0]?.default_branch?.platform_charges,
                  branchValetFee: res?.data[0]?.default_branch?.valet_fee,
                  chatStaffAvailable: res?.data[0]?.default_branch?.is_staff_available,
                  logo: res?.data[0]?.default_branch?.company_id?.company_logo,
                  chatRoomId: res?.data[0]?.default_branch?.chat_room_id,
                  details: res?.data[0],
                  isPublished:res?.data[0]?.default_company?.status==='published'&&res?.data[0]?.default_branch?.status==='published'?true:false
                };
              })
              .catch((e) => console.log(e));
            let path = "";

            if (state?.access === "admin") {
              val?.details?.is_admin_user === true
                ? (path = state?.redirectTo)
                : (path = "");
            } else if (state?.access === "manager") {
              val?.details?.is_admin_user === true ||
                val?.details?.is_manager_user === true
                ? (path = state?.redirectTo)
                : (path = "");
            } else {
              path = state?.redirectTo;
            }
            login({
              token: e?.access_token,
              company: val,
              userType:
                val?.details?.is_admin_user === true
                  ? "admin"
                  : val?.details?.is_manager_user === true
                    ? "manager"
                    : "staff",
            });
            if (branchData?.status !== "draft") {
              const NavigationState = branchData?.company_id?.status === "published" && branchData?.status === "published" ? `/${state?.companyName}/${state?.branchName}/dashboard` :
                ((branchData?.status === "rejected" || branchData?.status === "document_in_pending" || branchData?.status === "document_in_progress") ? (`/${branchData?.company_id?.slug}/${branchData?.slug}/application-correction`) :
                  (branchData?.status === "document_verified" || branchData?.status === "contract_agreement_pending" ||
                    branchData?.status === "contract_agreement_review_in_progress"|| branchData?.status === "contract_signature_pending" || branchData?.status === "contract_signature") ? `/${branchData?.company_id?.slug}/${branchData?.slug}/onboarding-form`
                    : '')
              if (NavigationState !== "") {
                navigate(
                  path !== "" && path !== undefined
                    ? state?.redirectTo
                    : NavigationState
                );
                toast.success("Login Successfully", toastClose);
              }
              else {
                toast.error("Something went wrong. Kindly contact your admin / support team")
              }
            }
            else {
              toast.warning("Documents verification still in progress. so you are not able to login.");
            }
          })
          .catch((e) => {
            toast.error(e?.message);
          });
      } else {
        toast.error("Invalid user details");
      }
    }
    setPreventDouble(false);

  };
  const ManagerLogin = () => {
    return <div className="w-full">
      <div className="mb-3 w-full">
        <label
          htmlFor="u-mail-id1"
          className="block text-sm font-bold text-gray-700"
        >
          Email
        </label>
        <input
          type="text"
          id="u-mail-id1"
          placeholder="abc@email.com"
          onChange={(e) => {
            setEmailInput(e.target.value)
            preventDouble && setPreventDouble(false)
          }}
          value={emailInput}
          required
          className={` ${(validationError?.userEmailError && emailInput?.length < 1) ||
            (validationError?.userEmailError &&
              emailInput?.length > 0 &&
              !!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                emailInput
              ))
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } mt-2 w-full px-4 py-3 flex justify-center items-center shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 placeholder:font-bold placeholder:text-[#bbb] placeholder:text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm`} />
      </div>
      {validationError?.userEmailError && emailInput?.trim()?.length < 1 && (
        <p className="text-xs text-right text-red-500 mt-2">
          *Email address is required
        </p>
      )}
      {validationError?.userEmailError &&
        emailInput?.trim()?.length > 0 &&
        !!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          emailInput
        ) && (
          <p className="text-xs text-right text-red-500 mt-2">
            *Enter a valid email address
          </p>
        )}
      <div className="mb-3 w-full relative">
        <label
          htmlFor="u-password-1"
          className="block text-sm font-bold text-gray-700"
        >
          Password
        </label>
        <input
          type={showPassword ? "text" : "password"}
          onChange={(e) => {
            setPasswordInput(e.target.value)
            preventDouble && setPreventDouble(false)
          }}
          value={passwordInput}
          id="u-password-1"
          placeholder="password"
          required
          className={`${validationError.passError && passwordInput?.length < 1
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } mt-2 w-full px-4 py-3 flex justify-center items-center shadow-sm rounded-lg border border-gray-300 focus:border-gray-600 placeholder:font-bold placeholder:text-[#bbb] placeholder:text-[14px] focus:outline-none focus:ring-1 focus:ring-gray-600 sm:text-sm`} />
        {/* <!-- Show/Hide password --> */}
        <PasswordShowHide
          setShow={setShowPassword}
          Show={showPassword}
          center={false}
        />
      </div>
      {validationError?.passError && passwordInput?.length < 1 && (
        <p className="text-xs text-right text-red-500 mt-2">
          *Password is required
        </p>
      )}
      <button
        type="button"
        onClick={() =>
          navigate(
            `/${state?.companyName
              ? state?.companyName
              : companyAndBranch?.companyName
            }/${state?.branchName
              ? state?.branchName
              : companyAndBranch?.branchName
            }/reset-password`,
            {
              state: {
                companyName: state?.companyName
                  ? state?.companyName
                  : companyAndBranch?.companyName,
                branchName: state?.branchName
                  ? state?.branchName
                  : companyAndBranch?.branchName,
              },
            }
          )
        }
        className="w-full self-end right-0 text-right font-semibold text-black hover:text-vspurple"
      >
        Reset password?
      </button>

    </div>
  }
  const StaffLogin = () => {
    return <div className="w-full">
      <div className="mb-3 w-full">
        <label
          htmlFor="u-phone-number"
          className="block text-sm font-bold text-gray-700"
        >
          Phone number
        </label>
        <VehicleOtp
          numInputs={10}
          handleChange={e => setPhoneNo(e)}
          setOtpNo={setPhoneNo}
          readOnly={false}
          required={true}
          type={"phone"}
          hasError={(validationError?.phoneNoError || validationError?.invalidPhoneNoError)
            && phoneNo?.length < 10
            ?
            true : false}
          dynamicError={false}
          inputType={'number'}

        />
      </div>
      {validationError?.phoneNoError && phoneNo?.length < 10 && (
        <p className="text-xs text-right text-red-500 mt-2">
          *Phone number is required
        </p>
      )}
      {validationError?.invalidPhoneNoError &&
        phoneNo?.length < 10 && (
          <p className="text-xs text-right text-red-500 mt-2">
            *Invalid Phone number
          </p>
        )}
      <div className="mb-3 w-full">
        <label
          htmlFor="u-pin-number"
          className="block text-sm font-bold text-gray-700"
        >
          PIN number
        </label>
        <VehicleOtp
          numInputs={4}
          handleChange={e => setPin(e)}
          setOtpNo={setPin}
          readOnly={false}
          required={true}
          hasError={
            validationError?.pinNoError && pin?.length < 4 ?
              true : false
          }
          inputType={'number'}
          dynamicError={false}
        />
      </div>
      {validationError?.pinNoError && pin?.length < 4 && (
        <p className="text-xs text-right text-red-500 mt-2">
          {pin?.length < 1 ? "*Pin is required" : "*Invalid PIN number"}
        </p>
      )}
    </div>
  }
  const UserSelection = () => {
    const styles1 = {
      menuList: (base) => ({
        ...base,
        height: "110px",

        "::-webkit-scrollbar": {
          width: "6px",
        },
        "::-webkit-scrollbar-track": {
          background: "#2684FF",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#888",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }),
      control: (base, state) => ({
        ...base,
        border: "none",
        marginTop: "4px",
        borderColor: state.isFocused ? "grey" : "red",
      }),
    };
    const userOption = [{ id: 1, name: "Admin / Manager" }, { id: 2, name: "Staff" }]
    return <div className="mb-3 w-full">
      <label
        htmlFor="User_Type_1"
        className="block text-sm font-bold text-gray-700"
      >
        User type
      </label>
      <div
        className="bg-white input-boxes flex justify-center border border-1 border-gray-300 rounded-md mt-2 w-full"
      >
        <div className="w-full user_type_selector">
          <Select
            id="User_Type_1"
            classNamePrefix="cursor-pointer react-select"
            className="react-select-container border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] capitalize"
            defaultMenuIsOpen={false}
            maxMenuHeight={250}
            isSearchable={false}
            onChange={(e) => {
              if (userType?.id === 1) {
                setPhoneNo("")
                setPin("")
              } else if (userType?.id) {
                setPasswordInput('')
                setEmailInput("")
              }
              setValidationError({
                userEmailError: false,
                phoneNoError: false,
                passwordError: false,
                invalidPhoneNoError: false,
                pinNoError: false,
              });
              setUserType(e)
            }}
            value={userType ? userType : ''}
            options={userOption}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => {
              return {
                id: option.id,
                name: option?.name,
              };
            }}
            placeholder={"Select user type"}
            styles={{
              styles1,
              placeholder: (base) => ({
                ...base,
                fontWeight: 'bold',
                color: '#9CA3AF'
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                border: "none",
                boxShadow: "none",
              }), input: (base, state) => ({
                ...base,
                '[type="text"]': {
                  border: "none",
                  boxShadow: "none",
                  outline: "none"
                }
              })
            }}
          />
        </div>
      </div>
    </div>
  }
  React.useEffect(() => {
    var {
      invalidPhoneNoError,
      phoneNoError,
      passError,
      userEmailError,
      pinNoError
    } = validationError;
    if (userType?.id === 1 ? (!!emailInput && !!passwordInput) : (!!pin && !!phoneNo)) {
      if (
        !(userType?.id === 1 ? (passError ||
          userEmailError) :
          (invalidPhoneNoError ||
            phoneNoError || pinNoError))
      ) {
        handleSubmit();
      } else {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);
  async function fieldValidation() {
    if (!!!emailInput?.trim() || !!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailInput)) {
      updateValidationError("userEmailError", true);
    }
    if (!!!phoneNo?.trim()) {
      updateValidationError("phoneNoError", true);
    } else {
      if (phoneNo.trim()?.length < 10) {
        updateValidationError("invalidPhoneNoError", true);
      }
    }
    if (pin?.trim()?.length < 4) {
      updateValidationError("pinNoError", true);
    }
    if (!!!passwordInput?.trim()) {
      updateValidationError("passError", true);
    }

    if (
      phoneNo?.trim()?.length === 10 &&
      passwordInput?.trim() &&
      emailInput?.trim() && pin?.trim()?.length === 4
    ) {
      setValidationError({
        userEmailError: false,
        phoneNoError: false,
        passwordError: false,
        invalidPhoneNoError: false,
        pinNoError: false,
      });
    }

    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }
  const handleValidation = async () => {
    setValidationError({
      userEmailError: false,
      phoneNoError: false,
      passwordError: false,
      invalidPhoneNoError: false,
      pinNoError: false,
    });
    await fieldValidation();
  }
  return (
    <>
      <CustomerLayout
        state={state}
        branch={companyAndBranch?.branchName}
        containerView={false}
        isCompany={true}
        goBack={true}
      >
        <Seo
          title={`Login | ${companyAndBranch?.companyName} | ${companyAndBranch?.branchName}`}
        />
        <div className="main-content-area w-full admin-login-page">
          <div className="px-4 py-10 flex justify-center w-full items-center">
            <div className="max-w-[400px] px-5 w-full flex flex-col items-center text-[14px] md:px-0"            >
              <h1 className="text-center text-[32px] font-bold text-black mb-8">
                LOGIN
              </h1>
              <UserSelection />
              {userType?.id === 1 ? ManagerLogin() : StaffLogin()}
              <div className="mt-8 w-full justify-center items-center flex">
                <button
                  type='button'
                  onClick={handleValidation}
                  disabled={preventDouble}
                  className="inline-flex w-full items-center justify-center h-[50px] text-[18px]  rounded-md border bg-primary border-primary hover:border-vspurple px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                >
                  {preventDouble ? "Loading ..." : "Login"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomerLayout>
    </>
  );
};
export default Login;
