import React from "react";
import PasswordShowHide from "../PasswordShowHide";

function CreateComponent({
  validationError,
  userName,
  setUserName,
  email,
  setEmail,
  phoneNo,
  setPhoneNo,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  setValidationError,
  userType,
}) {
  const [passShow, setPassShow] = React.useState(false);
  const [cpassShow, setCPassShow] = React.useState(false);
  async function fieldValidation() {
    if (!!!userName?.trim()) {
      updateValidationError("userNameError", true);
    }
    if (!!!phoneNo?.trim()) {
      updateValidationError("phoneNoError", true);
    } else {
      if (phoneNo.trim()?.length < 10) {
        updateValidationError("invalidPhoneNoError", true);
      }
    }
    if (!!!email?.trim()) {
      updateValidationError("emailError", true);
    }
    if (!!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      updateValidationError("EmailFormatError", true);
    }
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      updateValidationError("EmailFormatError", false);
    }
    if (!!!password?.trim()) {
      updateValidationError("passError", true);
    }
    if (!!!confirmPassword?.trim()) {
      updateValidationError("cpassError", true);
    }
    if (password?.trim()?.length > 0 || confirmPassword?.trim()?.length > 0) {
      if (password?.trim() !== confirmPassword?.trim()) {
        updateValidationError("passwordMismatchError", true);
      }
    }
    if (
      phoneNo?.trim()?.length === 10 &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&
      password?.trim() &&
      confirmPassword?.trim() &&
      userName?.trim() &&
      password?.trim() === confirmPassword?.trim()
    ) {
      setValidationError({
        userNameError: false,
        phoneNoError: false,
        cpassError: false,
        emailError: false,
        passError: false,
        EmailFormatError: false,
        passwordMismatchError: false,
      });
    }

    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }

  const handleModal = async (e) => {
    setValidationError({
      userNameError: false,
      phoneNoError: false,
      cpassError: false,
      emailError: false,
      passError: false,
      passwordMismatchError: false,
    });
    await fieldValidation();
  };
  return (
    <div className="main-content-area px-6 max-sm:px-5 pt-0">
      <div className="mx-auto">
        <div className="">
          <div className="mt-1 mb-1 block">
            <div className="space-y-6 bg-white py-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6">
                  <label className="block text-sm font-bold text-gray-700 mb-2">
                    Name *
                  </label>
                  <input
                    className={`appearance-none ${
                      validationError.userNameError && userName?.length < 1
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                    }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    placeholder="Enter the name"
                    id="grid-name"
                    type="text"
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName || ""}
                  />
                  {validationError.userNameError && userName?.length < 1 && (
                    <p className="text-xs text-right text-red-500 mt-2">
                      *Username is required
                    </p>
                  )}
                </div>
                <div className="col-span-6">
                  <label className="block text-sm font-bold text-gray-700 mb-2">
                    Email *
                  </label>
                  <input
                    className={`appearance-none ${
                      (validationError?.emailError && email?.length < 1) ||
                      (validationError?.EmailFormatError &&
                        email?.length > 0 &&
                        !!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                          email
                        ))
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                    }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    placeholder="Enter the email"
                    id="grid-email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email || ""}
                  />
                  {validationError?.emailError && email?.trim()?.length < 1 && (
                    <p className="text-xs text-right text-red-500 mt-2">
                      *Email address is required
                    </p>
                  )}
                  {validationError?.EmailFormatError &&
                    email?.trim()?.length > 0 &&
                    !!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      email
                    ) && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Enter a valid email address
                      </p>
                    )}
                </div>
                <div className="col-span-6">
                  <label className="block text-sm font-bold text-gray-700 mb-2">
                    Phone number *
                  </label>
                  <input
                    className={`appearance-none ${
                      (validationError?.phoneNoError && phoneNo?.length < 1) ||
                      (validationError.invalidPhoneNoError &&
                        phoneNo.trim()?.length < 10)
                        ? "border-red-300 focus:border-red-300"
                        : "border-gray-300 focus:border-primary"
                    }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                    placeholder="Enter the phone number"
                    id="phone-no"
                    type="text"
                    pattern="[0-9]*" 
                    inputMode="numeric"
                    maxLength={10}
                    onChange={(e) =>
                      /^\d*\.?\d*$/.test(e.target.value) &&
                      setPhoneNo(e.target.value)
                    }
                    value={phoneNo || ""}
                  />
                  {validationError?.phoneNoError && phoneNo?.length < 1 && (
                    <p className="text-xs text-right text-red-500 mt-2">
                      *Phone number is required
                    </p>
                  )}
                  {validationError?.invalidPhoneNoError &&
                    phoneNo?.length < 10 && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Invalid Phone number
                      </p>
                    )}
                </div>
                <div className="col-span-6 relative">
                  <label className="block text-sm font-bold text-gray-700 mb-2">
                    Password *
                  </label>
                  <div className="relative">
                    <input
                      className={`appearance-none ${
                        validationError.passError && password?.length < 1
                          ? "border-red-300 focus:border-red-300"
                          : "border-gray-300 focus:border-primary"
                      }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                      placeholder="Enter the password"
                      id="password"
                      type={passShow ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password || ""}
                    />
                    <PasswordShowHide setShow={setPassShow} Show={passShow} />
                  </div>
                  {validationError.passError && password?.length < 1 && (
                    <p className="text-xs text-right text-red-500 mt-2">
                      *Password is required
                    </p>
                  )}
                </div>
                <div className="col-span-6 relative">
                  <label className="block text-sm font-bold text-gray-700 mb-2">
                    Confirm password *
                  </label>
                  <div className="relative">
                    <input
                      className={`appearance-none ${
                        (validationError.cpassError &&
                          confirmPassword?.length < 1) ||
                        (validationError?.passwordMismatchError &&
                          confirmPassword?.length > 0 &&
                          password?.trim() !== confirmPassword?.trim())
                          ? "border-red-300 focus:border-red-300"
                          : "border-gray-300 focus:border-primary"
                      }  block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                      placeholder="Enter the confirm password "
                      id="c-password"
                      type={cpassShow ? "text" : "password"}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword || ""}
                    />
                    <PasswordShowHide setShow={setCPassShow} Show={cpassShow} />
                  </div>
                  {validationError.cpassError &&
                    confirmPassword?.length < 1 && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Confirm password is required
                      </p>
                    )}
                  {validationError?.passwordMismatchError &&
                    confirmPassword?.length > 0 &&
                    password?.trim() !== confirmPassword?.trim() && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Password and Confirm password does not match
                      </p>
                    )}
                </div>
                <div className="col-span-6 flex justify-end items-end pt-5">
                  <button
                    type="button"
                    onClick={(e) => handleModal(e)}
                    className="inline-flex items-center justify-center  border h-10 border-transparent rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-md  hover:bg-vspurple hover border-vspurple focus:outline-none focus:ring-2 focus:ring-vspurple focus:ring-offset-2 sm:w-auto"
                  >
                    <span className="ml-1.5">Create {userType}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateComponent;
