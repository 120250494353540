/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { API_TOKEN, BACKEND_URL, CHAT_IP } from '../utils/utils';
import { toast } from 'react-toastify';
import Modal from "react-modal";
import UserContext from '../contexts/userContext';
import { directus } from '../services/directus';
import LoadingSpinner from './LoadingSpinner';


const StaffChat = () => {
  const [socket, setSocket] = useState(null);
  const [username, setUsername] = useState();
  const [showAvilableChat, setShowAvilableChat] = useState(false);
  // const [userPresent, setUserPresent] = useState(false);
  const [room, setRoom] = useState();
  const [started, setStarted] = useState(false);
  const [subroom, setSubroom] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [notification, setNotification] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [chatLoader, setChatLoader] = useState(true);
  const [availableRooms, setAvailableRooms] = useState([]);
  // const [showMoveToBottomButton, setShowMoveToBottomButton] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const {
    details,
    AuthCheck,
    companyDetails,
    UserBranchDataUpdate} = React.useContext(UserContext);
  const [active, setActive] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const staffName = details?.first_name
  const roomId = companyDetails?.chatRoomId
  const staffAvailable = active
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 24, 27, 0.4)",
      zIndex: 9,
    },
    content: {
      top: "50%",
      left: "50%",
      display: "flex",
      justifySelf: "center",
      right: "auto",
      background: "transparant",
      elevation: 0,
      border: "none",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
    },
  };
  React.useEffect(() => {
    setActive(companyDetails?.chatStaffAvailable)
    !!details?.first_name && details?.first_name && setUsername(details?.first_name)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [companyDetails, details])
  const activeHandle = async (prop) => {
    setLoading(true)
    AuthCheck()
    active !== prop && setActive(!active);
    active !== prop && details?.default_branch?.id && await directus
      .items("Branch")
      .updateOne(Number(details?.default_branch?.id), { is_staff_available: !active })
      .then((res) => {
        res?.id&& UserBranchDataUpdate()
        toast.success("Status updated");
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
    setLoading(false)
  }
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
      // setUserPresent(true)
    }
    const presentUser = storedUsername?.trim()?.length > 0 ? true : false
    const storedRoom = presentUser ? localStorage.getItem('room') : roomId;
    if (storedRoom) {
      setRoom(storedRoom);
    }
    const storedSubroom = localStorage.getItem('subroom');
    if (storedSubroom) {
      setSubroom(storedSubroom);
    }
    if (!!staffName && !!roomId) {
      const newSocket = io(CHAT_IP);
      setSocket(newSocket);
      newSocket.emit('setUsername', { username: staffName, room: roomId });
      newSocket.emit('oldChats', { API_URL: BACKEND_URL + "/items/Chat_Section", token: API_TOKEN, username: "admin", room: storedRoom, subroom: storedSubroom });
      newSocket.emit('getRooms');
      newSocket.on('availableRooms', (availableRooms) => {
        setAvailableRooms(availableRooms)
      });
    }

    return () => {
      if (!presentUser) {
        // newSocket.disconnect();
      }
    };
  }, [companyDetails, details, staffName, roomId]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        localStorage.setItem("socketId", socket?.id)

        if (username && room && subroom) {
          // socket.emit('joinRoom', { username, room, subroom, type: 'staff' });
        }
      });
      socket.on('chatMessage', (msg) => {
        setMessages([...messages, msg]);
      });
      socket.on('subroomMessages', (subroomMsgs) => {
        setMessages(subroomMsgs);
      });
      socket.on('subroomMessage', (msg) => {
        const storedSubroom = localStorage.getItem('subroom');
        const subRoomId = !!storedSubroom ? storedSubroom : subroom
        if (msg.subroom === subroom) {
          msg?.subroom === subRoomId && setMessages([...messages, msg]);
        }
      });
      socket.on('getNotification', (res) => {
        const storedSubroom = localStorage.getItem('subroom');
        const subroomIndex = notification?.length > 0 && notification?.findIndex(e => e?.roomId === res?.subroom)
        if (username !== res?.username) {
          const isChatOpen = storedSubroom === res?.subroom
          const unReadCount = notification?.[subroomIndex]?.count
          const value1 = { roomId: res?.subroom, isRead: false, count: !!unReadCount > 0 ? unReadCount + 1 : 1 }
          const Initial = notification?.map(e => e?.roomId)
          if (isChatOpen) {
            if (Initial?.includes(res?.subroom) === false) {
              !!isChatOpen && isChatOpen && chatStatusUpdate({ subroom: res?.subroom })
              setNotification(prev => [...notification, { roomId: storedSubroom, isRead: true, count: 0 }])
            }
          }
          else {
            if (Initial?.includes(res?.subroom) === false) {
              setNotification([...notification, { roomId: storedSubroom, ...value1 }])
            } else {
              const innerInitial = notification?.filter(e => e?.roomId !== res?.subroom)
              setNotification([...innerInitial, value1])
            }
          }
        }
      });

      socket.emit('getRooms');
    }
  }, [socket, messages, subroom, username, notification]);

  const chatStatusUpdate = ({ subroom }) => {
    socket.emit('ChatOpenStatus', { username, room, subroom, type: 'staff' });
    return ""
  }
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  const joinRoom = (prop) => {
    if (socket) {
      setMessages([]);
      const subRoomId = !!prop ? prop : subroom
      localStorage.setItem('username', staffName);
      localStorage.setItem('subroom', subRoomId);
      setSubroom(subRoomId)
      localStorage.setItem('room', roomId);
      setRoom(roomId);

      socket.emit('joinRoom', { username, room, subroom: subRoomId, type: 'staff' })
      setStarted(true);
      setChatLoader(true)
      socket.on('subroomMessages', (subroomMsgs) => {
        setMessages(subroomMsgs);
      });
      chatStatusUpdate({ subroom: subRoomId })
    }
  };
  useEffect(() => {
    chatLoader && setTimeout(() => {
      setChatLoader(false)
    }, 500)
  }, [chatLoader])

  const sendSubroomMessage = () => {
    if (socket) {
      socket.emit('sendSubroomMessage', { message: message?.trim(), room, subroom });
      setMessage('');
    }
  };
  // const leaveRoom = () => {
  //   if (socket) {
  //     socket.emit('leaveRoom');
  //     setMessages([]);
  //     setStarted(false);
  //     setUserPresent(true);
  //   }
  // };

  let GlobalOff = false

  const chatHandle = ({ roomDetail }) => {
    if (!staffAvailable) {
      return toast.error("Please Turn on the chat status to proceed further.")
    }
    else {
      const innerInitial = notification?.filter(e => e?.roomId !== roomDetail?.name)
      setNotification([...innerInitial, { roomId: roomDetail?.name, isRead: true, count: 0 }])
      if (roomDetail?.status !== "In chat") {
        setSubroom(roomDetail?.name)
        joinRoom(roomDetail?.name)
        setShowAvilableChat(true)
      } else {
        if (roomDetail?.replierName !== username) {
          toast.error("Unable to perform this action still other staff already started the chat with the customer.")
        }
        else {
          setSubroom(roomDetail?.name)
          joinRoom(roomDetail?.name)
          setShowAvilableChat(true)
        }
      }
    }
  }

  const handleKeypress = (e) => {
    if (e?.key === 'Enter' && e?.shiftKey) {
      setMessage((prevMessage) => prevMessage + '\n');
    }
    else {
      if (e?.keyCode === 13) {
        e?.preventDefault();
        !!message?.trim() && sendSubroomMessage();
      }
    }
  };
  const activeChats = []
  const activeUsers = []
  const availableChats = []
  var notifyCount = 0
  Object.keys(availableRooms)?.length > 0 && Object?.keys(availableRooms)?.map((e, i) => {
    if (e === room) {
      Object?.values(availableRooms)[i]?.map((e, j) => {
        if (e?.status !== "User left" && e?.replierName === username) {
          activeUsers?.push(e?.name)
          GlobalOff = true
          // const unReadCount = notification?.filter(res => res?.roomId === e?.name)?.[0]?.count;
          if (e?.adminNotify > 0) { notifyCount = notifyCount + 1 };
          return activeChats?.push(
            <li
              onClick={() => e?.status === "User left" ? console.log("") : chatHandle({ roomDetail: Object?.values(availableRooms)?.[i]?.[j] })}
              key={j}
              className={`flex justify-between p-1 items-center ${e?.status === "User left" ? " cursor-default" : "cursor-pointer"}`}
            >
              <div className='flex items-center self-center'>
                <div className={`status-icon inline-block ${e?.status === "waiting" ? " bg-red-600 " : e?.status === "Staff left" ? " bg-yellow-300" : e?.status === "User left" ? " bg-black-800" : "bg-green-500 "} rounded-full 
                  w-2.5 h-2.5 text-white text-center`}>
                </div>
                <span className='ml-2 relative top-[1px]'>{e.name}</span>
                {e?.adminNotify > 0 && <span className='p-1 w-6 h-6 bg-orange-500 rounded-full text-center ml-2 text-sm'>{e?.adminNotify}</span>}
              </div>
              <div className='arrow-mark'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-3 h-3">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
              </div>
            </li>
          )
        } else {
          if (e?.status === "waiting" || e?.status === "Staff left") {
            notifyCount = notifyCount + 1
          }
          if (!GlobalOff && e?.replierName !== "") { GlobalOff = true }
          return availableChats?.push(
            <li
              onClick={() => e?.status === "User left" ? console.log("") : chatHandle({ roomDetail: Object?.values(availableRooms)?.[i]?.[j] })}
              key={j}
              className={`flex justify-between p-1 items-center ${e?.status === "User left" ? " cursor-default" : "cursor-pointer"}`}
            >
              <div className='flex items-center'>
                <div className={`status-icon inline-block text-black ${e?.status === "waiting" ? " bg-red-600 " : e?.status === "Staff left" ? " bg-yellow-300" : e?.status === "User left" ? " bg-black-800" : "bg-green-500 "} rounded-full w-2.5 h-2.5`} ></div>
                <span className='ml-2 relative top-[1px]'>{e.name}</span>
              </div>
              <div className='arrow-mark'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-3 h-3">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>
              </div>
            </li>
          )
        }


      })
    }
    return ""
  })
  // useEffect(() => {
  //   // Check if the user has scrolled up
  //   const handleScroll = () => {
  //     if (chatContainerRef.current.scrollTop < chatContainerRef.current.scrollHeight - chatContainerRef.current.clientHeight - 100) {
  //       setShowMoveToBottomButton(true);
  //     } else {
  //       setShowMoveToBottomButton(false);
  //     }
  //   };
  //   chatContainerRef?.current?.addEventListener('scroll', handleScroll);
  //   return () => {
  //     chatContainerRef?.current?.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  // const scrollToBottom = () => {
  //   messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  //   setShowMoveToBottomButton(false);
  // };

  // const handleMoveToBottom = () => {
  //   scrollToBottom();
  // };
  const globalUserHandle = (prop) => {
    GlobalOff ? toast.error("Unable to perform this action, since some active chats are in progress.") : activeHandle(prop)
  }
  const msgSendHandle = () => {
    if (!!message && message?.trim()) {
      message?.trim()?.length < 4096 ? sendSubroomMessage() : toast.error("Your message is too long. Please edit it down to 4096 characters or split over multiple messages.")

    }
  }
  const closeModal = () => {
    setSubroom('')
    setStarted(false);
    setShowAvilableChat(false)
    localStorage.removeItem('subroom');
    setIsOpen(false);
  };

  return (<div className='bg-white'>
    <div className=' bg-black min-h-5 min-w-5 absolute bottom-[-30px] z-[1] right-10 max-md:right-1' onClick={() => setIsOpen(true)}>
      <div className='relative flex items-center gap-2 bg-primary p-2 rounded-full px-3 cursor-pointer'>
        <svg fill="#fff" width="35px" height="35px" viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg" ><title>chat</title>
          <path d="M26,4C12.7,4,2.1,13.8,2.1,25.9c0,3.8,1.1,7.4,2.9,10.6c0.3,0.5,0.4,1.1,0.2,1.7l-3.1,8.5 c-0.3,0.8,0.5,1.5,1.3,1.3l8.6-3.3c0.5-0.2,1.1-0.1,1.7,0.2c3.6,2,7.9,3.2,12.5,3.2C39.3,48,50,38.3,50,26.1C49.9,13.8,39.2,4,26,4z M14,30c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S16.2,30,14,30z M26,30c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S28.2,30,26,30z M38,30 c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S40.2,30,38,30z" />
        </svg>
        {!modalIsOpen && notifyCount > 0 ? <div className=' bg-[#FF0000] absolute inline-block rounded-full w-6 h-6 text-white text-center right-0 top-[-8px] font-bold'>
          {notifyCount > 0 ? notifyCount : '1'}
        </div> : ""}
      </div>
    </div>

    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => closeModal()}
      style={customStyles}
      ariaHideApp={false}
      contentLabel="Example Modal"
    >
      <div className='bg-white rounded-md chat-box-admin-side'>
        {loading ? <LoadingSpinner /> : <div className={`admin-side-chatbox ${showAvilableChat ? "show-chat" : ""}`}>
          <div className='flex items-center my-2 justify-end relative'>
            {/* <div className='hidden cursor-pointer back-icon' onClick={() => setShowAvilableChat(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-[20px] h-[20px]">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
              </svg>
            </div> */}
            {/* <div className='cursor-pointer close-chat-icon' onClick={()=>setShowAvilableChat(false)}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
        <path strokeLinecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
      </svg>
      </div> */}
            <div className={`staff-online flex items-center cursor-pointer ${showAvilableChat ? "chat-full-view" : ""}`}>
              <span className='mr-2'>Chat Status:</span>
              <div className='border-2 border-primary flex rounded-[4px]'>
                <div className={`py-1 px-2 ${staffAvailable ? "bg-primary text-white" : ""}`} onClick={() => globalUserHandle(true)}>ON</div>
                <div className={`py-1 px-2 ${!staffAvailable ? "bg-primary text-white" : ""}`} onClick={() => globalUserHandle(false)}>OFF</div>
              </div>
            </div>
          </div>
          <div className='relative flex border rounded-md border-gray-300 c-box'>
            <div className='user-list min-w-[30%] flex justify-between flex-col bg-[#f0f4f9] rounded-l'>
              <div className='p-3 chat-scroll'>
                <span className='font-bold'>Active Chats</span>
                <ul className='scroll max-h-[100px] min-h-[100px]'>{
                  activeChats?.length > 0 ? activeChats?.map(e => e) : <span className='text-[13px] italic text-gray-500'>There is no active chats</span>
                }</ul>
              </div>
              <div className='p-3 chat-scroll'>
                <span className='font-bold'>Available Chats</span>
                <ul className='scroll max-h-[150px] min-h-[100px]'>{
                  availableChats?.length > 0 ? availableChats?.map(e => e) : <span className='text-[13px] italic text-gray-500'>There is no available chats</span>
                }</ul>
              </div>
            </div>
            <div className='chat-body min-w-[70%]  max-w-[70%]'>
              {started && subroom ? <>
                <div className='flex p-4 gap-2 chat_user_name'>
                  <div className='hidden cursor-pointer back-icon' onClick={() => setShowAvilableChat(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-[20px] h-[20px]">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                  </div>
                  <span className='font-bold '>{subroom}</span>
                  {/* <button onClick={leaveRoom}>End Chat</button> */}
                </div>
                {chatLoader ? <LoadingSpinner chatLoader={true} /> : <>
                  <div className="chat-messages min-h-[260px] max-h-[260px] scroll"
                    ref={chatContainerRef}
                  >
                    {messages?.map((msg, index) => (
                      <div key={index} style={{ whiteSpace: 'pre-line', lineHeight: '0.8' }} className={`flex mx-1 my-2 text-white ${msg?.username === username ? "justify-end" : 'justify-start'}`}>
                        <div className={`relative flex flex-col max-w-[90%]  ${msg?.username === username ? "text-right right-0  justify-end items-end" : "text-left"} `}>
                          {msg?.username !== username && <span className='flex text-xs text-black-500 font-bold mb-1'>
                            {msg?.username}
                          </span>}
                          <div>
                            <span className={`rounded inline-block p-[8px] text-left text-[14px] leading-normal text-[#1a1a1a] ${msg?.username === username ? 'bg-[#f0eafb]' : 'bg-[#fcf0e6]'}`}>
                              {msg?.text}
                            </span>
                          </div>
                          <span className='flex text-xs text-black-500 font-bold mt-1'>
                            {msg?.time}</span>
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                    {/* {showMoveToBottomButton && (
                    <button
                      style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        zIndex: '1',
                      }}
                      className=''
                      onClick={handleMoveToBottom}
                    >
                      Move to Bottom
                    </button>
                  )} */}
                  </div>
                  {staffAvailable ? activeUsers?.includes(subroom) ? <div className='flex justify-between items-end p-3 gap-3 inputbox'>
                    <textarea
                      type="text"
                      placeholder="Type your message..."
                      value={message || ''}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={handleKeypress}
                      className='resize-none h-[42px] w-full'
                    />
                    <button onClick={msgSendHandle}
                      className={`${message?.trim()?.length ? "" : "opacity-70"} min-h-[20px] inline-flex items-center justify-center p-2  border border-primary bg-primary rounded-full text-sm font-medium text-white shadow-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto`} >
                      <svg fill="#fff" width="25px" height="25px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" ><title>chat</title>
                        <path d="M2,3v7.8L18,12L2,13.2V21l20-9L2,3z" />
                      </svg>
                    </button>
                  </div> : <div className='p-2 text-center bg-black-900 text-white'>
                    This customer left the chat </div> : <div className='p-2'>
                    There is no staff available. Kindly check staff, if staff are available enable active staff!
                  </div>}</>}
              </> : <div className='chat-empty relative  h-full flex justify-center items-center'>
                Select customer to chat
              </div>
              }
            </div>
          </div>
          <div className='user-status flex flex-wrap bottom-0 p-3 justify-between'>
            <div className='m-1 max-md:w-1/3'>In chat <div className='inline-block bg-green-500 rounded-full w-2.5 h-2.5' /></div>
            <div className='m-1 max-md:w-1/3'>Waiting <div className='inline-block bg-red-600 rounded-full w-2.5 h-2.5' /></div>
            <div className='m-1 max-md:w-1/3'>Staff left <div className='inline-block bg-yellow-300 rounded-full w-2.5 h-2.5' /></div>
            <div className='m-1 max-md:w-1/3'>User left <div className='inline-block bg-black-800 rounded-full w-2.5 h-2.5' /></div>
          </div>
        </div>}
      </div>
    </Modal>
  </div>

  );
};

export default StaffChat;