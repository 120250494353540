/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { directus } from "../../services/directus";
import LayoutComponent from "../../components/LayoutComponent";
import UserContext from "../../contexts/userContext";
import Seo from "../../components/Seo";
import OfferCreateAndEdit from "../../components/usersForm/OfferCreateAndEdit";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import { userPresent } from "../../utils/CommonFunctions";
function OffersCreate() {
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [coupon, setCoupon] = React.useState("");
  const [offerImage, setOfferImage] = React.useState("");
  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
  const [offerImageSize, setOfferImageSize] = React.useState("");
  const { details, AuthCheck, companyDetails } = React.useContext(UserContext);
  const branch = details?.default_branch?.id;
  const [validationError, setValidationError] = React.useState({
    dateError: false,
    descriptionError: false,
    offerImageError: false,
    couponError: false,
  });

  const createHandle = async () => {
    if (!!dateFrom && !!description) {
      const LoggedCheck = await userPresent({ details: details });
      if (LoggedCheck?.status) {
        const imageId = offerImage ? await createImageHandle(offerImage) : null;
        await directus
          .items("Offers_and_Promotions")
          .createOne({
            date_from: dateFrom[0],
            date_to: dateFrom[1],
            short_description: description,
            coupon_code: coupon,
            offer_image: imageId,
            branch_info: branch,
          })
          .then((res) => {
            navigate(
              `/${companyDetails?.company}/${companyDetails?.branch}/offers/manage`
            );
            toast.success(" Created successfully");
          })
          .catch((err) => {
            navigate(".", { replace: true });
            console.log(err);
            toast.error(err?.message);
          });
      } else {
        toast.error(
          "Your account was suspended, kindly contact the respective manager/admin."
        );
      }
    }
  };
  const createImageHandle = async (props) => {
    const formData = new FormData();
    formData.append("folder", props);
    const val = await directus.files
      .createOne(formData)
      .then((res) => {
        return res?.id;
      })
      .catch((err) => {
        console.log("Image create error", err?.message);
      });
    return val;
  };
  React.useEffect(() => {
    AuthCheck();
    var { dateError, descriptionError, offerImageError, couponError } =
      validationError;
    if (!!dateFrom?.length > 0 && !!description) {
      if (!(dateError || descriptionError || offerImageError || couponError)) {
        setDetailsConfirmModel(true);
      } else {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);
  return (
    <LayoutComponent
      title={"CREATE OFFERS & PROMOTIONS"}
      fixedFooter={true}
      center={true}
    >
      <Seo title={"Create Offers&Promotions"} company={true} />
      <OfferCreateAndEdit
        dateFrom={dateFrom}
        description={description}
        coupon={coupon}
        validationError={validationError}
        setDateFrom={setDateFrom}
        setDescription={setDescription}
        setCoupon={setCoupon}
        setValidationError={setValidationError}
        offerImage={offerImage}
        offerImageSize={offerImageSize}
        setOfferImageSize={setOfferImageSize}
        setOfferImage={setOfferImage}
        type="Create"
        isUpdate={true}
      />
      {detailsConfirmModel ? (
        <ConfirmationModal
          openModel={detailsConfirmModel}
          setIsOpenModel={setDetailsConfirmModel}
          callFunction={createHandle}
          content={"Are you sure the given offer information is valid?"}
        />
      ) : null}
    </LayoutComponent>
  );
}

export default OffersCreate;
