/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LayoutComponent from "../components/LayoutComponent";
import LoadingSpinner from "../components/LoadingSpinner";
import Seo from "../components/Seo";
import UserContext from "../contexts/userContext";
import { directus } from "../services/directus";
import { toast } from "react-toastify";
import { userPresent } from "../utils/CommonFunctions";

function EditValetFee(props) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [valetPlatformCharge, setValetPlatformCharge] = React.useState();
  const [valetFee, setValetFee] = React.useState();
  const [preventDouble, setPreventDouble] = React.useState(false);
  const { details, companyDetails,commonInfo,BranchDataUpdate } = React.useContext(UserContext);
  const [taxPercent, setTaxPercent] = React.useState();
  const branch = details?.default_branch?.id;
  //Based on Refernce breakup calculation with subject to minimum Rs.3
  const valetParkingCharges = Number(Number(Number(valetFee)*(100/(100+Number(taxPercent? taxPercent: 18))))?.toFixed(2))
  const GSTForValetParking = Number((Number(valetFee)-Number(valetParkingCharges))?.toFixed(2))
  const commissionPGAmt =  Number(-(Number(Number(valetFee)*(((100+Number(taxPercent? taxPercent: 18))/100)*1/100))?.toFixed(2)))
  const GSTCommissionPGAmt = Number((-(Number(Number(valetFee)*(((100+Number(taxPercent? taxPercent: 18))/100)*1/100))?.toFixed(2)) * (taxPercent/100))?.toFixed(2))
  const platformCharge =  Number(-(Number((valetParkingCharges * ((valetPlatformCharge? valetPlatformCharge :20)/100))?.toFixed(2) )<3 ? 
      3 : (valetParkingCharges*((valetPlatformCharge? valetPlatformCharge :20)/100))?.toFixed(2)))
  const GSTPlatformCharges = Number((platformCharge*Number(taxPercent? taxPercent: 18)/100)?.toFixed(2))
  const valetFeeEx = [
    {
      'name': "Valet Parking Charges",
      'value': valetParkingCharges,
      'value1':valetParkingCharges,
      'iscolor':false
    },
    {
      'name': `GST ${taxPercent? taxPercent: 18}% for Cost of Valet Parking`,
      'value': GSTForValetParking,
      'value1': GSTForValetParking,
      'iscolor':false
    },
    {
      "name": `Total Payment Received from Customers + GST ${taxPercent? taxPercent: 18}%`,
      'value': valetFee,
      'value1': valetFee,
      'iscolor':true
    },
    {
      'name': "Commission for Payment Gateway on Whole AMT",
      'value': commissionPGAmt,
      'value1': 0,
      'iscolor':false
    },
    {
      'name': "GST on Commission on for Payment Gateway",
      'value': GSTCommissionPGAmt,
      'value1': 0,
      'iscolor':false
    },
    {
      'name': `Platform Charges (EWall) ${valetPlatformCharge? valetPlatformCharge :20}%`,
      'value': platformCharge,
      'value1': platformCharge,
      'iscolor':false
    },
    {
      'name': "GST on Platform Charges",
      'value': GSTPlatformCharges,
      'value1': GSTPlatformCharges,
      'iscolor':false
    },
    {
      'name': "Total Deduction",
      'value': Number((-GSTForValetParking)+commissionPGAmt+GSTCommissionPGAmt+platformCharge+GSTPlatformCharges),
      'value1': Number((-GSTForValetParking)+platformCharge+GSTPlatformCharges),
      'iscolor':true
    },
    {
      'name': "Amount Payable for you ",
      'value': Number(valetFee) + Number((-GSTForValetParking)+commissionPGAmt+GSTCommissionPGAmt+platformCharge+GSTPlatformCharges),
      'value1':Number(valetFee) + Number((-GSTForValetParking)+platformCharge+GSTPlatformCharges),
      'iscolor':true
    }
  ];

  React.useEffect(() => {
    if (companyDetails?.company !== undefined) {
      setValetPlatformCharge(companyDetails?.branchPlatformCharges);
      setValetFee(companyDetails?.branchValetFee);
      setTaxPercent(commonInfo?.tax_config_in_percent)
      setIsLoading(false);
    }
  }, [companyDetails?.company]);

  const submitHandle = async (e) => {
    const bodyValue = {
      valet_fee : Number(valetFee ? valetFee : 0)
    };
    if (branch) {
      const LoggedCheck = await userPresent({ details: details });
      if (LoggedCheck?.status) {
        setPreventDouble(true);
        await directus
          .items("Branch")
          .updateOne(Number(branch), bodyValue)
          .then((res) => {
            res?.id&&res?.company_id&&BranchDataUpdate({default_branch:{id:res?.id},default_company:{id:res?.company_id}});
            setPreventDouble(false);
            toast.success(" Updated successfully");
          })
          .catch((err) => {
            console.log(err);
            toast.error(err, {
              onClose: () => setPreventDouble(false),
            });
          });
      } else {
        toast.error(
          "Your account was suspended, kindly contact the respective manager/admin."
        );
      }
    } else {
      toast.error("Something went wrong", {
        onClose: () => setPreventDouble(false),
      });
    }
  };

  return (
    <LayoutComponent title={"Edit Fee"} fixedFooter={true} center={true}>
      <Seo title={"Edit Valet Number"} company={true} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="valet-num-section space-y-6 bg-white py-6 px-6 lg:px-8 h-full">
          <div className="flex justify-center items-center flex-col">
            <div className="flex justify-center items-center flex-col gap-2">
              <label className="font-bold" htmlFor="valet-fee">Valet Fee</label>
              <input
                type="text"
                name="user-name"
                id="valet-fee"
                pattern="[0-9]*" 
                inputMode="numeric"
                value={valetFee}
                onChange={(e) =>
                  /^\d*\.?\d*$/.test(e.target.value) &&
                  setValetFee(e.target.value)
                }
                maxLength={6}
                placeholder={"Enter your valet Fee"}
                className={`border-gray-300 focus:border-primary px-4 py-1.5 min-w-[315px] text-2xl block w-full  max-w-[315px] text-center rounded-md border shadow-sm border-gray-300 focus:border-primary h-12 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
              />  
            </div>
          </div>
          {valetFee >0 &&
            <div className="flex justify-center items-center flex-col">
                <div className="font-bold">Please check the fee breakup calculation</div>
                <div className="list py-6 text-sm">
                  <table className="w-full">
                  <tr>
                    <th className="text-left p-2 md:p-4 border border-1 border-[#5A5A5A]">Ledger Details</th>
                    <th className="text-left p-2 md:p-4 border border-1 border-[#5A5A5A]">Online</th>
                    <th className="text-left p-2 md:p-4 border border-1 border-[#5A5A5A]">Cash</th>
                  </tr>
                  {valetFeeEx?.map((e,i) =>(
                    <tr key={i} style={{backgroundColor: e.iscolor===true ? "#e5e5e5":"#FFFFFF", fontWeight:e.iscolor===true ? "bold":"normal" }}>
                        <td className="text-left p-2 md:p-4 border border-1 border-[#5A5A5A]">{e?.name}</td>
                        <td className={`text-left p-2 md:p-4 border border-1 border-[#5A5A5A] ${Number(e?.value) <0 ? "text-[red]":""}`}>Rs.{Number(e?.value)?.toFixed(2)}</td>
                        <td className={`text-left p-2 md:p-4 border border-1 border-[#5A5A5A] ${Number(e?.value1) <0 ? "text-[red]":""}`}>Rs.{Number(e?.value1)?.toFixed(2)}</td>
                      </tr>
                  ))}
                  </table>
                 <div className="text-[red] text-sm text-left py-5"> <span className="font-bold block">Note:</span>  The last payable settlement to you will be considered based on the deduction of cash payments collected for the platform charges with GST.</div>
                </div>
            </div>
          }
          <div className="form__actions flex justify-center items-center gap-5 mt-10">
            <button
              onClick={() => submitHandle()}
              disabled={preventDouble}
              className={`inline-flex items-center justify-center rounded-md border-transparent h-10 border-2 bg-primary px-4 py-2 text-sm font-semibold text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto`}
            >
              {"Submit"}{" "}
            </button>
          </div>
        </div>
      )}
    </LayoutComponent>
  );
}

export default EditValetFee;
