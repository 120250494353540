import React from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import NotFoundPage from "./NotFoundPage";
import Seo from "../components/Seo";
import CustomerLayout from "../components/CustomerLayout";
import seoData from '../seoContent.json';
import { Link } from "react-router-dom";

function TermAndCondtions() {
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState("");
  const val = window.location.href.split("/");
  React.useEffect(() => {
    setUser(val[3]);
    setLoading(false);
  }, [val]);
  return (
    <>
      <Seo title={seoData?.partner_terms?.title} />
      {user === "customer" ? (
        <CustomerLayout footer={false} head={true}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="p-6 lg:p-8 flex justify-center flex-col text-black-700">
              <b className="text-black-800">Terms and Conditions :</b>
              <br />
              1. Scope of Agreement by registering as a user with ValetSearch,
              you agree to be bound by these terms and conditions, as well as
              any additional terms and policies posted on the ValetSearch
              website. This agreement constitutes a legally binding contract
              between you and ValetSearch.
              <br />
              <br />
              2. Service ValetSearch provides a digital valet parking
              application that enables users to reserve and pay for parking in
              advance. Users are responsible for providing accurate and
              up-to-date information to ValetSearch, and for complying with all
              applicable laws and regulations related to parking.
              <br />
              <br />
              3. Fees and Payments users are responsible for paying all fees
              associated with their use of the ValetSearch platform. Payment
              must be made in advance, and failure to pay may result in the
              cancellation of your parking reservation.
              <br />
              <br />
              4. Liability and Indemnification users agree to indemnify and hold
              harmless ValetSearch and its affiliates, officers, directors,
              employees, and agents from any and all claims, damages,
              liabilities, costs, and expenses arising from their use of the
              ValetSearch platform.
              <br />
              <br />
              5. Termination either party may terminate this agreement at any
              time, for any reason, upon written notice to the other party. Upon
              termination, users must immediately cease using the ValetSearch
              platform and delete any associated data from their devices.
            </div>
          )}
        </CustomerLayout>
      ) : user === "partner" ? (
        <CustomerLayout footer={false} head={true}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="p-6 lg:p-8 flex justify-center flex-col text-black-700">
              <b className="text-black-800">Terms and Conditions :</b>
              <br />
              1. Scope of Agreement by registering as a vendor with Valet
              Search, you agree to be bound by these terms and conditions, as
              well as any additional terms and policies posted on the Valet
              Search website. This agreement constitutes a legally binding
              contract between you and ValetSearch.
              <br />
              <br />
              2. Service ValetSearch provides a digital valet parking
              application that enables vendors to manage their parking
              operations more efficiently. As a vendor, you are responsible for
              providing high-quality parking services to ValetSearch users, and
              for ensuring that all information you provide to ValetSearch is
              accurate and up-to-date.
              <br />
              <br />
              3. Fees and Payments ValetSearch charges a fee for each parking
              reservation made through the ValetSearch application. The fee
              amount will be determined by ValetSearch and may be subject to
              change. ValetSearch will remit payment to vendors on a regular
              basis, as outlined in the vendor agreement.
              <br />
              <br />
              4. Liability and Indemnification vendors agree to indemnify and
              hold harmless ValetSearch and its affiliates, officers,
              directors, employees, and agents from any and all claims, damages,
              liabilities, costs, and expenses arising from their use of the
              ValetSearch platform.
              <br />
              <br />
              5. Termination either party may terminate this agreement at any
              time, for any reason, upon written notice to the other party. Upon
              termination, vendors must immediately cease using the ValetSearch
              platform and return any materials provided by ValetSearch.
            </div>
          )}
        </CustomerLayout>
      ) : user === "general" ? (
        <CustomerLayout footer={false} head={true}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="p-6 lg:p-8 flex justify-center flex-col text-black-700">
              <b className="text-black-800">Terms and Conditions :</b>
              <br />
              <strong>1. Introduction</strong> <br />
              <span>Welcome to the ValetSearch website <Link className="text-primary" to="/">(https://valetsearch.com/)</Link>.
                This website is owned and operated by EWall Solutions Pvt. Ltd. ("EWall", "we", "us", or "our").
                By accessing or using the Website, you agree to be bound by these Terms and Conditions
                ("Terms"). Please review these Terms carefully before using the Website.</span>
              <br />
              <strong>2. Access and Use of the Website</strong> <br />
              <span>Subject to these Terms, EWall grants you a non-exclusive, non-transferable, limited license to access
                and use the Website for your personal, non-commercial use. You may not use the Website for any illegal
                or unauthorized purpose. You agree not to:</span>
              <ul className="list-disc ml-[5%]">
                <li>Access or attempt to access the Website or any portion of the Website through any automated means, including but
                  not limited to the use of bots, scripts, or other automated tools.</li>
                <li>Reverse engineer, decompile, disassemble, modify, or translate any portion of the Website.</li>
                <li>Introduce or attempt to introduce any unauthorized software, virus, worm, Trojan horse, or other harmful code onto the Website.</li>
                <li>Violate any applicable law or regulation.</li>
              </ul>
              <br />
              <strong>3. Valet Parking Services</strong> <br />
              <span>Through the Website, you may search for and book valet parking services offered by third-party
              providers ("Providers"). EWall does not provide any valet parking services itself. EWall is not responsible
              for the quality, reliability, or safety of the valet parking services offered by Providers.</span>
              <br />
              <strong>4. Booking and Payment</strong> <br />
              <span>When you book a valet parking service through the Website, you are entering into a contract directly with
              the Provider. EWall is not a party to the contract and does not assume any responsibility for the performance
              of the Provider's services.</span> <br />
              <span>All bookings are subject to the availability of the Provider and the terms and conditions of the Provider. EWall is
              not responsible for any cancellations or modifications to bookings made by the Provider.</span> <br />
              <span>Payments for valet parking services booked through the Website will be processed by the Provider. EWall is
              not responsible for any errors or omissions in the payment process.</span>
              <br />
              <strong>5. Disclaimer of Warranties</strong> <br />
              <span>THE WEBSITE AND ALL INFORMATION, CONTENT, AND MATERIALS AVAILABLE ON THE WEBSITE ARE PROVIDED "AS IS" AND
              WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. EWall DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
              NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</span>
              <br />
              <strong>6. Limitation of Liability</strong> <br />
              <span>IN NO EVENT WILL EWall BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES,
              ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF THE WEBSITE, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE,
              EVEN IF EWall HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span>
              <br />
              <strong>7. Indemnification</strong> <br />
              <span>You agree to indemnify, defend, and hold harmless EWall and its officers, directors, employees, agents, licensors, and suppliers from and
              against all losses, expenses, damages, and costs, including reasonable attorneys' fees, arising out of or in connection with your use of the
              Website or your violation of these Terms.</span>
              <br />
              <strong>8. Governing Law and Dispute Resolution</strong> <br />
              <span>These Terms shall be governed by and construed by the laws of India, without regard to its conflict of laws provisions. Any dispute arising out
              of or relating to these Terms will be resolved exclusively by the courts of India.</span>
              <br />
              <strong>9. Entire Agreement</strong> <br />
              <span>These Terms constitute the entire agreement between you and EWall relating to your use of the Website.</span>
              <br />
              <strong>10. Amendment</strong> <br />
              <span>EWall may amend these Terms at any time by posting the amended Terms on the Website. Your continued use of
              the Website after the posting of the amended Terms constitutes your acceptance of the amended Terms.</span>
              <br />
              <strong>11. Contact us</strong> <br />
              <span>If you have any questions about these Terms, please contact us at support[at]ewallsolutions.com.</span>
            </div>
          )}
        </CustomerLayout>
      )
        : (
          <NotFoundPage />
        )}
    </>
  );
}

export default TermAndCondtions;
