/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { directus } from "../../services/directus";
import LayoutComponent from "../../components/LayoutComponent";
import UserContext from "../../contexts/userContext";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import ConfirmationModal from "../../components/popupModals/ConfirmationModal";
import OfferCreateAndEdit from "../../components/usersForm/OfferCreateAndEdit";
import { getAssetURL } from "../../utils/get-asset-url";
import { userPresent } from "../../utils/CommonFunctions";

function OffersEdit() {
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = React.useState([]);
  const [description, setDescription] = React.useState("");
  const [coupon, setCoupon] = React.useState("");
  const [offerImage, setOfferImage] = React.useState("");
  const [detailsConfirmModel, setDetailsConfirmModel] = React.useState(false);
  const [offerImageSize, setOfferImageSize] = React.useState("");
  const [offerDetails, setOfferDetails] = React.useState("");
  const { state } = useLocation();
  const { details, AuthCheck, companyDetails } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const branch = details?.default_branch?.id;
  const [validationError, setValidationError] = React.useState({
    dateError: false,
    descriptionError: false,
    offerImageError: false,
    couponError: false,
  });
  const updateHandle = async () => {
    const LoggedCheck = await userPresent({ details: details });
    if (LoggedCheck?.status) {
      const imageId =
        offerDetails?.offer_image !== offerImage?.preview?.imageId &&
        offerImage !== ""
          ? await createImageHandle(offerImage)
          : null;
      const updateFields = {
        date_from: dateFrom[0],
        date_to: dateFrom[1],
        short_description: description,
        coupon_code: coupon,
      };
      if(imageId){
        updateFields['offer_image'] = imageId
      }else if(offerImage?.preview?.imageId === undefined || offerImage?.preview?.imageId === null){
        updateFields['offer_image'] = null
      }else{}
      await directus
        .items("Offers_and_Promotions")
        .updateOne(state, updateFields)
        .then((res) => {
          navigate(
            `/${companyDetails?.company}/${companyDetails?.branch}/offers/manage`
          );
          toast.success(" Updated successfully");
        })
        .catch((err) => {
          navigate(".", { replace: true });
          toast.error(err?.message);
        });
    } else {
      toast.error(
        "Your account was suspended, kindly contact the respective manager/admin."
      );
    }
  };
  const createImageHandle = async (props) => {
    const formData = new FormData();
    formData.append("folder", props);
    const val = await directus.files
      .createOne(formData)
      .then((res) => {
        return res?.id;
      })
      .catch((err) => {
        console.log("Image create error", err?.message);
      });
    return val;
  };
  React.useEffect(() => {
    AuthCheck();
    OfferDetail();
  }, []);
  const OfferDetail = async () => {
    await directus
      .items("Offers_and_Promotions")
      .readByQuery({
        fields: ["*"],
        filter: {
          id: { _eq: state },
          branch_info: { _eq: branch },
        },
      })
      .then((res) => {
        setOfferDetails(res?.data[0]);
        res?.data[0]?.date_from &&
          res?.data[0]?.date_to &&
          setDateFrom([
            new Date(res?.data[0]?.date_from),
            new Date(res?.data[0]?.date_to),
          ]);
        setDescription(res?.data[0]?.short_description);
        setCoupon(res?.data[0]?.coupon_code);
        setOfferImage({
          preview: {
            type: "image",
            url: getAssetURL(res?.data[0]?.offer_image),
            imageId: res?.data[0]?.offer_image,
          },
        });
      })
      .catch((e) => console.log(e));
    setIsLoading(false);
  };
  React.useEffect(() => {
    AuthCheck();
    var { dateError, descriptionError, offerImageError, couponError } =
      validationError;
    if (!!dateFrom?.length > 0 && !!description) {
      if (!(dateError || descriptionError || offerImageError || couponError)) {
        setDetailsConfirmModel(true);
      } else {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);
  const UrlDate = (prop) => {
    const localDate = new Date(prop);
    var d = localDate.getDate();
    var m = localDate.getMonth() + 1;
    var y = localDate.getFullYear();
    const date =
      (d <= 9 ? "0" + d : d) + "/" + (m <= 9 ? "0" + m : m) + "/" + y;
    return date;
  };
  return (
    <LayoutComponent
      title={"EDIT OFFERS & PROMOTIONS"}
      fixedFooter={true}
      center={true}
    >
      <Seo title={"Edit Offers&Promotions"} company={true} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <OfferCreateAndEdit
          dateFrom={dateFrom}
          description={description}
          coupon={coupon}
          validationError={validationError}
          setDateFrom={setDateFrom}
          setDescription={setDescription}
          setCoupon={setCoupon}
          setValidationError={setValidationError}
          offerImage={offerImage}
          offerImageSize={offerImageSize}
          setOfferImageSize={setOfferImageSize}
          setOfferImage={setOfferImage}
          type="Update"
          isUpdate={
            UrlDate(offerDetails?.date_from) !== UrlDate(dateFrom?.[0]) ||
            UrlDate(offerDetails?.date_to) !== UrlDate(dateFrom?.[1]) ||
            offerDetails?.coupon_code !== coupon ||
            offerDetails?.offer_image !== offerImage?.preview?.imageId ||
            offerDetails?.short_description !== description
              ? true
              : false
          }
        />
      )}
      {detailsConfirmModel ? (
        <ConfirmationModal
          openModel={detailsConfirmModel}
          setIsOpenModel={setDetailsConfirmModel}
          callFunction={updateHandle}
          content={"Are you sure the given offer information is valid?"}
        />
      ) : null}
    </LayoutComponent>
  );
}

export default OffersEdit;
