/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from "react";
import { toast } from "react-toastify";
import { directus } from "../services/directus";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import Timer from "react-compound-timer/build";
import LayoutComponent from "../components/LayoutComponent";
import UserContext from "../contexts/userContext";
import Seo from "../components/Seo";
import AssignAndConfirmModal from "../components/popupModals/AssignAndConfirm";
import Pagination from "../components/Pagination";
import { userPresent } from "../utils/CommonFunctions";
import moment from "moment";

function ValetList() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [valetData, setValetData] = React.useState([]);
  const [tax, setTax] = React.useState();
  const [activeIndex, setActiveIndex] = React.useState(
    new URLSearchParams(window.location.search).get("id") || "1"
  );
  const [vehicleData, setVehicleData] = React.useState([]);
  const [pagination, setPagination] = React.useState();
  const [page, setPage] = React.useState(1);
  const [allUsers, setAllUsers] = React.useState([]);
  const [reloadData, setReloadData] = React.useState(false);
  const [statusCard, setStatusCard] = React.useState([]);

  const [assignedStaff, setAssignedStaff] = React.useState({
    value: "-1",
    label: "Select the staff",
  });
  const navigate = useNavigate();
  const { currentUserDetails, details, AuthCheck, companyDetails,commonInfo } =
    React.useContext(UserContext);
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;

  const paginationCount = 10;
  const valetDatasCall = async (prop) => {
    AuthCheck();
    const filterValue =
      activeIndex === "1"
        ? 1
        : activeIndex === "2"
          ? 2
          : activeIndex === "3"
            ? 3
            : activeIndex === "4"
              ? 4
              : activeIndex === "5"
                ? 5
                : 6;
    const filterBody = details
      ? details?.is_staff_user
        ? {
            status_id: { sequence: { _eq: filterValue } },
            branch: { _eq: branch ? branch : prop?.branch },
            company: { _eq: company ? company : prop?.company },
            Assigned_Staff: { first_name: { _eq: details?.first_name } },
          }
        : {
            status_id: { sequence: { _eq: filterValue } },
            branch: { _eq: branch ? branch : prop?.branch },
            company: { _eq: company ? company : prop?.company },
          }
      : prop?.is_staff_user
      ? {
          status_id: { sequence: { _eq: filterValue } },
          branch: { _eq: branch ? branch : prop?.branch },
          company: { _eq: company ? company : prop?.company },
          Assigned_Staff: { first_name: { _eq: prop?.first_name } },
        }
      : {
          status_id: { sequence: { _eq: filterValue } },
          branch: { _eq: branch ? branch : prop?.branch },
        };
    const statusFilterBody = details
      ? details?.is_staff_user
        ? {
            related_valet_information: {
              _filter: {
                branch: { _eq: branch ? branch : prop?.branch },
                company: { _eq: company ? company : prop?.company },
                Assigned_Staff: { first_name: { _eq: details?.first_name } },
              },
            },
          }
        : {
            related_valet_information: {
              _filter: { company: { _eq: company ? company : prop?.company },
                            branch: { _eq: branch ? branch : prop?.branch }},
            },
          }
      : prop?.is_staff_user
      ? {
          related_valet_information: {
            _filter: {
              branch: { _eq: branch ? branch : prop?.branch },
              company: { _eq: company ? company : prop?.company },
              Assigned_Staff: { first_name: { _eq: prop?.first_name } },
            },
          },
        }
      : {
          related_valet_information: {
            _filter: { company: { _eq: company ? company : prop?.company },
            branch: { _eq: branch ? branch : prop?.branch },
          },
          },
        };
    await directus
      .items("Status")
      .readByQuery({
        fields: ["*"],
        sort: ["sequence"],
        deep: statusFilterBody,
      })
      .then((res) => {
        const statusCard1 = [];
        const statusCard2 = [];
        res?.data?.map((e, i) =>
          i < 2 ? statusCard1?.push(e) : statusCard2?.push(e)
        );
        setStatusCard({ statusCard1, statusCard2 });
        return "";
      })
      .catch((e) => console.log(e));
    await directus
      .items("Valet_Information")
      .readByQuery({
        fields: ["*",'Assigned_Staff.*',"status_id.*","status_id.next_status.*",'branch.*'],
        sort: activeIndex !== 3 ? ["-id"] : ["date_updated"],
        filter: filterBody,
        limit: paginationCount,
        page: page,
        meta: ["filter_count"],
      })
      .then((res) => {
        setPagination(Number(res?.meta?.filter_count));
        setValetData(res?.data);
        setIsLoading(true);
        return "";
      })
      .catch((e) => console.log(e));
  };
  const usersDatasCall = async (prop) => {
    const bodyValue = details?.is_admin_user
      ? {
          is_admin_user: { _eq: false },
          default_branch: { _eq: branch ? branch : prop?.branch },
          default_company: { _eq: company ? company : prop?.company },
          status: { _eq: "active" },
        }
      : {
          is_staff_user: { _eq: true },
          default_branch: { _eq: branch ? branch : prop?.branch },
          default_company: { _eq: company ? company : prop?.company },
          in_out_flag: true,
          status: { _eq: "active" },
        };
    await directus.users
      .readByQuery({
        fields: ["*"],
        filter: bodyValue,
      })
      .then((res) => {
        const PresetUsers = res?.data?.filter((e) => {
          if (details?.is_admin_user) {
            if (e?.is_manager_user === true) {
              return { value: e.id, label: e.first_name };
            } else {
              return (
                e?.in_out_flag === true && { value: e.id, label: e.first_name }
              );
            }
          } else {
            return { value: e.id, label: e.first_name };
          }
        });
        setAllUsers([
          ...PresetUsers?.map((e) => ({ value: e.id, label: e.first_name })),
        ]);
      })
      .catch((e) => console.log(e));
    prop?.id?.length > 1
      ? setAllUsers((e) => [...e, { value: prop?.id, label: prop?.first_name }])
      : setAllUsers((e) => [
          ...e,
          { value: details?.id, label: details?.first_name },
        ]); 
};
  const numPages =
    pagination % paginationCount === 0
      ? Math.floor(pagination / paginationCount)
      : Math.floor(pagination / paginationCount) + 1;

  const handleNavigation = (e) => {
    setPage(e);
    setActiveIndex(activeIndex);
    localStorage.setItem("id", `${activeIndex}`);
    return "";
  };
  React.useEffect(() => {
    AuthCheck();
    setIsLoading(false);
    const actId = localStorage?.getItem("id");
    if (actId !== activeIndex) {
      setPage(1);
      companyDetails &&
        navigate(
          `/${companyDetails?.company}/${companyDetails?.branch}/dashboard  `
        );
    }
    const currentUser = async () => {
      await directus.users.me
        .read({
          fields: ["id","first_name","default_branch.id","is_staff_user","default_company.id"],
        })
        .then((res) => {
          currentUserDetails(res);
          valetDatasCall({
            branch: res?.default_branch?.id,
            is_staff_user: res?.is_staff_user,
            first_name: res?.first_name,
            company: res?.default_company?.id,
          });
          usersDatasCall({
            branch: res?.default_branch?.id,
            is_staff_user: res?.is_staff_user,
            first_name: res?.first_name,
            id: res?.id,
            company: res?.default_company?.id,
          });
        })
        .catch((e) => console.log(e));
    };
    if (typeof branch !== "number") {
      currentUser();
    } else {
      valetDatasCall();
      usersDatasCall();
    }
  }, [activeIndex, page, reloadData]);
  React.useEffect(() => {
    branch && usersDatasCall();
    commonInfo&&setTax(commonInfo?.tax_config_in_percent);
  }, [companyDetails]);

  const pickupTimer = (prop) => {
    return (
      <Timer initialTime={Date.parse(new Date()) - Date.parse(new Date(prop))}>
        {({ start, resume, pause, stop, reset, timerState }) => {
          var vals = <Timer.Minutes />;
          return (
            // vals?._owner?.memoizedState?.d * (24 * 60) +
            // vals?._owner?.memoizedState?.h * 60 +
            vals?._owner?.memoizedState?.m
          );
        }}
      </Timer>
    );
  };

  const pickupTimerHours = (prop) => {
    return (
      <Timer initialTime={Date.parse(new Date()) - Date.parse(new Date(prop))}>
        {({ start, resume, pause, stop, reset, timerState }) => {
          var vals = <Timer.Seconds />;
          return (
            vals?._owner?.memoizedState?.d * (24)+
            vals?._owner?.memoizedState?.h
            // ,
            // vals?._owner?.memoizedState?.m,
            // vals?._owner?.memoizedState?.s
          );
        }}
      </Timer>
    );
  };

  const priorityQueue = () => {
    return valetData.sort(function (a, b) {
      var difference =
        new Date().getTime() - new Date(b.date_updated).getMinutes();
      var minutesDifference = Math.floor(difference / 1000 / 60);
      difference -= minutesDifference * 1000 * 60;
      return pickupTimer(b.date_updated) - pickupTimer(a.date_updated);
    });
  };
  priorityQueue();
  const pickupRequest = (prop) => {
    return (
      <div className="flex flex-col">
        <div className="mt-4 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle px-1">
            <div
              className={`overflow-hidden shadow-md ring-1 ring-black border ring-opacity-5 md:rounded-lg parking-status-table ${
                valetData?.length <= 0 ? "bordered" : "no-border"
              }`}
            >
              {valetData?.length ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-primary bg-opacity-5">
                    <tr>
                      <th
                        scope="col"
                        className="text-left p-4 text-sm font-semibold text-gray-900 uppercase"
                      >
                        Vehicle No
                      </th>
                      <th
                        scope="col"
                        className="text-left p-4 text-sm font-semibold text-gray-900 uppercase"
                      >
                        OTP
                      </th>
                      <th
                        scope="col"
                        className="text-right p-4 text-sm font-semibold text-gray-900 uppercase"
                      >
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {valetData?.map((e, i) => {
                      let Hours=e?.started_time && details?.is_staff_user !== true &&e?.pickup_request_assigned? pickupTimerHours(e?.started_time):pickupTimerHours(e?.date_updated) ?? ""
                      let Minutes=e?.started_time &&  details?.is_staff_user !== true &&  e?.pickup_request_assigned ? pickupTimer(e?.started_time):pickupTimer(e?.date_updated) ?? ""
                      return <tr
                        key={i}
                        className={`hover:bg-primary hover:bg-opacity-50 hover:text-black ${i === 0
                            ? "text-white !border bg-red-400 hover:bg-opacity-100 hover:bg-red-400 animate-pulse"
                            : i === 1
                              ? "bg-red-100 hover:bg-opacity-100 hover:bg-red-100"
                              : i === 2
                                ? "!border !border-red-400 hover:bg-opacity-100 hover:bg-transparent"
                                : "border-transparent"
                          } `}
                        onClick={() => modelOpenHandle(e)}
                      >
                        <td
                          className="whitespace-nowrap text-base p-4"
                          data-th="Vehicle No "
                        >
                          <span className="text-base md:text-[20px] tracking-[1px]">
                            {e?.Vehicle_Number}
                          </span>
                        </td>
                        <td
                          className="whitespace-nowrap text-sm p-4"
                          data-th="OTP "
                        >
                          <span className="text-base md:text-[20px] tracking-[1px]">
                            {e?.OTP_Value}
                          </span>
                        </td>
                        <td
                          className="whitespace-nowrap text-right text-base p-4 float-right"
                          data-th="Time"
                        >
                          <span className="text-black flex justify-end items-center font-medium min-h[40px] min-w-[40px] w-20 h-10 rounded">
                          {Hours}{
                          // Hours>1?" hours":
                          " hrs "}{Minutes}{
                          // Minutes>1?" minutes":
                          " mins"}
                          </span>
                        </td>
                      </tr>
                      }
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="whitespace-nowrap text-sm p-4 text-gray-500 flex justify-center">
                  {" "}
                  No data found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modelOpenHandle = (e) => {
    setVehicleData(e);
    setIsOpen(true);
  };
  const renderList = (prop) => {
    const lastTitle =
      activeIndex === "1"
        ? "Arrived At"
        : activeIndex === "2"
          ? "Left at"
          : activeIndex === "4" || activeIndex === "5"
            ? "Time"
            : "Delivered";
    return (
      <div className="flex flex-col">
        <div className="mt-4 md:mt-6 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle px-1">
            <div
              className={`overflow-hidden border shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg parking-status-table ${valetData?.length <= 0 ? "bordered" : "no-border"
                }`}
            >
              {valetData?.length ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="text-left p-4 text-sm font-semibold text-gray-900"
                      >
                        Vehicle No
                      </th>
                      <th
                        scope="col"
                        className="text-left p-4 text-sm font-semibold text-gray-900"
                      >
                        OTP
                      </th>
                      <th
                        scope="col"
                        className="text-right p-4 text-sm font-semibold text-gray-900"
                      >
                        {lastTitle}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white cursor-pointer">
                    {valetData?.map((e, i) => {
                      return (
                        <tr
                          className="hover:bg-primary hover:bg-opacity-5 "
                          key={i}
                          onClick={() => modelOpenHandle(e)}
                        >
                          <td
                            className="whitespace-nowrap text-sm px-4 py-2 md:p-4 text-gray-500"
                            data-th="Vehicle No "
                          >
                            <span className="text-black text-base md:text-[20px] tracking-[1px]">
                              {e?.Vehicle_Number}
                            </span>
                          </td>
                          <td
                            className="whitespace-nowrap text-sm px-4 py-2 md:p-4 text-gray-500"
                            data-th="OTP "
                          >
                            <span className="text-black text-base md:text-[20px] tracking-[1px]">
                              {e?.OTP_Value}
                            </span>
                          </td>
                         <td
                            className="whitespace-nowrap text-right text-lg px-4 py-2 md:p-4 text-gray-500 "
                            data-th={`${lastTitle} `}
                          >
                            {
                            activeIndex === "6"? <div className="timer text-black font-medium justify-end">
                            {moment(e?.date_updated ).format('YYYY-MM-DD, hh:mm A')}  </div>:
                           (e?.status_id?.sequence === 1 ? (
                              <div className="timer text-black font-medium justify-end">
                                {e?.started_time
                                  ? pickupTimerHours(e?.started_time) ?? ""
                                  : pickupTimerHours(
                                    e?.status_id?.sequence === 1
                                      ? e?.date_created
                                      : e?.date_updated
                                  ) ?? ""}{" hrs "}
                                  {e?.started_time
                                  ? pickupTimer(e?.started_time) ?? ""
                                  : pickupTimer(
                                    e?.status_id?.sequence === 1
                                      ? e?.date_created
                                      : e?.date_updated
                                  ) ?? ""}{" mins"}
                              </div>
                            ) : (
                              <div className="timer text-black font-medium justify-end">
                               {e?.date_updated
                                  ? pickupTimerHours(e?.date_updated)
                                  : ""}{" hrs "}
                                   {e?.date_updated
                                  ? pickupTimer(e?.date_updated)
                                  : ""}{" mins"}
                              </div>
                            ))}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="whitespace-nowrap text-sm p-4 text-gray-500 flex justify-center">
                  {" "}
                  No data found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const timeUpdate = async (prop) => {
    await directus
      .items("Valet_Information")
      .updateOne(Number(prop?.id), {
        started_time: prop?.date_updated,
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const statusUpdateHandle = async (prop) => {
    setIsLoading(true);
    const LoggedCheck = await userPresent({ details: details });
    if (LoggedCheck?.status) {
      if (!LoggedCheck?.present) {
        toast.error("You are not logged in. Kindly contact the respective manager/admin.");
      } else {
        if (vehicleData?.status_id?.next_status?.name !== undefined) {
          await directus
            .items("Valet_Information")
            .updateOne(Number(vehicleData?.id), {
              status_id: vehicleData?.status_id?.next_status?.id,
            })
            .then((res) => {
              toast.success("Vehicle status updated");
              setIsLoading(false);
              setActiveIndex(activeIndex);
            })
            .catch((err) => {
              navigate(".", { replace: true });
              console.log(err);
              toast.error("Something went wrong");
            });
          setIsOpen(false);
        } else {
          setIsLoading(false);
          toast.error("There is no status to update");
        }
      }
    } else {
      setIsLoading(false);
      toast.error("Your account was suspended, kindly contact the respective manager/admin.");
    }
    valetDatasCall();
  };

  const recordCashPayment = async(prop) =>{
    setIsLoading(true);
    const LoggedCheck = await userPresent({ details: details });
    if (LoggedCheck?.status) {
      if (!LoggedCheck?.present) {
        toast.error("You are not logged in. Kindly contact the respective manager/admin.");
      } else {
        if(vehicleData?.payment_status ==='not_paid'){
          await directus
          .items("Valet_Information")
          .updateOne(Number(vehicleData?.id), {
            payment_status: 'paid',
            valet_fee: Number(vehicleData?.branch?.valet_fee),
            tax_in_percent: tax,
            payment_mode :'cash'
          })
          .then((res) => {
            toast.success("Vehicle payment updated");
            setIsLoading(false);
            setActiveIndex(activeIndex);
          })
          .catch((err) => {
            navigate(".", { replace: true });
            console.log(err);
            toast.error("Something went wrong");
          });
          setIsOpen(false);
        }
        else{
          setIsLoading(false);
          toast.error("The payment for the vehicle is already done");
        }
      }
    }
    else {
      setIsLoading(false);
      toast.error("Your account was suspended, kindly contact the respective manager/admin.");
    }
    valetDatasCall();
  }

  const assignHandle = async () => {
    if (assignedStaff?.value === "-1" && details?.is_staff_user !== true) {
      return toast.error("Assigned Staff not provided!!!");
    } else {
      const LoggedCheck = await userPresent({ details: details });
      if (LoggedCheck?.status) {
        const bodyValue =
          vehicleData?.pickup_request_assigned === false &&
            vehicleData?.status_id?.sequence === 3
            ? {
              Assigned_Staff: { id: assignedStaff?.value },
              pickup_request_assigned: true,
            }
            : { Assigned_Staff: { id: assignedStaff?.value } };
        await directus
          .items("Valet_Information")
          .updateOne(Number(vehicleData?.id), bodyValue)
          .then(async (res) => {
            if (res?.status_id === 3) {
              setAssignedStaff(
                JSON.stringify({ value: "-1", label: "Select the staff" })
              );
              await timeUpdate(vehicleData);
              toast.success("Staff assigned successfully");
              setActiveIndex(activeIndex);
            } else {
              toast.success("Staff assigned successfully");
              setActiveIndex(activeIndex);
            }
          })
          .catch((err) => {
            navigate(".", { replace: true });
            console.log(err);
            toast.error("Something went wrong");
          });
        setIsLoading(false);
        valetDatasCall();
        setIsOpen(false);
      }
      else {
        toast.error("Your account was suspended, kindly contact the respective manager/admin.");
      }
    }
  };
  const statushandle = (prop) => {
    return (
      <li
        className={`border px-1 flex justify-center rounded-md gap-4 items-center ${prop?.length === 2
            ? "bg-primary bg-opacity-10 border-primary"
            : "bg-secondary bg-opacity-10 border-secondary"
          }`}
      >
        {prop?.map((e) => (
          <button
            key={e?.sequence}
            type="button"
            onClick={() => setActiveIndex(e?.sequence?.toString())}
            className={`px-1 border-b-4 py-2 min-h-[58px] text-[14px] font-medium uppercase relative ${activeIndex === e?.sequence?.toString()
                ? (activeIndex <= 2 ? "border-primary text-primary" : "border-secondary text-secondary")
                : "border-b-transparent text-gray-600"
              }`}
          >
            {e?.name}
            {e?.related_valet_information?.length > 0 && (
              <div
                className={`count-wrapper ${prop?.length === 2
                    ? "bg-primary "
                    : "bg-secondary count-show-down"
                  }
        ${activeIndex === e?.sequence?.toString()
                    ? (activeIndex <= 2 ? "border-primary text-primary" : "border-secondary text-secondary")
                    : "border-b-transparent text-gray-600"
                  }`}
              >
                <div className="inside-color">
                  {e?.related_valet_information?.length}
                </div>
              </div>
            )}
          </button>
        ))}
      </li>
    );
  };
  return (
    <>
      <LayoutComponent
        search={true}
        allUsers={allUsers}
        fixedFooter={true}
        setReloadData={setReloadData}
        reloadData={reloadData}
        middleWidth={false}
      >
        <Seo title={"Dashboard"} company={true} />
        {!isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="main-content-area p-4 md:p-8 pt-4">
              <div className="mx-auto">
                <div className="">
                  <div className="">
                    <ul className="valet-tabs list list-none w-full flex justify-between items-center flex-wrap">
                      {statushandle(statusCard?.statusCard1)}
                      {statushandle(statusCard?.statusCard2)}
                    </ul>
                    <div className="tab-contents">
                      <div className="w-full">
                        {activeIndex !== "3"
                          ? renderList(activeIndex)
                          : pickupRequest()}
                      </div>
                    </div>
                    {/* pagination */}
                    {numPages > 1 ? (
                      <Pagination
                        numPages={numPages}
                        data={valetData}
                        page={page}
                        handleNavigation={handleNavigation}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {modalIsOpen ? (
          <AssignAndConfirmModal
            setIsOpenModel={setIsOpen}
            vehicleData={vehicleData}
            allUsers={allUsers}
            assignHandle={assignHandle}
            statusUpdateHandle={statusUpdateHandle}
            recordCashPayment = {recordCashPayment}
            assignedStaff={assignedStaff}
            setAssignedStaff={setAssignedStaff}
          />
        ) : null}
      </LayoutComponent>
    </>
  );
}

export default ValetList;
