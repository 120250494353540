/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminCredential from "../components/companyRegistration/AdminCredential";
import CompanyInfo from "../components/companyRegistration/CompanyInfo";
import LayoutComponent from "../components/LayoutComponent";
import Seo from "../components/Seo";
import { directus } from "../services/directus";
import { RegistrationMailSender } from "../utils/Mailsender";
import seoData from '../seoContent.json';
import ConfirmationModal from "../components/popupModals/ConfirmationModal";

function CompanyRegistration() {
  const [page, setPage] = React.useState(1);
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [info, setInfo] = React.useState({
    companyName: "",
    cinNumber: "",
    logo: "",
    Address: "",
    Tags: [],
    State: "",
    City: "",
    docs1: "",
    docs2: "",
    location: "",
    typeOfCompany: "single_organization",
  });
  const [adminDetails, setAdminDetails] = React.useState({
    adminEmail: "",
    password: "",
    confirmPassword: "",
    adminPhoneNo: "",
    subscription: true,
    terms: false,
    valetFee:false,
    valetFeeValue:""
  });
  const navigate = useNavigate();
  const [validationError, setValidationError] = React.useState({
    companyNameError: false,
    cinNumberError: false,
    cinNumberFormatError: false,
    // logoError: false,
    AddressError: false,
    StateError: false,
    CityError: false,
    TagsError: false,
    docs1Error: false,
    docs2Error: false,
    locationError: false,
    typeOfCompanyError: false,
    adminEmailError: false,
    adminEmailFormatError: false,
    passwordError: false,
    confirmPasswordError: false,
    passwordMismatchError: false,
    subscriptionError: false,
    termsError: false,
    adminPhoneNoError: false,
    lenPhoneNoError: false,
    valetFeeValueError: false,
  });
  async function fieldValidation() {
    if (info?.companyName?.trim()?.length < 1) {
      updateValidationError("companyNameError", true);
    }
    if (info?.cinNumber?.trim()?.length < 1) {
      updateValidationError("cinNumberError", true);
    }
    if (
      !!!/^[a-zA-Z0-9]{4,30}$/gm.test(
        info?.cinNumber?.trim()
      )
    ) {
      updateValidationError("cinNumberFormatError", true);
    } else {
      updateValidationError("cinNumberFormatError", false);

    }
    // if (!!!info?.logo) {
    //   updateValidationError("logoError", true);
    // }
    if (!!!info?.Address?.trim()) {
      updateValidationError("AddressError", true);
    }
    if (!!!info?.Tags?.length > 0) {
      updateValidationError("TagsError", true);
    }
    if (!!!info?.State?.id) {
      updateValidationError("StateError", true);
    }
    if (!!!info?.City?.id) {
      updateValidationError("CityError", true);
    }
    if (!!!info?.docs1) {
      updateValidationError("docs1Error", true);
    }
    if (!!!info?.docs2) {
      updateValidationError("docs2Error", true);
    }
    if (!!!info?.location?.trim()) {
      updateValidationError("locationError", true);
    }
    if (!!!info?.typeOfCompany?.trim()) {
      updateValidationError("typeOfCompanyError", true);
    }
    return validationError;
  }

  async function fieldValidation1() {
    if (!!!adminDetails?.adminEmail?.trim()) {
      updateValidationError("adminEmailError", true);
    }
    if (
      !!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        adminDetails?.adminEmail
      )
    ) {
      updateValidationError("adminEmailFormatError", true);
    }
    if (!!!adminDetails?.password?.trim()) {
      updateValidationError("passwordError", true);
    }
    if (!!!adminDetails?.adminPhoneNo?.trim()) {
      updateValidationError("adminPhoneNoError", true);
    }
    if (!!!adminDetails?.adminPhoneNo?.trim()?.length < 10) {
      adminDetails?.adminPhoneNo?.trim()?.length > 1 &&
        updateValidationError("adminPhoneNoError", false);
      updateValidationError("lenPhoneNoError", true);
    }
    if (!!!adminDetails?.confirmPassword?.trim()) {
      updateValidationError("confirmPasswordError", true);
    }
    if (adminDetails?.password?.trim()?.length > 0 || adminDetails?.confirmPassword?.trim()?.length > 0) {
      if (adminDetails?.password?.trim() !== adminDetails?.confirmPassword?.trim()) {
        updateValidationError("passwordMismatchError", true);
      }
    }
    if (!!!adminDetails?.subscription) {
      updateValidationError("subscriptionError", true);
    }
    if (!!!adminDetails?.terms) {
      updateValidationError("termsError", true);
    }
    if (
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(adminDetails?.adminEmail)
    ) {
      updateValidationError("adminEmailFormatError", false);
    }
    if (adminDetails?.adminPhoneNo?.trim()?.length === 10) {
      updateValidationError("lenPhoneNoError", false);
    }
    if (adminDetails?.valetFee===true&&Number(adminDetails?.valetFeeValue)<=0) {
      updateValidationError("valetFeeValueError", true);
    }
     if (
      adminDetails?.terms &&
      adminDetails?.subscription &&
      adminDetails?.adminEmail?.trim() &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        adminDetails?.adminEmail
      ) &&
      adminDetails?.confirmPassword?.trim() &&
      adminDetails?.adminPhoneNo?.trim()?.length === 10 &&
      adminDetails?.adminPhoneNo?.trim() &&
      adminDetails?.password?.trim() &&
      adminDetails?.password?.trim() === adminDetails?.confirmPassword?.trim()&&
      (adminDetails?.valetFee===false||(Number(adminDetails?.valetFeeValue)>0))
    ) {
      setValidationError({
        companyNameError: false,
        cinNumberError: false,
        cinNumberFormatError: false,
        AddressError: false,
        TagsError: false,
        StateError: false,
        CityError: false,
        docs1Error: false,
        docs2Error: false,
        locationError: false,
        typeOfCompanyError: false,
        adminEmailError: false,
        passwordError: false,
        confirmPasswordError: false,
        passwordMismatchError: false,
        subscriptionError: false,
        termsError: false,
        adminPhoneNoError: false,
        adminEmailFormatError: false,
        valetFeeValueError:false
      });
    }

    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }
  const pageHandle = async (prop) => {
    const companyAlreadyExist =
      info?.companyName?.trim()?.length > 0 &&
      (await directus
        .items("Companies")
        .readByQuery({
          fields: ["id"],
          filter: { slug: { _eq: info?.companyName?.toLowerCase() } },
        })
        .then((res) => {
          return res?.data[0] ? true : false;
        })
        .catch((e) => {
          console.log(e);
        }));
    if (!companyAlreadyExist) {
      var flag = false;
      if (prop === "add") {
        await fieldValidation();
        var {
          companyNameError,
          cinNumberError,
          cinNumberFormatError,
          // logoError,
          AddressError,
          TagsError,
          StateError,
          CityError,
          docs1Error,
          docs2Error,
          locationError,
          typeOfCompanyError,
        } = validationError;
        if (
          !!info?.companyName?.trim() &&
          !!info?.cinNumber?.trim() &&
          /^[a-zA-Z0-9]{4,30}$/gm.test(
            info?.cinNumber?.trim()
          ) &&
          // !!info?.logo &&
          !!info?.Address?.trim() &&
          !!info?.Tags?.length > 0 &&
          !!info?.State?.id &&
          !!info?.City?.id &&
          (!!info?.docs1 || !!info?.docs2) &&
          !!info?.location?.trim() &&
          !!info?.typeOfCompany
        ) {
          setPage(page + 1);
          return (flag = false);
        } else {
          if (
            companyNameError ||
            cinNumberError ||
            cinNumberFormatError ||
            // logoError ||
            AddressError ||
            TagsError ||
            StateError ||
            CityError ||
            docs1Error ||
            docs2Error ||
            locationError ||
            typeOfCompanyError
          ) {
            return (flag = true);
          } else {
            return (flag = false);
          }
        }
      }
      return prop === "add" && page < 4
        ? !flag && setPage(page + 1)
        : page > 1 && prop !== "add"
          ? setPage(page - 1)
          : console.log("invalid");
    } else {
      toast.error("Company name already exist");
    }
  };
  React.useEffect(() => {
    var {
      companyNameError,
      cinNumberError,
      cinNumberFormatError,
      // logoError,
      AddressError,
      TagsError,
      StateError,
      CityError,
      docs1Error,
      docs2Error,
      locationError,
      typeOfCompanyError,
      lenPhoneNoError,
      adminEmailError,
      adminEmailFormatError,
      passwordError,
      adminPhoneNoError,
      subscriptionError,
      confirmPasswordError,
      termsError, passwordMismatchError,valetFeeValueError
    } = validationError;
    if (
      !!info?.companyName?.trim() &&
      !!info?.cinNumber?.trim() &&
      /^[a-zA-Z0-9]{4,30}$/gm.test(
        info?.cinNumber?.trim()
      ) &&
      // !!info?.logo &&
      !!info?.Address?.trim() &&
      !!info?.Tags?.length > 0 &&
      !!info?.State?.id &&
      !!info?.City?.id &&
      (!!info?.docs1 || !!info?.docs2) &&
      !!info?.location?.trim() &&
      !!info?.typeOfCompany
    ) {
      if (
        !!adminDetails?.adminEmail?.trim() &&
        !!adminDetails?.password?.trim() &&
        !!adminDetails?.confirmPassword?.trim() &&
        !!adminDetails?.subscription &&
        !!adminDetails?.adminPhoneNo?.trim() &&
        !!adminDetails?.terms
      ) {
        if (
          !(
            companyNameError ||
            cinNumberError ||
            cinNumberFormatError ||
            // logoError ||
            AddressError ||
            TagsError ||
            StateError ||
            CityError ||
            docs1Error ||
            docs2Error ||
            locationError ||
            typeOfCompanyError ||
            adminEmailError ||
            adminEmailFormatError ||
            passwordError ||
            adminPhoneNoError ||
            lenPhoneNoError ||
            confirmPasswordError || passwordMismatchError ||
            subscriptionError ||
            termsError||valetFeeValueError
          )
        ) {
          setConfirmModal(true);

        }
      }
    } else {
      if (
        companyNameError ||
        cinNumberError ||
        cinNumberFormatError ||
        // logoError ||
        AddressError ||
        TagsError ||
        StateError ||
        CityError ||
        docs1Error ||
        docs2Error ||
        locationError ||
        typeOfCompanyError || passwordMismatchError||valetFeeValueError
      ) {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);

  const submitHandle = async (e) => {
    await fieldValidation1();
  };

  const createImageHandle = async (props) => {
    const formData = new FormData();
    formData.append("folder", props);
    const val = await directus.files
      .createOne(formData)
      .then((res) => {
        return res?.id;
      })
      .catch((err) => {
        console.log("Image create error", err?.message);
      });
    return val;
  };
  const tagsIds = async (prop) => {
    const Tags_Category = [];
    info?.Tags?.map((e) =>
      Tags_Category.push({ Branch_id: prop, Tags_Category_id: e?.id })
    );
    await directus
      .items("Branch_Tags_Category")
      .createMany(Tags_Category)
      .then(async (res) => { })
      .catch((e) => console.log(e, "tags creation error"));
  };
  const createHandle = async (prop) => {

    // user create and company create
    if (adminDetails?.adminPhoneNo?.trim()?.length >= 10) {
      if (adminDetails?.password?.length >= 4) {
        if (
          adminDetails?.password?.trim() ===
          adminDetails?.confirmPassword?.trim()
        ) {
          await directus.users
            .createOne({
              first_name: adminDetails?.adminEmail?.trim(),
              email: adminDetails?.adminEmail?.trim(),
              password: adminDetails?.password?.trim(),
              password_copy: adminDetails?.password?.trim(),
              contact_number: adminDetails?.adminPhoneNo?.trim(),
              is_admin_user: true,
            })
            .then(async (userRes) => {
              if (userRes?.id) {
                const bodyValue = {
                  name: info?.companyName?.trim(),
                  address: info?.Address?.trim(),
                  type_of_organization: info?.typeOfCompany,
                  state_id: info?.State?.id,
                  city_id: info?.City?.id,
                };
                await directus
                  .items("Companies")
                  .createOne(bodyValue)
                  .then(async (res) => {
                    const ImageLogoId = await createImageHandle(info?.logo)
                    const ImageDocs1Id = await createImageHandle(info?.docs1);
                    const ImageDocs2Id = await createImageHandle(info?.docs2);
                    await branchCreateHandle({
                      user: userRes?.id,
                      id: res?.id,
                      ImageDocs1Id: ImageDocs1Id,
                      ImageDocs2Id: ImageDocs2Id,
                      ImageLogoId: ImageLogoId,
                    });
                    navigate("/success-registration", { state: "company" });
                  })
                  .catch((err) => {
                    navigate(".", { replace: true });
                    console.log(err);
                    toast.error("Company name already exist");
                  });
              }
            })
            .catch((err) => {
              navigate(".", { replace: true });
              console.log(err);
              toast.error(err?.message);
              return false;
            });
        } else {
          toast.error("Password mismatch");
        }
      } else {
        toast.error("Set minimum 4 character password");
      }
    } else {
      toast.error("Invalid phone number");
    }
  };
  const branchCreateHandle = async (prop) => {
    const bodyValue = {
      company_id: prop?.id,
      name: info?.location?.trim(),
      address: info?.Address?.trim(),
      cin_gov_number: info?.cinNumber?.trim(),
      state_id: info?.State?.id,
      city_id: info?.City?.id,
      document_1: prop?.ImageDocs1Id,
      document_2: prop?.ImageDocs2Id,
      type_of_organization: info?.typeOfCompany,
      main_admin:prop?.user,
      is_main: "yes",
      valet_fee: adminDetails?.valetFee ? adminDetails?.valetFeeValue ? Number(adminDetails?.valetFeeValue) : null : null
    };

    await directus
      .items("Branch")
      .createOne(bodyValue)
      .then(async (res) => {
        await companyUpdate({
          branchId: res?.id, id: prop?.id, document_1: prop?.ImageDocs1Id,
          document_2: prop?.ImageDocs2Id,
          company_logo: prop?.ImageLogoId,
        });
        await userUpdate({
          branchId: res?.id,
          companyId: prop?.id,
          id: prop?.user,
        });
        await tagsIds(res?.id);
        await RegistrationMailSender({
          emailTo: adminDetails?.adminEmail?.trim(),
          type: "branch",
          branchName: info?.location?.trim(),
          companyName: info?.companyName?.trim(),
        });
      })
      .catch((err) => {
        navigate(".", { replace: true });
        console.log(err);
        toast.error(err);
      });
  };
  const userUpdate = async (prop) => {
    let updateFields = {
      default_branch: prop?.branchId,
      default_company: prop?.companyId,
    };
    await directus.users
      .updateOne(prop?.id, updateFields)
      .then((res) => { })
      .catch((err) => {
        navigate(".", { replace: true });
        console.log(err);
        toast.error(err?.message);
      });
  };
  const companyUpdate = async (prop) => {
    const bodyValue = {
      default_branch: prop?.branchId,
      document_1: prop?.document_1,
      document_2: prop?.document_2,
      company_logo: prop?.company_logo,
    };
    await directus
      .items("Companies")
      .updateOne(Number(prop?.id), bodyValue)
      .then(async (res) => { })
      .catch((err) => {
        // navigate('.', { replace: true });
        console.log(err);
        toast.error(err);
      });
  };
  return (
    <LayoutComponent title={"COMPANY AND FIRST BRANCH REGISTRATION"} chat={false}>
      <Seo title={seoData?.company_register?.title} />
      <div>
        <main className="main-content-area px-6 py-8">
          <div className="mx-auto">
            <div className="block__status-bar">
              <nav aria-label="Progress">
                <ol className="rounded-md border border-gray-300 grid grid-cols-12 ">
                  <li className="relative col-span-6 md:col-span-6">
                    <button
                      className="group flex flex-col items-center px-6 py-4 text-sm font-medium w-full cursor-default	"
                      aria-current="step"
                    >
                      <span
                        className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ${page === 1 ? " border-primary" : "bg-primary"
                          }  group-hover:border-gray-400`}
                      >
                        <span
                          className={`${page === 1 ? "text-primary" : " text-white "
                            }  group-hover:text-gray-900`}
                        >
                          01
                        </span>
                      </span>
                      <span className="mt-2 text-xs text-center font-medium text-primary">
                        Company Info
                      </span>
                    </button>
                  </li>
                  <li className="relative col-span-6 md:col-span-6">
                    <button className="group flex items-center justify-center w-full cursor-default	">
                      <span className="flex flex-col items-center px-6 py-4 text-sm font-medium">
                        <span
                          className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ${page === 2
                            ? " border-primary"
                            : page < 2
                              ? " border-gray-300"
                              : "bg-primary"
                            } group-hover:border-gray-400`}
                        >
                          <span
                            className={`${page === 2
                              ? "text-primary"
                              : page < 2
                                ? " text-gray-500"
                                : "text-white"
                              }  group-hover:text-gray-900`}
                          >
                            02
                          </span>
                        </span>
                        <span className="mt-2 text-xs text-center font-medium text-gray-500 group-hover:text-gray-900">
                          Admin Credentials
                        </span>
                      </span>
                    </button>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="block__form">
              {/* <form> */}
              {page === 1 ? (
                <CompanyInfo
                  info={info}
                  setInfo={setInfo}
                  validationError={validationError}
                  type={"company"}
                  allFieldEdit={true}
                  mainFieldDisable={false}
                />
              ) : (
                <AdminCredential
                  adminDetails={adminDetails}
                  setAdminDetails={setAdminDetails}
                  validationError={validationError}
                />
              )}
              <div className="form__actions flex justify-center items-center gap-5 mt-10">
                {page === 2 && (
                  <button
                    onClick={() => pageHandle("sub")}
                    className="inline-flex items-center justify-center rounded-md h-10 border-2 border-red-600 bg-red-50 px-4 py-2 text-sm font-semibold text-red-600 focus:outline-none sm:w-auto"
                  >
                    Back{" "}
                  </button>
                )}
                <button
                  onClick={() =>
                    page === 1 ? pageHandle("add") : submitHandle()
                  }
                  className={`inline-flex items-center ${page === 2 &&
                    (!adminDetails?.subscription || !adminDetails?.terms)
                    ? "opacity-50 pointer-events-none"
                    : ""
                    } justify-center rounded-md border-transparent h-10 border-2 bg-primary px-4 py-2 text-sm font-semibold text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto`}
                >
                  {page === 1 ? " Next" : "Submit"}{" "}
                </button>
              </div>

              {/* </form> */}
            </div>
          </div>
        </main>
      </div>
      {confirmModal ? (
        <ConfirmationModal
          openModel={confirmModal}
          setIsOpenModel={setConfirmModal}
          callFunction={createHandle}
          content={"Are you sure the given information is valid?"}
        />
      ) : null}
    </LayoutComponent>
  );
}

export default CompanyRegistration;
