/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import CustomerLayout from "../../components/CustomerLayout";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import { directus } from "../../services/directus";
import { getAssetURL } from "../../utils/get-asset-url";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import palceHolderImg from "../../assets/images/placeHolder.png";
import seoData from "../../seoContent.json";
import Pagination from "../../components/Pagination";
import rightImg from "../../assets/images/parking_image_right.jpg";
import PopupShare from "../../components/Share/PopupShare";
import { FRONTEND_URL } from "../../utils/utils";
import UserContext from "../../contexts/userContext";

function BranchRedirect() {
  const [companyAndBranches, setCompanyAndBranches] = React.useState();
  const [companyInfo, setCompany] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [selectedTags, setSelectedTags] = React.useState();
  const [branchName, setBranchName] = React.useState();
  const [selectedCompany, setSelectedCompany] = React.useState();
  const [cityAndState, setCityAndState] = React.useState({
    city: "",
    state: "",
  });
  const { commonInfo } = React.useContext(UserContext);
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [tagOptions, setTagOptions] = React.useState();
  const [pagination, setPagination] = React.useState();
  const [page, setPage] = React.useState(1);
  const [homeLogo, setHomeLogo] = React.useState(commonInfo);
  const paginationCount = 9;
  
  React.useEffect(() => {
    setHomeLogo(commonInfo)
  },[commonInfo])

  const navigate = useNavigate();
  React.useEffect(() => {
    const branchList = async () => {
      await directus
        .items("Companies")
        .readByQuery({
          fields: ["*","branches.*","branches.company_id.*","branches.company_id.company_logo.*","branches.tags.*",'branches.tags.Tags_Category_id.*',
          'branches.city_id.*','branches.city_id.state_id.*','branches.state_id.*','branches.state_id.city_list.*'],
          filter: {
            status: { _eq: "published" },
          },
          deep: {
            branches: { _filter: { status: { _eq: "published" } } },
          },
        })
        .then((res) => {
          setLoading(false);
          const branchList = [];
          res?.data?.map((e) => e?.branches?.map((el) => branchList.push(el)));
          setCompany(res?.data);
          setPagination(branchList?.length);
          return setCompanyAndBranches(branchList);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    branchList();
    TagCategoryCall();
  }, []);
  const propTag = [];
  selectedTags?.length > 0 && selectedTags?.map((e) => propTag.push(e?.Name));
  const uniqueBranchNameFilter = [];
  companyAndBranches &&
    companyAndBranches?.filter((element, i) => {
      const isDuplicate = uniqueBranchNameFilter.some(
        (e) => e.name?.toLowerCase() === element?.name?.toLowerCase()
      );
      if (!isDuplicate) {
        return uniqueBranchNameFilter.push(element);
      }
      return "";
    });
  const checkFilter = ({ Check, e, selectedState, selectedCity }) => {
    return selectedCompany?.id && selectedState?.id && selectedCity?.id
      ? selectedState?.id === e?.state_id?.id &&
          selectedCompany?.id === e?.company_id?.id &&
          selectedCity?.id === e?.city_id?.id &&
          Check
      : selectedCompany?.id && selectedState?.id
      ? selectedState?.id === e?.state_id?.id &&
        selectedCompany?.id === e?.company_id?.id &&
        Check
      : selectedCompany?.id && selectedCity?.id
      ? selectedCity?.id === e?.city_id?.id &&
        selectedCompany?.id === e?.company_id?.id &&
        Check
      : selectedState?.id && selectedCity?.id
      ? selectedCity?.id === e?.city_id?.id &&
        selectedState?.id === e?.state_id?.id &&
        Check
      : selectedState?.id
      ? selectedState?.id === e?.state_id?.id && Check
      : selectedCity?.id
      ? selectedCity?.id === e?.city_id?.id && Check
      : selectedCompany?.id
      ? selectedCompany?.id === e?.company_id?.id && Check
      : Check;
  };
  const filterResults = (props) => {
    const tag = props?.tag;
    const data = props?.data;
    const branch = props?.branch;
    const selectedCity = props?.city;
    const selectedState = props?.state;
    const branchFilter =
      branch !== undefined &&
      branch &&
      Object.keys(branch)?.length &&
      companyAndBranches?.filter((e) => {
        const check = e?.name?.toUpperCase() === branch?.name?.toUpperCase();
        return checkFilter({
          Check: check,
          e: e,
          selectedCity: selectedCity,
          selectedState: selectedState,
        });
      });
    const cityFilter = companyAndBranches?.filter((e) => {
      const check = selectedCity?.id === e?.city_id?.id;
      return checkFilter({
        Check: check,
        e: e,
        selectedCity: selectedCity,
        selectedState: selectedState,
      });
    });

    const stateFilter = companyAndBranches?.filter((e) => {
      const check = selectedState?.id === e?.state_id?.id;
      return checkFilter({
        Check: check,
        e: e,
        selectedCity: selectedCity,
        selectedState: selectedState,
      });
    });
    const tagFilter =
      tag?.length > 0 &&
      data?.filter((e) => {
        const check = e?.tags?.some((ai) => {
          return propTag?.includes(ai?.Tags_Category_id?.Name);
        });
        return checkFilter({
          Check: check,
          e: e,
          selectedCity: selectedCity,
          selectedState: selectedState,
        });
      });
    const branchAndTagFilter =
      tag?.length > 0 &&
      branch !== undefined &&
      branch &&
      Object.keys(branch)?.length > 2 &&
      companyAndBranches?.filter(
        (e) =>
          e?.name?.toUpperCase() === branchName?.name?.toUpperCase() &&
          e?.tags?.some((ai) => {
            const check = propTag?.includes(ai?.Tags_Category_id?.Name);
            return checkFilter({
              Check: check,
              e: e,
              selectedCity: selectedCity,
              selectedState: selectedState,
            });
          })
      );
    const filterResult =
      tag?.length > 0 &&
      branch !== undefined &&
      branch &&
      Object.keys(branch)?.length > 2
        ? branchAndTagFilter
        : tag?.length > 0
        ? tagFilter
        : branch !== undefined && branch?.id
        ? branchFilter
        : selectedState !== undefined && selectedState?.id
        ? stateFilter
        : selectedCity !== undefined && selectedCity?.id
        ? cityFilter
        : data;
    return filterResult;
  };
  const branchLogoAndName = (props) => {
    const Value = filterResults(props)?.slice(
      page === 1 ? 0 : (page - 1) * paginationCount,
      page * paginationCount
    );
    return Value?.length > 0 ? (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[30px] mb-4 p-4 px-8 max-sm:px-5">
        {Value?.map((e, index) => {
          return (
            <div
              className="branch-icon-list rounded-md relative border border-[#e0e0e0]"
              key={e?.id}
            >
              {" "}
              <Link to={`/${e?.company_id?.slug}/${e?.slug}/customer/login`}>
                <div className="inline-block m-1 p-2 justify-center items-center branch-icon max-h-[185px] ">
                  <img
                    src={
                      e?.company_id?.company_logo?.id
                        ? getAssetURL(e?.company_id?.company_logo?.id)
                        : palceHolderImg
                    }
                    alt="Logo"
                  />
                </div>
              </Link>
              <div className="border-t border-[#e0e0e0]">
                <div className="px-4 pt-4 pb-2 bg-white capitalize text-sm text-center flex flex-col gap-2 flex-wrap justify-center branch-">
                  {e?.tags?.length > 0 && (
                    <div className="text-left min-h-[30px]">
                      {e?.tags?.map((e) => (
                        <span
                          key={e?.id}
                          className={`text-[#333] inline-block text-xs font-medium px-2.5 py-0.5 rounded-md m-1 pt-[4px]`}
                          style={{
                            backgroundColor: e?.Tags_Category_id?.bg_color,
                          }}
                        >
                          {e?.Tags_Category_id?.Name}
                        </span>
                      ))}
                    </div>
                  )}
                  <span className="branchname font-semibold text-lg text-left min-h-[40px] flex items-center">
                    {" "}
                    {e?.name}{" "}
                  </span>
                  <div className="mx-0 ml-0 mt-1 sm:mt-0 flex gap-1 items-center">
                    <span className="bg-gray-200 text-black text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-gray-400 dark:text-gray-200">
                      {e?.is_main === "No" ? "Branch" : "Main"}
                    </span>
                  </div>
                </div>
                <div className="b-address px-4 p-2 capitalize text-sm text-left w-full bg-white shadow-md">
                  {(e?.address || e?.valet_number) && (
                    <div className="mx-0 ml-0 mt-1 sm:mt-0 block sm:inline-block">
                      {e?.address && (
                        <span className="capitalize text-xs text-left flex gap-1 min-h-[48px]">
                          <span className="address-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="#8765E2"
                              className="w-[20px] h-[20px] relative top-[-2px]"
                            >
                              <title>Address</title>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                              />
                            </svg>
                          </span>
                          <span className="branch-addr">{e?.address}</span>
                        </span>
                      )}
                      <div className="valet-no min-h-[30px]">
                        {e?.valet_number && (
                          <span className=" pt-2 capitalize text-xs text-left flex gap-1 items-center">
                            <span className="phone-icon">
                              <svg
                                width="20px"
                                height="20px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <title>Valet number</title>
                                <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <path
                                    d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
                                    fill="#8765E2"
                                  />{" "}
                                </g>
                              </svg>
                            </span>
                            {e?.valet_number}
                          </span>
                        )}
                      </div>
                      {
                        <div className="pt-2 offers_promotions_link flex gap-1 items-center min-h-[30px]">
                          <svg
                            width="20"
                            height="20"
                            x="0"
                            y="0"
                            viewBox="0 0 512.003 512.003"
                            className="relative top-[-2px]"
                          >
                            <title>Offers</title>
                            <g>
                              <path
                                d="M477.958 262.633a15.004 15.004 0 0 1 0-13.263l19.096-39.065c10.632-21.751 2.208-47.676-19.178-59.023l-38.41-20.38a15.005 15.005 0 0 1-7.796-10.729l-7.512-42.829c-4.183-23.846-26.241-39.87-50.208-36.479l-43.053 6.09a15.004 15.004 0 0 1-12.613-4.099l-31.251-30.232c-17.401-16.834-44.661-16.835-62.061 0L193.72 42.859a15.01 15.01 0 0 1-12.613 4.099l-43.053-6.09c-23.975-3.393-46.025 12.633-50.208 36.479l-7.512 42.827a15.008 15.008 0 0 1-7.795 10.73l-38.41 20.38c-21.386 11.346-29.81 37.273-19.178 59.024l19.095 39.064a15.004 15.004 0 0 1 0 13.263L14.95 301.699c-10.632 21.751-2.208 47.676 19.178 59.023l38.41 20.38a15.005 15.005 0 0 1 7.796 10.729l7.512 42.829c3.808 21.708 22.422 36.932 43.815 36.93 2.107 0 4.245-.148 6.394-.452l43.053-6.09a15 15 0 0 1 12.613 4.099l31.251 30.232c8.702 8.418 19.864 12.626 31.03 12.625 11.163-.001 22.332-4.209 31.03-12.625l31.252-30.232c3.372-3.261 7.968-4.751 12.613-4.099l43.053 6.09c23.978 3.392 46.025-12.633 50.208-36.479l7.513-42.827a15.008 15.008 0 0 1 7.795-10.73l38.41-20.38c21.386-11.346 29.81-37.273 19.178-59.024l-19.096-39.065zM196.941 123.116c29.852 0 54.139 24.287 54.139 54.139s-24.287 54.139-54.139 54.139-54.139-24.287-54.139-54.139 24.287-54.139 54.139-54.139zm-27.944 240.77c-2.883 2.883-6.662 4.325-10.44 4.325s-7.558-1.441-10.44-4.325c-5.766-5.766-5.766-15.115 0-20.881l194.889-194.889c5.765-5.766 15.115-5.766 20.881 0s5.766 15.115 0 20.881l-194.89 194.889zm146.064 25.002c-29.852 0-54.139-24.287-54.139-54.139s24.287-54.139 54.139-54.139c29.852 0 54.139 24.287 54.139 54.139s-24.287 54.139-54.139 54.139z"
                                fill="#8765e2"
                                opacity="1"
                                data-original="#000000"
                                className=""
                              ></path>
                              <path
                                d="M315.061 310.141c-13.569 0-24.609 11.039-24.609 24.608s11.039 24.608 24.609 24.608c13.569 0 24.608-11.039 24.608-24.608s-11.039-24.608-24.608-24.608zM196.941 152.646c-13.569 0-24.608 11.039-24.608 24.608s11.039 24.609 24.608 24.609 24.609-11.039 24.609-24.609c-.001-13.568-11.04-24.608-24.609-24.608z"
                                fill="#8765e2"
                                opacity="1"
                                data-original="#8765e2"
                                className=""
                              ></path>
                            </g>
                          </svg>
                          <Link
                            to={`/${e?.company_id?.slug}/${e?.slug}/offers&promotions`}
                            className="text-xs text-left text-primary "
                          >
                            Offers & Promotions
                          </Link>
                        </div>
                      }
                    </div>
                  )}
                </div>
                <div className="bg-white px-4 pt-2 pb-4 flex gap-3 justify-between items-center main-actions">
                  <div className="more-actions">
                    <button
                      type="button"
                      className="text-white font-medium bg-primary focus:ring-2 focus:outline-none focus:ring-primary focus:ring-offset-2 rounded-md text-sm px-5 py-1.5 text-center inline-flex items-center"
                      onClick={() =>
                        navigate(
                          `/${e?.company_id?.slug}/${e?.slug}/customer/login`
                        )
                      }
                    >
                      Login
                    </button>
                  </div>
                  <PopupShare
                    url={`${FRONTEND_URL}/${e?.company_id?.slug}/${e?.slug}/customer/login`}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ) : (
      <div
        className={`border rounded-md border-gray-200 whitespace-nowrap text-sm m-10 justify-items-center p-4 text-gray-500 flex justify-center text-center mt-10 shadow-md ring-1 ring-black ring-opacity-5`}
      >
        <p className="text-center place-self-center">No data found</p>
      </div>
    );
  };
  const styles1 = {
    menuList: (base) => ({
      ...base,
      height: "110px",

      "::-webkit-scrollbar": {
        width: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "#2684FF",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    control: (base, state) => ({
      ...base,
      cursor: "pointer",
      border: "none",
      marginTop: "4px",
    }),
  };

  const TagCategoryCall = async () => {
    await directus
      .items("Tags_Category")
      .readByQuery({
        fields: ["*"],
      })
      .then((res) => {
        setTagOptions(res?.data);
        return "";
      })
      .catch((e) => console.log(e));
  };
  const selectComponent = (prop) => {
    return (
      <div className="w-full relative">
        <Select
          id="branch"
          classNamePrefix="react-select"
          className=" react-select-container  border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] capitalize"
          defaultMenuIsOpen={false}
          maxMenuHeight={250}
          onChange={(e) => {
            setPage(1);
            return prop?.company === false
              ? setBranchName(e)
              : setSelectedCompany(e);
          }}
          value={
            prop?.company === false
              ? prop?.value && Object.keys(prop?.value)?.length
                ? prop?.value
                : ""
              : selectedCompany && Object.keys(selectedCompany)?.length
              ? selectedCompany
              : ""
          }
          options={
            selectedTags?.length > 0
              ? prop?.company === false
                ? uniqueBranchNameFilter
                    ?.filter((e) =>
                      city?.id !== undefined && state?.id !== undefined
                        ? e?.city_id?.id === city?.id &&
                          e?.state_id?.id === state?.id
                        : city?.id !== undefined
                        ? e?.city_id?.id === city?.id
                        : state?.id !== undefined
                        ? e?.state_id?.id === state?.id
                        : e
                    )
                    ?.filter((e, i) => {
                      return e?.tags?.some((ai) => {
                        return selectedCompany?.id
                          ? [selectedCompany]?.some((el) =>
                              el?.branches?.some(
                                (qe) =>
                                  qe.name?.toUpperCase() ===
                                  e?.name?.toUpperCase()
                              )
                            ) && propTag?.includes(ai?.Tags_Category_id?.Name)
                          : propTag?.includes(ai?.Tags_Category_id?.Name);
                      });
                    })
                : companyInfo
                    ?.filter((f) =>
                      f?.branches?.some((e) =>
                        city?.id !== undefined && state?.id !== undefined
                          ? e?.city_id?.id === city?.id &&
                            e?.state_id?.id === state?.id
                          : city?.id !== undefined
                          ? e?.city_id?.id === city?.id
                          : state?.id !== undefined
                          ? e?.state_id?.id === state?.id
                          : e
                      )
                    )
                    ?.filter((Branch) =>
                      Branch?.branches?.some((e) => {
                        return e?.tags?.some((ai) => {
                          return branchName?.name
                            ? branchName?.name?.toUpperCase() ===
                                e?.name.toUpperCase() &&
                                propTag?.includes(ai?.Tags_Category_id?.Name)
                            : propTag?.includes(ai?.Tags_Category_id?.Name);
                        });
                      })
                    )
              : prop?.company === false
              ? selectedCompany && Object.keys(selectedCompany)?.length
                ? selectedCompany?.branches?.filter((e) =>
                    city?.id !== undefined && state?.id !== undefined
                      ? e?.city_id?.id === city?.id &&
                        e?.state_id?.id === state?.id
                      : city?.id !== undefined
                      ? e?.city_id?.id === city?.id
                      : state?.id !== undefined
                      ? e?.state_id?.id === state?.id
                      : e
                  )
                : uniqueBranchNameFilter?.filter((e) =>
                    city?.id !== undefined && state?.id !== undefined
                      ? e?.city_id?.id === city?.id &&
                        e?.state_id?.id === state?.id
                      : city?.id !== undefined
                      ? e?.city_id?.id === city?.id
                      : state?.id !== undefined
                      ? e?.state_id?.id === state?.id
                      : e
                  )
              : branchName && Object.keys(branchName)?.length
              ? companyInfo
                  ?.filter((Branch) =>
                    Branch?.branches?.some((e) => {
                      return (
                        e?.name?.toUpperCase() ===
                        branchName?.name?.toUpperCase()
                      );
                    })
                  )
                  ?.filter((f) =>
                    f?.branches?.some((e) =>
                      city?.id !== undefined && state?.id !== undefined
                        ? e?.city_id?.id === city?.id &&
                          e?.state_id?.id === state?.id
                        : city?.id !== undefined
                        ? e?.city_id?.id === city?.id
                        : state?.id !== undefined
                        ? e?.state_id?.id === state?.id
                        : e
                    )
                  )
              : companyInfo?.filter((f) =>
                  f?.branches?.some((e) =>
                    city?.id !== undefined && state?.id !== undefined
                      ? e?.city_id?.id === city?.id &&
                        e?.state_id?.id === state?.id
                      : city?.id !== undefined
                      ? e?.city_id?.id === city?.id
                      : state?.id !== undefined
                      ? e?.state_id?.id === state?.id
                      : true
                  )
                )
          }
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder={`Select your ${
            prop?.company === false ? "branch" : "company"
          }`}
          styles={{
            styles1,
            placeholder: (base) => ({
              ...base,
              fontWeight: "bold",
              color: "#9CA3AF",
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              border: "none",
              boxShadow: "none",
            }),
            input: (base, state) => ({
              ...base,
              '[type="text"]': {
                border: "none",
                boxShadow: "none",
                outline: "none",
              },
            }),
          }}
        />
        {prop?.value !== undefined &&
          prop?.value &&
          Object.keys(prop?.value)?.length > 1 && (
            <div
              className="w-full absolute right-20 close-icon"
              onClick={() => {
                prop?.setValue({});
              }}
            >
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
              >
                <title>Clear</title>
                <path
                  d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                  fill="#ccc"
                ></path>
              </svg>
            </div>
          )}
      </div>
    );
  };
  const handleNavigation = (e) => {
    setPage(e);
  };
  const numPages =
    pagination % paginationCount === 0
      ? Math.floor(pagination / paginationCount)
      : Math.floor(pagination / paginationCount) + 1;

  const PageCountCall = () => {
    const val = filterResults({
      data:
        selectedCompany && Object.keys(selectedCompany)?.length
          ? selectedCompany?.branches
          : companyAndBranches,
      branch: branchName,
      tag: selectedTags,
      city: city,
      state: state,
    });
    setPagination(val?.length);
  };
  const cityAndStateCall = () => {
    const cityList = [];
    const stateList = [];
    const branchList = branchName?.id
      ? [branchName]
      : selectedCompany?.id
      ? selectedCompany?.branches
      : companyAndBranches;
    const List =
      selectedTags?.length > 0
        ? branchList
            ?.filter((e) =>
              city?.id !== undefined && state?.id !== undefined
                ? e?.city_id?.id === city?.id && e?.state_id?.id === state?.id
                : city?.id !== undefined
                ? e?.city_id?.id === city?.id
                : state?.id !== undefined
                ? e?.state_id?.id === state?.id
                : e
            )
            ?.filter((e, i) => {
              return e?.tags?.some((ai) => {
                return selectedCompany?.id
                  ? [selectedCompany]?.some((el) =>
                      el?.branches?.some((qe) => qe.name === e?.name)
                    ) && propTag?.includes(ai?.Tags_Category_id?.Name)
                  : propTag?.includes(ai?.Tags_Category_id?.Name);
              });
            })
        : branchList;
    List?.map((element, i) => {
      const isCityDuplicate = cityList.some(
        (e) =>
          e.city_name?.toLowerCase() ===
          element?.city_id?.city_name?.toLowerCase()
      );
      const isStateDuplicate = stateList.some(
        (e) =>
          e.state_name?.toLowerCase() ===
          element?.state_id?.state_name?.toLowerCase()
      );
      if (!isCityDuplicate) {
        cityList.push(element?.city_id);
      }
      if (!isStateDuplicate) {
        stateList.push(element?.state_id);
      }
      return "";
    });
    setCityAndState({ city: cityList, state: stateList });
  };
  React.useEffect(() => {
    cityAndStateCall();
  }, [
    selectedCompany,
    selectedTags,
    branchName,
    city,
    state,
    companyAndBranches,
  ]);

  React.useEffect(() => {
    PageCountCall();
  }, [selectedCompany, selectedTags, branchName, city, state]);
  const selectCityComponent = (prop) => {
    return (
      <div className="w-full relative">
        <Select
          id="branch"
          classNamePrefix="react-select"
          className=" react-select-container  border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] capitalize"
          defaultMenuIsOpen={false}
          maxMenuHeight={250}
          onChange={(e) => {
            setPage(1);
            prop?.city === true ? setCity(e) : setState(e);
          }}
          value={
            prop?.value && Object.keys(prop?.value)?.length ? prop?.value : ""
          }
          options={
            prop?.city === true
              ? state?.id
                ? cityAndState?.city?.filter(
                    (e) => e?.state_id?.id === state?.id
                  )
                : cityAndState?.city
              : city?.id
              ? cityAndState?.state?.filter((f) =>
                  f?.city_list?.some((e) => e?.id === city?.id)
                )
              : cityAndState?.state
          }
          getOptionLabel={(option) => option?.[prop?.label]}
          getOptionValue={(option) => option.id}
          placeholder={`Select your ${prop?.city === false ? "state" : "city"}`}
          styles={{
            styles1,
            placeholder: (base) => ({
              ...base,
              fontWeight: "bold",
              color: "#9CA3AF",
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              border: "none",
              boxShadow: "none",
            }),
            input: (base, state) => ({
              ...base,
              '[type="text"]': {
                border: "none",
                boxShadow: "none",
                outline: "none",
              },
            }),
          }}
        />
        {prop?.value !== undefined &&
          prop?.value &&
          Object.keys(prop?.value)?.length > 1 && (
            <div
              className="w-full absolute right-20 close-icon"
              onClick={() => {
                prop?.setValue({});
              }}
            >
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
              >
                <title>Clear</title>
                <path
                  d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                  fill="#ccc"
                ></path>
              </svg>
            </div>
          )}
      </div>
    );
  };

  return (
    <CustomerLayout
      goBack={true}
      fixedFooter={true}
      IndexHome={true}
      head={"ourPartner"}
      noWrap={true}
    >
      <Seo title={seoData?.our_partners?.title} />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="title bg-[#8353e217] mb-6 py-16 px-5 max-sm:px-5">
            <div className="text-black text-center text-[16px] md:text-[24px] font-bold my-2">
              Find Valet Services & Parking Rentals Near You
            </div>
            <div className="text-black text-center text-[12px] md:text-[16px] font-normal my-2">
              Connect to the nearest parking rentals and valet services. Save time, avoid stress, and enjoy your day.
            </div>
          </div>
          <div className="title px-5 max-sm:px-5">
            <div className="text-black font-bold my-2">
              Search your results by tags, state, city, company, or branch (location):
            </div>
          </div>
          <div className="branch-redirect w-full grid grid-cols-1 md:grid-cols-2 justify-center items-start gap-4 pt-1 p-5 px-8 pb-0 max-md:pb-5 max-sm:px-5">
            <div className="w-full flex flex-col justify-center items-center md:pr-6 gap-2">
              <div className="w-full m-2 cursor-pointer">
                <Select
                  id="branch"
                  classNamePrefix="react-select"
                  className=" react-select-container  border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] capitalize"
                  defaultMenuIsOpen={false}
                  maxMenuHeight={250}
                  onChange={(e) => {
                    setPage(1);
                    return setSelectedTags(e);
                  }}
                  options={
                    selectedCompany && Object.keys(selectedCompany)?.length
                      ? tagOptions?.filter((el) =>
                          selectedCompany?.branches?.some((e, i) =>
                            branchName && Object.keys(branchName)?.length
                              ? e?.name?.toUpperCase() ===
                                  branchName?.name?.toUpperCase() &&
                                e?.tags?.some(
                                  (ai) =>
                                    el?.Name === ai?.Tags_Category_id?.Name
                                )
                              : e?.tags?.some(
                                  (ai) =>
                                    el?.Name === ai?.Tags_Category_id?.Name
                                )
                          )
                        )
                      : branchName && Object.keys(branchName)?.length
                      ? tagOptions?.filter((el) =>
                          companyAndBranches?.some(
                            (e) =>
                              e?.name?.toUpperCase() ===
                                branchName?.name?.toUpperCase() &&
                              e?.tags?.some(
                                (ai) => el?.Name === ai?.Tags_Category_id?.Name
                              )
                          )
                        )
                      : tagOptions
                  }
                  isMulti={true}
                  getOptionLabel={(option) => option.Name}
                  getOptionValue={(option) => option.Name}
                  placeholder={
                    "Select your tags like only restaurants, airport, hotels"
                  }
                  styles={{
                    styles1,
                    placeholder: (base) => ({
                      ...base,
                      fontWeight: "bold",
                      color: "#9CA3AF",
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      border: "none",
                      boxShadow: "none",
                      outline: "none",
                    }),
                    input: (base, state) => ({
                      ...base,
                      '[type="text"]': {
                        border: "none",
                        boxShadow: "none",
                        outline: "none",
                      },
                    }),
                  }}
                />
              </div>

              <div className="flex w-full m-2 gap-4 max-xs:flex-col max-xs:m-0 max-xs:gap-2">
                <div className="w-full cursor-pointer relative max-xs:my-2">
                  {selectCityComponent({
                    city: false,
                    label: "state_name",
                    value: state,
                    setValue: setState,
                  })}
                </div>
                <div className="w-full cursor-pointer relative max-xs:my-2">
                  {selectCityComponent({
                    city: true,
                    label: "city_name",
                    value: city,
                    setValue: setCity,
                  })}
                </div>
              </div>
              
              <div className="flex w-full m-2 gap-4 max-xs:flex-col max-xs:m-0 max-xs:gap-2">
                <div className="w-full cursor-pointer relative max-xs:my-2">
                  {selectComponent({
                    company: true,
                    value: selectedCompany,
                    setValue: setSelectedCompany,
                  })}
                </div>
                <div className="w-full cursor-pointer relative max-xs:my-2">
                  {selectComponent({
                    company: false,
                    value: branchName,
                    setValue: setBranchName,
                  })}
                </div>
              </div>
              
            </div>
            <div className="right-side-image w-full h-[230px] md:h-full bg-center bg-no-repeat bg-cover rounded-md" style={{backgroundImage: `url(${homeLogo?.home_image?.length > 0
                    ? getAssetURL(homeLogo?.home_image)
                    : rightImg})`}}/>
          </div>
          <div className="results pt-[10px]">
            <div className="block text-md font-bold px-8 max-sm:px-5 text-lg pt-[14px]">
              {(selectedTags && Object.keys(selectedTags)?.length) ||
              (selectedCompany && Object.keys(selectedCompany)?.length) ||
              (branchName && Object.keys(branchName)?.length) ||
              (city && Object.keys(city)?.length) ||
              (state && Object.keys(state)?.length)
                ? "Search Results"
                : "Branches"}
            </div>
            <div className="result-grid">
              {branchLogoAndName({
                data:
                  selectedCompany && Object.keys(selectedCompany)?.length
                    ? selectedCompany?.branches
                    : companyAndBranches,
                branch: branchName,
                tag: selectedTags,
                state: state,
                city: city,
              })}
            </div>
          </div>
          {/* pagination */}
          {numPages > 1 ? (
            <Pagination
              numPages={numPages}
              data={companyAndBranches}
              page={page}
              handleNavigation={handleNavigation}
            />
          ) : null}
        </>
      )}
    </CustomerLayout>
  );
}

export default BranchRedirect;
