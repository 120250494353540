import { FRONTEND_URL } from "../utils/utils";
// import { getAssetURL } from "./get-asset-url";
import site_main_logo from "../assets/images/valetsearch.png";
import forget_static_img from "../assets/images/forgetpassword-static.jpg";
import mail_static_img from "../assets/images/mail_staic_image.png";

function Email_template(props) {
  const temp = `<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
<head>
    <title></title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css?family=Cabin" rel="stylesheet" type="text/css"><!--<![endif]-->
    <style>
        * { box-sizing: border-box;  }
        body { margin: 0; padding: 0;  }
        a[x-apple-data-detectors] { color: inherit !important;text-decoration: inherit !important;  }
        #MessageViewBody a {   color: inherit;text-decoration: none;  }
        p { line-height: inherit  }
        .desktop_hide,
        .desktop_hide table {  mso-hide: all; display: none;   max-height: 0px;  overflow: hidden;  }
        .image_block img+div {  display: none;  }
        @media (max-width:700px) {
            .desktop_hide table.icons-inner,
            .social_block.desktop_hide .social-table {
                display: inline-block !important;
            }
            .icons-inner { text-align: center; }
            .icons-inner td {  margin: 0 auto; }
            .fullMobileWidth,
            .row-content {  width: 100% !important;   }
            .mobile_hide {  display: none;    }
            .stack .column {   width: 100%;    display: block;    }
            .mobile_hide {min-height: 0;  max-height: 0; max-width: 0;  overflow: hidden;   font-size: 0px;      }
            .desktop_hide,
            .desktop_hide table {  display: table !important;   max-height: none !important;  }
            .reverse {   display: table;  width: 100%; }
            .reverse .column.first {  display: table-footer-group !important;   }
            .reverse .column.last {    display: table-header-group !important; }
            .row-5 td.column.first .border { padding: 35px 0 35px 25px; border-top: 0; border-right: 0px;  border-bottom: 0;  border-left: 0;  }
            .row-5 td.column.last .border { padding: 0 0 5px;  border-top: 0;    border-right: 0px;   border-bottom: 0;  border-left: 0;   }
        }
    </style>
</head>
<body style="background-color: #f2f2f2; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f2f2f2;">
        <tbody>
            <tr>
                <td>
                    <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width:980px;" width="980">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 7px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                    <div class="spacer_block block-1" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #fbfbfb; color: #000000; width:980px;" width="980">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                    <div class="spacer_block block-1" style="height:7px;line-height:7px;font-size:1px;">&#8202;</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #fbfbfb; color: #000000; width:980px;" width="980">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1" width="66.66666666666667%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-left: 8px; padding-right: 10px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                    <table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                        <tr>
                                                            <td class="pad" style="padding-left:25px;width:100%;padding-right:0px;">
                                                                <div class="alignment" align="left" style="line-height:10px"><img src=${FRONTEND_URL}/${site_main_logo} style="display: block; height: auto; border: 0; width: 174px; max-width: 100%;" width="174" alt="ValetSearch" title="ValetSearch"></div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #fbfbfb; color: #000000; width:980px;" width="980">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 7px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                    <table class="divider_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                        <tr>
                                                            <td class="pad" style="padding-bottom:5px;padding-top:5px;">
                                                                <div class="alignment" align="center">
                                                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                        <tr>
                                                                            <td class="divider_inner" style="font-size: 1px; line-height: 1px; border-top: 3px solid #F2F2F2;"><span>&#8202;</span></td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #fbfbfb; color: #000000; width: 980px;" width="980">
                                        <tbody>
                                            <tr class="reverse">
                                                <td class="column column-1 first" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 35px; padding-left: 25px; padding-top: 35px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                    <div class="border">
                                                        <table class="paragraph_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                            <tr>
                                                                <td class="pad" style="padding-bottom:10px;padding-left:5px;padding-right:5px;padding-top:10px;">
                                                                    <div style="color:#393d47;direction:ltr;font-family:Cabin, Arial, Helvetica Neue, Helvetica, sans-serif;font-size:15px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:22.5px;">
                                                                        <p style="margin: 0;">${
                                                                          props?.forget ===
                                                                          true
                                                                            ? `<div>
                                                                        <p>Dear<strong> ${props?.name}</strong>,</p>
                                                                        <p>Welcome to our ValetSearch! As you requested, here is your newly generated password:<strong> ${props?.token}</strong>.</p>
                                                                       <p>Please use this password to <a href=${FRONTEND_URL}/${props?.company}/${props?.branch}/login >log in</a> to your account. We highly recommend not sharing the password with others to keep your account secure.
                                                                       </p><p> If you have any questions or need assistance, please don't hesitate to contact our support team at ValetSearch.
                                                                       Thank you for choosing our service!</p>
                                                                           <div>`
                                                                            : props?.user ===
                                                                              "admin"
                                                                            ? `<p>Dear <strong>Admin</strong>,</p>
                                            <p>   We have received an application from <strong>${props?.companyName}</strong> at <strong>${props?.branchName}</strong>.           
                                            Please verify the application information and the associated documents to confirm that <strong>${props?.companyName} - ${props?.branchName}</strong> has been included in the ValetSearch.           
                                           </p>  <p> Thank you for taking the time to approve <strong>${props?.companyName} - ${props?.branchName}</strong>. Your time is valuable and we appreciate your input.</p>`
                                                                            :
                                                                            props?.affilation===true?`<p>Dear <strong>Admin</strong>,</p>
                                                                            <p>You have received business affiliation from  <b>${props?.name}</b>, Kindly check the below details,</br>
                                                                            <p><b>Name: </b> ${props?.name},</p>
                                                                            <p><b>Phone number: </b> ${props?.phoneNo},</p>
                                                                            <p><b>Email: </b> ${props?.email},</p>
                                                                            <p><b>Message: </b> ${props?.message}</p> </p>
                                                                            `
                                                                             :props?.submitType?
                                                                             props?.user==="admin"? 
                                                                             props?.submitType==="document_correction"?
                                                                             `<p>Dear <strong>Admin</strong>,</p>
                                                                             <p>   We have received a document correction application from <strong>${props?.companyName}</strong> at <strong>${props?.branchName}</strong>.           
                                                                             Please verify the application information and the associated documents to confirm that <strong>${props?.companyName} - ${props?.branchName}</strong> has been included in the ValetSearch.           
                                                                            </p>  <p> Thank you for taking the time to approve <strong>${props?.companyName} - ${props?.branchName}</strong>. Your time is valuable and we appreciate your input.</p>`
                                                                           
                                                                           :
                                                                            props?.submitType==="agreement_submit"?
                                                                            `<p>Dear <strong>Admin</strong>,</p>
                                                                             <p>   We have received an Onboarding form application from <strong>${props?.companyName}</strong> at <strong>${props?.branchName}</strong>.           
                                                                             Please verify the submission details and process the branch to further status.</p>  
                                                                             <p> Thank you for taking the time to approve <strong>${props?.companyName} - ${props?.branchName}</strong>. Your time is valuable and we appreciate your input.</p>`
                                                                            :
                                                                            props?.submitType==="signature_submit"?
                                                                            `<p>Dear <strong>Admin</strong>,</p>
                                                                             <p>   We have received a contract document from <strong>${props?.companyName}</strong> at <strong>${props?.branchName}</strong>.           
                                                                             Please verify the agreement and process the branch to further status.
                                                                             </p>  
                                                                             <p> Thank you for taking the time to approve <strong>${props?.companyName} - ${props?.branchName}</strong>. Your time is valuable and we appreciate your input.</p>`  :""
                                                                           
                                                                            :
                                                                            props?.submitType==="document_correction"?
                                                                            ` <p> Dear <strong>${props?.companyName}</strong>,</p>
                                                                             <p>Thank you for your submission. This email serves as confirmation that we have received your application correction and that it is currently under review. </p>
                                                                             <p> Our staff team will assess your application and documents to verify the inclusion of your company or branch in ValetSearch.
                                                                             </p>
                                                                             <p> Please be aware that the review process may take some time.
                                                                             We appreciate your patience and interest in our <strong>ValetSearch</strong>.</p> 
                                                                             <p>We will contact you shortly through the registered admin email address. If you have any questions or concerns, please do not hesitate to contact us.
                                                                             </p>`:
                                                                            props?.submitType==="agreement_submit"?
                                                                            ` <p> Dear <strong>${props?.companyName}</strong>,</p>
                                                                             <p>Thank you for your submission. This email serves as confirmation that we have received your recent onboarding form submission and that it is currently under review.
                                                                             </p>
                                                                             <p> Our staff team will assess your application and documents to verify the inclusion of your company or branch in ValetSearch.
                                                                             </p>
                                                                             <p> Please be aware that the review process may take some time.
                                                                             We appreciate your patience and interest in our <strong>ValetSearch</strong>.</p> 
                                                                             <p>We will contact you shortly through the registered admin email address. If you have any questions or concerns, please do not hesitate to contact us.
                                                                             </p>`
                                                                            :
                                                                            props?.submitType==="signature_submit"?
                                                                            ` <p> Dear <strong>${props?.companyName}</strong>,</p>
                                                                             <p>Thank you for your submission. This email serves as confirmation that we have received your contract agreement with your signature and that it is currently under review.
                                                                             </p>
                                                                             <p> Our staff team will assess your agreement to verify the inclusion of your company or branch in ValetSearch.
                                                                             </p>
                                                                             <p> Please be aware that the review process may take some time.
                                                                             We appreciate your patience and interest in our <strong>ValetSearch</strong>.</p> 
                                                                             <p>We will contact you shortly through the registered admin email address. If you have any questions or concerns, please do not hesitate to contact us.
                                                                             </p>`:""
                                                                             
                                                                            :
                                                                             ` <p> Dear <strong>${props?.companyName}</strong>,</p>
                                             <p> Thank you for your interest in registering your company, <strong>${props?.companyName}<strong>, at </strong>${props?.branchName}</strong> with <strong>ValetSearch</strong>. This email serves as confirmation that we have received your application and that it is currently under review.   
                                             </p><p> Our staff team will assess your application and documents to verify the inclusion of your company or branch in ValetSearch. </p><p> Please be aware that the review process may take some time.
                                             We appreciate your patience and interest in our <strong>ValetSearch</strong>.</p> <p>We will contact you shortly through the registered admin email address. If you have any questions or concerns, please do not hesitate to contact us.
                                             </p>`}
                                             <p>Best regards, </p>
                                             <p> <Strong>ValetSearch Team</strong></p>
                                             </p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </td>
                                                <td class="column column-2 last" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; vertical-align: center; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                    <div class="border">
                                                        <table class="image_block block-1" width="100%" border="0" cellpadding="20" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                            <tr>
                                                                <td class="pad">
                                                                    <div class="alignment" align="center" style="line-height:10px"><img class="fullMobileWidth" src=${
                                                                      props?.forget ===
                                                                      true
                                                                        ?`${FRONTEND_URL}/${forget_static_img}`
                                                                        :`${FRONTEND_URL}/${mail_static_img}`
                                                                    } style="display: block; height: auto; border: 0; width: 282px; max-width: 100%;" width="282" alt="ValetSearch" title="ValetSearch"></div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="row row-6" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #fbfbfb; color: #000000; width:980px;" width="980">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                    <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="row row-7" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                        <tbody>
                            <tr>
                                <td>
                                    <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width:980px;" width="980">
                                        <tbody>
                                            <tr>
                                                <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                    <div class="spacer_block block-1" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                   
                </td>
            </tr>
        </tbody>
    </table><!-- End -->
</body>

</html>`;

  return temp;
}

export default Email_template;
