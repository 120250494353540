/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { toast } from "react-toastify";
import LayoutComponent from "../components/LayoutComponent";
import AdminCredential from "../components/companyRegistration/AdminCredential";
import BranchInfo from "../components/branchRegistration/BranchInfo";
import CompanyInfo from "../components/companyRegistration/CompanyInfo";
import { directus } from "../services/directus";
import { useNavigate } from "react-router-dom";
import Seo from "../components/Seo";
import { RegistrationMailSender } from "../utils/Mailsender";
import seoData from '../seoContent.json';
import ConfirmationModal from "../components/popupModals/ConfirmationModal";

function BranchRegistration() {
  const [page, setPage] = React.useState(1);
  const [companyInfo, setCompanyInfo] = React.useState();
  const [showKeyboard, setShowKeyboard] = React.useState();
  const [confirmModal, setConfirmModal] = React.useState(false);

  const [info, setInfo] = React.useState({
    companyName: companyInfo?.name || "",
    cinNumber:  "",
    //  logo: '',
    Address: "",
    Tags: [],
    State: "",
    City: "",
    docs1: "",
    docs2: "",
    location: "",
    typeOfCompany: companyInfo?.type_of_organization,
  });
  const [adminDetails, setAdminDetails] = React.useState({
    adminEmail: "",
    password: "",
    confirmPassword: "",
    adminPhoneNo: "",
    subscription: true,
    terms: false,
    valetFee:false,
    valetFeeValue:""
  });
  const navigate = useNavigate();
  const [validationError, setValidationError] = React.useState({
    companyNameError: false,
    //  logoError: false,
    cinNumberError: false,
    cinNumberFormatError: false,
    AddressError: false,
    StateError: false,
    CityError: false,
    TagsError: false,
    docs1Error: false,
    docs2Error: false,
    locationError: false,
    typeOfCompanyError: false,

    adminEmailError: false,
    adminEmailFormatError: false,
    passwordError: false,
    confirmPasswordError: false,
    passwordMismatchError: false,

    subscriptionError: false,
    termsError: false,
    adminPhoneNoError: false,
    lenPhoneNoError: false,
    valetFeeValueError: false,
  });
  async function fieldValidation() {
    if (info?.companyName?.trim()?.length < 1) {
      updateValidationError("companyNameError", true);
    }
    if (info?.cinNumber?.trim()?.length < 1) {
      updateValidationError("cinNumberError", true);
    }
    if (
      !!!/^[a-zA-Z0-9]{4,30}$/gm.test(
        info?.cinNumber?.trim()
      )
    ) {
      updateValidationError("cinNumberFormatError", true);
    } else {
      updateValidationError("cinNumberFormatError", false);

    }
    if (info?.Address?.trim()?.length < 1) {
      updateValidationError("AddressError", true);
    }
    if (!!!info?.State?.id) {
      updateValidationError("StateError", true);
    }
    if (!!!info?.City?.id) {
      updateValidationError("CityError", true);
    }
    if (info?.Tags?.length < 1) {
      updateValidationError("TagsError", true);
    }
    if (typeof info?.docs1 === "string") {
      updateValidationError("docs1Error", true);
    }
    if (typeof info?.docs2 === "string") {
      updateValidationError("docs2Error", true);
    }
    if (info?.location?.trim()?.length < 1) {
      updateValidationError("locationError", true);
    }
    if (info?.typeOfCompany?.trim()) {
      updateValidationError("typeOfCompanyError", true);
    }
    return validationError;
  }
  async function fieldValidation1() {
    if (!!!adminDetails?.adminEmail?.trim()) {
      updateValidationError("adminEmailError", true);
    }
    if (
      !!!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        adminDetails?.adminEmail
      )
    ) {
      updateValidationError("adminEmailFormatError", true);
    }
    if (!!!adminDetails?.password?.trim()) {
      updateValidationError("passwordError", true);
    }
    if (!!!adminDetails?.adminPhoneNo?.trim()) {
      updateValidationError("adminPhoneNoError", true);
    }
    if (!!!adminDetails?.adminPhoneNo?.trim()?.length < 10) {
      adminDetails?.adminPhoneNo?.trim()?.length > 1 &&
        updateValidationError("adminPhoneNoError", false);
      updateValidationError("lenPhoneNoError", true);
    }
    if (!!!adminDetails?.confirmPassword?.trim()) {
      updateValidationError("confirmPasswordError", true);
    }
    if (adminDetails?.password?.trim()?.length > 0 || adminDetails?.confirmPassword?.trim()?.length > 0) {
      if (adminDetails?.password?.trim() !== adminDetails?.confirmPassword?.trim()) {
        updateValidationError("passwordMismatchError", true);
      }
    }
    if (!!!adminDetails?.subscription) {
      updateValidationError("subscriptionError", true);
    }
    if (!!!adminDetails?.terms) {
      updateValidationError("termsError", true);
    }
    if (adminDetails?.adminPhoneNo?.trim()?.length === 10) {
      updateValidationError("lenPhoneNoError", false);
    }
    if (
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(adminDetails?.adminEmail)
    ) {
      updateValidationError("adminEmailFormatError", false);
    }
    if (adminDetails?.valetFee===true&&Number(adminDetails?.valetFeeValue)<=0) {
      updateValidationError("valetFeeValueError", true);
    }
    if (
      adminDetails?.terms &&
      adminDetails?.subscription &&
      adminDetails?.adminEmail?.trim() &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        adminDetails?.adminEmail
      ) &&
      adminDetails?.confirmPassword?.trim() &&
      adminDetails?.adminPhoneNo?.trim()?.length === 10 &&
      adminDetails?.adminPhoneNo?.trim() &&
      adminDetails?.password?.trim() &&
      adminDetails?.password?.trim() === adminDetails?.confirmPassword?.trim()&&
      (adminDetails?.valetFee===false||(Number(adminDetails?.valetFeeValue)>0))
    ) {
      setValidationError({
        companyNameError: false,
        // logoError: false,
        cinNumberError: false,
        cinNumberFormatError: false,
        AddressError: false,
        TagsError: false,
        StateError: false,
        CityError: false,
        docs1Error: false,
        docs2Error: false,
        locationError: false,
        typeOfCompanyError: false,
        adminEmailError: false,
        passwordError: false,
        confirmPasswordError: false,
        passwordMismatchError: false,
        lenPhoneNoError: false,
        subscriptionError: false,
        termsError: false,
        adminPhoneNoError: false,
        adminEmailFormatError: false,
        valetFeeValueError:false
      });
    }

    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }
  const pageHandle = async (prop) => {
    const branchAlreadyExist =
      info?.location?.trim()?.length > 0 &&
      (await directus
        .items("Branch")
        .readByQuery({
          fields: ["id","slug"],
          filter: {
            slug: {
              _eq:
                info?.location
                  ?.trim()
                  ?.toLowerCase()
                  ?.replaceAll(" ", "")
                  ?.replaceAll(".", "-") + companyInfo?.id,
            },
          },
        })
        .then((res) => {
          return res?.data[0] ? true : false;
        })
        .catch((e) => {
          console.log(e);
        }));
    if (!branchAlreadyExist) {
      var flag = false;
      if (prop === "add") {
        await fieldValidation();
        var {
          companyNameError,
          cinNumberError,
          cinNumberFormatError,
          //  logoError,
          AddressError,
          TagsError,
          StateError,
          CityError,
          docs1Error,
          docs2Error,
          locationError,
          typeOfCompanyError,
        } = validationError;
        // if ((!!info?.companyName && !!info?.logo && !!info?.Address && !!info?.Tags && !!info?.docs1 && !!info?.docs2 && !!info?.location && !!info?.typeOfCompany)) {
        if (
          !!info?.companyName?.trim() &&
          !!info?.cinNumber?.trim() &&
          /^[a-zA-Z0-9]{4,30}$/gm.test(
            info?.cinNumber?.trim()
          ) &&
          !!info?.Address?.trim() &&
          !!info?.Tags?.length > 0 &&
          !!info?.State?.id &&
          !!info?.City?.id &&
          (!!info?.docs1 || !!info?.docs2) &&
          !!info?.location?.trim() &&
          !!info?.typeOfCompany
        ) {
          setPage(page + 1);
          return (flag = false);
        } else {
          if (
            companyNameError ||
            cinNumberError ||
            cinNumberFormatError ||
            // logoError||
            AddressError ||
            TagsError ||
            StateError ||
            CityError ||
            docs1Error ||
            docs2Error ||
            locationError ||
            typeOfCompanyError
          ) {
            return (flag = true);
          } else {
            return (flag = false);
          }
        }
      }
      return prop === "add" && page < 4
        ? !flag && setPage(page + 1)
        : page > 1 && prop !== "add"
          ? setPage(page - 1)
          : console.log("invalid");
    } else {
      toast.error("Branch name already exist");
    }
  };
  React.useEffect(() => {
    setInfo({
      companyName: companyInfo?.name || "",
      cinNumber:  "",
      // logo: '',
      Address: "",
      Tags: [],
      State: "",
      City: "",
      docs1: "",
      docs2: "",
      location: "",
      typeOfCompany: companyInfo?.type_of_organization || "single_organization",
    });
  }, [companyInfo]);
  React.useEffect(() => {
    var {
      companyNameError,
      // logoError,
      cinNumberError,
      cinNumberFormatError,
      AddressError,
      TagsError,
      StateError,
      CityError,
      docs1Error,
      docs2Error,
      locationError,
      typeOfCompanyError,
      adminEmailError,
      adminEmailFormatError,
      passwordError,
      lenPhoneNoError,
      adminPhoneNoError,
      subscriptionError,
      confirmPasswordError,
      termsError, passwordMismatchError,valetFeeValueError
    } = validationError;
    if (
      !!info?.companyName?.trim() &&
      !!info?.cinNumber?.trim() &&
      /^[a-zA-Z0-9]{4,30}$/gm.test(
        info?.cinNumber?.trim()
      ) &&
      !!info?.Address?.trim() &&
      !!info?.Tags?.length > 0 &&
      !!info?.State?.id &&
      !!info?.City?.id &&
      (!!info?.docs1 || !!info?.docs2) &&
      !!info?.location?.trim() &&
      !!info?.typeOfCompany
    ) {
      if (
        !!adminDetails?.adminEmail &&
        !!adminDetails?.password &&
        !!adminDetails?.confirmPassword &&
        !!adminDetails?.subscription &&
        !!adminDetails?.adminPhoneNo &&
        !!adminDetails?.terms
      ) {
        if (
          !(
            companyNameError ||
            cinNumberError ||
            cinNumberFormatError ||
            //  || logoError
            AddressError ||
            TagsError ||
            StateError ||
            CityError ||
            docs1Error ||
            docs2Error ||
            locationError ||
            typeOfCompanyError ||
            adminEmailError ||
            adminEmailFormatError ||
            passwordError ||
            adminPhoneNoError ||
            lenPhoneNoError ||
            confirmPasswordError || passwordMismatchError ||
            subscriptionError ||
            termsError||valetFeeValueError
          )
        ) {
          setConfirmModal(true);
        }
      }
    } else {
      if (
        companyNameError ||
        cinNumberError ||
        cinNumberFormatError ||
        AddressError ||
        TagsError ||
        StateError ||
        CityError ||
        docs1Error ||
        docs2Error ||
        locationError ||
        typeOfCompanyError ||
        adminEmailFormatError || passwordMismatchError||valetFeeValueError
      ) {
        toast.error("Validation Failed!!!");
      }
    }
  }, [validationError]);

  const submitHandle = async (e) => {
    await fieldValidation1();
  };

  const createImageHandle = async (props) => {
    const formData = new FormData();
    formData.append("folder", props);
    const val = await directus.files
      .createOne(formData)
      .then((res) => {
        return res?.id;
      })
      .catch((err) => {
        console.log("Image create error", err?.message);
      });
    return val;
  };
  const tagsIds = async (prop) => {
    const Tags_Category = [];
    info?.Tags?.map((e) =>
      Tags_Category.push({ Branch_id: prop, Tags_Category_id: e?.id })
    );
    await directus
      .items("Branch_Tags_Category")
      .createMany(Tags_Category)
      .then(async (res) => { })
      .catch((e) => console.log(e, "tags creation error"));
  };

  const branchCreateHandle = async (prop) => {
    if (adminDetails?.adminPhoneNo?.trim()?.length >= 10) {
      if (adminDetails?.password?.length >= 4) {
        if (adminDetails?.password === adminDetails?.confirmPassword) {
          await directus.users
            .createOne({
              first_name: adminDetails?.adminEmail?.trim(),
              email: adminDetails?.adminEmail?.trim(),
              password: adminDetails?.password?.trim(),
              password_copy: adminDetails?.password?.trim(),
              contact_number: adminDetails?.adminPhoneNo?.trim(),
              is_admin_user: true,
            })
            .then(async (userRes) => {
              if (userRes?.id) {
                const ImageDocs1Id = await createImageHandle(info?.docs1);
                const ImageDocs2Id = await createImageHandle(info?.docs2);
                const bodyValue = {
                  company_id: companyInfo?.id,
                  name: info?.location?.trim(),
                  address: info?.Address?.trim(),
                  cin_gov_number: info?.cinNumber?.trim(),
                  state_id: info?.State?.id,
                  city_id: info?.City?.id,
                  document_1: ImageDocs1Id,
                  main_admin:userRes?.id,
                  is_main: "yes",
                  document_2: ImageDocs2Id,
                  type_of_organization: info?.typeOfCompany,
                  valet_fee: adminDetails?.valetFee ? adminDetails?.valetFeeValue ? Number(adminDetails?.valetFeeValue) : null : null
                };
                await directus
                  .items("Branch")
                  .createOne(bodyValue)
                  .then(async (res) => {
                    // await companyUpdate({ branchId: res?.id, id: companyInfo?.id })
                    await tagsIds(res?.id);
                    await userUpdate({
                      branchId: res?.id,
                      companyId: companyInfo?.id,
                      id: userRes?.id,
                    });
                    await RegistrationMailSender({
                      emailTo: adminDetails?.adminEmail?.trim(),
                      type: "branch",
                      branchName: info?.location?.trim(),
                      companyName: info?.companyName?.trim(),
                    });
                    navigate("/success-registration", { state: "branch" });
                  })
                  .catch((err) => {
                    navigate(".", { replace: true });
                    console.log(err);
                    toast.error(err);
                  });
              }
            })
            .catch((err) => {
              navigate(".", { replace: true });
              console.log(err);
              toast.error(err?.message);
            });
        } else {
          toast.error("Password mismatch");
        }
      } else {
        toast.error("Set minimum 4 character password");
      }
    } else {
      toast.error("Invalid phone number");
    }
  };
  const userUpdate = async (prop) => {
    let updateFields = {
      default_branch: prop?.branchId,
      default_company: prop?.companyId,
    };
    await directus.users
      .updateOne(prop?.id, updateFields)
      .then((res) => { })
      .catch((err) => {
        navigate(".", { replace: true });
        console.log(err);
        toast.error(err?.message);
      });
  };
  return (
    <LayoutComponent title={"BRANCH REGISTRATION"} fixedFooter={true} footerShow={showKeyboard} chat={false}>
      <Seo title={seoData?.branch_register?.title} />
      <main className="main-content-area px-6 py-8">
        <div className="mx-auto">
          <div className="block__status-bar cursor-default">
            <nav aria-label="Progress">
              <ol className="rounded-md border border-gray-300 grid grid-cols-12 ">
                <li className="relative col-span-4 md:col-span-4">
                  {/* <!-- Current Step --> */}
                  <button
                    // onClick={() => setPage(2)}
                    className="group flex flex-col items-center px-6 py-4 text-sm font-medium w-full cursor-default	"
                    aria-current="step"
                  >
                    <span
                      className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ${page <= 1
                        ? " border-primary"
                        : page <= 1
                          ? " border-gray-300"
                          : "bg-primary"
                        }  group-hover:border-gray-400`}
                    >
                      <span
                        className={`${page === 1
                          ? "text-primary"
                          : page < 1
                            ? " text-gray-500"
                            : "text-white"
                          }  group-hover:text-gray-900`}
                      >
                        01
                      </span>
                    </span>
                    <span className="mt-2 text-xs text-center font-medium text-primary">
                      Select your Company
                    </span>
                  </button>
                </li>
                <li className="relative col-span-4 md:col-span-4">
                  {/* <!-- Current Step --> */}
                  <button
                    // onClick={() => setPage(2)}
                    className="group flex flex-col items-center px-6 py-4 text-sm font-medium w-full cursor-default	"
                    aria-current="step"
                  >
                    <span
                      className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ${page === 2
                        ? " border-primary"
                        : page <= 2
                          ? " border-gray-300"
                          : "bg-primary"
                        }  group-hover:border-gray-400`}
                    >
                      <span
                        className={`${page === 2
                          ? "text-primary"
                          : page <= 2
                            ? " text-gray-500"
                            : "text-white"
                          }  group-hover:text-gray-900`}
                      >
                        02
                      </span>
                    </span>
                    <span className="mt-2 text-xs text-center font-medium text-primary">
                      Add Branch
                    </span>
                  </button>
                </li>
                <li className="relative col-span-4 md:col-span-4">
                  {/* <!-- Upcoming Step --> */}
                  <button
                    //  onClick={() => setPage(3)}
                    className="group flex items-center justify-center w-full cursor-default"
                  >
                    <span className="flex flex-col items-center px-6 py-4 text-sm font-medium">
                      <span
                        className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ${page === 3
                          ? " border-primary"
                          : page < 3
                            ? " border-gray-300"
                            : "bg-primary"
                          } group-hover:border-gray-400`}
                      >
                        <span
                          className={`${page <= 3
                            ? "text-primary"
                            : page < 2
                              ? " text-gray-500"
                              : "text-white"
                            }  group-hover:text-gray-900`}
                        >
                          03
                        </span>
                      </span>
                      <span className="mt-2 text-xs text-center font-medium text-gray-500 group-hover:text-gray-900">
                        Admin Credentials
                      </span>
                    </span>
                  </button>
                </li>
              </ol>
            </nav>
          </div>
          <div className="block__form">
            {/* <form> */}
            {page === 1 ? (
              <BranchInfo
                info={info}
                setInfo={setInfo}
                validationError={validationError}
                setPage={setPage}
                setCompanyInfo={setCompanyInfo}
                companyInfo={companyInfo}
                setShowKeyboard={setShowKeyboard}
              />
            ) : page === 2 ? (
              <CompanyInfo
                info={info}
                setInfo={setInfo}
                validationError={validationError}
                edit={true}
                logo={false}
                type={"branch"}
                allFieldEdit={true}
                mainFieldDisable={false}
              />
            ) : (
              <AdminCredential
                adminDetails={adminDetails}
                setAdminDetails={setAdminDetails}
                validationError={validationError}
              />
            )}
            {page !== 1 && (
              <div className="form__actions flex justify-center items-center gap-5 mt-10">
                {page > 1 && (
                  <button
                    onClick={() => pageHandle("sub")}
                    className="inline-flex items-center justify-center rounded-md h-10 border-2 border-red-600 bg-red-50 px-4 py-2 text-sm font-semibold text-red-600 focus:outline-none sm:w-auto"
                  >
                    Back{" "}
                  </button>
                )}
                <button
                  onClick={() =>
                    page <= 2 ? pageHandle("add") : submitHandle()
                  }
                  className={`inline-flex items-center justify-center rounded-md border-transparent h-10 border-2 ${page === 3 &&
                    (!adminDetails?.subscription || !adminDetails?.terms)
                    ? "opacity-50 pointer-events-none"
                    : ""
                    } bg-primary px-4 py-2 text-sm font-semibold text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto`}
                >
                  {page <= 2 ? " Next" : "Submit"}{" "}
                </button>
              </div>
            )}

            {/* </form> */}
          </div>
        </div>
        {confirmModal ? (
          <ConfirmationModal
            openModel={confirmModal}
            setIsOpenModel={setConfirmModal}
            callFunction={branchCreateHandle}
            content={"Are you sure the given information is valid?"}
          />
        ) : null}
      </main>
    </LayoutComponent>
  );
}

export default BranchRegistration;
