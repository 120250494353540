import React from "react";
import Files from "react-files";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
function OfferCreateAndEdit({
  dateFrom,
  description,
  coupon,
  validationError,
  setDateFrom,
  setDescription,
  setCoupon,
  setValidationError,
  offerImage,
  offerImageSize,
  setOfferImageSize,
  setOfferImage,
  type,
  isUpdate,
}) {
  async function fieldValidation() {
    if (!!!dateFrom?.length > 0) {
      updateValidationError("dateFromError", true);
    }
    if (!!!description?.trim()) {
      updateValidationError("descriptionError", true);
    }
    if (coupon?.trim()?.length > 0 && coupon?.trim()?.length < 4) {
      updateValidationError("couponError", true);
    } 
    if (
      dateFrom &&
      description?.trim() &&
      (coupon?.trim()?.length >= 4 || coupon?.trim()?.length===0)
    ) {
      setValidationError({
        dateFromError: false,
        descriptionError: false,
        offerImageError: false,
        couponError: false,
      });
    }

    return validationError;
  }
  function updateValidationError(field, state) {
    setValidationError((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }

  const handleModal = async (e) => {
    if (isUpdate) {
      setValidationError({
        dateFromError: false,
        offerImageError: false,
        descriptionError: false,
        couponError: false,
      });
      await fieldValidation();
    }
  };
  const preview = (props) => {
    return (
      props?.value && (
        <div className="files-list">
          <ul>
            <li
              key={props?.value?.id}
              className="flex flex-row justify-evenly text-center place-items-center"
            >
              <div>
                <div className="mt-2">
                  {props?.value?.preview?.type === "image" ? (
                    <img
                      className=""
                      src={props?.value?.preview?.url}
                      alt="Preview"
                    />
                  ) : (
                    <div className="">{props?.value?.extension}</div>
                  )}
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900 min-w-[150px] ">
                    {props?.value?.name}
                  </div>
                </div>
              </div>
            </li>
            <div className="list-item-remove" onClick={() => setOfferImage("")}>
              <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                stroke="#ff0000"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="css-i6dzq1 float-right"
              >
                <title>Delete</title>
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            </div>
          </ul>
        </div>
      )
    );
  };
  const ImageHandler = (event) => {
    let file_size = event[0]?.size;
    if (file_size / 1000000 < 2) {
      setOfferImageSize("");
      setOfferImage(event[0]);
    } else {
      setOfferImageSize(
        "* Please upload the attachment less than 2MB. / img/*(except .gif)  .doc, .pdf, .docx"
      );
    }
  };
  return (
    <div>
      <div className="main-content-area px-6 max-sm:px-5 pt-0">
        <div className="mx-auto">
          <div className="">
            <div className="mt-1 mb-1 block">
              <div className="space-y-6 bg-white py-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 edit-offers">
                    <label className="block text-sm font-bold text-gray-700 mb-2">
                      Date From - Date To *
                    </label>
                    <div className={`relative border rounded border-gray-200 py-2 px-4 ${
                        validationError?.dateFromError && dateFrom?.length < 1
                          ? "border-red-300 focus:border-red-300"
                          : "border-gray-300 focus:border-primary"
                      } `}>
                      <DateRangePicker
                        minDate={new Date()}
                        onChange={setDateFrom}
                        value={dateFrom}
                        clearIcon={null}
                      
                      />
                      {dateFrom?.length > 0 && (
                        <div
                          className="w-full absolute right-20 close-icon"
                          onClick={() => setDateFrom([])}
                        >
                          <svg
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                            className="css-tj5bde-Svg"
                          >
                            <title>Clear</title>
                            <path
                              d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                              fill="#ccc"
                            ></path>
                          </svg>
                        </div>
                      )}
                    </div>
                    {validationError?.dateFromError && dateFrom?.length < 1 && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Date From - Date To is required
                      </p>
                    )}
                  </div>
                  <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700 mb-2" htmlFor="short-description">
                      Short description *
                    </label>
                    <input
                      className={`appearance-none ${
                        validationError?.descriptionError &&
                        description?.trim()?.length < 1
                          ? "border-red-300 focus:border-red-300"
                          : "border-gray-300 focus:border-primary"
                      }  
                      block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                      placeholder="Enter the short description"
                      id="short-description"
                      type="text"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description || ""}
                    />
                    {validationError?.descriptionError &&
                      description?.trim()?.length < 1 && (
                        <p className="text-xs text-right text-red-500 mt-2">
                          *Short Description is required
                        </p>
                      )}
                  </div>
                  <div className="col-span-6">
                    <label className="block text-sm font-bold text-gray-700 mb-2" htmlFor="coupon-code">
                      Coupon code
                    </label>
                    <input
                      className={`appearance-none ${
                        validationError?.couponError && coupon?.length < 4
                          ? "border-red-300 focus:border-red-300"
                          : "border-gray-300 focus:border-primary"
                      }  
                      block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                      placeholder="Enter the coupon code"
                      id="coupon-code"
                      type="text"
                      onChange={(e) => setCoupon(e.target.value)}
                      value={coupon || ""}
                    />
                    {validationError?.couponError && coupon?.length < 4 && (
                      <p className="text-xs text-right text-red-500 mt-2">
                        *Coupon Code should be minimum 4 letters
                      </p>
                    )}
                  </div>
                  <div className="col-span-6">
                    <label
                      htmlFor="company-doc-1"
                      className="block text-sm font-bold text-gray-700 mb-2"
                    >
                      Offer image
                    </label>
                    <div className="block w-full">
                      <Files
                        className={`${
                          offerImageSize?.trim()?.length > 1
                            ? "border-red-300 focus:border-red-300"
                            : "focus:border-primary"
                        } pl-4 py-3 pr-9 min-w-[310px] w-full rounded border text-sm shadow-sm border-gray-200 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0 flex items-center font-bold text-[#bbb] text-[14px]`}
                        onChange={(e) => ImageHandler(e)}
                        // onError={console.log("first")}
                        accepts={["image/*", ".pdf", ".doc", ".docx"]}
                        multiple={false}
                        maxFileSize={2 * 1024 * 1024}
                        minFileSize={0}
                        clickable
                      >
                        Drop files here or click to upload
                      </Files>
                      {offerImage &&
                        offerImage?.preview?.url !== null &&
                        preview({ value: offerImage })}
                      {offerImageSize?.trim()?.length > 1 && (
                        <p className="text-xs text-right text-red-500 error-msg mt-0">
                          {offerImageSize?.trim()}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-6 flex justify-end items-end pt-5">
                    <button
                      type="button"
                      onClick={(e) => handleModal(e)}
                      className={`inline-flex items-center justify-center border border-transparent rounded-md hover:border-vspurple bg-primary px-4 py-2 text-sm font-semibold text-white shadow-md shadow-green-100 hover:bg-vspurple focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto ${
                        !isUpdate ? "opacity-50 ..." : ""
                      }`}
                    >
                      <span className="ml-1.5">{type} Offer</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferCreateAndEdit;
