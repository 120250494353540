/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import Select from "react-select";
import { directus } from "../../services/directus";
function BranchInfo(props) {
  const [companyList, setCompanyList] = React.useState([]);
  const { setPage, setCompanyInfo, companyInfo } = props;
  const branchListCall = async () => {
    await directus
      .items("Companies")
      .readByQuery({
        fields: ["id","branches","name","slug"],
        filter: { status: { _eq: "published" } },
      })
      .then((res) => {
        setCompanyList(res?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const styles1 = {
    menuList: (base) => ({
      ...base,
      height: "110px",
      "::-webkit-scrollbar": {
        width: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "#2684FF",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: "none",
      marginTop: "4px",
      backgroundColor: "none",
    }),
  };
  React.useEffect(() => {
    branchListCall();
  }, []);
  const selectHandle = (prop) => {
    setCompanyInfo(prop);
  };
  return (
    <div className="flex justify-between items-center my-8">
      <div className="bg-white input-boxes w-[90%] border border-1 border-gray-300">
        <div className=" col-span-2 search w-full">
          <div className="mx-auto text-gray-600">
            <Select
              id="branch"
              classNamePrefix="react-select"
              className=" border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] flex h-10 items-center text-[14px] rounded-md capitalize"
              defaultMenuIsOpen={false}
              defaultValue={companyInfo}
              maxMenuHeight={155}
              onFocus={() => props?.setShowKeyboard(true)}
              onBlur={() => props?.setShowKeyboard(false)}
              onChange={(e) => selectHandle(e)}
              options={companyList}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => {
                return {
                  id: option.id,
                  branches: option?.branches,
                  cname: option?.name,
                };
              }}
              placeholder={"Select your company"}
              styles={{
                styles1,
                placeholder: (base)=>({
                  ...base,
                  fontWeight: 'bold',
                  color:'#9CA3AF'
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: "none",
                  boxShadow: "none",
                  flex: "0 0 100%",
                }),
                input: (base, state) => ({
                  ...base,
                  '[type="text"]': {
                    border: "none",
                    boxShadow: "none",
                    outline: "none",
                  },
                }),
              }}
            />
          </div>
        </div>
      </div>
      <div className="block ml-2">
        <button
          title="Add Branch"
          onClick={() => (companyInfo ? setPage(2) : "")}
          className={`w-[42px] h-[42px] rounded-full ${
            !companyInfo ? "opacity-50 pointer-events-none" : ""
          } bg-primary text-white flex justify-center items-center focus:ring-2 focus:ring-primary focus:ring-offset-2`}
        >
          <span>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="3"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <title>Next</title>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  );
}

export default BranchInfo;
