import React from "react";
import Files from "react-files";
import Select from "react-select";
import { directus } from "../../services/directus";
import { BACKEND_URL } from "../../utils/utils";
import { Link } from "react-router-dom";
import { getAssetURL } from "../../utils/get-asset-url";

function CompanyInfo(props) {
  const { info, setInfo, validationError } = props;
  const [logoSize, setLogoSize] = React.useState("");
  const [docs2Size, setDocs2Size] = React.useState("");
  const [tagOptions, setTagOptions] = React.useState("");
  const [stateOptions, setStateOptions] = React.useState("");
  const [cityOptions, setCityOptions] = React.useState("");
  function updateFields(field, state) {
    setInfo((prevState) => ({
      ...prevState,
      [field]: state,
    }));
  }
  const logoChangedHandler = (event) => {
    let file_size = event[0]?.size;
    if (file_size / 1000000 < 2) {
      setLogoSize("");
      updateFields("logo", event[0]);
    } else {
      setLogoSize("* Please upload the attachment image less than 2MB.");
    }
  };
  const doc1ChangedHandler = (event) => {
    let file_size = event[0]?.size;
    if (file_size / 1000000 < 2) {
      setDocs2Size("");
      updateFields("docs1", event[0]);
    } else {
      setDocs2Size(
        "* Please upload the attachment less than 2MB. / img/*(except .gif)  .doc, .pdf, .docx"
      );
    }
  };
  const doc2ChangedHandler = (event) => {
    let file_size = event[0]?.size;
    if (file_size / 1000000 < 2) {
      setDocs2Size("");
      updateFields("docs2", event[0]);
    } else {
      setDocs2Size(
        "* Please upload the attachment less than 2MB. / img/*(except .gif)  .doc, .pdf, .docx"
      );
    }
  };
  const preview = (props) => {
    return (
      props?.value && (
        <div className="files-list">
          <ul>
            <li
              key={props?.value?.id}
              className="flex flex-row justify-evenly text-center place-items-center"
            >
              <div>
                <div className="">
                  {(props?.value?.preview?.type === "image" || (!props?.value?.filename_download && props?.value !== undefined && props?.value&&props?.value?.preview?.type !== "file" )) ? (
                    <img
                      className=""
                      src={(props?.value?.filename_download === undefined && props?.value?.extension === undefined && props?.value) ? getAssetURL(props?.value) : props?.value?.preview?.url}
                      alt="Preview"
                    />
                  ) : (
                    <div className="">{props?.value?.extension || props?.value?.filename_download}</div>
                  )}
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900 min-w-[150px] ">
                    {props?.value?.name}
                  </div>
                </div>
              </div>
            </li>
            <div className="flex justify-end items-center gap-2">
            <div
              className="list-item-remove order-2"
              onClick={() => updateFields(props?.set, "")}
            >
              <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                stroke="#ff0000"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="css-i6dzq1"
              >
                <title>Next</title>
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            </div>
            {props?.value?.filename_download && <Link to={`${BACKEND_URL}/assets/${props?.value?.id}?download`}> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
            </svg>
            </Link>}
            </div>
          </ul>
        </div>
      )
    );
  };
  const styles1 = {
    menuList: (base) => ({
      ...base,
      height: "110px",

      "::-webkit-scrollbar": {
        width: "6px",
      },
      "::-webkit-scrollbar-track": {
        background: "#2684FF",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    control: (base, state) => ({
      ...base,
      cursor: "pointer",
      border: "none",
      marginTop: "4px",
    }),
  };

  React.useEffect(() => {
    TagCategoryCall();
    StatesCall();
    info?.State?.id && CityCall(info?.State?.id)
  }, [info?.State?.id]);
  const TagCategoryCall = async () => {
    await directus
      .items("Tags_Category")
      .readByQuery({
        fields: ["*"],
      })
      .then((res) => {
        const tagOptionsRes = res?.data?.map(e => { return { ...e, Tags_Category_id: e?.id } })
        setTagOptions(tagOptionsRes);
        return "";
      })
      .catch((e) => console.log(e));
  };
  const StatesCall = async () => {
    await directus
      .items("States")
      .readByQuery({
        fields: ["*"],
      })
      .then((res) => {
        setStateOptions(res?.data);
        return "";
      })
      .catch((e) => console.log(e));
  };
  const CityCall = async (state_id) => {
    await directus
      .items("Cities")
      .readByQuery({
        fields: ["*"],
        filter: {
          state_id: { _eq: state_id },
        }
      })
      .then((res) => {
        setCityOptions(res?.data);
        return "";
      })
      .catch((e) => console.log(e));
  };

  const selectedTags =
    tagOptions &&
    tagOptions?.filter((cv) => {
      const val = !info?.Tags.find((e) => {
        return e.Tags_Category_id === cv.id;
      });
      return val === true ? null : cv
    });

  const selectedState =
    stateOptions &&
    stateOptions?.filter((cv) => {
      return info?.State?.id === cv.id;
    });
  const selectedCity =
    cityOptions &&
    cityOptions?.filter((cv) => {
      return info?.City?.id === cv.id;
    });
  return (
    <div className="space-y-6 bg-white py-6 onboard-form-wrapper" >
      <div className="flex flex-col justify-start items-start md:flex-row gap-2">
        <div className="text-sm font-medium text-gray-900 min-w-[250px]">
          Type of Company *
        </div>
        <div className="block w-full mt-2 md:mt-0">
          <div className="flex items-center mb-4">
            <input
              id="default-radio-1"
              type="radio"
              name="default-radio"
              checked={
                info?.typeOfCompany === "single_organization" ? true : false
              }
              disabled={props?.allFieldEdit === false ? true : false}
              value={"single_organization"}
              onChange={(e) => updateFields("typeOfCompany", e.target.value)}
              className={`${validationError?.typeOfCompanyError &&
                info?.typeOfCompany?.length < 1
                ? "border-red-300 focus:border-red-300"
                : "border-gray-300 focus:border-primary"
                } w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2`}
            />
            <label
              htmlFor="default-radio-1"
              className="ml-2 text-sm font-medium text-gray-900 "
            >
              {props?.type === "company"
                ? " Organization"
                : "Same Organization"}
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="franchise"
              type="radio"
              name="default-radio"
              value={"franchise"}
              disabled={props?.allFieldEdit === false ? true : false}
              checked={info?.typeOfCompany === "franchise" ? true : false}
              onChange={(e) => updateFields("typeOfCompany", e.target.value)}
              className={`${validationError?.typeOfCompanyError &&
                info?.typeOfCompany?.length < 1
                ? "border-red-300 focus:border-red-300"
                : "border-gray-300 focus:border-primary"
                } w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2`}
            />
            <label
              htmlFor="franchise"
              className="ml-2 text-sm font-medium text-gray-900 "
            >
              Franchise
            </label>
          </div>
          {validationError?.typeOfCompanyError &&
            info?.typeOfCompany?.length < 1 && (
              <p className="text-xs text-right text-red-500 error-msg mt-0">
                *Type of Company is required
              </p>
            )}
        </div>
      </div>
      <div className="flex flex-col justify-start items-start md:flex-row gap-2">
        <label
          htmlFor="company-name-1"
          className="text-sm font-medium text-gray-900 min-w-[250px] "
        >
          Company Name {(props?.edit !== true && props?.mainFieldDisable !== true) ? "*" : ""}
        </label>
        <input
          type="text"
          name="user-name"
          id="company-name-1"
          // required
          disabled={(props?.edit === true || props?.mainFieldDisable === true) ? true : false}
          value={info?.companyName || ""}
          onChange={(e) => updateFields("companyName", e.target.value)}
          autoComplete="given-name"
          className={`${validationError?.companyNameError &&
            info?.companyName?.trim()?.length < 1
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } pl-4 py-2 pr-9 block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
        />
      </div>
      {validationError?.companyNameError &&
        info?.companyName?.trim()?.length < 1 && (
          <p className="text-xs text-right text-red-500 error-msg mt-0">
            *Company Name is required
          </p>
        )}
      <div className="flex flex-col justify-start items-start md:flex-row gap-2">
        <label
          htmlFor="company-cin-1"
          className="text-sm font-medium text-gray-900 min-w-[250px] "
        >
          CIN / Other government certification name {props?.edit !== true ? "*" : ""}
        </label>
        <input
          type="text"
          name="user-name"
          id="company-cin-1"
          // required
          disabled={(props?.allFieldEdit === false) ? true : false}
          value={info?.cinNumber || ""}
          onChange={(e) => e.target.value.length <= 30 && updateFields("cinNumber", e.target.value)}
          autoComplete="given-name"
          className={`${(validationError?.cinNumberError &&
            info?.cinNumber?.trim()?.length < 1) ||
            (validationError?.cinNumberFormatError &&
              (info?.cinNumber?.length >= 1 && !/^[a-zA-Z0-9]{4,30}$/gm.test(
                info?.cinNumber?.trim()
              )))
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } pl-4 py-2 pr-9 block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
        />
      </div>
      {validationError?.cinNumberError &&
        info?.cinNumber?.trim()?.length < 1 && (
          <p className="text-xs text-right text-red-500 error-msg mt-0">
            *CIN is required
          </p>
        )}
      {(validationError?.cinNumberFormatError &&
        (info?.cinNumber?.length >= 1 && !/^[a-zA-Z0-9]{4,30}$/gm.test(
          info?.cinNumber?.trim()
        ))) && (
          <p className="text-xs text-right text-red-500 mt-2">
            *Enter a valid CIN
          </p>
        )}
      <div className="flex flex-col justify-start items-start md:flex-row gap-2">
        <label
          htmlFor="company-doc-1"
          className="text-sm font-medium text-gray-900 min-w-[250px] "
        >
          Upload document(s) to verify  {/* your {props?.type} */}
        </label>
      </div>
      <div className="flex flex-col justify-start items-start md:flex-row gap-2">
        <label
          htmlFor="company-doc-1"
          className="text-sm font-medium text-gray-900 min-w-[250px] "
        >
          GST Certificate *
        </label>
        <div className={`block w-full ${props?.allFieldEdit === false ? " pointer-events-none" : ''}`}>
          <Files
            className={`${validationError?.docs1Error &&
              info?.docs1?.length < 1 &&
              validationError?.docs2Error &&
              info?.docs2?.length < 1
              ? "border-red-300 focus:border-red-300"
              : "border-gray-300 focus:border-primary"
              } pl-4 py-1.5 pr-9 w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 md:mt-0 h-10 flex items-center font-bold text-[#bbb] text-[14px]`}
            onChange={(e) => doc1ChangedHandler(e)}
            // onError={console.log("first")}
            accepts={["image/*", ".pdf", ".doc", ".docx"]}
            multiple={false}
            maxFileSize={2 * 1024 * 1024}
            minFileSize={0}
            clickable
          >
            Drop files here or click to upload
          </Files>
          {info?.docs1 && preview({ value: info?.docs1, set: "docs1" })}
        </div>
      </div>
      <div className="w-50 bg-white block text-center or-text or-text-left">
        <div className="bg-primary flex items-center translate-x-[150px] max-md:translate-x-0 mx-auto rounded-[50%] border border-4 border-white justify-center text-white text-[20px] w-[60px] h-[60px] font-bold relative z-[1]">
          Or
        </div>
      </div>
      <div className="flex flex-col justify-start items-start md:flex-row gap-2">
        <label
          htmlFor="company-doc-2"
          className="text-sm font-medium text-gray-900 min-w-[250px] "
        >
          Company Registration Certificate *
        </label>
        <div className={`block w-full ${props?.allFieldEdit === false ? " pointer-events-none" : ''}`}>
          <Files
            className={`${validationError?.docs1Error &&
              info?.docs1?.length < 1 &&
              validationError?.docs2Error &&
              info?.docs2?.length < 1
              ? "border-red-300 focus:border-red-300"
              : "border-gray-300 focus:border-primary"
              } pl-4 py-1.5 pr-9 w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 h-10 flex items-center font-bold text-[#bbb] text-[14px]`}
            onChange={(e) => doc2ChangedHandler(e)}
            accepts={["image/*", ".pdf", ".doc", ".docx"]}
            multiple={false}
            maxFileSize={2 * 1024 * 1024}
            minFileSize={0}
            clickable
          >
            Drop files here or click to upload
          </Files>
          {info?.docs2 && preview({ value: info?.docs2, set: "docs2" })}
          {validationError?.docs1Error &&
            validationError?.docs2Error &&
            info?.docs1?.length < 1 &&
            info?.docs2?.length < 1 &&
            docs2Size?.trim()?.length === 0 && (
              <p className="text-xs text-right text-red-500 error-msg mt-0">
                *GST certificate or company registration certificate is required
              </p>
            )}
          {docs2Size?.trim()?.length > 1 && (
            <p className="text-xs text-right text-red-500 error-msg mt-0">
              {docs2Size?.trim()}
            </p>
          )}
        </div>
      </div>
      {props?.logo !== false && (
        <div className="flex flex-col justify-start items-start md:flex-row gap-2">
          <label
            htmlFor="company-logo-1"
            className="text-sm font-medium text-gray-900 min-w-[250px] "
          >
            Company Logo
          </label>
          <div className={`block w-full ${props?.allFieldEdit === false ? " pointer-events-none" : ''}`}>
            <Files
              className={`${validationError?.logoError && info?.logo?.length < 1
                ? "border-red-300 focus:border-red-300"
                : "border-gray-300 focus:border-primary"
                } pl-4 py-1.5 pr-9 w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 md:mt-0 h-10 flex items-center font-bold text-[#bbb] text-[14px]`}
              onChange={(e) => logoChangedHandler(e)}
              // onError={console.log("first")}
              accepts={["image/*"]}
              multiple={false}
              maxFileSize={2 * 1024 * 1024}
              minFileSize={0}
              clickable
            >
              Drop files here or click to upload
            </Files>
            {info?.logo && preview({ value: info?.logo, set: "logo" })}
          </div>
        </div>
      )}
      {/* {validationError?.logoError &&
        info?.logo?.length < 1 &&
        logoSize?.trim()?.length === 0 && (
          <p className="text-xs text-right text-red-500 error-msg mt-0">
            *Company Logo is required
          </p>
        )} */}
      {logoSize?.trim()?.length > 1 && (
        <p className="text-xs text-right text-red-500 error-msg mt-0">
          {logoSize?.trim()}
        </p>
      )}
      <div className="flex flex-col justify-start items-start md:flex-row gap-2">
        <label
          htmlFor="company-location-1"
          className="text-sm font-medium text-gray-900 min-w-[250px] "
        >
          Branch Location (Branch Name){props?.mainFieldDisable !== true ? " *" : ""}
        </label>
        <input
          type="text"
          name="user-name"
          id="company-location-1"
          // required
          value={info?.location || ""}
          disabled={props?.mainFieldDisable}
          onChange={(e) => updateFields("location", e.target.value)}
          autoComplete="given-name"
          className={`${validationError?.locationError && info?.location?.trim()?.length < 1
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } pl-4 py-2 pr-9 block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary h-10 focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
        />
      </div>
      {validationError?.locationError && info?.location?.trim()?.length < 1 && (
        <p className="text-xs text-right text-red-500 error-msg mt-0">
          *Branch Location is required
        </p>
      )}

      <div className="flex flex-col justify-start items-start md:flex-row gap-2">
        <label
          htmlFor="company-address-1"
          className="text-sm font-medium text-gray-900 min-w-[250px] "
        >
          Branch Address *
        </label>
        <textarea
          name="user-name"
          id="company-address-1"
          // required
          disabled={props?.allFieldEdit === false ? true : false}
          value={info?.Address}
          onChange={(e) => updateFields("Address", e.target.value)}
          className={`${validationError?.AddressError && info?.Address?.trim()?.length < 1
            ? "border-red-300 focus:border-red-300"
            : "border-gray-300 focus:border-primary"
            } pl-4 py-2 pr-9 block w-full rounded-md border text-sm shadow-sm border-gray-300 focus:border-primary  focus:outline-none focus:ring-1 focus:ring-primary placeholder:text-gray-300 mt-2 md:mt-0`}
        ></textarea>
      </div>
      {validationError?.AddressError && info?.Address?.trim()?.length < 1 && (
        <p className="text-xs text-right text-red-500 error-msg mt-0">
          *Branch Address is required
        </p>
      )}

      <div className={`${props?.allFieldEdit === false ? " pointer-events-none" : ''} flex flex-col justify-start items-start md:flex-row gap-2 relative`}>
        <label
          htmlFor="company-tag-1"
          className="text-sm font-medium text-gray-900 min-w-[250px]"
        >
          State or Union territory*
        </label>
        <div className="relative w-full">
          <Select
            id="state"
            classNamePrefix="react-select"
            className=" react-select-container  border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] h-10 items-center text-[14px] capitalize"
            defaultMenuIsOpen={false}
            maxMenuHeight={250}
            onChange={(e) => {
              updateFields(
                "State",
                e
              )
              updateFields(
                "City",
                "")
              CityCall(e?.id)
            }
            }
            options={info?.State?.length > 0 ?
              selectedState
              : stateOptions}
            isMulti={false}
            hideSelectedOptions={true}
            value={info?.State?.id ? info?.State : ""}
            getOptionLabel={(option) => option.state_name}
            getOptionValue={(option) => option}
            placeholder={"Select your State or Union territory"}
            theme={(theme) => ({
              ...theme,
              colors: {
                neutral0: "white",
                neutral50: "#9CA3AF",
              },
            })}
            styles={{
              styles1,
              placeholder: (base) => ({
                ...base,
                fontWeight: "bold",
                color: "#9CA3AF",
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                boxShadow: "#8765E2",
                borderColor: "#D1D5DB",
              }),
              input: (base, state) => ({
                ...base,
                '[type="text"]': {
                  border: "none",
                  boxShadow: "none",
                  outline: "none",
                },
              }),
            }}
          />
          {info?.State?.id && (
            <div
              className="w-full absolute right-20 close-icon"
              onClick={() => {
                updateFields("State", "")
                updateFields("City", "")
              }}
            >
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-tj5bde-Svg"
              >
                <title>Clear</title>
                <path
                  d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                  fill="#000"
                ></path>
              </svg>
            </div>
          )}
        </div>
      </div>
      {validationError?.StateError && info?.State?.length < 1 && (
        <p className="text-xs text-right text-red-500 error-msg mt-0">
          *State or Union territory is required
        </p>
      )}
      {info?.State?.id &&
        <>
          <div className="flex flex-col justify-start items-start md:flex-row gap-2 relative">
            <label
              htmlFor="company-tag-1"
              className="text-sm font-medium text-gray-900 min-w-[250px]"
            >
              City or Town *
            </label>
            <div className={`relative w-full  ${props?.allFieldEdit === false ? " pointer-events-none" : ''}`}>
              <Select
                id="city"
                classNamePrefix="react-select"
                className=" react-select-container  border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] h-10 items-center text-[14px] capitalize"
                defaultMenuIsOpen={false}
                maxMenuHeight={250}
                onChange={(e) => {
                  updateFields("City", e)
                }
                }
                options={info?.City?.length > 0 ? selectedCity : cityOptions}
                isMulti={false}
                hideSelectedOptions={true}
                value={info?.City?.id ? info?.City : ""}
                getOptionLabel={(option) => option?.city_name}
                getOptionValue={(option) => option}
                placeholder={"Select your City or Town"}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    neutral0: "white",
                    neutral50: "#9CA3AF",
                  },
                })}
                styles={{
                  styles1,
                  placeholder: (base) => ({
                    ...base,
                    fontWeight: "bold",
                    color: "#9CA3AF",
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "#8765E2",
                    borderColor: "#D1D5DB",
                  }),
                  input: (base, state) => ({
                    ...base,
                    '[type="text"]': {
                      border: "none",
                      boxShadow: "none",
                      outline: "none",
                    },
                  }),
                }}
              />
              {info?.City?.id && (
                <div
                  className="w-full absolute right-20 close-icon"
                  onClick={() => {
                    updateFields(
                      "City",
                      "")
                  }}
                >
                  <svg
                    height="20"
                    width="20"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    focusable="false"
                    className="css-tj5bde-Svg"
                  >
                    <title>Clear</title>
                    <path
                      d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                      fill="#000"
                    ></path>
                  </svg>
                </div>
              )}
            </div>
          </div>
          {validationError?.CityError && info?.City?.length < 1 && (
            <p className="text-xs text-right text-red-500 error-msg mt-0">
              *City or Town is required
            </p>
          )}
        </>
      }
      <div className={`flex flex-col justify-start items-start md:flex-row gap-2 ${props?.allFieldEdit === false ? " pointer-events-none" : ''}`}>
        <label
          htmlFor="company-tag-1"
          className="text-sm font-medium text-gray-900 min-w-[250px]"
        >
          Tags (Categories) *
        </label>
        <Select
          id="tags"
          classNamePrefix="react-select"
          className=" react-select-container  border-gray-500 border-0 text-gray-900 text-sm w-full min-h-[42px] h-10 items-center text-[14px] capitalize"
          defaultMenuIsOpen={false}
          maxMenuHeight={250}
          onChange={(e) => {
            updateFields(
              "Tags",
              e?.map((e) => e)
            )
          }
          }
          options={tagOptions}
          isMulti={true}
          hideSelectedOptions={true}
          value={info?.Tags?.length > 0 ? selectedTags : ""}
          getOptionLabel={(option) => {
            return option.Name
          }}
          getOptionValue={(option) => option}
          placeholder={"Select your tags"}
          theme={(theme) => ({
            ...theme,
            colors: {
              neutral0: "white",
              neutral50: "#9CA3AF",
            },
          })}
          styles={{
            styles1,
            placeholder: (base) => ({
              ...base,
              fontWeight: "bold",
              color: "#9CA3AF",
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              boxShadow: "#8765E2",
              borderColor: "#D1D5DB",
            }),
            input: (base, state) => ({
              ...base,
              '[type="text"]': {
                border: "none",
                boxShadow: "none",
                outline: "none",
              },
            }),
          }}
        />
      </div>
      {validationError?.TagsError && info?.Tags?.length < 1 && (
        <p className="text-xs text-right text-red-500 error-msg mt-0">
          *Tags is required
        </p>
      )}
    </div>
  );
}

export default CompanyInfo;
