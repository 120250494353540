import React, { Suspense, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import {
  PartnerWithUs,
  Login,
  Edit,
  ValetList,
  Create,
  Detail,
  StaffEdit,
  StaffCreate,
  Register,
  CustomerLogin,
  CustomerPayment,
  VehicleStatus,
  MasterVehicleSearch,
  Attendances,
  CompanyRegistration,
  BranchRegistration,
  Success,
  ForgetPassword,
  TermAndCondtions,
  BranchRedirect,
  AboutUS,
  PrivacyPolicy,
  CookiePolicy,
  RefundPolicy,
  UserCreate,
  ManageUsers,
  UserEdit,
  ManageStaffUsers,
  ScanQr,
  Analytics,
  EditCompanyInfo,
  BusinessAffiliation,
  Instruction,
  SupportRequest,
  EditValetNum,
  EditValetFee,
  OffersCreate,
  OffersManage,
  OffersEdit,
  OffersAndPromotion,
  Payments,
  ApplicationCorrection,
  OnBoardingForm
} from "..";
const NotFoundPage = React?.lazy(() => import("../NotFoundPage"));
function AllRoutes({ AdminUser, Manager, auth, IsPublished }) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const PrivateRoute = (props) => {
    const path = location?.pathname?.split("/");
    return (
      !loading && (
        <Navigate
          to={`/${path[1]}/${path[2]}/login`}
          replace
          state={{ redirectTo: location?.pathname, access: props?.access, companyName: path[1], branchName: path[2] }}
        />
      )
    );
  };

  React.useEffect(() => {
    if (auth !== null) {
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [auth]);

  const adminRoutes = [
    {
      path: "/:companyName/:branchName/users/manage",
      element: <ManageUsers />,
    },
    { path: "/:companyName/:branchName/users/edit", element: <UserEdit /> },
    { path: "/:companyName/:branchName/users/create", element: <UserCreate /> },
    { path: "/:companyName/:branchName/payments", element: <Payments /> },
  ];
  const adminAndManager = [
    {
      path: "/:companyName/:branchName/staff/manage",
      element: <ManageStaffUsers />,
    },
    { path: "/:companyName/:branchName/staff/edit", element: <StaffEdit /> },
    {
      path: "/:companyName/:branchName/staff/create",
      element: <StaffCreate />,
    },
    {
      path: "/:companyName/:branchName/staff-in-out-status-board",
      element: <Attendances />,
    },
    {
      path: "/:companyName/:branchName/vehicle-search",
      element: <MasterVehicleSearch />,
    },
    { path: "/:companyName/:branchName/offers/create", element: <OffersCreate /> },
    { path: "/:companyName/:branchName/offers/edit", element: <OffersEdit /> },
    { path: "/:companyName/:branchName/offers/manage", element: <OffersManage /> },
    { path:"/:companyName/:branchName/analytics", element: <Analytics /> },
    { path:"/:companyName/:branchName/edit-company-info", element: <EditCompanyInfo /> },
 
  ];
  const privateRoutepaths = [
    { path: "/:companyName/:branchName/dashboard", element: <ValetList /> },
    { path: "/:companyName/:branchName/valet-create", element: <Create /> },
    { path: "/:companyName/:branchName/valet-detail", element: <Detail /> },
    { path: "/:companyName/:branchName/valet-edit", element: <Edit /> },
    { path: "/:companyName/:branchName/scan-your-qrcode", element: <ScanQr /> },
    { path: "/:companyName/:branchName/edit-valet-number", element: <EditValetNum /> },
    { path: "/:companyName/:branchName/edit-valet-fee", element: <EditValetFee /> },
  ];
  const privateRegistrationRoutes = [
    { path: "/:companyName/:branchName/application-correction", element: <ApplicationCorrection /> },
    { path: "/:companyName/:branchName/onboarding-form", element: <OnBoardingForm /> },
  ];
  const pulicRoutes = [
    { path: "/", element: <BranchRedirect /> },
    {
      path: "/:companyName/:branchName/customer/register",
      element: <Register />,
    },
    {
      path: "/:companyName/:branchName/customer/login",
      element: <CustomerLogin />,
    },
    {
      path: "/:companyName/:branchName/customer/payment",
      element: <CustomerPayment />,
    },
    {
      path: "/:companyName/:branchName/customer/status",
      element: <VehicleStatus />,
    },
    { path: "/:companyName/:branchName/login", element: <Login /> },
    {
      path: "/:companyName/:branchName/reset-password",
      element: <ForgetPassword />,
    },
    { path: "/partner-with-us", element: <PartnerWithUs /> },
    { path: "/company-register", element: <CompanyRegistration /> },
    { path: "/branch-register", element: <BranchRegistration /> },
    { path: "/success-registration", element: <Success /> },
    { path: "/:user/terms-of-service", element: <TermAndCondtions /> },
    { path: "/about-us", element: <AboutUS /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/cookie-policy", element: <CookiePolicy /> },
    { path: "/refund-policy", element: <RefundPolicy /> },
    { path: "/contact-us-for-business-affiliation", element: <BusinessAffiliation /> },
    { path: "/suggestions", element: <SupportRequest /> },
    { path: "/how-to-use", element: <Instruction /> },
    {
      path: "/:companyName/:branchName/offers&promotions",
      element: <OffersAndPromotion />,
    },
  ];
  return (
    <Routes>

      {privateRegistrationRoutes?.map((e, i) => (
        <Route
          key={i}
          exact
          path={e?.path}
          element={
            auth ? (
              AdminUser ? (
                e?.element
              ) : (
                <NotFoundPage />
              )
            ) : (
              <PrivateRoute access={"admin"} />
            )
          }
        />
      ))}
      {privateRoutepaths?.map((e, i) => (
        <Route
          key={i}
          exact
          path={e?.path}
          element={!IsPublished ? <NotFoundPage /> : auth ? e?.element : <PrivateRoute access={"staff"} />}
        />
      ))}
      {adminRoutes?.map((e, i) => (
        <Route
          key={i}
          exact
          path={e?.path}
          element={
            auth ? (
              IsPublished && AdminUser ? (
                e?.element
              ) : (
                <NotFoundPage />
              )
            ) : (
              <PrivateRoute access={"admin"} />
            )
          }
        />
      ))}
      {adminAndManager?.map((e, i) => (
        <Route
          key={i}
          exact
          path={e?.path}
          element={
            auth ? (
              !(
                AdminUser ||
                Manager
              ) && !IsPublished ? (
                <NotFoundPage />
              ) : (
                e?.element
              )
            ) : (
              <PrivateRoute access={"manager"} />
            )
          }
        />
      ))}
      {pulicRoutes?.map((e, i) => (
        <Route key={i} exact path={e?.path} element={e?.element} />
      ))}
      <Route
        path="/*"
        element={
          <Suspense fallback={<div></div>}>
            <NotFoundPage />
          </Suspense>
        }
      />
    </Routes>
  );
}
export default AllRoutes;
