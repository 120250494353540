/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LayoutComponent from "../../components/LayoutComponent";
import LoadingSpinner from "../../components/LoadingSpinner";
import Seo from "../../components/Seo";
import UserContext from "../../contexts/userContext";
import { directus } from "../../services/directus";
import moment from "moment";
function Analytics() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [today, setToday] = React.useState("");
  const [yesterday, setYesterday] = React.useState("");
  const [countAndInitialDate, setCountAndInitialDate] = React.useState("");
  const [week, setWeek] = React.useState("");
  const { details, AuthCheck } = React.useContext(UserContext);
  const branch = details?.default_branch?.id;
  const company = details?.default_company?.id;

  React.useEffect(() => {
    AuthCheck();
    const body = {
      branch: { _eq: branch },
      company: { _eq: company },
      status_id: { sequence: { _eq: 6 } },
    };
    const CountAndDeliveryCall = async () => {
      await directus
        .items("Valet_Information")
        .readByQuery({
          fields: ["*"],
          filter: body,
          sort: ["date_updated"],
          meta: ["filter_count"],
        })
        .then((res) => {
          setCountAndInitialDate({ count: res?.meta?.filter_count, date: res?.data?.[0]?.date_updated })
        }).catch(e => console.log(e))
    }
    const DataCall = async ({ type }) => {

      const startDate = moment(new Date())
        ?.subtract(type === "today" ? 0 : type === "yesterday" ? 1 : 7, "days")
        ?.startOf("day")
        ?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"); //?.add(330, 'minutes')
      const EndDate = moment(new Date())
        ?.subtract(type === "yesterday" ? 1 : 0, "days")
        ?.endOf("day")
        ?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"); //?.add(330, 'minutes')
      await directus
        .items("Valet_Information")
        .readByQuery({
          fields: ["*"],
          filter: {
            ...body,
            ...{
              _and: [
                {
                  date_updated: {
                    _gte: startDate,
                  },
                },
                {
                  date_updated: {
                    _lte: EndDate,
                  },
                },
              ],
            },
          },
          meta: ["filter_count"],
        })
        .then((res) => {
          type === "today"
            ? setToday(res?.meta?.filter_count)
            : type === "yesterday"
              ? setYesterday(res?.meta?.filter_count)
              : setWeek(res?.meta?.filter_count);
        })
        .catch((e) => console.log(e));
      setIsLoading(false);
    };
    DataCall({ type: "today" });
    DataCall({ type: "yesterday" });
    DataCall({ type: "week" });
    CountAndDeliveryCall()
    const handleBackPress = () => {
      window.location.reload();
    };
    window.onpopstate = handleBackPress;
  }, [branch, company]);
  return (
    <LayoutComponent title={"Analytics"} fixedFooter={true} center={true}>
      <Seo title={"Analytics"} company={true} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="main-content-area px-6 max-sm:px-5 pt-0">
          <div className="py-4">
            <div className="relative border rounded-md border-gray-200 overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div className="items-center flex flex-col text-black-800 text-xl font-medium capitalize  gap-2 p-4">
                <div className="text-center">
                  <b>Lifetime :</b><span>{" " + countAndInitialDate?.count}{countAndInitialDate?.count > 1 ? " Vehicles " : " Vehicle "}
                    {countAndInitialDate?.date && <span className="block sm:inline-block">(From : {new Date(countAndInitialDate?.date)?.toLocaleDateString()} To : {new Date()?.toLocaleDateString()})</span>}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="py-4 flex flex-col">
            <div
              className={`relative border rounded-md border-gray-200 overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 md:rounded-lg ${today === 0 && yesterday === 0 && week === 0
                ? "bordered"
                : "no-border"
                }`}
            >
              {countAndInitialDate?.count === 0 && today === 0 && yesterday === 0 && week === 0 ? (
                <div className="whitespace-nowrap text-sm p-4 text-gray-500 flex justify-center">
                  No data found
                </div>
              ) : (
                <div className="items-center  flex flex-col text-black-800 text-xl font-medium capitalize  gap-2 p-4">
                  <div>
                    <b>Today :</b><span>{" "}{today}{" "}{today > 1 ? "Vehicles" : "Vehicle"}</span>
                  </div>
                  <div>
                    <b>Yesterday :</b><span>{" "}{yesterday}{" "}{yesterday > 1 ? "Vehicles" : "Vehicle"}</span>
                  </div>
                  <div>
                    <b>Last 7 days :</b><span>{" "}{week}{" "}{week > 1 ? "Vehicles" : "Vehicle"}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </LayoutComponent>
  );
}

export default Analytics;
